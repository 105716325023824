<div class="custom-modal-wrapper">
  <div class="modal-title-custom">
    {{ "GENERALS.HUBSPOT" | translate }}
  </div>

  <div class="modal-body">
    <form [formGroup]="hubspotForm">
      <div class="form-group w-50">
        <label>{{ "LABEL.HUBSPOT_ID" | translate }}</label>
        <input
          type="text"
          name="hubspot_id"
          formControlName="hubspot_id"
          class="form-control"
        />
      </div>
    </form>
  </div>
  <div class="clear"></div>

  <div class="modal-footer-custom">
    <button (click)="cancel()" class="btn gray-bg fl-l">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button (click)="confirm()" class="btn orange-bg fl-r">
      {{ "GENERALS.CONFIRM" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class OrdersService {
  private ordersUrl = "billing/orders";
  private orderUrlProviders = "billing/orders/providers";
  private orderHistory = "history/order";
  public docTypeInvoice = "invoices";

  constructor(private http: HttpClient, private auth: AuthenticationService) {}
  // GET Orders for client
  getOrdersList(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.ordersUrl, {
      params: params,
    });
  }

  // GET Orders for provier
  getProviderOrdersList(params = {}, id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.orderUrlProviders + "/" + id,
      {
        params: params,
      }
    );
  }
  getOrderById(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.ordersUrl + "/" + id
    );
  }

  getOrderByIdentityNumber(params, id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.ordersUrl +
        "/get-order-by-identity-number/" +
        id,
      params
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + "/status-transitions",
      this.auth.setOptions()
    );
  }

  /**
   * Get statuses
   */
  getStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "orders/statuses");
  }

  /**
   * Assign expat
   */
  assignOrderExpat(orderID, orderItemId, expatIDs): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}${this.ordersUrl}/${orderID}/details/${orderItemId}/expats`,
        expatIDs,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Update arriving/delivery date on order for expats
   */

  updateExpatDates(orderID, orderItemId, expatID, date): Observable<any> {
    return this.http
      .patch<any>(
        `${environment.gateway_endpoint}${this.ordersUrl}/${orderID}/details/${orderItemId}expats/${expatID}`,
        date,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateOrderDetails(orderID, orderItemId, params): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}${this.ordersUrl}/${orderID}/details/${orderItemId}`,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  deleteAssignedExpat(orderID, offerID, expatID): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          this.ordersUrl +
          "/" +
          orderID +
          "/details/" +
          offerID +
          "/expats/" +
          expatID
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update Status Bulk
  updateStatusBulk(status_id, order_ids, reason = ""): Observable<any> {
    let body: any = { status_id: status_id, order_ids: order_ids };
    if (reason) {
      body = {
        status_id: status_id,
        order_ids: order_ids,
        declined_reason: reason,
      };
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + this.ordersUrl,
      body,
      httpOptions
    );
  }

  // GET Orders history
  getOrderHistory(id, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.orderHistory + "/" + id,
      {
        params: params,
      }
    );
  }

  /**
   * Export filtered orders to CSV
   * @param params
   */
  exportOrders(params = {}) {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + "/export/orders/",
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  getRatingActivity(providerId: number, params = {}): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}/entities/providers/${providerId}/ratings`,
      { params: params }
    );
  }
}

<!-- details navigation row (left title - right navigation) -->
<div windowScrollAppNav class="details-navigation">
  <!-- navigation -->
  <div class="fl-r">
    <nav mat-tab-nav-bar [disableRipple]="true">
      <a
        mat-tab-link
        [routerLink]="['/commissions/billed']"
        [active]="router.url.indexOf('/commissions/billed') > -1"
      >
        {{ "OFFERS.BILLED" | translate }}
      </a>
      <a
        mat-tab-link
        [routerLink]="['/commissions/list']"
        [active]="router.url.indexOf('/commissions/list') > -1"
      >
        {{ "OFFERS.COMMISSIONS-LIST" | translate }}
      </a>
      <a
        mat-tab-link
        [routerLink]="['/commissions/requests']"
        [active]="router.url.indexOf('/commissions/requests') > -1"
      >
        {{ "OFFERS.REQUESTS" | translate }}
        {{ "(" + pendingRequestsCount + ")" }}
      </a>
    </nav>
  </div>
  <!-- end navigation -->
</div>
<!-- end details navigation row (left title - right navigation) -->
<div class="clear"></div>

<router-outlet (activate)="onActivate($event)"></router-outlet>

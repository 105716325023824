import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import cloneDeep from "lodash-es/cloneDeep";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { ServiceCategory } from "../../../../_models/servicecategory";

@Component({
  selector: "app-add-categories",
  templateUrl: "./add-categories.component.html",
})
export class AddCategoriesComponent implements OnChanges {
  selection = new SelectionModel(true, []);
  readonlySelection = new SelectionModel(true, []);
  categories: ServiceCategory[];

  @Input() level: number;
  @Input() providerId: number;
  @Input() set serviceCategories(value) {
    if (value) {
      this.categories = cloneDeep(value);
    }
  }
  @Input() parentIsSelected = false;

  @Input() set selectedServiceCategories(data) {
    if (data) {
      const value = cloneDeep(data);
      const keys = Object.keys(value);
      keys.forEach((key) => {
        const idx = this.categories.findIndex((f) => f.id === value[key].id);
        if (idx > -1) {
          this.readonlySelection.select(this.categories[idx]);
        }
      });
      this.previouslySelectedCategory = data;
    }
  }
  @Output() onselect: EventEmitter<any> = new EventEmitter();

  isLoadingGeneral: boolean;
  previouslySelectedCategory: ServiceCategory[];

  constructor(
    calendar: NgbCalendar,
    private alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    private service: ProvidersService
  ) {}

  parseSelectedCategory(path) {
    if (path && this.previouslySelectedCategory) {
      const path_segments = path.split("/");

      return this.previouslySelectedCategory[
        path_segments[path_segments.length - 1]
      ]
        ? this.previouslySelectedCategory[
            path_segments[path_segments.length - 1]
          ].children
        : null;
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentIsSelected && changes.parentIsSelected.previousValue) {
      if (changes.parentIsSelected.currentValue) {
        this.categories.forEach((row) => {
          this.selection.select(row);
        });
      } else {
        this.categories.forEach((row) => {
          const idx = this.selection.selected.find((f) => f.id === row.id);
          if (idx) {
            this.handleEvent(row);
          }
        });
        this.selection.clear();
      }
    } else if (
      changes.parentIsSelected &&
      changes.parentIsSelected.currentValue &&
      !changes.parentIsSelected.firstChange
    ) {
      this.categories.forEach((row) => {
        const idx = this.selection.selected.find((f) => f.id === row.id);
        if (!idx) {
          this.handleEvent(row);
        }
        this.selection.select(row);
      });
    }
  }

  changeSelection(item) {
    this.selection.toggle(item);
    this.handleEvent(item);
  }

  handleEvent(item, update = 0) {
    if (!item.children_count) {
      this.onselect.emit({
        selection: item,
        id: item.parent_id,
        update: update,
      });
    }
  }

  trackById(index, item) {
    return item.id;
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClientsService } from "src/app/_services/clients.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";

@Component({
  selector: "app-client-orders",
  templateUrl: "./client-orders.component.html",
  styleUrls: ["./client-orders.component.css"],
})
export class ClientOrdersComponent implements OnInit {
  entity_id;
  user;
  entity_type;

  constructor(
    private route: ActivatedRoute,
    private clientsService: ClientsService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.entity_id = this.route.snapshot.parent.params.id;
  }

  ngOnInit() {
    this.getEntityDetails();
  }

  getEntityDetails() {
    this.clientsService.getClient(this.entity_id).subscribe((data) => {
      this.user = data.result;
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForClient(this.user, "orders")
      );
      const primaryAccount = this.user.users.filter(
        (f) => f.is_primary === true
      )[0];
      this.entity_type = primaryAccount.entity_type;
    });
  }
}

<div class="modal-form" *ngIf="user">
  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.FIRST-NAME" | translate }} *</mat-label>
        <input [(ngModel)]="user.first_name" matInput />
      </mat-form-field>
    </div>

    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.LAST-NAME" | translate }} *</mat-label>
        <input [(ngModel)]="user.last_name" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.EMAIL" | translate }} *</mat-label>
        <input [(ngModel)]="user.email" matInput />
      </mat-form-field>
    </div>

    <div class="w-50">
      <div class="w-50">
        <app-phone-prefix-selector
          [control]="form.get('phone_prefix')"
        ></app-phone-prefix-selector>
      </div>
      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100 p-0">
          <mat-label>{{ "LABEL.PHONE-NUMBER" | translate }} *</mat-label>
          <input
            (keypress)="validateNumericalInput($event)"
            [(ngModel)]="user.phone"
            matInput
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }} *</mat-label>
        <input [(ngModel)]="user.company_position" matInput />
      </mat-form-field>
    </div>

    <div class="w-50" *ngIf="userType === 'client'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.DEPARTMENT" | translate }}</mat-label>
        <mat-select [(ngModel)]="user.department_id">
          <mat-option
            *ngFor="let department of departments"
            [value]="department.id"
          >
            {{ department.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="w-50" *ngIf="userType !== 'expat'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.ROLE" | translate }} *</mat-label>
        <mat-select [(ngModel)]="user.role_id">
          <mat-option *ngFor="let role of roles" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row" *ngIf="!edit && user && userType === 'admin'">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "GENERALS.PASSWORD" | translate }} *</mat-label>
        <input [(ngModel)]="user.password" matInput />
      </mat-form-field>
    </div>
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label
          >{{ "GENERALS.PASSWORD-CONFIRMATION" | translate }} *</mat-label
        >
        <input [(ngModel)]="user.password_confirmation" matInput />
      </mat-form-field>
    </div>
  </div>
</div>

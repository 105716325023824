<div class="left-navigation-wrapper">
  <div class="logo" [routerLink]="['/dashboard']">
    <img src="assets/images/logo.svg" alt="" />
  </div>
  <div class="btn-wrapper">
    <a class="btn orange-bg" target="_blank" href="{{ urlMarketplace }}">{{
      "LEFT-NAVIGATION.GOTOMARKET" | translate
    }}</a>
  </div>
  <div class="left-navigation-elem-wrapper" *ngIf="this.user">
    <ul>
      <!-- Dashboard -->
      <li
        [routerLink]="['/dashboard']"
        [ngClass]="{
          'active-nav': router.url.indexOf('/dashboard') > -1
        }"
        *ngIf="
          this.authService.$hasSecondaryResource(
            resource.REPORTS,
            subResource.FINANCIAL
          )
        "
      >
        <a>
          <i class="icon-dashboard_comp"></i>
          {{ "LEFT-NAVIGATION.NAV1" | translate }}
        </a>
      </li>

      <!-- Admins -->
      <li
        [routerLink]="['/admins']"
        [ngClass]="{ 'active-nav': router.url.indexOf('/admins') > -1 }"
        *ngIf="this.authService.$hasResource(resource.ADMIN)"
      >
        <a>
          <i class="icon-clients_comp"></i>
          {{ "LEFT-NAVIGATION.NAV12" | translate }}
        </a>
      </li>

      <!-- Providers -->
      <li
        [routerLink]="['/providers']"
        [ngClass]="{
          'active-nav': router.url.indexOf('/providers') > -1
        }"
        *ngIf="this.authService.$hasResource(resource.PROVIDER)"
      >
        <a>
          <i class="icon-providers_compound"></i>
          {{ "LEFT-NAVIGATION.NAV2" | translate }}
          <span
            class="side-menu-badge orange-bg"
            *ngIf="
              providersBadge &&
              authService.$can(
                action.MANAGE,
                resource.PROVIDER,
                subResource.DETAILS
              )
            "
            >{{ providersBadge }}</span
          >
        </a>
      </li>

      <!-- Services -->
      <li
        [routerLink]="['/service-offers/list']"
        [ngClass]="{
          'active-nav': router.url.indexOf('/service-offers') > -1
        }"
        *ngIf="this.authService.$hasResource(resource.SERVICE)"
      >
        <a>
          <i class="icon-services-comp"></i>
          {{ "LEFT-NAVIGATION.NAV3" | translate }}
          <span
            class="side-menu-badge orange-bg"
            *ngIf="
              offersBadge &&
              authService.$can(
                action.MANAGE,
                resource.SERVICE,
                subResource.DETAILS
              )
            "
            >{{ offersBadge }}</span
          >
        </a>
      </li>

      <!-- Clients -->
      <li
        [routerLink]="['/clients']"
        [ngClass]="{
          'active-nav': router.url.indexOf('/clients') > -1
        }"
        *ngIf="this.authService.$hasResource(resource.CLIENT)"
      >
        <a>
          <i class="icon-clients_comp"></i>
          {{ "SIDE_MENU.CLIENTS" | translate }}
          <span
            class="side-menu-badge orange-bg"
            *ngIf="
              clientsBadge &&
              authService.$can(
                action.MANAGE,
                resource.CLIENT,
                subResource.DETAILS
              )
            "
            >{{ clientsBadge }}</span
          >
        </a>
      </li>

      <!-- Expats -->
      <li
        [routerLink]="['/expats']"
        [ngClass]="{
          'active-nav':
            router.url.indexOf('/clients') === -1 &&
            router.url.indexOf('/expats') > -1
        }"
        *ngIf="this.authService.$hasResource(resource.EXPAT)"
      >
        <a>
          <i class="icon-clients_comp"></i>
          {{ "LEFT-NAVIGATION.EXPATS" | translate }}
          <span
            class="side-menu-badge orange-bg"
            *ngIf="
              expatsBadge &&
              authService.$can(
                action.MANAGE,
                resource.EXPAT,
                subResource.DETAILS
              )
            "
            >{{ expatsBadge }}</span
          >
        </a>
      </li>

      <!-- Orders -->
      <li
        *ngIf="this.authService.$hasResource(resource.ORDER)"
        [routerLink]="['/orders']"
        [ngClass]="{
          'active-nav':
            router.url.indexOf('/orders') > -1 &&
            router.url.indexOf('/clients') === -1 &&
            router.url.indexOf('/providers') === -1
        }"
      >
        <a>
          <i class="icon-orders_comp"></i>
          {{ "LEFT-NAVIGATION.NAV5" | translate }}
        </a>
      </li>

      <!-- RFQs -->
      <li
        *ngIf="this.authService.$hasResource(resource.RFQ)"
        [routerLink]="['/rfqs-bids']"
        [ngClass]="{
          'active-nav': router.url.indexOf('/rfqs-bids') > -1
        }"
      >
        <a>
          <i class="icon-rfqs"></i>
          {{ "LEFT-NAVIGATION.NAV6" | translate }}
        </a>
      </li>

      <!-- Commissions -->
      <li
        *ngIf="
          this.authService.$hasSecondaryResource(
            resource.CATEGORY,
            subResource.FINANCIAL
          )
        "
        class="has-sub-nav"
        expand-menu
        [ngClass]="{
          'active-nav': router.url.indexOf('/commissions') > -1
        }"
      >
        <a>
          <i class="icon-commissions_comp"></i>
          {{ "LEFT-NAVIGATION.COMMISSIONS" | translate }}
        </a>
      </li>

      <div
        *ngIf="
          this.authService.$hasSecondaryResource(
            resource.CATEGORY,
            subResource.FINANCIAL
          )
        "
        class="sub-navigation open-sub-navigation"
      >
        <ul>
          <!-- Custom Commissions -->
          <li [routerLink]="['/commissions/custom']">
            <a
              [ngClass]="{
                'active-sub-nav': router.url.indexOf('/commissions/custom') > -1
              }"
            >
              {{ "SUB-NAVIGATION.CUSTOM_COMMISSIONS" | translate }}
            </a>
          </li>

          <!-- Approve Commissions -->
          <li [routerLink]="['/commissions/approve']">
            <a
              [ngClass]="{
                'active-sub-nav':
                  router.url.indexOf('/commissions/approve') > -1
              }"
            >
              {{ "SUB-NAVIGATION.APPROVE_COMMISSIONS" | translate }}
              <span
                class="side-menu-badge orange-bg"
                *ngIf="
                  commissionsBadge &&
                  authService.$can(
                    action.MANAGE,
                    resource.CATEGORY,
                    subResource.FINANCIAL
                  )
                "
                >{{ commissionsBadge }}</span
              >
            </a>
          </li>
        </ul>
      </div>

      <!-- Financial-->
      <li
        *ngIf="
          this.authService.$can(
            action.MANAGE,
            resource.ORDER,
            subResource.FINANCIAL
          )
        "
        class="has-sub-nav"
        expand-menu
        [ngClass]="{
          'active-nav': router.url.indexOf('/financial') > -1
        }"
      >
        <a>
          <span class="icon-wrapper"
            ><img src="assets/images/financial.svg" alt=""
          /></span>
          {{ "LEFT-NAVIGATION.FINANCIAL" | translate }}
        </a>
      </li>

      <div
        *ngIf="this.authService.$hasResource(resource.ORDER)"
        class="sub-navigation open-sub-navigation"
      >
        <!-- Invoices -->
        <li [routerLink]="['/financial/invoices']">
          <a
            [ngClass]="{
              'active-sub-nav': router.url.indexOf('/financial/invoices') > -1
            }"
          >
            {{ "SUB-NAVIGATION.INVOICES" | translate }}
          </a>
        </li>

        <!-- Collected Fees -->
        <li
          [routerLink]="['/financial/fees']"
          [ngClass]="{
            'active-sub-nav': router.url.indexOf('/financial/fees') > -1
          }"
        >
          <a
            [ngClass]="{
              'active-sub-nav': router.url.indexOf('/financial/fees') > -1
            }"
          >
            {{ "SUB-NAVIGATION.FEES" | translate }}
          </a>
        </li>

        <!-- Stripe subscribers -->
        <li
          [routerLink]="['/financial/subscribers']"
          [ngClass]="{
            'active-sub-nav': router.url === '/financial/subscribers'
          }"
        >
          <a
            [ngClass]="{
              'active-sub-nav': router.url === '/financial/subscribers'
            }"
          >
            {{ "LEFT-NAVIGATION.SUBSCRIBERS" | translate }}
          </a>
        </li>

        <!-- Stripe plan management -->
        <li
          [routerLink]="['/financial/subscribers/plans']"
          [ngClass]="{
            'active-sub-nav': router.url === '/financial/subscribers/plans'
          }"
        >
          <a
            [ngClass]="{
              'active-sub-nav': router.url === '/financial/subscribers/plans'
            }"
          >
            {{ "LEFT-NAVIGATION.NAV-PLANS" | translate }}
          </a>
        </li>
      </div>

      <div
        *ngIf="
          this.authService.$can(
            action.MANAGE,
            resource.ORDER,
            subResource.FINANCIAL
          )
        "
        class="sub-navigation open-sub-navigation"
      >
        <ul></ul>
      </div>

      <!-- CMS -->
      <li
        class="has-sub-nav"
        expand-menu
        [ngClass]="{ 'active-nav': router.url.indexOf('/cms') > -1 }"
      >
        <a>
          <i class="icon-cms_comp"></i>
          {{ "LEFT-NAVIGATION.NAV10" | translate }}
        </a>
      </li>

      <div class="sub-navigation open-sub-navigation">
        <ul>
          <!-- Categories -->
          <li
            [routerLink]="['/cms/service-categories']"
            *ngIf="this.authService.$hasResource(resource.CATEGORY)"
          >
            <a routerLinkActive="active-sub-nav">
              {{ "SUB-NAVIGATION.CATEGORISATION" | translate }}
            </a>
          </li>

          <!-- Ads -->
          <li [routerLink]="['/cms/ads/pricing']">
            <a routerLinkActive="active-sub-nav">
              {{ "SUB-NAVIGATION.ADS-PRICING" | translate }}
            </a>
          </li>
          <!-- Tax Rates -->
          <li [routerLink]="['/cms/tax-rates']">
            <a routerLinkActive="active-sub-nav">
              {{ "SUB-NAVIGATION.TAX_RATES" | translate }}
            </a>
          </li>
        </ul>
      </div>

      <!-- Help -->
      <li (click)="goToBlog()">
        <a>
          <i class="icon-help_comp"></i>
          {{ "LEFT-NAVIGATION.NAV11" | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>

import { Routes, RouterModule } from "@angular/router";
import { ClientActivityComponent } from "./components/dashboard/clients/client-activity/client-activity.component";
import { ExpatActivityComponent } from "./components/dashboard/expats/expat-activity/expat-activity.component";
import { OrderActivityComponent } from "./components/dashboard/orders/order-activity/order-activity.component";
import { ProviderActivityComponent } from "./components/dashboard/providers/provider-activity/provider-activity.component";
import { OfferActivityComponent } from "./components/dashboard/service-offers/offer-activity/offer-activity.component";
import { AuthLayoutComponent } from "./layout/auth-layout/auth-layout.component";
import { LoginComponent } from "./components/auth/login/login.component";
import { RecoverPasswordComponent } from "./components/auth/recover-password/recover-password.component";
import { SignUpComponent } from "./components/auth/sign-up/sign-up.component";
import { StaticLayoutComponent } from "./layout/static-layout/static-layout.component";
import { TermsComponent } from "./components/static/terms/terms.component";
import { PrivancyComponent } from "./components/static/privancy/privancy.component";
import { DashboardLayoutComponent } from "./layout/dashboard-layout/dashboard-layout.component";
import { DashboardComponent } from "./components/dashboard/dashboard/dashboard.component";
import { AuthGuard } from "./_guards/auth.guard";
import { ProvidersComponent } from "./components/dashboard/providers/index/providers.component";
import { ClientsComponent } from "./components/dashboard/clients/index/clients.component";
import { CmsComponent } from "./components/dashboard/cms/index/cms.component";
import { DocumentationStandardsComponent } from "./components/dashboard/cms/documentation-standards/documentation-standards.component";
import { ResetPasswordComponent } from "./components/auth/reset-password/reset-password.component";
import { ServiceOffersComponent } from "./components/dashboard/service-offers/service-offers.component";
import { ServiceOffersListComponent } from "./components/dashboard/service-offers/service-offers-list/service-offers-list.component";
import { AddServiceOfferComponent } from "./components/dashboard/service-offers/add-service-offer/add-service-offer.component";
import { ServiceOfferDetailsComponent } from "./components/dashboard/service-offers/service-offers-details/service-offers-details.component";
import { OrderDetailsComponent } from "./components/dashboard/orders/order-details/order-details.component";
import { OrderDocumentsComponent } from "./components/dashboard/orders/order-documents/order-documents.component";
import { OrderComponent } from "./components/dashboard/orders/order/order.component";
import { OrdersComponent } from "./components/dashboard/orders/orders.component";
import { ClientComponent } from "./components/dashboard/clients/client/client.component";
import { ProviderComponent } from "./components/dashboard/providers/provider/provider.component";
import { UsersListComponent } from "./components/dashboard/users/users-list/users-list.component";
import { ServiceOfferComponent } from "./components/dashboard/service-offers/service-offer/service-offer.component";
import { ProviderOffersComponent } from "./components/dashboard/providers/provider-offers/provider-offers.component";
import { ExpatsComponent } from "./components/dashboard/expats/expats.component";
import { ProviderUsersComponent } from "./components/dashboard/providers/provider-users/provider-users.component";
import { ClientUsersComponent } from "./components/dashboard/clients/client-users/client-users.component";
import { ClientOrdersComponent } from "./components/dashboard/clients/client-orders/client-orders.component";
import { ProviderOrdersComponent } from "./components/dashboard/providers/provider-orders/provider-orders.component";
import { CategoriesComponent } from "./components/dashboard/cms/categories/categories.component";
import { OrderInvoicesComponent } from "./components/dashboard/orders/order-invoices/order-invoices.component";
import { ClientDocumentsComponent } from "./components/dashboard/clients/client-documents/client-documents.component";
import { ProviderDocumentsComponent } from "./components/dashboard/providers/provider-documents/provider-documents.component";
import { CommissionComponent } from "./components/dashboard/providers/commission/commission.component";
import { ProviderCategoriesComponent } from "./components/dashboard/providers/provider-categories/provider-categories.component";
import { CompanyUserDetailsComponent } from "./components/dashboard/users/company-user-details/company-user-details.component";
import { ExpatComponent } from "./components/dashboard/expats/expat/expat.component";
import { ExpatDetailsComponent } from "./components/dashboard/expats/expat-details/expat-details.component";
import { UserComponent } from "./components/dashboard/users/user/user.component";
import { CommissionsListComponent } from "./components/dashboard/commissions/list/commissions-list.component";
import { CommissionsRequestsComponent } from "./components/dashboard/commissions/requests/commissions-requests.component";
import { CommissionsBilledComponent } from "./components/dashboard/commissions/billed/commissions-billed.component";
import { InvoicesListComponent } from "./components/dashboard/invoices/list/invoices-list.component";
import { InvoicesLogComponent } from "./components/dashboard/invoices/log/invoices-log.component";
import { InvoiceComponent } from "./components/dashboard/invoices/invoice/invoice.component";
import { RFQSComponent } from "./components/RFQS/index/rfqs.component";
import { RFQComponent } from "./components/RFQS/rfq/rfq.component";
import { RFQDetailsComponent } from "./components/RFQS/rfq-details/rfq-details.component";
import { QuotationsComponent } from "./components/RFQS/quotations/quotations.component";
import { RFQHistoryComponent } from "./components/RFQS/rfq-history/rfq-history.component";
import { ExpatDocumentsComponent } from "./components/dashboard/expats/expat-documents/expat-documents.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AddBundleComponent } from "./components/dashboard/service-offers/bundles/add-bundle.component";
import { AssociatedOffersComponent } from "./components/dashboard/service-offers/associated-offers/associated-offers.component";
import { MyAccountComponent } from "./components/dashboard/admins/my-account/my-account.component";
import { AdminsComponent } from "./components/dashboard/admins/admins/admins.component";
import { ReviewsOffersComponent } from "./components/dashboard/service-offers/reviews-offers/reviews-offers.component";
import { AdsComponent } from "./components/dashboard/cms/ads/ads.component";
import { AdsWrapperComponent } from "./components/dashboard/cms/ads-wrapper/ads-wrapper.component";
import { AdCampaignsComponent } from "./components/dashboard/cms/ad-campaigns/ad-campaigns.component";
import { UserAgreementsComponent } from "./components/dashboard/providers/user-agreements/user-agreements.component";
import { PricingComponent } from "./components/dashboard/pricing/pricing/pricing.component";
import { ClientPlansComponent } from "./components/dashboard/pricing/client-plans/client-plans.component";
import { ProviderPlansComponent } from "./components/dashboard/pricing/provider-plans/provider-plans.component";
import { CompanyDetailsComponent } from "./modules/company/company-details/company-details.component";
import { TaxRatesComponent } from "./components/dashboard/cms/tax-rates/tax-rates.component";

const appRoutes: Routes = [
  // redirect
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  { path: "cms", redirectTo: "/cms/service-categories", pathMatch: "full" },
  { path: "cms/ads", redirectTo: "/cms/ads/pricing", pathMatch: "full" },
  {
    path: "service-offers",
    redirectTo: "/service-offers/list",
    pathMatch: "full",
  },

  // auth routes

  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "recover-password",
        component: RecoverPasswordComponent,
      },
      {
        path: "sign-up",
        component: SignUpComponent,
      },
      {
        path: "set-password/:token",
        component: ResetPasswordComponent,
      },
    ],
  },

  // dashboard routes
  {
    path: "",
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      // subscriptions route
      {
        path: "financial/subscribers",
        loadChildren: () =>
          import("./modules/subscriptions/subscriptions.module").then(
            (m) => m.SubscriptionsModule
          ),
      },
      {
        path: "my-account",
        component: MyAccountComponent,
      },
      {
        path: "dashboard-old",
        component: DashboardComponent,
      },
      {
        path: "admins",
        component: AdminsComponent,
      },
      {
        path: "admins/:id",
        component: MyAccountComponent,
      },
      {
        path: "pricing",
        component: PricingComponent,
        children: [
          {
            path: "client",
            component: ClientPlansComponent,
          },
          {
            path: "provider",
            component: ProviderPlansComponent,
          },
        ],
      },
      {
        path: "providers",
        component: ProvidersComponent,
      },
      {
        path: "providers/:id",
        component: ProviderComponent,
        children: [
          {
            path: "",
            component: CompanyDetailsComponent,
            data: { pathName: "Details" },
          },
          {
            path: "users",
            component: ProviderUsersComponent,
          },
          {
            path: "categories",
            component: ProviderCategoriesComponent,
          },
          {
            path: "offers",
            component: ProviderOffersComponent,
          },
          {
            path: "orders",
            component: ProviderOrdersComponent,
          },
          {
            path: "history",
            component: ProviderActivityComponent,
          },
          {
            path: "docs",
            component: UserAgreementsComponent,
          },
          {
            path: "agreements",
            component: ProviderDocumentsComponent,
          },
          {
            path: "commission",
            component: CommissionComponent,
          },
        ],
      },
      {
        path: "commissions",
        children: [
          {
            path: "custom",
            component: CommissionsListComponent,
          },
          {
            path: "approve",
            component: CommissionsRequestsComponent,
          },
        ],
      },
      {
        path: "financial",
        children: [
          {
            path: "invoices",
            component: InvoicesListComponent,
          },
          {
            path: "invoices/:id",
            component: InvoiceComponent,
          },
          {
            path: "fees",
            component: CommissionsBilledComponent,
          },
          {
            path: "history",
            component: InvoicesLogComponent,
          },
        ],
      },
      {
        path: "clients",
        component: ClientsComponent,
      },
      {
        path: "clients/:id",
        component: ClientComponent,
        children: [
          {
            path: "",
            component: CompanyDetailsComponent,
            data: { pathName: "Details" },
          },
          {
            path: "users",
            component: ClientUsersComponent,
          },
          {
            path: "orders",
            component: ClientOrdersComponent,
          },
          {
            path: "history",
            component: ClientActivityComponent,
          },
          {
            path: "docs",
            component: ClientDocumentsComponent,
          },
        ],
      },
      {
        path: "rfqs-bids",
        component: RFQSComponent,
      },
      {
        path: "rfqs-bids/:id",
        component: RFQComponent,
        children: [
          {
            path: "",
            component: RFQDetailsComponent,
            data: { pathName: "Details" },
          },
          {
            path: "quotations",
            component: QuotationsComponent,
          },
          {
            path: "history",
            component: RFQHistoryComponent,
          },
        ],
      },
      {
        path: "service-offers",
        component: ServiceOffersComponent,
        children: [
          {
            path: "list",
            component: ServiceOffersListComponent,
          },
          {
            path: "new",
            component: AddServiceOfferComponent,
          },
          {
            path: "bundle",
            component: AddBundleComponent,
          },
          {
            path: ":id/bundle",
            component: AddBundleComponent,
          },
          {
            path: ":id/edit",
            component: AddServiceOfferComponent,
          },
        ],
      },
      {
        path: "service-offers/:id",
        component: ServiceOfferComponent,
        children: [
          {
            path: "",
            component: ServiceOfferDetailsComponent,
            data: { pathName: "Details" },
          },
          {
            path: "associated-offers",
            component: AssociatedOffersComponent,
          },
          {
            path: "reviews-offers",
            component: ReviewsOffersComponent,
          },
          {
            path: "history",
            component: OfferActivityComponent,
          },
          // {
          //   path: 'history',
          //   component: OfferHistoryComponent
          // },
        ],
      },
      {
        path: "orders",
        component: OrdersComponent,
      },
      {
        path: "orders/:id",
        component: OrderComponent,
        children: [
          {
            path: "",
            component: OrderDetailsComponent,
            data: { pathName: "Details" },
          },
          {
            path: "docs",
            component: OrderDocumentsComponent,
          },
          {
            path: "invoices",
            component: OrderInvoicesComponent,
          },
          {
            path: "history",
            component: OrderActivityComponent,
          },
        ],
      },
      {
        path: "expats",
        component: ExpatsComponent,
      },
      {
        path: "expats/:id",
        component: ExpatComponent,
        children: [
          {
            path: "",
            component: ExpatDetailsComponent,
            data: { pathName: "Details" },
          },
          {
            path: "history",
            component: ExpatActivityComponent,
          },
          {
            path: "docs",
            component: ExpatDocumentsComponent,
          },
        ],
      },
      {
        path: "users",
        component: UsersListComponent,
      },
      {
        path: "users/:id",
        component: UserComponent,
        children: [
          {
            path: "",
            component: CompanyUserDetailsComponent,
            data: { pathName: "Details" },
          },
        ],
      },

      {
        path: "cms",
        component: CmsComponent,
        children: [
          {
            path: "service-categories",
            component: CategoriesComponent,
          },
          {
            path: ":service_category_id/documentation-standards",
            component: DocumentationStandardsComponent,
          },
          {
            path: "ads",
            component: AdsWrapperComponent,
            children: [
              {
                path: "pricing",
                component: AdsComponent,
              },
              {
                path: "ad-campaigns",
                component: AdCampaignsComponent,
              },
            ],
          },
          {
            path: "tax-rates",
            loadChildren: () =>
              import("./modules/tax/tax.module").then((m) => m.TaxModule),
          },
        ],
      },
    ],
  },

  // static routes
  {
    path: "",
    component: StaticLayoutComponent,
    children: [
      {
        path: "terms",
        component: TermsComponent,
      },
      {
        path: "privancy-policy",
        component: PrivancyComponent,
      },
    ],
  },

  // not found
  {
    path: "",
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "not-found",
        component: NotFoundComponent,
      },
      {
        path: "**",
        redirectTo: "/not-found",
      },
    ],
  },
];

export const routing = RouterModule.forRoot(appRoutes, {
  relativeLinkResolution: "legacy",
});

<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="inviteProvider"
    #inviteProvider
    identifier="inviteProvider"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">{{ "GENERALS.INVITE" | translate }}</div>
    </div>

    <div class="clear"></div>
    <div class="custom-modal-body">
      <!-- <div class="modal-body-desc">{{ 'GENERALS.IPSUM' | translate }}</div> -->
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-NAME" | translate }} *</label>
        <input
          type="text"
          name="company_name"
          class="form-control"
          required
          [(ngModel)]="providerInvite.company_name"
        />
      </div>
      <div class="form-group w-50">
        <label>{{ "LABEL.EMAIL" | translate }} *</label>
        <input
          type="text"
          name="invite.email"
          class="form-control"
          required
          [(ngModel)]="providerInvite.email"
        />
      </div>
      <div class="form-group w-100">
        <label>{{ "LABEL.MESSAGE" | translate }} </label>
        <textarea
          type="text"
          name="invite.email"
          class="form-control"
          rows="8"
          required
          [ngStyle]="{ height: '160px' }"
          [(ngModel)]="providerInvite.email_message"
        ></textarea>
      </div>
      <div class="modal-form">
        <app-loader-spinner-general
          [isLoadingGeneral]="isLoadingGeneral"
        ></app-loader-spinner-general>
      </div>
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button type="button" class="btn gray-bg" (click)="cancelInviteModal()">
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button
        type="button"
        class="btn orange-bg"
        (click)="sendProviderInvites()"
      >
        {{ "FORMS.SEND" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->

export enum LoadingTypeEnum {
  INFO = "info",
  BIG_INFO = "big_info",
  PROFILE = "profile",
  TABLE = "table",
  FILTER = "filter",
  CHART = "chart",
  PIE = "pie",
  BUTTONS = "buttons",
  INFO_BUTTONS = "info-buttons",
  TIMELINE = "timeline",
  INPUT = "input",
  CARD = "card",
}

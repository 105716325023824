import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";

import { ActionTypes, UnsafeAction } from "../app.actions";
import { AppState } from "../app.state";

@Injectable()
export class WidgetsEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  @Effect()
  setNotification$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.SET_NOTIFICATION),
    map((action: UnsafeAction) => {
      return {
        type: ActionTypes.SET_NOTIFICATION_SUCCESS,
        payload: action.payload,
      };
    })
  );

  @Effect()
  setMessages$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.SET_MESSAGES),
    map((action: UnsafeAction) => {
      return {
        type: ActionTypes.SET_MESSAGES_SUCCESS,
        payload: action.payload,
      };
    })
  );
}

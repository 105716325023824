import { Injectable } from "@angular/core";
import { Breadcrumb } from "../_models/breadcrumb";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class BreadcrumbService {
  private breadcrumbsSource = new BehaviorSubject<Breadcrumb[] | null>(null);
  breadcrumbsChanges$ = this.breadcrumbsSource.asObservable();

  changeBreadcrumbs(breadcrumbs: Breadcrumb[] | null): void {
    this.breadcrumbsSource.next(breadcrumbs);
  }

  setForOffer(offer, label: string): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: "/service-offers/" + offer.id, label: offer.title },
      { url: null, label: label },
    ];
  }

  setForNewOffer(): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: null, label: "Add service offer" },
    ];
  }

  setForAddBundle(): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: null, label: "Add bundle" },
    ];
  }

  setForEditBundle(offer): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: "/service-offers/" + offer.bundle_id, label: offer.title },
      { url: null, label: "edit bundle" },
    ];
  }

  setForOfferSales(): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: "/service-offers/sales", label: "sales" },
    ];
  }

  setForOpportunities(): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: "/service-offers/sales", label: "opportunities" },
    ];
  }

  setStatic(label: string, url: string): Breadcrumb[] {
    return [{ url: url, label: label }];
  }

  setMyAccount(user): Breadcrumb[] {
    return [
      { url: "/admins", label: "admins" },
      { url: null, label: user.name },
    ];
  }

  setForProvider(provider, label): Breadcrumb[] {
    return [
      { url: "/providers", label: "providers" },
      { url: "/providers/" + provider.id, label: provider.company_name },
      { url: null, label: label },
    ];
  }

  setForOrder(order, label): Breadcrumb[] {
    return [
      { url: "/orders", label: "orders" },
      {
        url: "/orders/" + order.identity_number,
        label:
          "Order no. " + order.group_number + " - " + order.identity_number,
      },
      { url: null, label: label },
    ];
  }

  setForClient(client, label: string): Breadcrumb[] {
    return [
      { url: "/clients", label: "clients" },
      { url: "/clients/" + client.id, label: client.company_name },
      { url: null, label: label },
    ];
  }

  setForUser(user, userRole, entityType, label): Breadcrumb[] {
    return [
      { url: `/${userRole}/`, label: userRole },
      { url: `/${userRole}/${user.entity_id}`, label: entityType },
      { url: null, label: label },
    ];
  }

  setForExpat(expat, label: string): Breadcrumb[] {
    return [
      { url: "/expats", label: "expats" },
      { url: "/expats/" + expat.id, label: expat.user.name },
      { url: null, label: label },
    ];
  }

  setForRFQ(rfq, label): Breadcrumb[] {
    const rfqLabel = rfq.is_bid ? "Bid" : "RFQ";
    return [
      { url: "/rfqs-bids", label: "RFQs & Bids" },
      { url: "/rfqs-bids/" + rfq.id, label: rfqLabel + " #" + rfq.id },
      { url: null, label: label },
    ];
  }

  setForList(url: string, label: string): Breadcrumb[] {
    return [
      { url: url, label: label },
      { url: null, label: "list" },
    ];
  }

  setForSubscriptions(url: string, label: string): Breadcrumb[] {
    return [
      { url: "/financial/subscribers/plans", label: "List" },
      { url: url, label: label },
    ];
  }

  setForCommissions(url, label): Breadcrumb[] {
    return [
      { url: "/commissions/list", label: "commissions" },
      { url: url, label: label },
    ];
  }

  setForInvoice(invoice): Breadcrumb[] {
    return [
      { url: "/financial/invoices", label: "invoices" },
      { url: null, label: "Invoice #" + invoice.id },
      { url: null, label: "View" },
    ];
  }

  setForDocStandards(serviceCategory): Breadcrumb[] {
    return [
      { url: "/cms/service-categories", label: "Service Categories" },
      { url: null, label: serviceCategory.label },
      { url: null, label: "Documentation standards" },
    ];
  }
}

<form class="row" [formGroup]="offerForm">
  <div class="form-group-row mb-3 w-100">
    <label class="characteristic-left">
      {{ "SERVICES.FOR_BUSINESS" | translate }}
    </label>
    <div>
      <mat-slide-toggle formControlName="for_business"></mat-slide-toggle>
    </div>
  </div>
  <div class="form-group-row w-100">
    <label>
      {{ "SERVICES.FOR_INDIVIDUAL" | translate }}
    </label>
    <mat-slide-toggle formControlName="for_individual"></mat-slide-toggle>
  </div>
</form>

import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-details-navigation",
  templateUrl: "./details-navigation.component.html",
  styleUrls: ["./details-navigation.component.css"],
})
export class DetailsNavigationComponent {
  @Input() menu;

  constructor(private router: Router) {}
}

<div class="d-flex loading-wrapper" *ngIf="isLoading">
  <div class="w-profile">
    <app-loader-spinner-general
      [repeat]="3"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      [repeat]="5"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
  </div>
  <div class="w-info">
    <app-loader-spinner-general
      [repeat]="3"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
  </div>
</div>

<div class="details-body-wrapper" *ngIf="!isLoading">
  <!-- 2 col details -->
  <div class="details-2c">
    <!-- left -->
    <div class="details-2c-left m-r-15">
      <div class="small-box">
        <div class="small-box-header">
          <div class="medium-title">
            {{ "GENERALS.ORDER-NO" | translate }}
            {{ order.group_number }} -
            {{ order.identity_number }}
          </div>
          <div
            *ngIf="order.details.length && order.details[0].rfq_id"
            class="m-10-0"
          >
            <a
              class="action-link-orange"
              href="/rfqs-bids/{{ order.details[0].rfq_id }}"
            >
              {{ "RFQS.SEE-RFQ" | translate }}
            </a>
          </div>

          <div class="small-box-status">
            <div class="status-label-{{ order.status_id }}">
              {{ order.status_name }}
            </div>
          </div>
        </div>

        <div class="small-box-details">
          <div class="fw-500" *ngIf="order.declined_reason || ''">
            <span class="gray block-span"
              >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
            >
            {{ order.declined_reason }}
          </div>

          <div class="fw-500">
            <span class="gray block-span"
              >{{ "GENERALS.PLACED-ON" | translate }}:</span
            >
            {{ order.created_at | date : "dd/MM/yyyy" }}
          </div>
          <div class="fw-500">
            <span class="gray block-span"
              >{{ "GENERALS.DUE_BY" | translate }}:</span
            >
            {{ order.max_due_date | date : "dd/MM/yyyy" }}
          </div>
        </div>

        <div class="small-box-details">
          <div class="small-title">
            {{ "GENERALS.FROM" | translate | titlecase }}:
            {{ order.client.company_name }}
          </div>
        </div>

        <div class="small-box-list small-box-star">
          <ul>
            <ng-container *ngFor="let item of rating">
              <li>
                <div>
                  <span class="gray">{{ item.rating_criteria_name }}</span>
                  <app-rating [currentRate]="item.rating"></app-rating>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>

        <div class="small-box-list">
          <div *ngIf="isLoadingStatus">
            <app-loader-spinner-general
              [repeat]="4"
              [loadingType]="LoadingType.BUTTONS"
            ></app-loader-spinner-general>
          </div>
          <ul>
            <ng-container *ngFor="let status of statuses">
              <li>
                <div
                  (click)="openConfirmAction(status)"
                  class="btn-small orange-bg"
                >
                  {{ status.text }}
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right">
      <div
        *ngIf="order.prev_order_id > 0 || order.next_order_id > 0"
        class="small-box-top-action"
      >
        <div class="fl-l">
          <a
            class="action-link-orange"
            *ngIf="order.prev_order_id > 0"
            href="/orders/{{ order.prev_order_id }}"
          >
            <i class="icon-left-open"></i>
            {{ "GENERALS.PREV-ORDER" | translate }}</a
          >
        </div>
        <div class="fl-r">
          <a
            class="action-link-orange"
            *ngIf="order.next_order_id > 0"
            href="/orders/{{ order.next_order_id }}"
          >
            {{ "GENERALS.NEXT-ORDER" | translate }}
            <i class="icon-right-open"></i
          ></a>
        </div>
      </div>
      <div class="white-box-wrapper">
        <div
          class="order-details-box-wrapper"
          *ngFor="let offer of order.details; let i = index"
        >
          <div class="order-details-box-top">
            <div class="order-details-box-left">
              <img
                *ngIf="!offer?.primary_image_path?.length"
                src="assets/images/footer-img.jpg"
                class="pointer"
                alt="img"
                (click)="navigateToMarketplace(offer.offer_id)"
              />

              <img
                *ngIf="offer?.primary_image_path?.length"
                src="{{ offer.primary_image_path }}"
                class="pointer"
                alt="img"
                (click)="navigateToMarketplace(offer.offer_id)"
              />

              <div class="fl-l order-details-left-content">
                <div
                  class="medium-title order-details-box-left-header pointer"
                  (click)="navigateToMarketplace(offer.offer_id)"
                >
                  {{ offer.offer_name }}
                </div>
                <div class="order-details-box-left-body">
                  <div class="info-row-editable">
                    <span class="inner-label">
                      {{ "GENERALS.PRICE-PER-UNIT" | translate }}:
                    </span>

                    <input
                      class="editable-input edit-no-input"
                      *ngIf="editprice"
                      value="{{ offer.unit_price }}"
                      [(ngModel)]="offer.unit_price"
                    />
                    <span *ngIf="!editprice">
                      {{ offer.unit_price | currency : "USD" }}
                    </span>
                    <span class="editable-input-action">
                      <i
                        *ngIf="!editprice && order.status_id === 1"
                        (click)="editprice = !editprice"
                        class="icon-edit"
                      >
                      </i>
                      <i
                        *ngIf="editprice"
                        (click)="
                          editprice = !editprice; updateOrderDetails(offer)
                        "
                        class="icon-ok"
                      >
                      </i>
                    </span>
                  </div>

                  <div class="info-row">
                    <span class="inner-label">
                      {{ "GENERALS.TOTAL-PRICE" | translate }}:
                    </span>
                    {{ offer.total_price | currency : "USD" }}
                  </div>
                  <div class="info-row" *ngIf="!isEditable">
                    <span class="inner-label"
                      >{{ "GENERALS.QUANTITY" | translate }}:
                    </span>
                    {{ offer.quantity }}
                  </div>

                  <div
                    class="cart-item-details-range-wrapper"
                    *ngIf="isEditable"
                  >
                    <div class="cart-item-details-range">
                      <button (click)="qtyChanges(i, offer, -1)">
                        <i class="icon-minus-1"></i>
                      </button>
                      <div class="cart-range-qty">
                        {{ offer.quantity }}
                      </div>
                      <button (click)="qtyChanges(i, offer, 1)">
                        <i class="icon-plus-1"></i>
                      </button>
                      <div
                        *ngIf="
                          initialQty[i] && initialQty[i] !== offer.quantity
                        "
                        (click)="
                          initialQty[i] = offer.quantity;
                          updateOrderDetails(offer)
                        "
                        class="update-qty btn orange-bg"
                      >
                        {{ "GENERALS.SAVE-AND-UPDATE" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-details-box-right-wrapper">
              <div class="order-details-box-right">
                <div class="fw-500 gray">
                  {{ "GENERALS.PROVIDER" | translate }}:
                  {{ order.provider.company_name }}
                </div>

                <div class="order-details-box-link">
                  <ul>
                    <li
                      (click)="
                        openDialog('contact_provider', null, {
                          id: offer.provider_id,
                          type: 'provider'
                        })
                      "
                    >
                      <i class="icon-eye-1"></i>
                      {{ "GENERALS.VIEW-PROVIDER-DETAILS" | translate }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="clear"></div>
          </div>

          <div class="order-details-box-bottom">
            <div class="expat-row-title">
              {{ "PORTAL.ASSIGNED-EXPATS" | translate }}:
              {{ offer.expats.length }} / {{ offer.quantity }}
            </div>
          </div>
        </div>

        <div class="white-box-footer">
          <div class="fl-l medium-title">
            {{ "GENERALS.TOTAL" | translate }}:
            {{ total | currency : order.currency_code }}
          </div>
        </div>
      </div>
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>

<div class="row">
  <!-- left -->
  <div class="w-100">
    <!-- Categorisation -->
    <div class="white-box-row">
      <div class="white-box-header no-border">
        <div class="medium-title fl-l">
          {{ "GENERALS.SERVICE-CATEGORISATION" | translate }}
        </div>
      </div>
      <div class="clear"></div>
      <div class="white-box-body p-t-0">
        <div class="row">
          <app-loader-spinner-general
            class="w-100"
            *ngIf="!categories"
            [repeat]="4"
            [loadingType]="LoadingType.INPUT"
          ></app-loader-spinner-general>
          <div class="w-100" *ngIf="categories">
            <app-categories-tree
              [serviceCategories]="categories"
              [selectedServiceCategories]="item.categories"
              [isRFQ]="true"
              [edit]="edit"
              (onselect)="updateCategorySelection($event)"
              [templateRef]="serviceOfferRef"
            >
            </app-categories-tree>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end left -->

  <!-- right -->
  <ng-template #serviceOfferRef let-selectedId="id">
    <div class="w-100 p-l-0">
      <!-- Service offers description -->
      <div class="white-box-row">
        <div class="white-box-body p-b-0">
          <div *ngFor="let category of item.offers_request; let idx = index">
            <ng-container *ngIf="category.service_category_id === selectedId">
              <div class="rfq-service-header">
                <div class="medium-title">
                  {{
                    item.categories[idx].label
                      ? item.categories[idx].label
                      : item.categories[idx].service_category_name
                  }}
                </div>
                <div class="rfq-expats-qty">
                  <span class="fl-l">
                    {{ "RFQS.EXPATS-NUM" | translate }}:
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">{{
                        "GENERALS.NUMBER-OF-EXPATS-GREATER-THAN-0" | translate
                      }}</span>
                    </span>
                  </span>
                  <span class="cart-item-details-range">
                    <button (click)="qtyChanges(idx, -1)">
                      <i class="icon-minus-1"></i>
                    </button>
                    <!-- <div class="cart-range-qty">
                  {{ category.quantity }}
                </div> -->
                    <input
                      type="number"
                      min="1"
                      class="input-cart-range-qty"
                      [(ngModel)]="category.quantity"
                      value="category.quantity"
                      onkeypress="return event.charCode >= 48"
                      (ngModelChange)="qtyChanges(idx, 0)"
                    />
                    <button (click)="qtyChanges(idx, 1)">
                      <i class="icon-plus-1"></i>
                    </button>
                  </span>
                </div>
              </div>

              <div class="rfq-service-desc">
                <label>
                  {{ "GENERALS.DESCRIPTION" | translate }}*
                  <span appHoverHelper class="input-helper">
                    <i class="icon-help_comp"></i>
                    <span class="helper-description">{{
                      "RFQS.SERVICE-OFFER-DESC" | translate
                    }}</span>
                  </span>
                </label>

                <textarea
                  class="form-control"
                  placeholder="{{ 'RFQS.SERVICE-OFFER-DESC' | translate }}"
                  [(ngModel)]="item.offers_request[idx].service_description"
                ></textarea>
              </div>
            </ng-container>
          </div>
          <div *ngIf="item.offers_request && !item.offers_request.length">
            <div class="small-title center-helper">
              {{ "RFQS.SELECTED-SERVICES" | translate }}
            </div>
          </div>
        </div>
        <!-- end Service offers description -->
      </div>
      <!-- end right -->
    </div>
  </ng-template>
</div>

<div class="details-body-wrapper">
  <div class="table-wrapper">
    <div class="table-responsive">
      <table matSort (matSortChange)="sortData($event)">
        <thead>
          <tr>
            <ng-container matColumnDef="select"> </ng-container>
            <th>{{ "GENERALS.AUTHOR-NAME" | translate }}</th>
            <th>
              {{ "GENERALS.ACTION" | translate }}
            </th>
            <th>
              {{ "LABEL.CHANGES" | translate }}
            </th>
            <th>
              {{ "GENERALS.CREATED-AT" | translate }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let item of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td class="gray">{{ item.author.name }}</td>
            <td class="gray">{{ item.action }}</td>
            <td class="gray">
              <ng-container *ngFor="let key of item.new_value | keys">
                <div>
                  <span class="dark-gray">{{ key }}:</span>
                  {{ item.new_value[key] | json }}
                </div>
              </ng-container>
            </td>
            <td class="gray">
              {{ item.created_at | date : "dd/MM/yyyy HH:mm:s" }}
            </td>
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="3" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- paginator -->
    <div class="paginator">
      <pagination-controls
        (pageChange)="getItems($event)"
        previousLabel=""
        nextLabel=""
      >
      </pagination-controls>
    </div>

    <!-- end paginator -->
  </div>
  <!-- end table -->
</div>

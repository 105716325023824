import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { UserDetails } from "src/app/_models/_menu/user-details";
import { ExpatsService } from "src/app/_services/expats.service";
import { UsersService } from "src/app/_services/users.service";
import { combineLatest } from "rxjs";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
})
export class UserComponent implements OnInit {
  menu;
  userID;
  entityID;
  user;
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private expatService: ExpatsService,
    private userService: UsersService
  ) {
    combineLatest(this.route.params, this.route.queryParams).subscribe(
      ([params, query]) => {
        if (params.id) {
          this.userID = params.id;
        }
        if (query) {
          this.entityID = query.entity_id;
        }
        this.menu = new UserDetails(params["id"]);
      }
    );
  }

  ngOnInit() {
    const params = { entity_id: this.entityID };
    this.userService.list(params).subscribe((res) => {
      this.user = res.result.items.filter(
        (f) => f.id.toString() === this.userID.toString()
      )[0];
    });
  }
}

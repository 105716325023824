<ng-container *ngIf="isLoadingGeneral">
  <div class="wrapper-profile">
    <app-loader-spinner-general
      class="w-profile"
      [loadingType]="LoadingType.PROFILE"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      class="w-info h-100"
      [repeat]="6"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
  </div>
</ng-container>

<div class="details-body-wrapper">
  <div class="details-2c">
    <div *ngIf="!isLoadingGeneral && user">
      <!-- left -->
      <div class="details-2c-left m-r-15">
        <div class="small-box">
          <div class="small-box-header-img" (click)="openDocModal()">
            <img
              [src]="
                user.primary_image_path
                  ? user.primary_image_path
                  : '../assets/images/profile-pics-placeholder.jpg'
              "
              alt="img"
              class="profile-pic"
            />
            <div class="edit-trigger">
              {{ "GENERALS.CHANGE-PIC" | translate }}
            </div>
          </div>
          <div class="small-box-details">
            <div class="medium-title">{{ user.name }}</div>

            <div class="small-box-status">
              <div
                class="small-box-status entity_status-label-{{
                  user.status_id
                }}"
              >
                {{ user.status_name }}
              </div>
            </div>

            <div class="fw-500" *ngIf="user.status_reason || ''">
              <span class="gray block-span"
                >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
              >
              {{ user.status_reason }}
            </div>

            <div class="fw-500">
              <span class="gray block-span"
                >{{ "GENERALS.REGISTRED-ON" | translate }}:</span
              >
              {{ user.created_at | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <div class="small-box-list" *ngIf="!adminId">
            <ul>
              <li class="action-link-gray" (click)="openResetPasswordModal()">
                <i class="icon-right-dir"></i>
                {{ "AUTH.CHANGE-PASSWORD" | translate }}
              </li>
            </ul>
          </div>
          <ng-container *ngIf="isLoadingStatus">
            <div class="loading-actions-wrapper">
              <app-loader-spinner-general
                [loadingType]="LoadingType.BUTTONS"
              ></app-loader-spinner-general>
              <app-loader-spinner-general
                [loadingType]="LoadingType.BUTTONS"
              ></app-loader-spinner-general>
            </div>
          </ng-container>
          <div class="small-box-list" *ngIf="!isLoadingStatus && actions">
            <ul>
              <ng-container *ngFor="let option of actions[user.status_id]">
                <li>
                  <div
                    (click)="openConfirmAction(option)"
                    class="btn-small orange-bg"
                  >
                    {{ option.text }}
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <!-- end left -->

      <!-- right -->
      <div class="details-2c-right">
        <div class="white-box-wrapper">
          <div class="white-box-row">
            <div class="white-box-header">
              <div class="medium-title fl-l">
                {{ "GENERALS.ACCOUNT-INFO" | translate }}
              </div>
              <div
                class="white-box-header-action fl-r"
                (click)="openEditModal('account')"
              >
                {{ "GENERALS.EDIT" | translate }}
                <i class="icon-cog-outline"></i>
              </div>
            </div>
            <div class="clear"></div>
            <div class="white-box-body">
              <p>{{ user.name }}</p>
              <p>{{ user.role_name }}</p>
              <p>Company position: {{ user.company_position }}</p>
              <p>
                <a href="mailto:{{ user.email }}">
                  <i class="icon-mail"></i>
                  {{ user.email }}
                </a>
              </p>
              <p>
                <i class="icon-phone"></i>
                {{ dictionariesService.getPhonePrefix(user.phone_prefix) }}
                {{ user.phone }}
              </p>
            </div>
          </div>
        </div>
        <!-- end right -->
      </div>
      <!-- end 2 col details -->
    </div>
  </div>
</div>

<app-reset-pass-modal
  (changePass)="onChanges($event)"
  (inputModel)="updateCurrentPassword($event)"
></app-reset-pass-modal>

<app-add-profile-picture
  [entityType]="'user'"
  [entityId]="user?.id"
  [title]="'GENERALS.UPLOAD-PIC'"
  (changePicture)="handleEvent($event)"
></app-add-profile-picture>

<mat-form-field appearance="outline" class="w-100">
  <mat-label>
    <ng-content select="[slot='label']"></ng-content>
  </mat-label>
  <mat-select
    [formControl]="control"
    disableOptionCentering
    panelClass="city-panel"
    (selectionChange)="onSelectChange($event)"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="'LABEL.CITY' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="citySearchControl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      style="display: none"
      *ngIf="selectedCity"
      [value]="selectedCity"
    >
      {{ selectedCity }}
    </mat-option>
    <mat-option
      *ngFor="let city of filteredCities$ | async"
      [value]="city.name"
      >{{ city.name }}</mat-option
    >
  </mat-select>
</mat-form-field>

<div windowScrollAppNav class="details-navigation">
  <!-- <div class="fl-l big-title"  (click)="goBack()" *ngIf="expat"> <i class="icon-left-open back-arrow"></i>  {{ expat.user.name }} </div> -->
  <div class="fl-l big-title" *ngIf="user">{{ user.name }}</div>

  <div class="fl-r">
    <app-details-navigation [menu]="menu"></app-details-navigation>
  </div>
  <div class="clear"></div>

  <router-outlet></router-outlet>
</div>

import { Component } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegistrationValidator } from "src/app/_directives/registration-validator";
import { ResetPassword } from "src/app/_models/reset-password";
import { AlertService } from "../../../_services/alert.service";
import { AuthenticationService } from "../../../_services/authentication.service";

@Component({
  selector: "app-reset-password-form",
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent {
  isLoading: boolean;
  reset: ResetPassword;
  token: string;
  registrationFormGroup: UntypedFormGroup;
  passwordFormGroup: UntypedFormGroup;

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.reset = new ResetPassword();

    this.route.params.subscribe((params) => {
      this.token = params.token;
    });

    this.passwordFormGroup = this.formBuilder.group(
      {
        password: ["", Validators.required],
        repeatPassword: ["", Validators.required],
      },
      {
        validator: RegistrationValidator.validate.bind(this),
      }
    );
    this.registrationFormGroup = this.formBuilder.group({
      username: ["", Validators.required],
      passwordFormGroup: this.passwordFormGroup,
    });
  }

  resetPassword() {
    this.isLoading = true;
    this.reset.reset_token = this.token;
    this.authenticationService.resetPassword(this.reset).subscribe(
      (data) => {
        this.isLoading = false;
        this.router.navigateByUrl("/dashboard");
        if (data.message) {
          this.alertService.success(data.messages);
        }
      },
      (error) => {
        this.isLoading = false;
        this.alertService.errors(error.error.errors);
      }
    );
  }
}

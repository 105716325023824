export class ProvidersCommissions {
  provider_id: number;
  categories: Array<any>;
}

export class Commissions {
  commissions: Array<Commission>;
}

export class Commission {
  id: number;
  commission_type_id: number;
  commission: number;
  start_date: string;
  end_date: string;
  provider_id: number;
  service_category_id: number;
  provider_commission_id: number;
}

export class ProviderInvoiceDueDays {
  invoice_due_days: number;
  provider_id: number;
}

<div class="auth-wrapper">
  <!-- language selection -->
  <div class="auth-lang-selector">
    <app-translate></app-translate>
  </div>
  <!-- end language selection -->

  <div class="auth-laytout-responsive">
    <div class="auth-left-inner">
      <div class="logo">
        <img src="assets/images/logo.svg" />
      </div>
      <div class="auth-left-body">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!-- <div class="auth-right-wrapper">
      <div class="auth-right-inner">
        <p class="auth-right-title">{{ 'AUTH.RIGHT-TITLE' | translate }}</p>
        <div class="auth-right-line"></div>
        <p class="auth-right-desc">{{ 'AUTH.RIGHT-DESCRIPION' | translate }}</p>
      </div>
  </div> -->
</div>

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CommissionsService } from "../../../_services/commissions.service";

@Component({
  selector: "app-commissions-wrapper",
  templateUrl: "./commissions-wrapper.component.html",
})
export class CommissionsWrapperComponent implements OnInit {
  pendingRequestsCount: number = 0;

  constructor(
    public router: Router,
    private commissionService: CommissionsService
  ) {}

  ngOnInit(): void {
    this.getCommissionsRequestCount();
  }

  getCommissionsRequestCount() {
    this.commissionService.getRequestsCount().subscribe((data) => {
      this.pendingRequestsCount = data.result.count;
    });
  }

  onActivate(elementRef) {
    if (
      Object.prototype.hasOwnProperty.call(
        elementRef,
        "shouldUpdateCommissionsRequestsCount"
      )
    ) {
      elementRef.shouldUpdateCommissionsRequestsCount.subscribe(() => {
        this.getCommissionsRequestCount();
      });
    }
  }
}

<div windowScrollDashboardBodyAction class="dashboard-body-action">
  <div class="clear-btn btn white-bg fl-r" (click)="goToStripeTable()">
    {{ "LABEL.MANAGE" | translate }}
  </div>
</div>

<stripe-pricing-table
  pricing-table-id="prctbl_1M3y8YByT6Cm3bGGZYn1a1HK"
  publishable-key="pk_live_TVh9HEOoiCkXHLYy3l6X2uS000RsroeT9d"
>
</stripe-pricing-table>

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { ClientsService } from "src/app/_services/clients.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { ExpatsService } from "src/app/_services/expats.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmActionModalComponent } from "../../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";

@Component({
  selector: "app-user-details-actions-panel",
  templateUrl: "./user-details-actions-panel.component.html",
})
export class UserDetailsActionsPanelComponent implements OnChanges {
  @Input() set entity(value) {
    if (value) {
      this.service = value.service;
      this.id = value.id;
      this.userType = value.userType;
      this.user$ = this[value.service][value.method](value.id);
      this.getEntityDetails();
    }
  }

  @Input() refresh;

  @Output() switchAccount: EventEmitter<any> = new EventEmitter();
  @Output() changeActions: EventEmitter<any> = new EventEmitter();
  @Output() changesBulkAction: EventEmitter<any> = new EventEmitter();

  id;
  user;
  isLoadingGeneral;
  statusOptions;
  service;
  user$;
  password;
  userType;

  constructor(
    private clientsService: ClientsService,
    public providersService: ProvidersService,
    public ngxSmartModalService: NgxSmartModalService,
    private dictionaryService: DictionariesService,
    private alertService: AlertService,
    private expatService: ExpatsService,
    public dialog: MatDialog
  ) {}

  getEntityDetails() {
    this.isLoadingGeneral = true;

    this.user$.subscribe((data) => {
      this.user = data.result;

      if (this.user.rating && this.user.rating.length) {
        let calculatedRating: number[] = [];
        calculatedRating = this.user.rating.map((f) => f["rating"]);
        this.user.calculatedRating =
          calculatedRating.reduce((a, b) => a + b) / this.user.rating.length;
        this.user.calculatedRating = +this.user.calculatedRating.toFixed(2);
      }
      this.getStatusTransitions();
      this.isLoadingGeneral = false;
    });
  }

  public getStatusTransitions() {
    this[this.service].getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.statusOptions = data.result[this.user.status_id];
      }
    });
  }

  ngOnChanges(changes) {
    if (changes && changes.refresh) {
      this.getEntityDetails();
    }
  }

  openModal() {
    this.ngxSmartModalService.getModal("switchAccount").open();
  }

  switchToAccount() {
    this.switchAccount.emit(this.password);
  }

  cancelModal() {
    this.ngxSmartModalService.getModal("switchAccount").close();
  }

  handleEvent(e) {
    this.getEntityDetails();
  }

  openDocModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").open();
  }

  openConfirmAction(action) {
    const ids = [this.id];
    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this[this.service].updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.getEntityDetails();
              this.changesBulkAction.emit();
            },
            (error) => {
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this[this.service]
        .updateStatusBulk(action.id, ids, null)
        .subscribe((data) => {
          this.getEntityDetails();
          this.changesBulkAction.emit();
        });
    }
  }

  changeManagedState() {
    this.providersService
      .updateManagedState(this.user.id, this.user.is_managed)
      .subscribe((data) => {
        this.changeActions.emit();
      });
  }
}

<!-- details navigation row (left title - right navigation) -->
<div windowScrollAppNav class="details-navigation">
  <!-- title -->
  <div class="fl-l big-title" *ngIf="order">
    <i
      class="icon-left-open back-arrow"
      routerLink="../"
      [queryParams]="filters"
    ></i>
    {{ "GENERALS.ORDER-NO" | translate }} {{ order.group_number }} -
    {{ order.identity_number }}
  </div>
  <!-- end title -->

  <!-- navigation -->
  <div class="fl-r">
    <app-details-navigation [menu]="menu"></app-details-navigation>
  </div>
  <!-- end navigation -->
</div>
<!-- end details navigation row (left title - right navigation) -->
<div class="clear"></div>

<router-outlet></router-outlet>

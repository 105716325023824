<div class="modal-title-wrapper">
  <div class="modal-title" *ngIf="commission.id">
    {{ "COMMISSION.EDIT-COMMISSION" | translate }}
  </div>
  <div class="modal-title" *ngIf="!commission.id">
    {{ "COMMISSION.ADD-COMMISSION" | translate }}
  </div>
</div>

<div class="clear"></div>

<div class="custom-modal-body">
  <div class="modal-form">
    <div class="row">
      <div class="form-group w-50">
        <label>{{ "GENERALS.COMMISSION" | translate }}</label>

        <mat-radio-group
          class="form-group-radio-btn"
          *ngFor="let type of commissionTypes"
          [(ngModel)]="commission.commission_type_id"
        >
          <mat-radio-button [value]="type.id">
            {{ type.label }}
          </mat-radio-button>
        </mat-radio-group>

        <input
          [ngClass]="{
            'no-pointer-events ': !commission.commission_type_id
          }"
          type="text"
          id="editCommissionInput"
          class="form-control"
          placeholder="{{ 'GENERALS.COMMISSION' | translate }}"
          name="commission"
          (click)="$event.stopPropagation()"
          [(ngModel)]="commission.commission"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "GENERALS.SELECT-DATE" | translate }}</label>

        <mat-radio-group
          class="form-group-radio-btn"
          *ngFor="let type of dateOptions"
          [(ngModel)]="selectedDateOption"
        >
          <mat-radio-button [value]="type.id">
            {{ type.label }}
          </mat-radio-button>
        </mat-radio-group>

        <div [ngClass]="{ 'no-pointer-events': !selectedDateOption }">
          <ng-container *ngIf="selectedDateOption === 'multipleMonths'">
            <app-date-range-calendar
              class="calendar fl-l"
              [dateRange]="dateRangeMultipleMonths"
              [isDisabled]="!selectedDateOption"
              [minDate]="minDate"
              (change)="setDate($event)"
              placeholder="{{ 'GENERALS.SELECT-DATE' | translate }}"
            ></app-date-range-calendar>
          </ng-container>

          <ng-container *ngIf="selectedDateOption === 'singleMonth'">
            <app-date-range-calendar
              class="calendar fl-l"
              [singleDate]="dateRange"
              [isDisabled]="!selectedDateOption"
              [minDate]="minDate"
              (change)="setDate($event)"
              placeholder="{{ 'GENERALS.SELECT-DATE' | translate }}"
            ></app-date-range-calendar>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="clear"></div>

<div class="modal-footer">
  <button
    type="button"
    class="btn gray-bg"
    (click)="closeCommission.emit(); initEmpty()"
  >
    {{ "GENERALS.CANCEL" | translate }}
  </button>

  <button
    type="button"
    class="btn orange-bg"
    *ngIf="action === 'edit'"
    (click)="updateCommission()"
  >
    {{ "GENERALS.UPDATE" | translate }}
  </button>

  <button
    type="button"
    class="btn orange-bg"
    *ngIf="action === 'add'"
    (click)="addCommission()"
  >
    {{ "GENERALS.SAVE" | translate }}
  </button>
</div>

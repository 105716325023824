import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class AdminService {
  constructor(private http: HttpClient) {}

  // GET Admins
  getAdmins(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "admins", {
      params: params,
    });
  }

  // GET current user details
  getCurrentUser(id): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "users/" + id);
  }

  /**
   *  Update current user
   */
  updateCurrentUser(user): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + "users/update-current-user",
        user,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   *  Update admin user
   */
  updateAdminUser(user, id): Observable<any> {
    return this.http
      .put<any>(environment.gateway_endpoint + "users/" + id, user, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   *  Update current user password
   */
  updatePassword(params = {}): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + "users/update-password",
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Add Admins
  addAdmin(params = {}): Observable<any> {
    return this.http.post<any>(environment.gateway_endpoint + "admins", params);
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { User } from "../_models/user";
import { ResetPassword } from "../_models/reset-password";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { MatDialog } from "@angular/material/dialog";
import {
  PermissionActionEnum,
  PermissionSecondaryResourceEnum,
} from "../modules/shared/_enums/permission-resource.enum";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    public ngxSmartModalService: NgxSmartModalService,
    public dialog: MatDialog
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(environment.user_local_storage_key))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public setOptions() {
    const x_client_secret = btoa(
      environment.client_secret +
        ":" +
        environment.client_id +
        ":" +
        environment.role
    );

    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Client-Secret": x_client_secret,
      }),
    };
  }

  /**
   * Login
   */
  login(username: string, password: string): any {
    const body = {
      username: username,
      password: password,
    };

    const options = this.setOptions();

    return this.http
      .post<any>(environment.gateway_endpoint + "login", body, options)
      .pipe(
        map((data) => {
          // login successful if there's a jwt token in the response
          if (data && data.result.session.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes

            const user: User = data.result.user;
            localStorage.setItem(
              environment.user_local_storage_key,
              JSON.stringify(user)
            );
            this.currentUserSubject.next(user);
            console.log(environment.domain);
            this.cookieService.set(
              environment.session_local_storage_key,
              JSON.stringify(data.result.session),
              5, // expires in 5 days
              "/",
              environment.domain,
              true
            );
          }
          return data;
        })
      );
  }

  /**
   * Clear Storage
   */
  removeLocalStorage() {
    localStorage.removeItem(environment.session_local_storage_key);
    localStorage.removeItem(environment.user_local_storage_key);
    this.currentUserSubject.next(null);
  }

  /**
   * Clear Cookies
   */
  removeCookies() {
    this.cookieService.delete(
      environment.session_local_storage_key,
      "/",
      environment.domain
    );
    this.cookieService.delete(
      environment.user_local_storage_key,
      "/",
      environment.domain
    );
  }

  /**
   * Logout
   */
  logout(errStatus?) {
    // remove user from local storage to log user out
    const session = JSON.parse(
      this.cookieService.get(environment.session_local_storage_key)
    );

    const options = {
      headers: new Headers({
        Authorization: `Bearer ${session.token}`,
      }),
    };

    if (errStatus === 401) {
      // close modals
      this.dialog.closeAll();
      this.ngxSmartModalService.closeAll();

      this.removeLocalStorage();
      this.removeCookies();
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: this.router.url },
      });
      return;
    }

    // close modals
    this.dialog.closeAll();
    this.ngxSmartModalService.closeAll();

    return this.http
      .post<any>(environment.gateway_endpoint + "users/logout", options)
      .pipe(
        map((model) => {
          this.removeLocalStorage();
          this.removeCookies();
          this.router.navigate(["/login"], {
            queryParams: { returnUrl: this.router.url },
          });
          return model;
        }),
        catchError((error, caught) => {
          return throwError(caught);
        })
      );
  }

  abort404() {
    this.router.navigate(["/page/not-found"]);
  }

  abort403() {
    this.router.navigate(["/page/forbidden"]);
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "/resetPassword",
        resetPassword,
        this.setOptions()
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  sendResetPasswordEmail(email) {
    return this.http
      .post<any>(environment.gateway_endpoint + "reset-password/request", {
        username: email,
      })
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  resendConfirmationMail(id) {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "users/" +
          id +
          "/send-confirmation-mail",
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Confirm a user's email address as an admin
   */
  confirmEmail(id) {
    return this.http
      .get<any>(
        environment.gateway_endpoint + "users/" + id + "/confirm",
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  makePrimry(id) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "users/" + id + "/make-primary-user",
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Check if the user has a specific permission
   */
  public $can(
    action: string,
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    const permission = `${action} ${resource} ${secondaryResource}`;
    return this.currentUser.pipe(
      map((user) => user.permissions.indexOf(permission) > -1)
    );
  }

  /**
   * Check if the user has view access on a resource
   */
  public $hasResource(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.VIEW,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user has view access on a secondary resource
   */
  public $hasSecondaryResource(
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    return this.$can(PermissionActionEnum.VIEW, resource, secondaryResource);
  }

  /**
   * Check if the user is allowed to create a resource
   */
  public $canCreate(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.CREATE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user is allowed to update a resource
   */
  public $canUpdate(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.UPDATE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user is allowed to manage a resource
   */
  public $canManage(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.MANAGE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }
}

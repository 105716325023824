<app-loader-spinner-general
  [isLoadingGeneral]="isLoading"
></app-loader-spinner-general>

<div class="details-body-wrapper">
  <!-- 2 col details -->
  <div class="details-2c">
    <!-- left -->
    <div class="details-2c-left" *ngIf="offer">
      <div class="small-box">
        <div class="small-box-header-img">
          <img [src]="offer.primary_image_path" alt="img" />
        </div>
        <!-- <div class="small-box-header-img">
            <img src="../../../assets/images/footer-img.jpg" alt="img" />
          </div> -->
        <div class="small-box-details">
          <div class="medium-title">{{ offer.title }}</div>
          <div class="small-box-status">
            <div class="services-status-label-{{ offer.status_id }}">
              {{ offer.status_label }}
            </div>
          </div>
          <div
            *ngIf="offer.status_id === 5 || offer.status_id === 3"
            class="fw-500"
          >
            <span class="gray block-span"
              >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
            >
            {{ offer.decline_message }}
          </div>
          <div class="fw-500">
            <span class="gray block-span"
              >{{ "GENERALS.OFFERED-BY" | translate }}:</span
            >
            {{ offer.provider.company_name }}
          </div>
        </div>

        <div class="small-box-list">
          <ul>
            <ng-container
              *ngIf="offer.status_id === 6 || offer.status_id === 7"
            >
              <li class="switch-check-list">
                <span class="inner-label">
                  {{ "GENERALS.SHOW-MP" | translate }}:
                </span>
                <input
                  class="checkbox-switch"
                  (change)="updateStatus()"
                  [(ngModel)]="offer.active"
                  type="checkbox"
                  name="change-status-switch"
                />
              </li>
            </ng-container>

            <ng-container *ngFor="let action of actions[offer.status_id]">
              <li>
                <div
                  (click)="openConfirmAction(action)"
                  class="btn-small orange-bg"
                >
                  {{ action.text }}
                </div>
              </li>
            </ng-container>

            <li>
              <div (click)="copyOffer(offer.id)" class="btn-small orange-bg">
                {{ "GENERALS.COPY-OFFER" | translate }}
              </div>
            </li>

            <li
              class="action-link-gray"
              *ngIf="!(offer.status_id === 3) && !(offer.status_id === 5)"
              (click)="goToMarketplaceOffer(offer.id)"
            >
              <i class="icon-right-dir"></i>
              {{ "GENERALS.PREVIEW-IN-MARKETPLACE" | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right">
      <!-- table -->
      <div class="table-wrapper">
        <div class="table-responsive">
          <table>
            <thead>
              <tr>
                <th>
                  {{ "GENERALS.AUTHOR-NAME" | translate }}
                </th>
                <th>
                  {{ "GENERALS.ACTION" | translate }}
                </th>
                <th>
                  {{ "GENERALS.DESCRIPTION" | translate }}
                </th>
                <th>
                  {{ "GENERALS.CREATED-AT" | translate }}
                </th>
                <!-- <th class="ta-r"><i class="icon-cog-outline-1"></i></th> -->
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="offerHistory">
                <tr
                  *ngFor="
                    let item of offerHistory
                      | paginate
                        : {
                            itemsPerPage: itemsPage,
                            currentPage: p,
                            totalItems: total
                          };
                    let i = index
                  "
                >
                  <td class="gray">{{ item.user_name }}</td>
                  <td class="gray">{{ item.title }}</td>
                  <td class="gray">{{ item.info }}</td>
                  <td class="gray">
                    {{ item.created_at | date : "dd/MM/yyyy" }}
                  </td>
                </tr>
                <tr *ngIf="offerHistory?.length === 0">
                  <td colspan="3" class="gray">
                    {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <!-- paginator -->
        <div class="paginator">
          <pagination-controls
            (pageChange)="getOfferHistory($event, offer.id)"
            previousLabel=""
            nextLabel=""
          >
          </pagination-controls>
        </div>

        <!-- end paginator -->
      </div>
      <!-- end table -->
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { OrdersService } from "src/app/_services/orders.service";

@Component({
  selector: "app-order-activity",
  templateUrl: "./order-activity.component.html",
  styleUrls: ["./order-activity.component.css"],
})
export class OrderActivityComponent implements OnInit {
  public id;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private orderService: OrdersService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  ngOnInit() {
    this.orderService.getOrderByIdentityNumber({}, this.id).subscribe((res) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForOrder(res.result, "history")
      );
      this.id = res.result.id;
    });
  }
}

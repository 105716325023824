<div class="details-body-wrapper">
  <!-- 2 col details -->
  <div class="details-2c d-flex">
    <!-- left -->
    <app-rfq-details-actions-panel></app-rfq-details-actions-panel>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right">
      <!-- table -->
      <div class="table-wrapper">
        <div class="table-responsive">
          <table matSort (matSortChange)="sortData($event)">
            <thead>
              <tr>
                <ng-container matColumnDef="select"> </ng-container>
                <th>
                  {{ "GENERALS.AUTHOR-NAME" | translate }}
                </th>
                <th>
                  {{ "GENERALS.ACTION" | translate }}
                </th>
                <th>
                  {{ "GENERALS.DESCRIPTION" | translate }}
                </th>
                <th>
                  {{ "GENERALS.CREATED-AT" | translate }}
                </th>
                <!-- <th class="ta-r"><i class="icon-cog-outline-1"></i></th> -->
              </tr>
            </thead>
            <tbody *ngIf="isLoadingGeneral">
              <tr>
                <td colspan="100">
                  <app-loader-spinner-general
                    [loadingType]="LoadingType.TABLE"
                  ></app-loader-spinner-general>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!isLoadingGeneral">
              <tr
                *ngFor="
                  let item of items
                    | paginate
                      : {
                          itemsPerPage: itemsPage,
                          currentPage: p,
                          totalItems: total
                        }
                "
              >
                <td class="gray">{{ item.user_name }}</td>
                <td class="gray">{{ item.title }}</td>
                <td class="gray">{{ item.info }}</td>
                <td class="gray">
                  {{ item.created_at | date : "dd/MM/yyyy" }}
                </td>
              </tr>
              <tr *ngIf="items?.length === 0">
                <td colspan="3" class="gray">
                  {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- paginator -->
        <div class="paginator">
          <pagination-controls
            (pageChange)="getItems($event)"
            previousLabel=""
            nextLabel=""
          >
          </pagination-controls>
        </div>
        <!-- end paginator -->
      </div>
      <!-- end table -->
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>

import { Component, Input, OnInit } from "@angular/core";
import { ActivityService } from "../../../_services/activity.service";
import { ItemsListComponent } from "../items-list/items-list.component";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.css"],
})
export class ActivityComponent extends ItemsListComponent implements OnInit {
  @Input() model: string;
  @Input() modelId: number;

  constructor(private service: ActivityService) {
    super();
  }

  ngOnInit() {
    this.getItems();
  }

  public getItems(page = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = this.getParams(page);
    this.isLoadingGeneral = true;

    this.request_call = this.service
      .list(this.model, this.modelId, params)
      .subscribe(
        (data) => {
          this.processResponse(data);
        },
        (error) => {
          this.isLoadingGeneral = false;
        }
      );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { CountryTax, Tax } from "src/app/_models/tax";
import { TaxRatesService } from "src/app/_services/tax-rates.service";
import { AlertService } from "src/app/_services/alert.service";
import { Observable, forkJoin } from "rxjs";
@Component({
  selector: "app-edit-tax-rates",
  templateUrl: "./edit-tax-rates.component.html",
  styleUrls: ["./edit-tax-rates.component.scss"],
})
export class EditTaxRatesComponent implements OnInit {
  isLoadingGeneral = false;
  form: UntypedFormGroup;
  taxUpdateSubs: Observable<Tax>[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditTaxRatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CountryTax,
    private fb: UntypedFormBuilder,
    private service: TaxRatesService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.setupForm();

    this.patchForm();
  }

  private setupForm(): void {
    this.form = this.fb.group({
      personal_income: [0, Validators.required],
      employee_social_contributions: [0, Validators.required],
      company_social_contributions: [0, Validators.required],
    });
  }

  private patchForm(): void {
    this.data.taxes.forEach((tax: Tax) => {
      this.form.get(tax.tax_type).setValue(tax.value);
    });
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.data.taxes.forEach((tax: Tax) => {
      tax.value = this.form.get(tax.tax_type).value;

      this.taxUpdateSubs.push(this.service.setCountryTaxes(tax));
    });

    forkJoin({
      ...this.taxUpdateSubs,
    }).subscribe({
      next: (res) => {
        this.dialogRef.close();
      },
      error: (error) => {
        if (error && error.message) {
          this.alertService.stringError(error.message);
        }
      },
    });
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
  ElementRef,
  AfterViewInit,
  ViewContainerRef,
} from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatTable } from "@angular/material/table";
import { ServiceCategory } from "../../../../../_models/servicecategory";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "../../../../../modules/shared/_enums/permission-resource.enum";
import { AddCategoryComponent } from "../../add-category/add-category.component";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ServiceCategoriesService } from "../../../../../_services/servicecategories.service";
import { AuthenticationService } from "../../../../../_services/authentication.service";
import { ShareDataService } from "src/app/_services/share-data.service";
import { ProviderCommissionEnum } from "../../../../../_enums/provider-commission-enum";

@Component({
  selector: "app-category-item",
  templateUrl: "./category-item.component.html",
  styleUrls: ["./category-item.component.css"],
  animations: [
    trigger("detailExpand", [
      state(
        "void",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state("*", style({ height: "*", visibility: "visible" })),
      transition("void <=> *", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class CategoryItemComponent implements AfterViewInit {
  displayedColumns: string[];
  currentCategory: ServiceCategory;
  providerCommissionEnum = ProviderCommissionEnum;
  public resource = PermissionResourceEnum;
  public subResource = PermissionSecondaryResourceEnum;
  public action = PermissionActionEnum;

  @Input() categories: ServiceCategory[];
  @Input() isMainLevel;

  @Output() update: EventEmitter<ServiceCategory[]> = new EventEmitter();
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() subcategory: EventEmitter<ServiceCategory> = new EventEmitter();
  @Output() activate: EventEmitter<ServiceCategory> = new EventEmitter();
  @Output() deactivate: EventEmitter<ServiceCategory> = new EventEmitter();
  @Output() edit: EventEmitter<ServiceCategory> = new EventEmitter();

  @ViewChild("table", { static: true }) table: MatTable<CategoryItemComponent>;
  @ViewChild("tableWrapper", { static: true }) tableWrapper: ElementRef;
  @ViewChild("childTable", { static: true }) childTable: ElementRef;

  @ViewChild("container", { static: true }) container;
  @ViewChild(AddCategoryComponent, { static: true })
  private modal: AddCategoryComponent;

  // @ViewChild('draggableTableControl') draggableTableControl: ViewContainerRef;

  @ViewChild("draggableTableControl", { static: true })
  private draggableTableControl: ElementRef;
  hasClass;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public renderer: Renderer2,
    private serviceCategoriesService: ServiceCategoriesService,
    public authService: AuthenticationService,
    private shareDataServ: ShareDataService
  ) {
    this.displayedColumns = ["icon", "name", "commission", "status", "options"];
    this.currentCategory = new ServiceCategory();
    document.addEventListener("click", this.outsideClick.bind(this));
  }

  ngAfterViewInit() {
    this.onResize();
  }

  outsideClick(event: any) {
    if (
      this.container &&
      !this.container.nativeElement.contains(event.target)
    ) {
      this.currentCategory = new ServiceCategory();
    }
  }

  drop(event: CdkDragDrop<CategoryItemComponent[]>) {
    const prevIndex = this.categories.findIndex((d) => d === event.item.data);
    moveItemInArray(this.categories, prevIndex, event.currentIndex);
    this.table.renderRows();

    this.update.emit(this.categories);
  }

  forward(categories: ServiceCategory[]) {
    this.update.emit(categories);
  }

  clickStopper(event) {
    event.stopPropagation();
  }

  downloadTemplate(service_category) {
    this.serviceCategoriesService
      .downloadTemplate(service_category.id)
      .subscribe((data) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = service_category.label + ".xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      });
  }

  toggleMenu(category: ServiceCategory) {
    if (
      !this.currentCategory.id ||
      (this.currentCategory.id && this.currentCategory.id !== category.id)
    ) {
      this.currentCategory = category;
    } else {
      this.currentCategory = new ServiceCategory();
    }
  }

  onResize() {
    if (this.tableWrapper) {
      const draggableMainTable = document.getElementsByTagName("table")[0];
      const draggableMainTableRow =
        draggableMainTable.querySelector("tbody tr");
      const draggableMainTableTd =
        draggableMainTableRow.getElementsByTagName("td");

      const draggableMainTableTdWidthArr = [];
      for (const key in draggableMainTableTd) {
        if (Object.prototype.hasOwnProperty.call(draggableMainTableTd, key)) {
          draggableMainTableTdWidthArr.push(
            draggableMainTableTd[key].offsetWidth
          );
        }
      }

      const getDraggableTable =
        this.tableWrapper.nativeElement.getElementsByTagName("table")[0];
      const getDraggableTableBody =
        getDraggableTable.getElementsByTagName("tbody")[0];
      const getDraggableTableRow =
        getDraggableTableBody.getElementsByTagName("tr");

      for (const key in getDraggableTableRow) {
        if (Object.prototype.hasOwnProperty.call(getDraggableTableRow, key)) {
          const getDraggableTableTd =
            getDraggableTableRow[key].getElementsByTagName("td");

          if (
            getDraggableTableTd.length > 0 &&
            getDraggableTableTd.length < 6
          ) {
            this.renderer.setStyle(
              getDraggableTableTd[0],
              "width",
              draggableMainTableTdWidthArr[0] + "px"
            );
            this.renderer.setStyle(
              getDraggableTableTd[1],
              "width",
              draggableMainTableTdWidthArr[1] + "px"
            );
            this.renderer.setStyle(
              getDraggableTableTd[2],
              "width",
              draggableMainTableTdWidthArr[2] + "px"
            );
            this.renderer.setStyle(
              getDraggableTableTd[3],
              "width",
              draggableMainTableTdWidthArr[3] + "px"
            );
            this.renderer.setStyle(
              getDraggableTableTd[4],
              "width",
              draggableMainTableTdWidthArr[4] + "px"
            );
          }
        }
      }
    }
  }

  rowClick(e) {
    setTimeout(() => {
      this.onResize();
    }, 0);

    const html = [];

    if (e) {
      this.handleClick(e).forEach((element) => {
        html.push(element.className);
      });
    }

    this.hasClass = html.join().includes("action-coll");

    this.shareDataServ.sendData(this.hasClass);
  }

  handleClick(e) {
    const path = [];
    let node = e.target;
    while (node !== document.body) {
      path.push(node);
      node = node.parentNode;
    }
    return path;
  }

  startDrag() {
    this.onResize();
  }
}

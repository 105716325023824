import {
  Directive,
  HostBinding,
  HostListener,
  ElementRef,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[toggle-action-table]",
})
export class ToggleActionTableDirective {
  elParent;
  constructor(private el: ElementRef, public renderer: Renderer2) {}

  @HostBinding("class.toggle-is-open") isOpen = false;

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }

  @HostListener("document:click", ["$event"])
  public onClick(event) {
    this.elParent = this.el.nativeElement.closest(".table-responsive");

    if (this.el.nativeElement.contains(event.target)) {
      this.isOpen = !this.isOpen;
      setTimeout(() => {
        if (event.target.offsetParent?.classList?.contains("toggle-is-open")) {
          this.renderer.addClass(this.elParent, "overlap-action-table");
        }
      }, 110);
    } else {
      this.isOpen = false;

      if (this.elParent) {
        this.renderer.removeClass(this.elParent, "overlap-action-table");
      }
    }
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.isOpen = false;
    if (this.elParent) {
      this.renderer.removeClass(this.elParent, "overlap-action-table");
    }
  }

  // @HostListener('click') toggleOpen($event) {
  //     this.isOpen = !this.isOpen;
  // }

  //  @HostListener('document:click', ['$event.target']) clickedOutside($event) {
  //      event.preventDefault();
  //      this.isOpen = false;
  //      console.log('CLICKED OUTSIDE');
  //   }
}

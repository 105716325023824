import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { Expat } from "../../../../_models/expat";
import { UsersService } from "../../../../_services/users.service";
import { ExpatsService } from "../../../../_services/expats.service";
import { AlertService } from "../../../../_services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { DocumentsService } from "src/app/_services/documents.service";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { dependentFields } from "../../../../validators/dependent.fields";
import { ActivatedRoute } from "@angular/router";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-add-expat-form",
  templateUrl: "./add-expat-form.component.html",
  styleUrls: ["./add-expat-form.component.css"],
})
export class AddExpatForm implements OnInit, OnDestroy {
  @Input()
  public expat: Expat;

  @Input()
  public clientId: number;

  @Output() emitter: EventEmitter<any> = new EventEmitter();

  public prefix;
  public user;
  public recentlyAddedExpat;
  public showExpatAddedMessage;
  public isLoadingGeneral;
  public countries;
  public filteredCountries;
  public filteredPrefix;
  public edit: boolean;
  public imgURL: any;
  public message: string;
  public form!: UntypedFormGroup;

  private imagePath;
  private id;
  private departments;
  private subscriptions: Subscription[] = [];
  public countryFilterControl = new UntypedFormControl();

  constructor(
    private dictionariesService: DictionariesService,
    private userService: UsersService,
    private expatsService: ExpatsService,
    private alertService: AlertService,
    private translate: TranslateService,
    private documentService: DocumentsService,
    private fb: UntypedFormBuilder
  ) {}

  public ngOnInit() {
    this.getCountryList();
    this.getDepartmentsList();

    this.getExpat();

    this.setupForm();
    this.onPhonePrefixChange();
    this.filterCountries();
    this.setupPhonePrefix();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private filterCountries() {
    this.countryFilterControl.valueChanges.subscribe((inputValue) => {
      this.filteredCountries = this.countries.filter(
        (value) =>
          value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
      );
    });
  }

  private getDepartmentsList() {
    this.dictionariesService.getDepartments().subscribe((res) => {
      this.departments = res.result;
    });
  }

  private getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
        this.filteredCountries = data.result;

        this.prefix = data.result.map((p) => {
          if (this.edit) {
            if (
              this.dictionariesService.getPhonePrefix(
                this.expat.phone_prefix
              ) === this.dictionariesService.getPhonePrefix(p.phone_code)
            ) {
              p.phone_code = this.dictionariesService.getPhonePrefix(
                p.phone_code
              );
              this.expat.phone_prefix = p.phone_code;
            } else {
              p.phone_code =
                this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
            }
          } else {
            p.phone_code =
              this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
          }
          return p;
        });

        this.filteredPrefix = this.prefix;
        this.setupPhonePrefix();
      }
    });
  }

  public setupPhonePrefix() {
    if (this.form && this.form.controls.phone_prefix) {
      this.form.controls.phone_prefix.setValue(this.getPhonePrefix());
    }
  }

  public emitChanges(e: string) {
    if (e === "save" || e === "another") {
      this.triggerValidation();
      this.isLoadingGeneral = true;
      const params = this.expat;
      params.client_id = this.clientId;
      params.user_id = this.expat.user_id;
      params.expat_id = this.expat.user_id;
      params.email = this.form.controls.email.value;
      params.country_code = this.form.controls.country_code.value;

      if (!params.details) {
        params.details = {};
      }

      params.first_name = this.form.controls.first_name.value;
      params.last_name = this.form.controls.last_name.value;
      params.phone_prefix = this.form.controls.phone_prefix.value;
      params.phone = this.form.controls.phone.value;

      params.details.department_name = this.form.controls.department_name.value;
      params.details.company_position =
        this.form.controls.company_position.value;

      params.children_number = this.form.controls.children_number.value;
      params.adults_number = this.form.controls.adults_number.value;
      const expat$ = this.edit
        ? this.expatsService.updateExpat(params)
        : this.expatsService.createExpat(params);

      this.getCountryList();

      expat$.subscribe(
        (res) => {
          if (res.message !== undefined) {
            this.alertService.success(res.message);
          } else {
            this.alertService.success("Saved successfully");
            this.recentlyAddedExpat = res.result;
            this.showExpatAddedMessage = true;
            this.form.reset();
            setTimeout(() => (this.showExpatAddedMessage = false), 5000);
          }
          if (e === "save") {
            this.emitter.emit({ type: e, id: res.result.id });
          } else {
            this.expat = new Expat();
            this.imgURL = "";
          }
          if (this.imagePath) {
            this.uploadImage(res.result.id);
          }
          this.isLoadingGeneral = false;
        },
        (error) => {
          if (error && error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoadingGeneral = false;
        }
      );
    } else {
      this.emitter.emit(e);
    }
  }

  public uploadImage(id) {
    const files = {
      file: [this.imagePath[0]],
      name: null,
      status: null,
    };
    this.documentService
      .uploadImage(id, "image", files, "expat", null)
      .subscribe(
        (res) => {
          this.isLoadingGeneral = false;
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoadingGeneral = false;
        }
      );
  }

  public preview(files) {
    console.warn(files);

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.translate.get("GENERALS.ONLY-IMG").subscribe((data) => {
        this.message = data;
      });
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  public removeUploaded() {
    this.imgURL = "";
    this.message = "";
  }

  private triggerValidation() {
    this.form.markAllAsTouched();
  }

  private onPhonePrefixChange() {
    this.subscriptions.push(
      this.form.controls.phone_prefix.valueChanges.subscribe((newValue) => {
        if (newValue) {
          this.filteredPrefix = this.filterPrefixes(newValue);
        }
      })
    );
  }

  public filterPrefixes(search: string) {
    return this.prefix.filter((value) =>
      value.phone_code
        .toString()
        .toLowerCase()
        .includes(search.toString().toLowerCase())
    );
  }

  public getPhonePrefix() {
    if (this.expat.user && this.expat.user.phone_prefix) {
      if (this.prefix) {
        const prefix = this.prefix.find((pre) => {
          const regExp = /\(([^)]+)\)/;
          return (
            pre.phone_code === this.expat.user.phone_prefix ||
            regExp.exec(pre.phone_code)
          );
        });
        return prefix.phone_code;
      }
    }
    return "";
  }

  private setupForm() {
    this.form = this.fb.group(
      {
        first_name: new UntypedFormControl(
          this.getFormControl("user", "first_name"),
          Validators.required
        ),
        last_name: new UntypedFormControl(
          this.getFormControl("user", "last_name"),
          Validators.required
        ),
        department_name: new UntypedFormControl(
          this.getFormControl("details", "department_name"),
          Validators.required
        ),
        company_position: new UntypedFormControl(
          this.getFormControl("details", "company_position"),
          Validators.required
        ),
        email: new UntypedFormControl(this.getFormControl("user", "email"), [
          Validators.required,
          Validators.email,
        ]),
        phone_prefix: new UntypedFormControl(Validators.required),
        phone: new UntypedFormControl(this.phone, [
          Validators.pattern("[0-9]+"),
          Validators.required,
        ]),
        adults_number: new UntypedFormControl(this.expat.adults_number, [
          Validators.pattern("[0-9]+"),
          Validators.required,
        ]),
        children_number: new UntypedFormControl(this.expat.children_number, [
          Validators.pattern("[0-9]+"),
          Validators.required,
        ]),
        country_code: new UntypedFormControl(
          this.expat.country_code,
          Validators.required
        ),
      },
      {
        validators: dependentFields("phone", "phone_prefix"),
      }
    );
  }

  private get phone() {
    if (this.expat && this.expat.user && this.expat.user.phone) {
      return this.expat.user.phone;
    }
    return null;
  }

  private getFormControl(firstProperty: string, secondProperty: string) {
    if (
      this.expat[firstProperty] &&
      this.expat[firstProperty][secondProperty] &&
      this.expat[firstProperty][secondProperty]
    ) {
      return this.expat[firstProperty][secondProperty];
    }
    return "";
  }

  private getExpat() {
    if (!this.expat) {
      this.expat = new Expat();
      this.edit = false;
    } else {
      this.edit = true;
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

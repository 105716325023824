<!-- modal -->
<div class="p-3">
  <div>
    <div class="modal-title">
      {{ "GENERALS.ADD-PROVIDER" | translate }}
    </div>

    <div class="modal-step-wrapper m-0">
      <ul>
        <li [ngClass]="{ 'active-step': currentStep === 1 }">
          {{ "GENERALS.ACCOUNT" | translate }}
        </li>
        <li [ngClass]="{ 'active-step': currentStep === 2 }">
          {{ "GENERALS.COMPANY" | translate }}
        </li>
        <li [ngClass]="{ 'active-step': currentStep === 3 }">
          {{ "GENERALS.SERVICES" | translate }}
        </li>
      </ul>
    </div>
  </div>

  <div class="clear"></div>

  <div class="custom-modal-body">
    <!-- add provider step 1 -->
    <ng-container *ngIf="currentStep === 1">
      <app-loader-spinner-general
        *ngIf="isLoading.provider"
        [repeat]="4"
        [loadingType]="LoadingType.INFO"
      ></app-loader-spinner-general>
      <app-add-provider-step-1
        *ngIf="!isLoading.provider"
        [user]="provider"
        [isCanceled]="isCanceled"
      ></app-add-provider-step-1>
    </ng-container>
    <!-- end add provider step 1 -->

    <!-- add provider step 2 -->

    <ng-container *ngIf="currentStep === 2">
      <app-loader-spinner-general
        [repeat]="8"
        *ngIf="isLoading.company"
        [loadingType]="LoadingType.INFO"
      ></app-loader-spinner-general>
      <app-add-provider-step2
        *ngIf="!isLoading.company"
        [provider]="provider"
        [countries]="countries"
      ></app-add-provider-step2>
    </ng-container>
    <!-- end add provider step 2 -->

    <!-- add provider step 3 -->
    <ng-container *ngIf="currentStep === 3">
      <div class="modal-body-desc">
        {{ "GENERALS.REGISTRATION-STEPT-3-PROVIDER-DESC" | translate }}
      </div>
      <div *ngIf="isLoading.categories">
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <ng-container *ngIf="!isLoading.categories">
        <div class="modal-form">
          <div class="row">
            <div class="form-group w-100">
              <app-add-commissions
                [serviceCategories]="categories"
                [level]="0"
                (onselect)="updateCategorySelection($event)"
              ></app-add-commissions>
            </div>
          </div>

          <app-chips-select
            [items]="countries"
            [multiple]="true"
            [placeholder]="'GENERALS.COUNTRIES-CHIPS-PLACEHOLDER'"
            [bindValue]="'name'"
            [bindLabel]="'name'"
            (onselect)="selectedCountries = $event"
          ></app-chips-select>
        </div>
      </ng-container>
    </ng-container>
    <!-- end add provider step 3 -->
  </div>
  <div class="clear"></div>
  <div mat-dialog-actions align="end">
    <button
      type="button"
      class="btn gray-bg m-r-20"
      mat-button
      mat-dialog-close
    >
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button
      *ngIf="currentStep === 1"
      type="button"
      class="btn orange-bg"
      (click)="createProviderAccount()"
    >
      {{ "GENERALS.NEXT" | translate }}
    </button>
    <button
      *ngIf="currentStep === 2"
      type="button"
      class="btn orange-bg"
      (click)="createProviderCompany()"
    >
      {{ "GENERALS.NEXT" | translate }}
    </button>
    <button
      *ngIf="currentStep === 3"
      type="button"
      class="btn orange-bg"
      (click)="setCountriesAndCategories()"
    >
      {{ "GENERALS.FINISH" | translate }}
    </button>
    <button
      *ngIf="currentStep === 3"
      type="button"
      class="btn orange-bg"
      (click)="setCountriesAndCategories('add_another')"
    >
      {{ "GENERALS.ADD-ANOTHER" | translate }}
    </button>
  </div>
</div>
<!-- end modal -->

<!-- default search input -->
<div class="advanced-search-input">
  <div *ngIf="showTextField" class="icon-inside-input">
    <i class="icon-search-1"></i>
    <input
      (keyup)="emitKeyword()"
      type="text"
      name="search_text"
      placeholder="{{ searchPlaceholder | translate }}"
      [(ngModel)]="keyword"
    />
  </div>

  <span
    #toggleAdvancedSearch
    (click)="toggleAdvanceSearch()"
    class="toggle-search-btn"
    *ngIf="availableFilters && availableFilters.length > 0"
  >
    <i [ngClass]="!showAdvanced ? 'icon-plus' : 'icon-minus'"></i>
    <!-- <span *ngIf="!showAdvanced">{{
      'GENERALS.ADVANCED-SEARCH' | translate
    }}</span>
    <span *ngIf="showAdvanced">{{
      'GENERALS.HIDE-ADVANCED-SEARCH' | translate
    }}</span> -->

    <span>{{
      (!showAdvanced
        ? "GENERALS.ADVANCED-SEARCH"
        : "GENERALS.HIDE-ADVANCED-SEARCH"
      ) | translate
    }}</span>
  </span>
  <span *ngIf="total" class="gray total-items-fiter"
    >{{ total }} {{ "GENERALS.RESULTS" | translate | lowercase }}</span
  >
  <div class="clear"></div>
</div>
<!-- end default search input -->

<!-- toggle advanced tools for search (filters)-->
<div #showAdvancedSearch class="toggle-advanced-search" *ngIf="showAdvanced">
  <ng-container *ngFor="let filterElement of availableFilters">
    <div
      class="advance-search-row"
      *ngIf="filterOptions && filterOptions[filterElement.id]"
    >
      <div class="select-group-block">
        <label>{{ filterElement.label | translate }}</label>
        <ng-select
          class="customiz-select"
          [items]="filterElement.data"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ 'GENERALS.SELECT' | translate }}"
          [(ngModel)]="filters[filterElement.id]"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          (change)="filter.emit(filters)"
          (search)="
            filterElement.canLoadMoreData && loadMoreData($event, filterElement)
          "
        >
        </ng-select>
      </div>
    </div>
  </ng-container>

  <!-- period selection -->
  <ng-container *ngIf="showCalendar">
    <div class="advance-search-row">
      <label>{{ "FILTERS.DATE_RANGE" | translate }} </label>
      <div class="fl-l">
        <app-date-range-calendar
          [dateRange]="dateRangeMultipleMonths"
          [placeholder]="'GENERALS.SELECT-DATE' | translate"
          (change)="setCalendarFilter($event)"
        >
        </app-date-range-calendar>
      </div>
      <div *ngIf="showClearBtn" class="fl-l calendar-clear-inline">
        <div class="orange-bg btn-small" (click)="clearFilterData()">
          {{ "GENERALS.CLEAR-DATE" | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <!-- end period selection -->

  <!-- end provider search -->
  <!-- invoice issue date selection -->
  <ng-container *ngIf="issueDate">
    <div class="advance-search-row">
      <label>{{ "FILTERS.INVOICE-ISSUE-DATE" | translate }} </label>
      <div class="fl-l">
        <app-date-range-calendar
          [dateRange]="issueDateRangeMultipleMonths"
          [placeholder]="'GENERALS.SELECT-DATE' | translate"
          (change)="setInvoiceIssueDateFilter($event)"
        >
        </app-date-range-calendar>
      </div>
      <div *ngIf="showClearIssueDateBtn" class="fl-l calendar-clear-inline">
        <div
          class="orange-bg btn-small"
          (click)="clearInvoiceIssueDateFilter()"
        >
          {{ "GENERALS.CLEAR-DATE" | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <!-- end invoice issue date selection -->

  <!-- invoice due date selection -->
  <ng-container *ngIf="dueDate">
    <div class="advance-search-row">
      <label>{{ "FILTERS.INVOICE-DUE-DATE" | translate }} </label>
      <div class="fl-l">
        <app-date-range-calendar
          [dateRange]="dueDateRangeMultipleMonths"
          [placeholder]="'GENERALS.SELECT-DATE' | translate"
          (change)="setInvoiceDueDateFilter($event)"
        >
        </app-date-range-calendar>
      </div>
      <div *ngIf="showClearDueDateBtn" class="fl-l calendar-clear-inline">
        <div class="orange-bg btn-small" (click)="clearInvoiceDueDateFilter()">
          {{ "GENERALS.CLEAR-DATE" | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <!-- end invoice due date selection -->

  <!-- invoice payment date selection -->
  <ng-container *ngIf="paymentDate">
    <div class="advance-search-row">
      <label>{{ "FILTERS.INVOICE-PAYMENT-DATE" | translate }} </label>
      <div class="fl-l">
        <app-date-range-calendar
          [dateRange]="paymentDateRangeMultipleMonths"
          [placeholder]="'GENERALS.SELECT-DATE' | translate"
          (change)="setInvoicePaymentDateFilter($event)"
        >
        </app-date-range-calendar>
      </div>
      <div *ngIf="showClearPaymentDateBtn" class="fl-l calendar-clear-inline">
        <div
          class="orange-bg btn-small"
          (click)="clearInvoicePaymentDateFilter()"
        >
          {{ "GENERALS.CLEAR-DATE" | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <!-- end invoice payment date selection -->
</div>

<!-- end toggle advanced tools for search (filters)-->

<div class="modal-form">
  <div class="row">
    <div class="w-100">
      <div class="form-group-title">
        {{ "GENERALS.COMPANY-INFORMATION" | translate }}
      </div>
    </div>
  </div>
  <div *ngIf="this.edit !== false" class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.COMPANY-NAME" | translate }} *</mat-label>
        <input [(ngModel)]="provider.company_name" matInput />
      </mat-form-field>
    </div>
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.COMPANY-LEGAL-NAME" | translate }} *</mat-label>
        <input [(ngModel)]="provider.company_legal_name" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <div class="w-100">
        <app-loader-spinner-general
          *ngIf="isLoading.company"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <mat-form-field
        appearance="outline"
        class="w-100"
        *ngIf="!isLoading.company"
      >
        <mat-label>{{ "LABEL.COMPANY-TYPE" | translate }} *</mat-label>
        <mat-select [(ngModel)]="provider.company_type_id">
          <mat-option *ngFor="let type of companyTypes" [value]="type.id">
            {{ type.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.COMPANY-TAX-NUMBER" | translate }} *</mat-label>
        <input [(ngModel)]="provider.company_tax_number" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "GENERALS.CURRENCY" | translate }} *</mat-label>
        <mat-select
          [(ngModel)]="provider.currency_id"
          [disabled]="edit && preselectedCurrency"
        >
          <mat-option *ngFor="let curr of currency" [value]="curr.id">
            {{ curr.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="w-50">
      <div class="w-100">
        <app-loader-spinner-general
          *ngIf="isLoading.professional"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <mat-form-field
        appearance="outline"
        class="w-100"
        *ngIf="!isLoading.professional"
      >
        <mat-label>{{ "LABEL.PROF-ASSOCIATION" | translate }}</mat-label>
        <mat-select [(ngModel)]="provider.professional_association_id">
          <mat-option
            *ngFor="let item of professionalAssociation"
            [value]="item.id"
          >
            {{ item.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <div class="w-100">
        <app-loader-spinner-general
          *ngIf="isLoading.company"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <mat-form-field
        appearance="outline"
        class="w-100"
        *ngIf="!isLoading.employees"
      >
        <mat-label>{{ "LABEL.NUMBER-OF-EMPLOYEES" | translate }}</mat-label>
        <mat-select [(ngModel)]="provider.number_of_employees_id">
          <mat-option
            *ngFor="let employee of employeesNumber"
            [value]="employee.id"
          >
            {{ employee.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.GOOGLE_PLACES_ID" | translate }}</mat-label>
        <input [(ngModel)]="provider.google_places_id" matInput />
      </mat-form-field>
    </div>
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.HUBSPOT_ID" | translate }} *</mat-label>
        <input [(ngModel)]="provider.hubspot_id" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="w-100">
      <label>{{ "LABEL.COMPANY-SHORT-DESCRIPTION" | translate }} *</label>
      <textarea
        name="short_description"
        class="textarea-control"
        [(ngModel)]="provider.short_description"
      ></textarea>
    </div>
  </div>

  <div class="row">
    <div class="w-100">
      <div class="form-group-title">
        {{ "GENERALS.COMPANY-ADDRESS" | translate }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.COMPANY-ADDRESS" | translate }} *</mat-label>
        <input [(ngModel)]="provider.address" matInput />
      </mat-form-field>
    </div>

    <div class="w-50" [formGroup]="form">
      <app-country-selector
        #countrySelector
        formControlName="country_code"
        [onlyCode]="true"
      >
        <ng-container slot="label">
          {{ "LABEL.COMPANY-COUNTRY" | translate }}
        </ng-container>
      </app-country-selector>
    </div>
  </div>

  <div class="row">
    <div class="w-50 p-0">
      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.COUNTY-STATE" | translate }} *</mat-label>
          <input [(ngModel)]="provider.region" matInput />
        </mat-form-field>
      </div>
      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.ZIP-CODE" | translate }} *</mat-label>
          <input [(ngModel)]="provider.zip_code" matInput />
        </mat-form-field>
      </div>
    </div>

    <div class="w-50 p-0" [formGroup]="form">
      <app-city-selector
        [countrySelectorComponent]="countrySelector"
        formControlName="city"
      >
        <ng-container slot="label">
          {{ "LABEL.COMPANY-CITY" | translate }}
        </ng-container>
      </app-city-selector>
    </div>
  </div>

  <div class="row">
    <div class="w-100">
      <div class="form-group-title">
        {{ "GENERALS.BANK-DETAILS" | translate }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <div class="row">
        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "LABEL.BANK-NAME" | translate }} *</mat-label>
            <input [(ngModel)]="provider.bank_name" matInput />
          </mat-form-field>
        </div>
        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "LABEL.SWIFT-CODE" | translate }} *</mat-label>
            <input [(ngModel)]="provider.swift_code" matInput />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }} *</mat-label>
        <input [(ngModel)]="provider.bank_account_number" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="w-100">
      <div class="form-group-title">
        {{ "GENERALS.LEGAL-REPRESENTATIVE" | translate }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label
          >{{
            "LABEL.LEGAL-REPRESENTATIVE-FIRST-LAST-NAME" | translate
          }}
          *</mat-label
        >
        <input [(ngModel)]="provider.legal_representative_name" matInput />
      </mat-form-field>
    </div>

    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label
          >{{ "LABEL.LEGAL-REPRESENTATIVE-EMAIL" | translate }} *</mat-label
        >
        <input [(ngModel)]="provider.legal_representative_email" matInput />
      </mat-form-field>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { ExpatDetails } from "src/app/_models/_menu/expat-details";
import { ExpatsService } from "src/app/_services/expats.service";
import { combineLatest } from "rxjs";

@Component({
  selector: "app-expat",
  templateUrl: "./expat.component.html",
})
export class ExpatComponent implements OnInit {
  menu;
  id;
  expat;
  filters;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private expatService: ExpatsService
  ) {
    combineLatest(this.route.params, this.route.queryParams).subscribe(
      ([params, query]) => {
        this.id = params["id"];
        this.filters = query;

        this.menu = new ExpatDetails(params["id"], query);
      }
    );
  }

  ngOnInit() {
    // get expat details to show the name at the top
    this.expatService.getExpatById(this.id).subscribe((data) => {
      this.expat = data.result;
    });
  }
}

<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="addProfilePicture"
    #addProfilePicture
    identifier="addProfilePicture"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">
        {{ title ? (title | translate) : ("GENERALS.ADD-DOC" | translate) }}
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <app-loader-spinner-general
        [isLoadingGeneral]="isLoading"
      ></app-loader-spinner-general>
      <div class="modal-form">
        <div class="row">
          <div class="form-group" [ngClass]="'w-100'">
            <label>{{ "GENERALS.UPLOAD-PIC" | translate }}</label>

            <div class="custom-upload-box">
              <file-upload
                [(ngModel)]="uploadedFiles.file"
                [control]="fileUploadControl"
              >
                <ng-template #placeholder>
                  <span><i class="icon-upload-cloud"></i></span>
                  <span>{{ "GENERALS.DROP-FILE-TO-UPLOAD" | translate }} </span>
                  <span class="orange">
                    {{ "GENERALS.BROWSE" | translate }}</span
                  >
                </ng-template>

                <ng-template
                  let-i="index"
                  let-file="file"
                  let-control="control"
                  #item
                >
                  <div class="file-info">
                    <span class="file-name">{{ file.name }}</span>
                    <span
                      class="remove-file"
                      (click)="control.removeFile(file)"
                    >
                      <i class="icon-trash"></i
                      >{{ "GENERALS.REMOVE-FILE" | translate }}</span
                    >
                  </div>
                </ng-template>
              </file-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button type="button" class="btn gray-bg" (click)="cancelModal()">
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button
        [disabled]="isLoading"
        type="button"
        class="btn orange-bg"
        (click)="uploadImage()"
      >
        {{ "GENERALS.ADD" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->

import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const expatsUrl = "entities/expats";
@Directive()
@Injectable()
export class ExpatsService {
  @Output() statusChange: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {}

  getExpatsList(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + expatsUrl, {
      params: params,
    });
  }

  getExpatById(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + expatsUrl + "/" + id
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/expats/status-transition"
    );
  }

  getSearchFields() {
    return this.http.get(
      environment.gateway_endpoint + "entities/expats/search-fields"
    );
  }

  createExpat(params): Observable<any> {
    return this.http
      .post<any>(environment.gateway_endpoint + expatsUrl, params, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateExpat(params): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + expatsUrl + "/" + params.id,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateStatusBulk(status_id, expats_ids, reason = ""): Observable<any> {
    let body: any = { status_id: status_id, ids: expats_ids };
    if (reason) {
      body = {
        status_id: status_id,
        ids: expats_ids,
        declined_reason: reason,
      };
    }
    const response = this.http.patch<any>(
      environment.gateway_endpoint + "entities/expats/updateStatus",
      body,
      httpOptions
    );

    this.statusChange.emit();

    return response;
  }

  // GET Expat history
  getEntityHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "history/expat/" + id,
      { params: params }
    );
  }

  getProfilePicture(id) {
    return this.http.get(
      environment.gateway_endpoint + "expat/" + id + "/show-profile-picture"
    );
  }

  /**
   * Get number of expats pending approval
   */
  getPendingCount(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + expatsUrl + "/pending/count",
      { params: { "low-priority": "low-priority" } }
    );
  }
}

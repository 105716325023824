import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Directive()
@Injectable()
export class CommissionsService {
  private serviceUrl = "entities/providers/commissions";
  private requestsURL = "entities/providers/commission-requests";

  @Output() statusChange: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {}

  // GET Commissions
  getCommissions(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.serviceUrl, {
      params: params,
    });
  }

  /**
   * Add a new custom commission to a provider category
   * @param commission
   */
  addCommission(commission): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.serviceUrl,
        commission,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Status transitions for commission requests
   */
  getRequestsStatusTransitions(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.requestsURL + "/status-transitions"
    );
  }

  /**
   * Update an existing custom commission
   * @param commissions
   */
  updateCommission(commissions): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + "entities/providers/commissions",
        commissions,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Delete a custom commission
   * @param commissionId
   */
  deleteCommission(commissionId): Observable<any> {
    return this.http.delete<any>(
      environment.gateway_endpoint + this.serviceUrl + "/" + commissionId
    );
  }

  getRequestsCount(params = {}): Observable<any> {
    params["low-priority"] = "low-priority";
    return this.http.get<any>(
      environment.gateway_endpoint + this.requestsURL + "/count",
      {
        params: params,
      }
    );
  }

  /**
   * Get the list of commission requests to approve
   * @param params
   */
  getRequests(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.requestsURL, {
      params: params,
    });
  }

  /**
   * Bulk approve or decline requests
   * @param status_id
   * @param commission_ids
   * @param reason
   */
  updateStatusBulk(status_id, commission_ids, reason = ""): Observable<any> {
    let body: any = {
      status_id: status_id,
      commission_request_ids: commission_ids,
    };
    if (reason) {
      body = {
        status_id: status_id,
        commission_request_ids: commission_ids,
        declined_reason: reason,
      };
    }
    const response = this.http.patch<any>(
      environment.gateway_endpoint + this.requestsURL,
      body,
      httpOptions
    );

    this.statusChange.emit();

    return response;
  }
}

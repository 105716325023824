import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Provider } from "../_models/provider";
import { map } from "rxjs/operators";
import { ProvidersCommissions } from "../_models/providerscommissions";
import { User } from "../_models/user";
import { ProviderInvite } from "../_models/provider-invite";
import { EntityData } from "../modules/company/models/entity.data";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Directive()
@Injectable()
export class ProvidersService {
  private providersUrl = "entities/providers";

  @Output() statusChange: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {}

  // GET Providers
  getProviders(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.providersUrl,
      { params: params }
    );
  }

  // GET Provider by id
  getProvider(id: number): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + `entities/providers/${id}`
    );
  }

  // GET Provider history
  getEntityHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "history/provider/" + id,
      { params: params }
    );
  }

  // GET Providers
  getProviderCommission(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.providersUrl + `/${id}/commission`
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/provider/status-transitions"
    );
  }

  // POST Create Provider step 1
  createProviderStep1(provider: Provider): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.providersUrl,
        provider,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // POST Create Provider step 2
  createProviderStep2(provider: Provider, id: number): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.providersUrl + "/" + id,
        provider,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // POST Create Provider step 3
  createProviderStep3(
    providerId: number,
    categories: ProvidersCommissions
  ): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          `entities/providers/${providerId}/categories`,
        categories,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update provider commissions and categories
  updateCategory(
    categories: ProvidersCommissions,
    providerId
  ): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/categories`,
        categories,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateHubspot(data: any, providerId): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/hubspot`,
        data,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model.result;
        })
      );
  }
  deleteCommission(id): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint + "entities/providers/commissions/" + id,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  removeCategory(categoryId, providerId): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          "entities/providers/" +
          providerId +
          "/categories/" +
          categoryId,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // POST Set Country Provider step 3
  setCountryProviderStep3(countries, providerId): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/countries`,
        countries,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // POST Create User for provider
  createUser(id, user: User): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.providersUrl + `/${id}/users`,
        user,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update Status Bulk
  updateStatusBulk(status_id, provider_ids, reason = null): Observable<any> {
    const body = { status_id: status_id, entity_ids: provider_ids };
    if (reason) {
      body["reason"] = reason;
    }
    const response = this.http.patch<any>(
      environment.gateway_endpoint + this.providersUrl,
      body,
      httpOptions
    );

    this.statusChange.emit();

    return response;
  }

  // Log in as specified provider
  switchToAccount(account_info) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "switch-to-provider",
        account_info,
        httpOptions
      )
      .pipe(
        map((data) => {
          if (data && data.result.token) {
            // get user information from the admin, not the provider
            const user = localStorage.getItem(
              environment.user_local_storage_key
            );
            data.result.user = JSON.parse(user);
            // serialize and encode session data to use on portal
            const response = btoa(JSON.stringify(data.result));
            window.open(
              environment.provider_portal + "sso/?token=" + response,
              "_blank"
            );
            // window.location.href =
            //   environment.provider_portal + 'sso/?token=' + response;
          }
        })
      );
  }

  // PUT Edit Company info
  updateCompanyDetails(provider: EntityData): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}${this.providersUrl}/${provider.id}`,
        provider,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Get provider categories
  getProviderCategories(id): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}entities/providers/${id}/categories`
    );
  }

  // Update Managed state
  updateManagedState(id: number, managedState: boolean): Observable<any> {
    return this.http.patch<any>(
      `${environment.gateway_endpoint}entities/providers/${id}/managed-state`,
      { is_managed: managedState },
      httpOptions
    );
  }

  // POST provider sent invite
  inviteProvider(providerInvite: ProviderInvite): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "entities/providers/invite",
      providerInvite,
      httpOptions
    );
  }

  getProviderCountries(providerId): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        "entities/providers/" +
        providerId +
        "/countries"
    );
  }

  /**
   * Get number of providers pending approval
   */
  getPendingCount(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.providersUrl + "/pending/count",
      { params: { "low-priority": "low-priority" } }
    );
  }

  setProviderInvoiceDueDays(invoiceDueDays, providerId): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/invoice-due`,
        invoiceDueDays,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}

<div class="white-box-row">
  <div class="white-box-header">
    <div class="medium-title fl-l">
      {{ "GENERALS.COMPANY-INFO" | translate }}
    </div>
    <div
      class="white-box-header-action orange fl-r edit-info"
      (click)="openEditModal()"
    >
      <i class="icon-edit"></i>
      <span class="fw-700">
        {{ "GENERALS.EDIT" | translate }}
      </span>
    </div>
  </div>
  <div class="clear"></div>
  <div class="white-box-body">
    <div class="row">
      <div class="w-50">
        <div>
          <span class="font-weight-bold">
            {{ "LABEL.COMPANY-NAME" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.company_name }}
          </p>
        </div>

        <div>
          <span class="font-weight-bold">
            {{ "LABEL.TAX-NUMBER" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.company_tax_number | notAvailable }}
          </p>
        </div>

        <div>
          <span class="font-weight-bold">
            {{ "LABEL.COMPANY-ADDRESS" | translate }}
          </span>
          <p class="case-label m-t-10" *ngIf="entity.address">
            {{ entity.address }}, {{ entity.city }}, {{ entity.country_code }},
            {{ entity.zip_code }}
          </p>
          <p class="case-label m-t-10" *ngIf="!entity.address">N/A</p>
        </div>

        <div>
          <span class="font-weight-bold">
            {{ "LABEL.COMPANY-TYPE" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.company_type_name | notAvailable }}
          </p>
        </div>

        <div *ngIf="entityType === entityTypes.PROVIDER">
          <span class="font-weight-bold">
            {{ "LABEL.LEGAL-REPRESENTATIVE-FIRST-LAST-NAME" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.legal_representative_name }}
          </p>
        </div>

        <div *ngIf="entityType === entityTypes.PROVIDER">
          <span class="font-weight-bold">
            {{ "LABEL.LEGAL-REPRESENTATIVE-EMAIL" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.legal_representative_email | notAvailable }}
          </p>
        </div>

        <p *ngIf="entityType === entityTypes.PROVIDER">
          <span>{{ "LABEL.ONLINE-PAYMENTS-STATUS" | translate }}</span>
          <span
            class="entity_status"
            [className]="primaryAccount?.user.stripe_account?.status"
          >
            {{ primaryAccount?.user.stripe_account?.status }}
          </span>
        </p>
      </div>

      <div class="w-50">
        <div *ngIf="entityType === entityTypes.PROVIDER">
          <span class="font-weight-bold">
            {{ "LABEL.COMPANY-LEGAL-NAME" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.company_legal_name | notAvailable }}
          </p>
        </div>

        <div *ngIf="entityType === entityTypes.PROVIDER">
          <span class="font-weight-bold">
            {{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.bank_account_number | notAvailable }}
          </p>
        </div>

        <div *ngIf="entityType === entityTypes.PROVIDER">
          <span class="font-weight-bold">
            {{ "LABEL.BANK" | translate }}
          </span>
          <p class="case-label m-t-10 m-t-10">
            {{ entity.bank_name | notAvailable }}
          </p>
        </div>

        <div *ngIf="entityType === entityTypes.PROVIDER">
          <span class="font-weight-bold">
            {{ "LABEL.SWIFT-CODE" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.swift_code | notAvailable }}
          </p>
        </div>

        <div *ngIf="entityType === entityTypes.PROVIDER">
          <span class="font-weight-bold">
            {{ "LABEL.PROF-ASSOCIATION" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.professional_association_name | notAvailable }}
          </p>
        </div>

        <div *ngIf="entityType === entityTypes.CLIENT">
          <span class="font-weight-bold">
            {{ "LABEL.INDUSTRY" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.industry_name | notAvailable }}
          </p>
        </div>

        <div>
          <span class="font-weight-bold">
            {{ "LABEL.NUMBER-OF-EMPLOYEES" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.number_of_employees_name | notAvailable }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="w-50 hubspot-field"
        *ngIf="entityType === entityTypes.PROVIDER"
      >
        <div
          class="white-box-header-action orange fl-r edit-info"
          (click)="openHubspotModal()"
        >
          <i class="icon-edit"></i>
          <span class="fw-700">
            {{ "GENERALS.EDIT" | translate }}
          </span>
        </div>
        <div class="flex-grow-1">
          <span class="font-weight-bold">
            {{ "LABEL.HUBSPOT_ID" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ entity.hubspot_id | notAvailable }}
          </p>
        </div>
      </div>

      <div class="w-50" *ngIf="entityType === entityTypes.PROVIDER">
        <span class="font-weight-bold">
          {{ "LABEL.COMPANY-SHORT-DESCRIPTION" | translate }}
        </span>
        <p
          *ngIf="entity.short_description"
          class="desc-content-innerHtml case-label m-t-10"
          [innerHTML]="entity.short_description"
        ></p>
      </div>
    </div>
  </div>
</div>

export default function deepCastToInt(value) {
  if (typeof value !== "object") {
    return value;
  }

  const newValue = {};

  for (const x in value) {
    if (parseInt(value[x], 10) == value[x]) {
      // don't touch the search text; see NES-1500
      if (x === "search_text") {
        newValue[x] = value[x];
      } else {
        newValue[x] = parseInt(value[x], 10);
      }
    } else if (typeof value[x] === "object") {
      newValue[x] = deepCastToInt(value[x]);
    } else {
      newValue[x] = value[x];
    }
  }

  return newValue;
}

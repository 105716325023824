<ngx-smart-modal
  id="bulkUploadErrors"
  #bulkUploadErrors
  identifier="bulkUploadErrors"
  [dismissable]="false"
  (onAnyCloseEvent)="close()"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "GENERALS.UPLOAD-OFFERS" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <!-- Modal body -->
  <div class="custom-modal-body">
    <div class="fw-500 fs-16 m-b-10">
      {{ "GENERALS.UPLOAD-OFFERS-ERRORS-1" | translate }}.
    </div>
    <div class="fw-500 fs-16 m-b-10">
      {{ "GENERALS.UPLOAD-OFFERS-ERRORS-2" | translate }}.
    </div>
    <div class="fw-500 fs-16 m-b-10">
      {{ "GENERALS.UPLOAD-OFFERS-ERRORS-3" | translate }}.
    </div>

    <div class="fw-500 fs-16 m-b-10">
      <div class="btn orange-bg" (click)="downloadSpreadsheet()">
        {{ "GENERALS.DOWNLOAD-SPREADSHEET" | translate }}
      </div>
    </div>
    <br />

    <div
      class="fw-500 fs-16 m-b-10"
      [innerHTML]="'GENERALS.NOT-UPLOAD-ORIGINAL-AGAIN' | translate"
    ></div>
  </div>

  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button type="button" class="btn gray-bg" (click)="close()">
      {{ "GENERALS.CLOSE" | translate }}
    </button>
  </div>
</ngx-smart-modal>

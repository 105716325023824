<app-loader-spinner-general
  [isLoadingGeneral]="isLoadingGeneral"
></app-loader-spinner-general>

<div class="details-body-wrapper" *ngIf="user || !isLoadingGeneral">
  <!-- 2 col details -->
  <div class="details-2c">
    <!-- left -->
    <div class="details-2c-left">
      <div class="small-box">
        <div class="small-box-header-img" (click)="openPicModal()">
          <img
            [src]="
              user.primary_image_path
                ? user.primary_image_path
                : '../assets/images/profile-pics-placeholder.jpg'
            "
            alt="img"
            class="profile-pic"
          />
          <div class="edit-trigger">
            {{ "PORTAL.CHANGE-PIC" | translate }}
          </div>
        </div>
        <div class="small-box-details">
          <div class="medium-title">{{ user.company_name }}</div>
          <div class="small-box-status">
            <div class="entity_status-label-{{ user.status_id }}">
              {{ user.status_name }}
            </div>
          </div>
          <div class="fw-500" *ngIf="user.status_reason || ''">
            <span class="gray block-span"
              >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
            >
            {{ user.status_reason }}
          </div>
          <div class="fw-500">
            <span class="gray block-span"
              >{{ "GENERALS.REGISTRED-ON" | translate }}:</span
            >
            {{ user.created_at | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="small-box-list" *ngIf="actions">
          <ul>
            <ng-container *ngFor="let option of actions[user.status_id]">
              <li *ngIf="showAction(option.id)">
                <div
                  (click)="changeStatus(option, user)"
                  class="btn-small orange-bg"
                >
                  {{ option.text | translate }}
                </div>
              </li>
            </ng-container>
            <li
              *ngIf="!user.confirmed_account"
              class="action-link-gray"
              (click)="resendConfirmationMail()"
            >
              <i class="icon-mail"></i>
              {{ "GENERALS.RESEND-CONFIRMATION-MAIL" | translate }}
            </li>
            <li
              *ngIf="!user.is_primary"
              class="action-link-gray"
              (click)="makePrimry(user.id)"
            >
              <i class="icon-ok"></i>
              {{ "GENERALS.MAKE-PRIMARY" | translate }}
            </li>
            <li class="action-link-gray" (click)="resetPassword()">
              <i class="icon-lock-reset"></i>
              {{ "AUTH.CHANGE-PASSWORD" | translate }}
            </li>
          </ul>
        </div>
        <!-- <div class="small-box-status status-label-{{ user.status_id }}">
          {{ user.status_name?.toUpperCase() }}
        </div> -->
      </div>
    </div>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.ACCOUNT-INFO" | translate }}
            </div>
            <div class="white-box-header-action fl-r" (click)="openModal()">
              {{ "GENERALS.EDIT" | translate }}
              <i class="icon-cog-outline"></i>
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <p>{{ user.name }}</p>
            <p>{{ user.role_name }}</p>
            <p *ngIf="user.role.toLowerCase() === 'client'">
              {{ user.company_position }},
              {{ user.department_name }}
            </p>
            <p *ngIf="user.role.toLowerCase() === 'provider'">
              {{ user.company_position }}
            </p>
            <p>
              <a href="mailto:{{ user.email }}">
                <i class="icon-mail"></i>
                {{ user.email }}
              </a>
            </p>
            <p>
              <i class="icon-phone"></i>
              {{ dictionariesService.getPhonePrefix(user.phone_prefix) }}
              {{ user.phone }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>

<app-add-profile-picture
  [entityType]="'user'"
  [entityId]="user?.id"
  [title]="'GENERALS.UPLOAD-PIC'"
  (changePicture)="handleEvent($event)"
></app-add-profile-picture>

import * as moment from "moment";

export class InvoiceExtraData {
  invoice_amount: number;
  invoice_currency: string;
  invoice_number: string;
  invoice_due_date: string | moment.Moment;
  invoice_issue_date: string | moment.Moment;
  invoice_status: string;
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "../../shared/shared.module";
import { AssignPlanComponent } from "./components/assign-plan/assign-plan.component";
import { AssignPlanFormComponent } from "./components/assign-plan-form/assign-plan-form.component";

@NgModule({
  declarations: [AssignPlanComponent, AssignPlanFormComponent],
  imports: [CommonModule, SharedModule, TranslateModule],
  exports: [AssignPlanComponent],
})
export class SubscriptionsSharedModule {}

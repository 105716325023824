import { ProviderUser } from "./provider-user";

export class Provider {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  region: string;
  city: string;
  address: string;
  company_tax_number: string;
  zip_code: string;
  company_name: string;
  department_id: number;
  department_name: string;
  users: Array<ProviderUser>;
  countries: Array<any>;
  status_id: number;
  status_name: string;
  company_type_id: number;
  company_type_name: string;
  phone: string;
  phone_prefix: string;
  company_position: string;
  short_description: string;
  legal_representative_name: string;
  legal_representative_email: string;
  bank_account_number: string;
  bank_name: string;
  swift_code: string;
  created_at: string;
  currency_id: number;
  professional_association_id: number;
  number_of_employees_id: number;
  company_legal_name: string;
  entity_type_id = 1;
  invoice_due_days: number;
  google_places_id: string;
  hubspot_id: string;
}

<div *ngIf="breadcrumbs" class="breadcrumbs-item-wrapper">
  <ng-container
    *ngFor="let breadcrumb of breadcrumbs; let last = last; let first = first"
  >
    <a
      *ngIf="breadcrumb.url"
      class="breadcrumbs-item"
      [routerLink]="[breadcrumb.url]"
      [ngClass]="{ orange: last }"
      >{{ breadcrumb.label }}</a
    >
    <span
      *ngIf="!breadcrumb.url"
      class="breadcrumbs-item"
      [ngClass]="{ orange: last }"
      >{{ breadcrumb.label }}</span
    >
    <ng-container *ngIf="!last">
      <span class="breadcrumbs-separator">/</span></ng-container
    >
  </ng-container>
</div>

export class Pricing {
  links: Array<any>;

  constructor() {
    this.links = [
      {
        link: "/pricing/provider",
        filters: [],
        label: "Provider plans",
        page: "provider-plans",
      },
      {
        link: "/pricing/client",
        filters: [],
        label: "Client plans",
        page: "client-plans",
      },
    ];
  }
}

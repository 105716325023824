<ngx-smart-modal
  id="previewBanner"
  #previewBanner
  identifier="previewBanner"
  [dismissable]="false"
  (onAnyCloseEvent)="close()"
  (onOpen)="isModalOpen()"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "GENERALS.PREVIEW-BANNER" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <!-- Modal body -->
  <div class="custom-modal-body" *ngIf="item">
    <div class="modal-body-desc m-t-0 fw-500">
      {{ "GENERALS.HOW-BANNER-LOOKS" | translate }}.
      <br />
      {{ "GENERALS.PLACEMENT" | translate }}:
      <span class="orange">{{ "BANNER-TYPE." + item.key | translate }}</span>
    </div>

    <div #previewAd class="preview-banner-wrapper" id="{{ item.key }}">
      <img src="{{ item.image_url }}" alt="banner" />
    </div>
  </div>

  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button type="button" class="btn gray-bg" (click)="close()">
      {{ "GENERALS.CLOSE" | translate }}
    </button>
  </div>
</ngx-smart-modal>

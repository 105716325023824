import { Provider } from "./provider";

export class Invoice {
  id: number;
  paid_on: string;
  due_on: string;
  issued_on: string;
  amount: string;
  currency_code: string;
  status: string;
  written_off_user_id: string;
  written_off_reason: string;
  start_date: string;
  end_date: string;
  provider_id: string;
  status_id: number;
  provider: Provider;
}

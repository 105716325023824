import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClientDetails } from "src/app/_models/_menu/client-details";
import { Client } from "../../../../_models/client";
import { ClientsService } from "../../../../_services/clients.service";
import { Location } from "@angular/common";
import { combineLatest } from "rxjs";

@Component({
  selector: "app-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.css"],
})
export class ClientComponent implements OnInit {
  private id;
  public menu: ClientDetails;
  public client: Client;
  filters;

  constructor(
    private route: ActivatedRoute,
    private service: ClientsService,
    private location: Location
  ) {
    combineLatest(this.route.params, this.route.queryParams).subscribe(
      ([params, query]) => {
        this.id = params["id"];
        this.filters = query;

        this.menu = new ClientDetails(params["id"], query);
      }
    );
  }

  ngOnInit() {
    // get provider details to show the name at the top
    this.service.getClient(this.id).subscribe((data) => {
      this.client = data.result;
    });
  }
}

<!-- General info -->
<div class="modal-form">
  <div class="row">
    <div class="form-group w-50">
      <label>{{ "GENERALS.CLIENT" | translate }} *</label>

      <ng-select
        class="customiz-select chips-select"
        [items]="clients"
        bindLabel="company_name"
        bindValue="id"
        placeholder="{{ 'MESSAGING.SELECT-RECIPIENT' | translate }}"
        notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        [(ngModel)]="item.client_id"
        (search)="getClients($event)"
      >
      </ng-select>
    </div>

    <div class="form-group w-50">
      <label
        >{{ "LABEL.COUNTRY" | translate }} *
        <span appHoverHelper class="input-helper fl-in"
          ><i class="icon-help_comp"></i>
          <span class="helper-description">{{
            "RFQS.COUNTRY-HELPERS-TXT" | translate
          }}</span>
        </span>
      </label>
      <ng-select
        class="customiz-select"
        [items]="countries"
        bindLabel="name"
        bindValue="country_code"
        placeholder="{{ 'LABEL.SELECT-COUNTRY' | translate }}"
        notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        [(ngModel)]="item.country"
        (change)="countryChanged()"
      >
      </ng-select>
    </div>
  </div>

  <div class="row">
    <div class="form-group w-50">
      <label
        >{{ "LABEL.CITY" | translate }} *
        <span appHoverHelper class="input-helper fl-in"
          ><i class="icon-help_comp"></i>
          <span class="helper-description">{{
            "RFQS.CITY-HELPERS-TXT" | translate
          }}</span>
        </span></label
      >
      <ng-select
        class="customiz-select"
        [items]="cities"
        bindLabel="name"
        bindValue="name"
        placeholder="{{ 'GENERALS.SELECT-CITY' | translate }}"
        [(ngModel)]="item.city"
        notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        (search)="loadCities($event)"
        (focus)="loadCities()"
      >
      </ng-select>
    </div>

    <!-- delivery date -->
    <div class="form-group w-50">
      <label
        >{{ "RFQS.DELIVERY-DEADLINE-DATE" | translate }} *
        <span appHoverHelper class="input-helper fl-in"
          ><i class="icon-help_comp"></i>
          <span class="helper-description">{{
            "RFQS.DELIVERY-DATE-TXT" | translate
          }}</span>
        </span></label
      >
      <app-date-range-calendar
        [placeholder]="'GENERALS.SELECT-DATE' | translate"
        [singleDate]="item.delivery_date"
        [showLabel]="false"
        [minDate]="minDeliveryDate"
        (change)="setFormDate($event, 'delivery')"
      >
      </app-date-range-calendar>
    </div>
    <!-- end delivery date -->
  </div>

  <div class="row">
    <div class="form-group w-100">
      <!-- availability start date -->
      <div class="w-50">
        <label
          >{{ "RFQS.AVAILABILITY-START" | translate }} *
          <span appHoverHelper class="input-helper fl-in"
            ><i class="icon-help_comp"></i>
            <span class="helper-description">{{
              "RFQS.AVAILABILITY-START-HELPER-TXT" | translate
            }}</span>
          </span></label
        >

        <app-date-range-calendar
          [placeholder]="'GENERALS.SELECT-DATE' | translate"
          [singleDate]="item.start_date"
          [showLabel]="false"
          [minDate]="minStartDate"
          [maxDate]="maxStartDate"
          (change)="setFormDate($event, 'start')"
        >
        </app-date-range-calendar>
      </div>

      <div class="form-group timepicker">
        <ngb-timepicker [(ngModel)]="item.start_time"></ngb-timepicker>
      </div>

      <!-- end availability start date -->
    </div>
  </div>

  <div class="row">
    <div class="form-group w-100">
      <!-- availability end date -->
      <div class="w-50">
        <label
          >{{ "RFQS.AVAILABILITY-END" | translate }} *
          <span appHoverHelper class="input-helper fl-in"
            ><i class="icon-help_comp"></i>
            <span class="helper-description">{{
              "RFQS.AVAILABILITY-END-HELPER-TXT" | translate
            }}</span>
          </span></label
        >

        <app-date-range-calendar
          [placeholder]="'GENERALS.SELECT-DATE' | translate"
          [singleDate]="item.end_date"
          [showLabel]="false"
          [minDate]="minEndDate"
          [maxDate]="maxEndDate"
          (change)="setFormDate($event, 'end')"
        >
        </app-date-range-calendar>
      </div>

      <div class="form-group timepicker">
        <ngb-timepicker [(ngModel)]="item.end_time"></ngb-timepicker>
      </div>

      <!-- end availability end date -->
    </div>
  </div>

  <div class="row" *ngIf="type === 'bid'">
    <div class="form-group w-50">
      <label
        >{{ "RFQS.TARGET-PRICE" | translate }} *
        <!-- <span appHoverHelper class="input-helper fl-in"
                  ><i class="icon-help_comp"></i>
                  <span class="helper-description"></span> </span> -->
      </label>
      <input
        type="number"
        name="target_price"
        class="form-control"
        placeholder="{{ 'RFQS.TARGET-PLACEHOLDER' | translate }}"
        [(ngModel)]="item.target_price"
      />
    </div>

    <div class="form-group w-50">
      <label
        >{{ "GENERALS.CURRENCY" | translate }} *
        <!-- <span appHoverHelper class="input-helper fl-in"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description"></span>
                  </span> -->
      </label>
      <ng-select
        class="customiz-select chips-select"
        [items]="currency"
        bindLabel="label"
        bindValue="id"
        placeholder="{{ 'GENERALS.SELECT-CURRENCY' | translate }}"
        notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        [(ngModel)]="item.target_currency_id"
      >
      </ng-select>
    </div>
  </div>

  <div class="row" *ngIf="type === 'bid'">
    <div class="form-group w-50">
      <label>{{ "RFQS.DECISION-CRITERIA" | translate }} * </label>
      <mat-radio-group
        class="form-group-radio-btn"
        *ngFor="let type of decisionCriteria"
        [(ngModel)]="item.decision_criteria"
        (change)="setDecisionCriteria()"
      >
        <mat-radio-button [value]="type.id">
          {{ type.label | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<!-- end General info -->

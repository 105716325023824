import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { RFQSService } from "src/app/_services/rfqs.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { Dictionary } from "../../../_models/dictionary";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";

@Component({
  selector: "app-rfq-details-page",
  templateUrl: "./rfq-details.component.html",
  styleUrls: ["./rfq-details.component.scss"],
})
export class RFQDetailsComponent implements OnInit {
  id;
  RFQ;
  edit;
  countries;
  itemChanges;
  setModalOpen = true;
  userType;
  isLoadingGeneral;
  request_call;
  public cities: Dictionary[];

  constructor(
    private route: ActivatedRoute,
    private rfqService: RFQSService,
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private dictionariesService: DictionariesService,
    private breadcrumbService: BreadcrumbService,
    private http: HttpClient
  ) {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getRFQDetails();
      }
    });
    this.setModalOpen = false;
  }

  initEmpty() {
    this.edit = null;
    this.itemChanges = cloneDeep(this.RFQ);
    this.itemChanges.categories.forEach((el) => {
      el.edit_flag = true;
    });
  }

  getRFQDetails() {
    this.isLoadingGeneral = true;

    this.rfqService.getRFQById(this.id).subscribe((res) => {
      this.isLoadingGeneral = false;
      this.RFQ = res.result;
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForRFQ(this.RFQ, "details")
      );
      this.initEmpty();
    });
  }

  ngOnInit() {
    this.getCountryList();
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
      }
    });
  }

  openEditModal() {
    this.ngxSmartModalService.getModal("editRFQAndBid").open();
  }

  isModalOpen() {
    this.setModalOpen = true;
  }

  isModalClose() {
    this.setModalOpen = false;
  }

  cancelModal() {
    this.initEmpty();
    this.ngxSmartModalService.closeLatestModal();
  }

  qtyChanges(idx, unit) {
    if (
      (unit < 0 && this.itemChanges.offers_request[idx].quantity > 1) ||
      unit > 0
    ) {
      // && < max.qty
      this.itemChanges.offers_request[idx].quantity =
        this.itemChanges.offers_request[idx].quantity + unit;
      this.updateDraftRFQ();
    }
  }

  removeProvider(idx) {
    this.itemChanges.providers.splice(idx, 1);
    this.updateDraftRFQ();
  }

  removeServiceCategory(idx) {
    this.itemChanges.categories.splice(idx, 1);
    this.updateDraftRFQ();
  }

  checkIfValid() {
    if (!this.itemChanges) {
      return false;
    }
    let isValid = true;
    this.itemChanges.offers_request.forEach((off) => {
      if (!off.quantity || off.quantity < 1) {
        isValid = false;
      }
    });
    return isValid;
  }

  update() {
    switch (this.RFQ.status_name.toLowerCase()) {
      case "draft":
        this.updateDraftRFQ();
        break;
      case "open":
        this.updateRFQ();
        break;
      case "published":
        this.updateRFQ();
        break;
      case "extended":
        this.updateRFQ();
        break;
    }
  }

  updateDraftRFQ() {
    this.itemChanges.rfq_id = this.itemChanges.id;
    this.isLoadingGeneral = true;

    this.rfqService.updateDraftRFQDetails(this.itemChanges).subscribe(
      (res) => {
        this.RFQ = res.result;
        this.RFQ.delivery_date = this.RFQ.delivery_date.slice(0, -3);
        this.cancelModal();
        this.initEmpty();
        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  updateRFQ() {
    this.isLoadingGeneral = true;
    this.itemChanges.rfq_id = this.itemChanges.id;

    this.rfqService.updateRFQDetails(this.itemChanges).subscribe(
      (res) => {
        this.RFQ = res.result;
        this.cancelModal();
        this.initEmpty();
        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  loadCities(event?) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.request_call = this.dictionariesService
      .cities((event && event.term) || "", this.itemChanges.country)
      .subscribe((response) => {
        if (response.success) {
          this.cities = response.result;
        }
      });
  }

  countryChanged() {
    this.cities = [];
    this.itemChanges.city = null;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

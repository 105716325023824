<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "LEFT-NAVIGATION.EXPATS" | translate }}</div>
  <!-- end left -->

  <!-- right -->
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; getItems()"
      (filter)="filters = $event; getItems()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; getItems()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <button
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); getItems()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </button>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="openConfirmAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->

<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="{{ active }}"
        matSortDirection="{{ direction }}"
      >
        <thead>
          <tr>
            <ng-container matColumnDef="select">
              <th>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [disableRipple]="true"
                >
                </mat-checkbox>
              </th>
            </ng-container>
            <th mat-sort-header="id" class="text-right justify-end border-r">
              {{ "GENERALS.ID" | translate }}
            </th>
            <th mat-sort-header="name">
              {{ "LABEL.NAME" | translate }}
            </th>
            <th mat-sort-header="email">
              {{ "LABEL.EMAIL" | translate }}
            </th>
            <th>{{ "LABEL.PHONE-NUMBER" | translate }}</th>
            <th mat-sort-header="country_of_residence">
              {{ "LABEL.RESIDENCE-COUNTRY" | translate }}
            </th>
            <th mat-sort-header="expat_status">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let expat of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (keyup)="$event.stopPropagation()"
                (change)="$event ? changeSelection(expat) : null"
                [checked]="selection.isSelected(expat)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>
            <td
              class="text-right justify-end border-r"
              (click)="navigateByUrl(filters.page, expat.id)"
              (keyup)="navigateByUrl(filters.page, expat.id)"
            >
              <a
                [href]="'/expats/' + expat.id"
                class="font-semibold text-gray-dark"
              >
                #{{ expat.id }}
              </a>
            </td>
            <td
              (click)="navigateByUrl(filters.page, expat.id)"
              (keyup)="navigateByUrl(filters.page, expat.id)"
            >
              {{ expat.user?.name }}
            </td>
            <td
              (click)="navigateByUrl(filters.page, expat.id)"
              (keyup)="navigateByUrl(filters.page, expat.id)"
            >
              {{ expat.user?.email }}
            </td>
            <td
              (click)="navigateByUrl(filters.page, expat.id)"
              (keyup)="navigateByUrl(filters.page, expat.id)"
            >
              {{
                expat.user
                  ? dictionariesService.getPhonePrefix(expat.user.phone_prefix)
                  : ""
              }}{{ expat.user?.phone }}
            </td>
            <td
              (click)="navigateByUrl(filters.page, expat.id)"
              (keyup)="navigateByUrl(filters.page, expat.id)"
            >
              {{ expat.country_code | country | async }}
            </td>
            <td
              (click)="navigateByUrl(filters.page, expat.id)"
              (keyup)="navigateByUrl(filters.page, expat.id)"
            >
              <div
                class="status {{
                  StatusColorEnum[expat.status_name | lowercase]
                }}"
              >
                {{ expat.status_name | uppercase }}
              </div>
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action with overlay -->
              <div
                toggle-action-table
                class="table-action-wrapper"
                *ngIf="actions"
              >
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li *ngFor="let status of actions[expat.status_id]">
                      <button
                        class="btn transparent-bg p-0"
                        (click)="openConfirmAction(status, expat)"
                      >
                        {{ status.text | uppercase }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="9" class="text-gray-dark">
              <div class="notification_row bg_light_blue m-10-0 p-20">
                {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let expat of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(expat) : null"
              [checked]="selection.isSelected(expat)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
          <div
            (click)="navigateByUrl(filters.page, expat.id)"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ expat.id }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.FIRST-LAST-NAME" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ expat.user.name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.POSITION-IN-COMPANY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ expat.company_position }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.EMAIL" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ expat.user.email }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.PHONE-NUMBER" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{
              expat.user
                ? dictionariesService.getPhonePrefix(expat.user.phone_prefix)
                : ""
            }}{{ expat.user.phone }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="entity_status-label-{{ expat.status_id }}">
              {{ expat.status_name }}</span
            >
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.HOME-COUNTRY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ expat.country_code }}
          </div>
        </div>

        <div *ngIf="actions" class="responsive-table-action">
          <span
            *ngFor="let status of actions[expat.status_id]"
            (click)="openConfirmAction(status, expat)"
          >
            {{ status.text }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="getItems($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>
  <!-- end paginator -->
</div>

<!-- end table -->

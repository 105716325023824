import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import cloneDeepWith from "lodash-es/cloneDeepWith";
import { NgxSmartModalService } from "ngx-smart-modal";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { AlertService } from "src/app/_services/alert.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import deepCastToInt from "../../../../_helpers/deepCastToInt";
import { Client } from "../../../../_models/client";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { ClientsService } from "../../../../_services/clients.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { AddClientComponent } from "../add-client/add-client.component";
import { ClientInviteComponent } from "../client-invite/client-invite.component";

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.css"],
})
export class ClientsComponent extends ItemsListComponent implements OnInit {
  items: Client[];
  request_call;
  availableFilters;
  active = "";
  direction = "";
  isXs: boolean = false;
  isFiltersLoaded: boolean = false;
  switchID;

  @ViewChild(AddClientComponent, { static: true })
  private _initEmpty: AddClientComponent;
  @ViewChild(ClientInviteComponent, { static: true })
  private _clientInviteModal: ClientInviteComponent;

  constructor(
    private service: ClientsService,
    private dictionariesService: DictionariesService,
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.route.queryParams.subscribe((query) => {
      if (query && Object.keys(query).length > 0) {
        this.filters = cloneDeepWith(query, deepCastToInt);
      } else {
        this.clearFilters();
        const params = this.getParams(1);
        this.filters = params;
      }
      if (this.filters["search_text"]) {
        this.search_text = this.filters["search_text"];
      }
      if (this.filters["per_page"]) {
        this.perPage = this.filters["per_page"];
      }
      if (this.filters["order"]) {
        this.active = this.filters["order"];
        this.direction = this.filters["order_direction"];
      } else {
        this.active = "";
        this.direction = "";
      }

      this.getItems();
    });
    this.showBulkActions = false;
    this.getStatusTransitions();
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/clients", "clients")
    );
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    let url = "";
    if (id) {
      url = "/clients/" + id;
    } else {
      url = "/clients";
    }
    this.router.navigate([url], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoadingGeneral = true;
    let params = {};
    params = cloneDeep(this.filters);

    this.request_call = this.service
      .getClients(params)
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  protected getService() {
    return this.service;
  }

  public getStatusTransitions() {
    this.dictionariesService
      .getStatusTransitions("client")
      .subscribe((data) => {
        if (data.success) {
          this.actions = data.result;
        }
      });
  }

  openModal() {
    this._initEmpty.initEmpty();
    this.ngxSmartModalService.getModal("addClient").open();
  }

  openInviteModal() {
    this._clientInviteModal.initEmpty();
    this.ngxSmartModalService.getModal("inviteClient").open();
  }

  openSwitchModal(id) {
    this.switchID = id;
    this.ngxSmartModalService.getModal("switchAccount").open();
  }

  switchToAccount(e) {
    this.ngxSmartModalService.getModal("switchAccount").close();

    this.service
      .switchToAccount({
        client_id: this.switchID,
        password: e,
      })
      .subscribe(
        (res) => {
          this.ngxSmartModalService.getModal("switchAccount").close();
        },
        (error) => {
          this.alertService.errors(error.error.errors);
        }
      );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

<div class="custom-modal-wrapper">
  <div class="modal-title-custom">
    {{ "GENERALS.CONFIRM" | translate }} {{ data.action.text }}
  </div>

  <div class="modal-body-custom">
    <div *ngIf="data.action.needs_confirmation" class="row m-b-15">
      <div class="w-100">
        {{ "GENERALS.WANT-TO" | translate }}
        <span class="fw-500">{{ data.action.text }}</span
        >?
      </div>
    </div>

    <div *ngIf="data.action.needs_reason" class="row">
      <div class="w-100">
        <label>{{ "LABEL.ENTER-REASON" | translate }}</label>
        <textarea
          name="reason"
          class="textarea-control"
          [(ngModel)]="reason"
        ></textarea>
      </div>
    </div>

    <div
      *ngIf="!data.action.needs_confirmation && !data.action.needs_reason"
      class="row"
    >
      <div class="w-100">
        {{ "GENERALS.WANT-TO" | translate }}
        <span class="fw-500">{{ data.action.text }}</span
        >?
      </div>
    </div>
  </div>
  <div class="clear"></div>

  <div class="modal-footer-custom">
    <button (click)="cancel()" class="btn gray-bg fl-l">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button (click)="confirm()" class="btn orange-bg fl-r">
      {{ "GENERALS.CONFIRM" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>

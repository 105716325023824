<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="addRFQAndBid"
    #addRFQAndBid
    identifier="addRFQAndBid"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title" *ngIf="type">
        {{ "RFQS.ADD-" + type.toUpperCase() | translate }}
      </div>

      <div class="modal-step-wrapper">
        <ul>
          <li [ngClass]="{ 'active-step': currentStep === 1 }">
            {{ "RFQS.GENERAL-INFO" | translate }}
          </li>
          <li [ngClass]="{ 'active-step': currentStep === 2 }">
            {{ "GENERALS.SERVICEOFFERS" | translate }}
          </li>
          <li [ngClass]="{ 'active-step': currentStep === 3 }">
            {{ "RFQS.INVITE-PROVIDERS" | translate }}
          </li>
        </ul>
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <!-- add rfq step 1 -->
      <ng-container *ngIf="currentStep === 1">
        <app-rfq-general-info [type]="type" [rfq]="item"></app-rfq-general-info>
      </ng-container>
      <!-- end add rfq step 1 -->

      <!-- add rfq step 2 -->

      <ng-container *ngIf="currentStep === 2">
        <app-rfq-service-offers
          [item]="item"
          [edit]="true"
          (disabledNextAction)="disabledNextAction($event)"
        ></app-rfq-service-offers>
      </ng-container>
      <!-- end add rfq step 2 -->

      <!-- add rfq step 3 -->
      <ng-container *ngIf="currentStep === 3">
        <app-invite-providers
          [country]="item.country"
          [categoriesFilters]="item.categories"
          [item]="item"
          [type]="type"
        ></app-invite-providers>
      </ng-container>

      <!-- end add rfq step 3 -->
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button type="button" class="btn gray-bg" (click)="cancelModal()">
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button type="button" class="btn white-bg-border" (click)="saveDraft()">
        {{ "GENERALS.SAVE-DRAFT" | translate }}
      </button>
      <button
        *ngIf="currentStep > 1"
        type="button"
        class="btn white-bg-border"
        (click)="currentStep = currentStep - 1"
      >
        {{ "LABEL.BACK" | translate }}
      </button>
      <button
        *ngIf="currentStep !== 3"
        type="button"
        class="btn orange-bg"
        [disabled]="!checkItemProps()"
        (click)="currentStep = currentStep + 1"
      >
        {{ "GENERALS.NEXT" | translate }}
      </button>

      <button
        *ngIf="currentStep === 3"
        type="button"
        class="btn orange-bg"
        [disabled]="!isOpenRfqBid"
        (click)="create()"
      >
        {{ "GENERALS.FINISH" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router, NavigationEnd, Event } from "@angular/router";
import { ExpatsService } from "../../../../_services/expats.service";
import { OrdersService } from "../../../../_services/orders.service";
import { AlertService } from "src/app/_services/alert.service";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "app-order-modal",
  templateUrl: "./order-popup.component.html",
  styleUrls: ["./order-popup.component.css"],
})
export class OrderPopupComponent implements OnInit {
  stage;
  status_id = 1;
  expat;
  orderParamID;
  user;
  offerID;
  selectedExpats = [];
  entity;
  entity_id;
  role;
  minDate: NgbDate;

  constructor(
    public dialogRef: MatDialogRef<OrderPopupComponent>,
    private router: Router,
    private expatsService: ExpatsService,
    private orderService: OrdersService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.stage = data.type;
    this.expat = data.expat
      ? data.expat
      : {
          data: {
            client_id: data.entity_id,
            edit: false,
          },
        };
    this.orderParamID = data.id ? data.id : null;

    for (let i = 0; i < 5; i++) {
      this.selectedExpats[i] = {
        id: null,
        arriving_on: null,
      };
    }

    if (this.stage.indexOf("_") > -1) {
      const params = data.type.split("_");
      this.stage = params[0];
      this.role = params[1];
      this.entity = data.data;
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove("largeModal");
      }
    });

    if (this.role === "provider" && this.stage === "contact") {
      document.body.classList.add("largeModal");
    }
  }

  ngOnInit() {
    this.minDate = this.setCalendarLimit(
      new Date(this.addDayToDate(new Date(), 1))
    );
  }

  setCalendarLimit(referenceDate) {
    return new NgbDate(
      referenceDate.getFullYear(),
      referenceDate.getMonth() + 1,
      referenceDate.getDate()
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    const params = { expats: [] };
    this.selectedExpats.forEach((item) => {
      if (item.id) {
        params["expats"].push(item);
      }
    });
    if (params["expats"].length) {
      this.orderService
        .assignOrderExpat(this.orderParamID, this.offerID, params)
        .subscribe(
          (res) => {
            this.dialogRef.close("Successfully assigned");
          },
          (error) => {
            if (error.error.errors) {
              this.alertService.errors(error.error.errors);
            }
          }
        );
    } else {
      this.onNoClick();
    }
  }

  addedExpat(e) {
    if (this.data.openedFrom === "expats") {
      this.dialogRef.close(e.type);
    }
  }

  navigateTo() {
    this.dialogRef.close();
    this.router.navigate([this.role + "s/" + this.entity.id]);
  }

  addDayToDate(date, val) {
    return moment(date).add(val, "days").format();
  }
}

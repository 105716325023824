import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { combineLatest } from "rxjs";
import { RFQSService } from "src/app/_services/rfqs.service";
import { RFQSDetails } from "src/app/_models/_menu/rfqs-details";

@Component({
  selector: "app-rfq",
  templateUrl: "./rfq.component.html",
})
export class RFQComponent implements OnInit {
  private id;
  public menu: RFQSDetails;
  public RFQ;
  filters;

  constructor(
    private route: ActivatedRoute,
    private service: RFQSService,
    private location: Location
  ) {
    combineLatest(this.route.params, this.route.queryParams).subscribe(
      ([params, query]) => {
        this.id = params["id"];
        this.filters = query;

        this.menu = new RFQSDetails(params["id"], query);
      }
    );
  }

  ngOnInit() {
    // get provider details to show the name at the top
    this.service.getRFQById(this.id).subscribe((data) => {
      this.RFQ = data.result;
    });
  }
}

import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "src/app/_services/alert.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { AdminService } from "src/app/_services/admins.service";
import { ResetPassModalComponent } from "../../reset-pass-modal/reset-pass-modal.component";
import { UsersService } from "src/app/_services/users.service";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { ConfirmActionModalComponent } from "../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { AddUserFormComponent } from "../../users/add-user-form/add-user-form.component";
import { finalize, take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { User } from "src/app/_models/user";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-my-account-page",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy
{
  adminId;
  localUser;
  id;
  isLoadingGeneral = false;
  isLoadingStatus = false;
  user;
  @ViewChild(ResetPassModalComponent, { static: true })
  private _initEmpty: ResetPassModalComponent;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    public ngxSmartModalService: NgxSmartModalService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private translate: TranslateService,
    private userService: UsersService,
    private breadcrumbService: BreadcrumbService,
    public dictionariesService: DictionariesService
  ) {
    super();
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.adminId = params.id;
      }
    });

    this.localUser = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
    if (this.localUser) {
      this.id = this.localUser.id;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.getCurrentUser();
    if (this.adminId) {
      this.getStatusTransitions();
    }
  }

  getCurrentUser() {
    const id = this.adminId ? this.adminId : this.id;
    this.isLoadingGeneral = true;

    this.adminService
      .getCurrentUser(id)
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe((res) => {
        this.user = res.result;
        this.user.userType = "admin";
        this.breadcrumbService.changeBreadcrumbs(
          this.breadcrumbService.setMyAccount(this.user)
        );
      });
  }

  protected getService() {
    return this.userService;
  }

  public getStatusTransitions() {
    this.isLoadingStatus = true;

    this.userService
      .getStatusTransitions()
      .pipe(
        take(1),
        finalize(() => (this.isLoadingStatus = false))
      )
      .subscribe((data) => {
        if (data.success) {
          this.actions = data.result;
        }
      });
  }

  updateCurrentPassword(e) {
    const params = {
      current_password: e.current_password,
      password: e.password,
      password_confirmation: e.password_confirmation,
    };
    this.adminService.updatePassword(params).subscribe(
      (data) => {
        this.closeResetPasswordModal();
        this.translate.get("GENERALS.PASS-SAVE-SUCCESS").subscribe((trans) => {
          this.alertService.success(trans);
        });
      },
      (error) => {
        if (error?.error?.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  updateUserAccount() {
    const update$ = this.adminId
      ? this.adminService.updateAdminUser(this.user, this.adminId)
      : this.adminService.updateCurrentUser(this.user);
    update$
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe(
        (res) => {
          if (res.messages) {
            this.alertService.success(res.messages);
          }
          this.getCurrentUser();
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  openEditModal(info) {
    this.dialog
      .open(AddUserFormComponent, {
        data: {
          user: this.user,
          edit: true,
          about: this.adminId ? "user" : "account",
          userType: this.adminId ? "admin" : "user",
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: User) => {
        if (user) {
          this.updateUserAccount();
        } else {
          this.getCurrentUser();
        }
      });
  }

  openResetPasswordModal() {
    this._initEmpty.initEmpty();
    this.ngxSmartModalService.getModal("ressetPassModal").open();
  }

  closeResetPasswordModal() {
    this.ngxSmartModalService.getModal("ressetPassModal").close();
  }

  openDocModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").open();
  }

  handleEvent(e) {
    this.getCurrentUser();
  }

  onChanges(e) {
    const type = e.type ? e.type : e;
    switch (type) {
      case "cancel_reset_pass":
        this.closeResetPasswordModal();
        break;
    }
  }

  openConfirmAction(action) {
    const ids = [this.adminId];
    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.userService.updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.getCurrentUser();
            },
            (error) => {
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this.userService
        .updateStatusBulk(action.id, ids, null)
        .subscribe((data) => {
          this.getCurrentUser();
        });
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

<nav
  *ngIf="menu && menu.links && menu.links.length"
  mat-tab-nav-bar
  [disableRipple]="true"
>
  <a
    mat-tab-link
    *ngFor="let item of menu.links"
    [routerLink]="item.link"
    [queryParams]="item.filters"
    [active]="router.url.split('?')[0] === item.link"
    >{{ item.label }}</a
  >
</nav>

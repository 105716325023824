import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { OrderDetails } from "../../../../_models/_menu/order-details";
import { OrdersService } from "src/app/_services/orders.service";
import { Location } from "@angular/common";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { combineLatest } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"],
})
export class OrderComponent implements OnInit {
  menu;
  order;
  private id: any;
  filters;
  private user;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrdersService,
    private location: Location,
    private bs: BreadcrumbService
  ) {
    combineLatest(this.route.params, this.route.queryParams).subscribe(
      ([params, query]) => {
        this.id = params["id"];
        this.filters = query;

        this.menu = new OrderDetails(params["id"], query);
      }
    );
  }

  ngOnInit() {
    const params = {};
    this.user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
    // get order details to show the name at the top
    this.orderService
      .getOrderByIdentityNumber(params, this.id)
      .subscribe((data) => {
        this.order = data.result;
      });
  }
}

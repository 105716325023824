import { Component, Input, OnInit } from "@angular/core";
import { LoadingTypeEnum } from "../../_enums/loading-type.enum";

@Component({
  selector: "app-loader-spinner-general",
  templateUrl: "loader-spinner-general.component.html",
  styleUrls: ["loader-spinner-general.component.scss"],
})
export class LoaderSpinnerGeneralComponent implements OnInit {
  @Input() isLoadingGeneral: boolean;
  @Input() repeat: number | number[] = 1;
  @Input() loadingType: LoadingTypeEnum = LoadingTypeEnum.INFO;

  ngOnInit(): void {
    if (typeof this.repeat === "number") {
      this.repeat = this.numberToArray(this.repeat);
    }
  }

  get LoadingTypeEnum() {
    return LoadingTypeEnum;
  }

  private numberToArray(i: number): number[] {
    return Array(i)
      .fill(0)
      .map((x, i) => i);
  }
}

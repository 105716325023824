export class IconsList {
  icons = [
    { id: "icon-record-outline", text: "Default" },
    { id: "icon-relocation", text: "Relocation" },
    { id: "icon-immigration", text: "Immigration" },
    { id: "icon-tax-services", text: "Taxation" },
    { id: "icon-moving-services", text: "Moving" },
    { id: "icon-area-orientation", text: "Area orientation" },
    { id: "icon-house-search", text: "House search" },
    { id: "icon-school-search", text: "School search" },
    { id: "icon-settling-in", text: "Settling-in assistance" },
    { id: "icon-training", text: "Trainings" },
    { id: "icon-departure-assistance", text: "Departure assistance" },
    { id: "icon-eu-citizen", text: "EU Citizens" },
    { id: "icon-non-eu-citizens", text: "Non EU Citizens" },
    { id: "icon-credit-card", text: "Visa support" },
    { id: "icon-other-immigration", text: "Other immigration services" },
    { id: "icon-eu-forms", text: "European forms" },
    {
      id: "icon-taxation-arrival",
      text: "Taxation services for expats' arrival",
    },
    {
      id: "icon-taxation-stay",
      text: "Taxation services during expats' stay",
    },
    {
      id: "icon-taxation-departure",
      text: "Taxation services for expats' leave",
    },
    { id: "icon-inventory", text: "Inventory services" },
    { id: "icon-storage", text: "Storage" },
    { id: "icon-moving", text: "Transportation services" },
    { id: "icon-language_trainings", text: "Language trainings" },
    { id: "icon-intercultural_trainings", text: "Intercultural training" },
    { id: "icon-house_hunt", text: "House hunt" },
    { id: "icon-lease_negotiation", text: "Lease negotiation" },
    { id: "icon-lease_renewal", text: "Lease renewal/extension" },
    {
      id: "icon-check_in_assistance",
      text: "Check-in assistance for house search",
    },
    { id: "icon-temporary_housing", text: "Temporary housing" },
    {
      id: "icon-opening_bank_account",
      text: "Opening bank account assistance",
    },
    { id: "icon-check_in_assistance", text: "Check-in assistance" },
    { id: "icon-medical_assistance", text: "Medical assistance" },
    {
      id: "icon-transportation_assistance",
      text: "Transportation assistance at arrival",
    },
    { id: "icon-spouse_support", text: "Spouse support" },
    { id: "icon-domestic_support", text: "Domestic support" },
    { id: "icon-utilities", text: "Utilities connection" },
    { id: "icon-furniture_rental", text: "Furniture rental" },
    { id: "icon-other", text: "Other" },
    { id: "icon-tenancy", text: "Tenancy manangement" },
    {
      id: "icon-car_rental",
      text: "Car rental/car purchase support services",
    },
    { id: "icon-car_registrations", text: "Local car registration" },
    {
      id: "icon-airport_transfer",
      text: "Airport transfer services at arrival",
    },
    {
      id: "icon-departure_asssitance",
      text: "Check-out assistance (departure)",
    },
    {
      id: "icon-banking_de_registration",
      text: "Banking assistance de-registration",
    },
    {
      id: "icon-transportation_assistance_leaving",
      text: "Transportation assistance",
    },
    { id: "icon-contract_termination", text: "Contracts termination" },
    {
      id: "icon-uitility_contracts",
      text: "Utilities/Security/Other contracts termination",
    },
    { id: "icon-lease_contract_termination", text: "Lease termination" },
    { id: "icon-de_registration", text: "Local car de-registration" },
    { id: "icon-renewal", text: "Renewals" },
    { id: "icon-residence_change", text: "Residence changes" },
    { id: "icon-work_authorization", text: "Work authorizations" },
    {
      id: "icon-residence_permit_non_eu",
      text: "Residence permits non EU",
    },
    {
      id: "icon-approval_family_members",
      text: "Approval or communication for nonEU family members",
    },
    { id: "icon-residence_permit", text: "Residence permits" },
    { id: "icon-residence_renewal", text: "Residence permits renewals" },
    { id: "icon-permanent_residence", text: "Permanent residence permits" },
    { id: "icon-residence_cancel", text: "Residence permits cancellation" },
    { id: "icon-residence_change-1", text: "Residence permits changes" },
    { id: "icon-other-1", text: "Other types of residence permits" },
    { id: "icon-work_authorization-copy", text: "Work authorization" },
    { id: "icon-work_renewal", text: "Work authorizations renewals" },
    { id: "icon-study_recognition", text: "Study recognition process" },
    { id: "icon-apostille_process", text: "Apostille process support" },
    {
      id: "icon-police_clerance",
      text: "Obtaining police clearance certificate in country of residence",
    },
    {
      id: "icon-company_documents",
      text: "Obtaining company's documents needed for various immigration processes",
    },
    { id: "icon-notary_assistance", text: "Notary assistance support" },
    {
      id: "icon-translation_and_legalisation",
      text: "Translation and legalization coordination",
    },
    {
      id: "icon-child_support",
      text: "Child benefit support in country of residence",
    },
    {
      id: "icon-super_authentication",
      text: "Super-authentication process",
    },
    { id: "icon-short_term_visa", text: "Short-term visas" },
    { id: "icon-long_term_visa", text: "Long-term visas" },
    { id: "icon-work", text: "Long-term visa support for work" },
    {
      id: "icon-visa_secondament",
      text: "Long-term visa support for secondment",
    },
    {
      id: "icon-visa_family",
      text: "Long-term visa support for family reunification",
    },
    {
      id: "icon-other-copy",
      text: "Long-term visa support other purposes",
    },
    { id: "icon-visa_renewal", text: "Long-term visa renewal" },
    { id: "icon-visa_business", text: "Visa for business" },
    { id: "icon-visa_pleasure", text: "Visa for pleasure" },
    {
      id: "icon-invitation_letter_support",
      text: "Invitation letters support",
    },
    { id: "icon-surveys", text: "Surveys" },
    { id: "icon-short_term", text: "Short-term" },
    { id: "icon-long-temr", text: "Long-term" },
    { id: "icon-furniture", text: "Furniture" },
    { id: "icon-vehicles", text: "Vehicles" },
    { id: "icon-pet", text: "Pets" },
    { id: "icon-art", text: "Fine art" },
    { id: "icon-a1_form", text: "A1 form" },
    { id: "icon-s1_form", text: "S1 form" },
    { id: "icon-health_card", text: "European Health Insurance Card" },
    { id: "icon-other_form", text: "Other forms" },
    { id: "icon-tax_residency-copy", text: "Tax residency file" },
    { id: "icon-exit_tax", text: "Exit tax-related interview services" },
    { id: "icon-exit_certificate", text: "Certificate proving taxes paid" },
    {
      id: "icon-labour_notification-copy-2",
      text: "Labour Department notifications at leave",
    },
    { id: "icon-tax_interview-copy", text: "Tax de-registration" },
    {
      id: "icon-retirement_contributions-copy",
      text: "Retirement contributions certificate",
    },
    {
      id: "icon-anual_tax_declaration",
      text: "Annual tax declaration for incomes obtained abroad",
    },
    {
      id: "icon-labour_notification-copy",
      text: "Labour Department notifications",
    },
    {
      id: "icon-tax_calculator",
      text: "Income tax calculations and social contributions calculations for secondees",
    },
    {
      id: "icon-tax_certificate",
      text: "Certificate proving taxes paid during stay",
    },
    {
      id: "icon-retirement_contributions",
      text: "Retirement contributions certificat during staye",
    },
    { id: "icon-tax_residency", text: "Tax residency file at arrival" },
    {
      id: "icon-tax_interview",
      text: "Entry tax-related interview services",
    },
    { id: "icon-social_security", text: "Social security enrollment" },
    { id: "icon-tax_registration", text: "Tax registration" },
    { id: "icon-tax_number", text: "Obtaining Tax Identification Number" },
    {
      id: "icon-labour_notification",
      text: "Labour Department notifications at arrival",
    },
  ];
}

import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from "@angular/common/http";
import { Observable, Subject } from "rxjs";

@Injectable()
export class QueueInterceptor implements HttpInterceptor {
  private processed: boolean = false; // to indicate Special header data received
  private request = new Subject<any>(); // Subject stream to indicate custom responses
  private queue: HttpRequest<any>[] = []; // to store http requests

  requestSetter(response: any) {
    this.request.next(response);
  } // set subject stream data
  requestGetter(): Observable<any> {
    return this.request.asObservable();
  } // listen to Stream changes and return stream data

  // remove previous requests from queue/ Array
  removeRequest(req: HttpRequest<any>) {
    const i = this.queue.indexOf(req);
    if (i >= 0) {
      this.queue.splice(i, 1);
    }
  }

  // Default Interceptor Functionality
  public intercept(
    req: HttpRequest<any>,
    delegate: HttpHandler
  ): Observable<any> {
    return Observable.create((observer) => {
      if (req.params.has("low-priority")) {
        req = req.clone({ params: req.params.delete("low-priority") });
        this.queue.push(req);
        this.requestGetter().subscribe((res) => {
          const i = this.queue.indexOf(req);
          if (i >= 0) {
            this.queue.splice(i, 1);
            const subscription = delegate.handle(req).subscribe(
              (event) => {
                if (event instanceof HttpResponse) {
                  this.processed = true;
                  this.request.next(true);
                  this.removeRequest(req);
                  observer.next(event);
                }
              },
              (err) => {
                this.removeRequest(req);
                observer.error(err);
              },
              () => {
                this.removeRequest(req);
                observer.complete();
              }
            );
            // remove request from queue when cancelled
            return () => {
              this.removeRequest(req);
              subscription.unsubscribe();
              this.request.unsubscribe();
            };
          }
        });

        /**
         * to process calls after the subject stream is unsubscribed
         */
        if (this.processed == true) {
          const i = this.queue.indexOf(req);
          if (i >= 0) {
            this.queue.splice(i, 1);
            const subscription = delegate.handle(req).subscribe(
              (event) => {
                if (event instanceof HttpResponse) {
                  this.removeRequest(req);
                  observer.next(event);
                }
              },
              (err) => {
                this.removeRequest(req);
                observer.error(err);
              },
              () => {
                this.removeRequest(req);
                observer.complete();
              }
            );
            // remove request from queue when cancelled
            return () => {
              this.removeRequest(req);
              subscription.unsubscribe();
            };
          }
        }
      } else {
        const subscription = delegate.handle(req).subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              this.processed = true;
              this.requestSetter({ key: this.processed });
              this.removeRequest(req);
              observer.next(event);
            }
          },
          (err) => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          }
        );
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      }
    });
  }
}

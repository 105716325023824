import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-translate",
  templateUrl: "./translate.component.html",
})
export class TranslateComponent {
  selectedLang;
  constructor(
    public translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.translate.addLangs(["en"]);
    this.translate.setDefaultLang("en");

    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang.match(/en/) ? browserLang : "en");
    this.setLang();
  }

  setLang() {
    this.selectedLang = this.translate.currentLang;
    const lang = this.cookieService.get(environment.lang_cookie_key);
    if (lang) {
      this.selectedLang = lang;
      this.translate.use(this.selectedLang);
    } else {
      this.selectedLang = this.translate.currentLang;
    }
  }
  changeLang(e) {
    if (e) {
      this.cookieService.set(environment.lang_cookie_key, e, 100, "/");
    } else {
      this.cookieService.delete(environment.lang_cookie_key, "/");
    }
    this.translate.use(e);
  }
}

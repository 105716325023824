<ng-container *ngIf="isLoadingGeneral">
  <div class="d-flex loading-wrapper">
    <div class="w-profile">
      <app-loader-spinner-general
        [loadingType]="LoadingType.PROFILE"
      ></app-loader-spinner-general>
    </div>
    <div class="w-info">
      <div class="w-first-row">
        <app-loader-spinner-general
          [repeat]="5"
          [loadingType]="LoadingType.INFO"
        ></app-loader-spinner-general>
      </div>
    </div>
  </div>
</ng-container>

<div class="details-body-wrapper" *ngIf="!isLoadingGeneral && user">
  <!-- 2 col details -->
  <div class="details-2c">
    <!-- left -->
    <div class="details-2c-left m-r-15">
      <div class="small-box">
        <div class="small-box-header-img" (click)="openPicModal()">
          <img
            [src]="
              user.primary_image_path
                ? user.primary_image_path
                : '../assets/images/profile-pics-placeholder.jpg'
            "
            alt="img"
            class="profile-pic"
          />
          <div class="edit-trigger">
            {{ "PORTAL.CHANGE-PIC" | translate }}123
          </div>
        </div>
        <div class="small-box-details">
          <div class="medium-title" title="{{ user.name }}">
            {{ user.name }}
          </div>
          <div class="small-box-status">
            <div class="entity_status-label-{{ user.status_id }}">
              {{ user.status_name }}
            </div>
          </div>
          <ng-container *ngIf="expat">
            <div class="fw-500" *ngIf="expat.declined_reason || ''">
              <span class="gray block-span"
                >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
              >
              {{ expat.declined_reason }}
            </div>
          </ng-container>

          <div class="fw-500" *ngIf="user && user.created_at">
            <span class="gray block-span">
              {{ "PORTAL.REGISTRED-SINCE" | translate }}:
            </span>
            {{ user.created_at | date : "dd/MM/yyyy" }}
          </div>
        </div>

        <div class="small-box-list">
          <div *ngIf="isLoadingStatus">
            <app-loader-spinner-general
              [repeat]="4"
              [loadingType]="LoadingType.BUTTONS"
            ></app-loader-spinner-general>
          </div>
          <ul *ngIf="!isLoadingStatus">
            <li
              *ngIf="!user.confirmed_account"
              (click)="resendConfirmationMail()"
              class="action-link-gray"
            >
              <i class="icon-mail"></i>
              {{ "GENERALS.RESEND-CONFIRMATION-MAIL" | translate }}
            </li>
            <li
              *ngIf="user.status_id === 3"
              (click)="resetPassword()"
              class="action-link-gray"
            >
              <i class="icon-lock-reset"></i>
              {{ "AUTH.CHANGE-PASSWORD" | translate }}
            </li>
            <ng-container *ngFor="let status of user.statuses">
              <li>
                <div
                  (click)="openConfirmAction(status)"
                  class="btn-small orange-bg"
                >
                  {{ status.text }}
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "PORTAL.ACCOUNT-CONTACT-INFO" | translate }}
            </div>
            <div class="white-box-header-action fl-r" (click)="editDetails()">
              {{ "GENERALS.EDIT" | translate }}
              <i class="icon-cog-outline"></i>
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <p>
              <span>{{ expat?.user.name }}</span>
            </p>
            <p>{{ expat?.company_name }}</p>
            <p>
              <i class="icon-visa_business"></i>
              {{ expat?.company_position
              }}<ng-container *ngIf="expat?.department_name"
                >, {{ expat?.department_name }}</ng-container
              >
            </p>
            <p>
              <a href="mailto:{{ expat?.user.email }}">
                <i class="icon-mail"></i>
                {{ expat?.user.email }}
              </a>
            </p>
            <p>
              <i class="icon-phone"></i>
              {{
                expat
                  ? dictionariesService.getPhonePrefix(expat.user.phone_prefix)
                  : ""
              }}
              {{ expat?.user.phone }}
            </p>
            <p *ngIf="expat?.address">
              <i class="icon-area-orientation"></i>
              {{ expat?.address }}, {{ expat?.zip_code }}, {{ expat?.city }},
              {{ expat?.country_code }}
            </p>
          </div>
        </div>

        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "PORTAL.ACCOMPANYING-FAMILY" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <p>
              {{ "PORTAL.ADULTS" | translate }}:
              {{ expat?.adults_number }}
            </p>
            <p>
              {{ "PORTAL.CHILDREN" | translate }}:
              {{ expat?.children_number }}
            </p>
          </div>
        </div>
      </div>
      <!-- end right -->
    </div>
    <!-- end 2 col details -->
  </div>
</div>

<app-add-doc
  [entityType]="'expat'"
  [entityId]="id"
  [docType]="'image'"
  [docId]="docId"
  [title]="'PORTAL.UPLOAD-PIC'"
  (changeDoc)="handleEvent($event)"
></app-add-doc>

<app-add-profile-picture
  [entityType]="'expat'"
  [entityId]="id"
  [title]="'GENERALS.UPLOAD-PIC'"
  (changePicture)="handleEvent($event)"
></app-add-profile-picture>

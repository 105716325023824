import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { Subject } from "rxjs";
import { ClientsService } from "./clients.service";
import { CommissionsService } from "./commissions.service";
import { ExpatsService } from "./expats.service";
import { ProvidersService } from "./providers.service";
import { ServiceOffersService } from "./serviceoffers.service";

@Directive()
@Injectable()
export class MenuBadgesService {
  private subject = new Subject<any>();

  @Output() providersChanged: EventEmitter<any> = new EventEmitter();
  @Output() clientsChanged: EventEmitter<any> = new EventEmitter();
  @Output() expatsChanged: EventEmitter<any> = new EventEmitter();
  @Output() offersChanged: EventEmitter<any> = new EventEmitter();
  @Output() commissionsChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private providersService: ProvidersService,
    private clientsService: ClientsService,
    private expatsService: ExpatsService,
    private offersService: ServiceOffersService,
    private commissionsService: CommissionsService
  ) {
    providersService.statusChange.subscribe((event) => {
      this._getPendingProviders();
    });
    clientsService.statusChange.subscribe((event) => {
      this._getPendingClients();
    });
    expatsService.statusChange.subscribe((event) => {
      this._getPendingExpats();
    });
    offersService.statusChange.subscribe((event) => {
      this._getPendingOffers();
    });
    commissionsService.statusChange.subscribe((event) => {
      this._getPendingCommissions();
    });
  }

  /**
   * Get the number of providers pending approval
   *
   * @private
   */
  private _getPendingProviders() {
    this.providersService.getPendingCount().subscribe((data) => {
      if (data.success) {
        this.providersChanged.emit(data.result);
      }
    });
  }

  /**
   * Get the number of clients pending approval
   *
   * @private
   */
  private _getPendingClients() {
    this.clientsService.getPendingCount().subscribe((data) => {
      if (data.success) {
        this.clientsChanged.emit(data.result);
      }
    });
  }

  /**
   * Get the number of expats pending approval
   *
   * @private
   */
  private _getPendingExpats() {
    this.expatsService.getPendingCount().subscribe((data) => {
      if (data.success) {
        this.expatsChanged.emit(data.result);
      }
    });
  }

  /**
   * Get the number of services pending approval
   *
   * @private
   */
  private _getPendingOffers() {
    this.offersService.getPendingCount().subscribe((data) => {
      if (data.success) {
        this.offersChanged.emit(data.result);
      }
    });
  }

  /**
   * Get the number of custom commissions pending approval
   *
   * @private
   */
  private _getPendingCommissions() {
    this.commissionsService.getRequestsCount().subscribe((data) => {
      if (data.success) {
        this.commissionsChanged.emit(data.result);
      }
    });
  }

  /**
   * Get all available counters
   */
  getAllCounters() {
    this._getPendingProviders();
    this._getPendingClients();
    this._getPendingExpats();
    this._getPendingOffers();
    this._getPendingCommissions();
  }
}

<div class="modal-form">
  <div class="row">
    <div class="form-group w-50">
      <label>{{ "LABEL.COMPANY-NAME" | translate }}*</label>
      <input
        type="text"
        name="company_name"
        class="form-control"
        [(ngModel)]="user.company_name"
      />
    </div>
    <div class="form-group w-50">
      <label>{{ "LABEL.COMPANY-LEGAL-NAME" | translate }} </label>
      <input
        type="text"
        name="company_name"
        class="form-control"
        [(ngModel)]="user.company_legal_name"
      />
    </div>
  </div>

  <div class="row">
    <div class="form-group w-50">
      <label>{{ "LABEL.FIRST-NAME" | translate }} *</label>
      <input
        type="text"
        name="name"
        class="form-control"
        [(ngModel)]="user.first_name"
      />
    </div>
    <div class="form-group w-50">
      <label>{{ "LABEL.LAST-NAME" | translate }} *</label>
      <input
        type="text"
        name="name"
        class="form-control"
        [(ngModel)]="user.last_name"
      />
    </div>
  </div>

  <div class="row">
    <div class="form-group w-50">
      <label>{{ "LABEL.EMAIL" | translate }} *</label>
      <input
        type="text"
        name="email"
        class="form-control"
        [(ngModel)]="user.email"
      />
    </div>

    <div class="form-group w-50">
      <label>{{ "LABEL.PHONE-NUMBER" | translate }} *</label>
      <div class="row">
        <div class="w-50">
          <app-phone-prefix-selector
            [control]="form.get('phone_prefix')"
          ></app-phone-prefix-selector>
        </div>
        <div class="form-group w-50">
          <input
            class="form-control"
            (keypress)="validateNumericalInput($event)"
            [(ngModel)]="user.phone"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group w-50">
      <label>{{ "LABEL.POSITION-IN-COMPANY" | translate }} *</label>
      <input
        type="text"
        name="company_position"
        class="form-control"
        [(ngModel)]="user.company_position"
      />
    </div>
    <div class="form-group w-50">
      <label>{{ "LABEL.PASSWORD" | translate }} *</label>
      <input
        type="text"
        name="password"
        class="form-control"
        [(ngModel)]="user.password"
      />
    </div>
  </div>
</div>

<div class="details-body-wrapper">
  <!-- table -->
  <div class="table-wrapper">
    <ng-container *ngIf="!isXs; else responsiveTable">
      <div class="table-responsive">
        <table>
          <thead>
            <tr>
              <th>{{ "DOCUMENTS.ID" | translate }}</th>
              <th>{{ "DOCUMENTS.NAME" | translate }}</th>
              <th>
                {{ "DOCUMENTS.UPLOADED_BY" | translate }}
              </th>
              <th>
                {{ "GENERALS.STATUS" | translate }}
              </th>

              <th class="ta-r"></th>
            </tr>
          </thead>
          <tbody *ngIf="isLoadingGeneral">
            <tr>
              <td colspan="100">
                <app-loader-spinner-general
                  [loadingType]="LoadingType.TABLE"
                ></app-loader-spinner-general>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isLoadingGeneral">
            <tr *ngFor="let item of items; let i = index">
              <td class="gray">{{ item.id }}</td>
              <td class="gray">{{ item.name }}</td>
              <td class="gray">
                {{ item.user_name }}
              </td>
              <td>
                <div class="status-label-{{ item.status }}">
                  {{ item.status }}
                </div>
              </td>

              <td class="ta-r">
                <!-- table action btn -->
                <!-- table action with overlay -->
                <div toggle-action-table class="table-action-wrapper">
                  <i class="icon-dot-3"></i>

                  <div class="table-action">
                    <ul>
                      <li>
                        <a href="{{ item.file }}" target="_blank">
                          {{ "GENERALS.VIEW-DOCUMENT" | translate }}
                        </a>
                      </li>
                      <!-- <li (click)="deleteDocument(item.id)"> 
                     {{ 'GENERALS.DELETE' | translate }}
                   </li> -->
                    </ul>
                  </div>
                </div>

                <!-- end table action with overlay -->
              </td>
            </tr>

            <tr *ngIf="items?.length === 0">
              <td colspan="6" class="gray">
                {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-template #responsiveTable>
      <!-- responsive table -->
      <app-loader-spinner-general
        *ngIf="isLoadingGeneral"
        [loadingType]="LoadingType.TABLE"
      ></app-loader-spinner-general>
      <ng-container *ngIf="!isLoadingGeneral">
        <div
          class="resposive-table-wrapper"
          *ngFor="let item of items; let i = index"
        >
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.ID" | translate }}
            </div>
            <div class="responsive-table-data-item">{{ item.id }}</div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.NAME" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{ item.name }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.UPLOADED_BY" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{ item.user_name }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "GENERALS.STATUS" | translate }}
            </div>
            <div class="responsive-table-data-item">
              <span class="status-label-{{ item.status }}">{{
                item.status
              }}</span>
            </div>
          </div>
          <div class="responsive-table-action">
            <span>
              <a href href="{{ item.file }}">{{
                "GENERALS.VIEW-DOCUMENT" | translate
              }}</a></span
            >
          </div>
        </div>
      </ng-container>
      <!-- end responsive table -->
    </ng-template>
  </div>
  <!-- end table -->
</div>

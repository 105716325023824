<div windowScrollDashboardBodyAction class="dashboard-body-action">
  <div class="clear-btn btn white-bg fl-r" (click)="goToStripeTable()">
    {{ "LABEL.MANAGE" | translate }}
  </div>
</div>

<div>
  <stripe-pricing-table
    pricing-table-id="prctbl_1M3yATByT6Cm3bGGrtZTEnt4"
    publishable-key="pk_live_TVh9HEOoiCkXHLYy3l6X2uS000RsroeT9d"
  >
  </stripe-pricing-table>
</div>

import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../_services/alert.service";

@Component({
  selector: "app-alert",
  templateUrl: "alert.component.html",
})
export class AlertComponent implements OnInit {
  messages: any;
  messagesType: any;
  isErrValidation: boolean;
  isSuccNotif: boolean;
  isErrNotif: boolean;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.alertService.getMessage().subscribe((messages) => {
      if (messages && typeof messages === "object") {
        this.messages = messages.text;
        this.messagesType = messages.type;

        if (messages.type === "error") {
          this.isErrValidation = true;
          setTimeout(() => {
            this.isErrValidation = false;
            setTimeout(() => {
              this.messages = "";
            }, 300);
          }, 5000);
        } else if (messages.type === "success") {
          this.isSuccNotif = true;
          setTimeout(() => {
            this.isSuccNotif = false;
            setTimeout(() => {
              this.messages = "";
            }, 300);
          }, 5000);
        } else if (messages.type === "string-error") {
          this.isErrNotif = true;
        }
      }
    });
  }

  closeError(): void {
    setTimeout(() => {
      this.messages = "";
    }, 300);
    this.isErrValidation = false;
  }

  closeErrorNotification() {
    setTimeout(() => {
      this.messages = "";
      this.alertService.closeErrNotification(true);
    }, 300);
    this.isErrNotif = false;
  }
}

import { Component, Input, OnInit, OnChanges, OnDestroy } from "@angular/core";
import { Dictionary } from "../../../../_models/dictionary";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { Location } from "@angular/common";
import { UsersService } from "src/app/_services/users.service";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { filter, finalize, take, takeUntil } from "rxjs/operators";
import { User } from "src/app/_models/user";
import { Subject } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-edit-primary-user",
  templateUrl: "./edit-primary-user.component.html",
})
export class EditPrimaryUserComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: User;
  @Input() edit = false;
  @Input() isCanceled: boolean;
  @Input() loaderAnim: boolean;
  @Input() userType: string;

  private destroyed$: Subject<void> = new Subject();

  phoneCode;
  phoneNumber;
  isLoadingGeneral: boolean = false;
  prefix;
  countries;
  departments: Dictionary;
  phone_prefix;
  roles;

  form: UntypedFormGroup;

  constructor(private dictionariesService: DictionariesService) {}

  ngOnInit() {
    this.getDepartments();
    this.getCountryList();

    this.form = new UntypedFormGroup({
      phone_prefix: new UntypedFormControl(""),
    });

    this.form
      .get("phone_prefix")
      .valueChanges.pipe(
        filter((value) => value),
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        this.user.phone_prefix = value;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  getCountryList() {
    this.dictionariesService
      .getCountryList()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data && data.result) {
          this.countries = data.result;
          this.prefix = data.result;
          this.prefix.map((p) => {
            if (
              this.edit &&
              this.user.phone_prefix &&
              this.dictionariesService.getPhonePrefix(
                this.user.phone_prefix
              ) === this.dictionariesService.getPhonePrefix(p.phone_code)
            ) {
              p.phone_code =
                this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
              this.setPrefix(p);
              this.phone_prefix = p.phone_code;
            } else {
              p.phone_code =
                this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
            }
          });
        }
      });
  }

  ngOnChanges(changes) {
    if (changes.isCanceled && changes.isCanceled.currentValue) {
      this.phoneCode = null;
      this.phoneNumber = null;
      this.phone_prefix = null;
    }
  }

  getDepartments() {
    this.isLoadingGeneral = true;

    this.dictionariesService
      .getDepartments()
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe((data) => {
        this.departments = data.result;
      });
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setPrefix(e) {
    if (e) {
      const code = e.phone_code.match(/\((.*?)\)/);
      this.user.phone_prefix = code[1];
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

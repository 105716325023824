import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-provider-plans",
  templateUrl: "./provider-plans.component.html",
  styleUrls: ["./provider-plans.component.css"],
})
export class ProviderPlansComponent {
  goToStripeTable() {
    window.open(environment.stripe_provider_table_url);
  }
}

<div
  #headerRef
  id="header-wrapper-admin"
  class="header-wrapper-no-bg"
  (window:scroll)="fixedHeader()"
>
  <div id="header-top" class="header-top">
    <!-- breadcrumbs - header left section -->
    <div class="header-breadcrumbs">
      <app-breadcrumbs></app-breadcrumbs>
    </div>

    <!-- end breadcrumbs - header left section -->

    <div class="header-top-action-wrapper fl-r">
      <!-- widgets -->
      <app-top-menu></app-top-menu>

      <!-- language selection -->
      <div class="header-top-lang fl-l">
        <app-translate></app-translate>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</div>

<div class="clear"></div>

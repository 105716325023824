import { finalize, Observable, take } from "rxjs";
import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Customer } from "../../../_models/customer";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { ClientsService } from "../../../_services/clients.service";
import { ProvidersService } from "../../../_services/providers.service";
import { EntityTypeEnum } from "../../shared/_enums/entity-type.enum";
import { EntityUserData } from "../models/entity-user.data";
import { EntityData } from "../models/entity.data";
import { LoadingTypeEnum } from "../../shared/_enums/loading-type.enum";

@Component({
  selector: "app-company-details",
  templateUrl: "./company-details.component.html",
  styleUrls: ["./company-details.component.scss"],
})
export class CompanyDetailsComponent implements OnInit {
  public isLoading = false;
  public entityId: number;
  public entityType: string;
  public request$: Observable<any>;
  public entity: EntityData;
  public primaryAccount: EntityUserData;
  public customer: Customer;
  public service: ClientsService | ProvidersService;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private location: Location,
    private clientsService: ClientsService,
    private providersService: ProvidersService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params.id) {
        if (this.location.path().indexOf(EntityTypeEnum.PROVIDER) > -1) {
          this.entityType = EntityTypeEnum.PROVIDER;
          this.service = this.providersService;
        } else if (this.location.path().indexOf(EntityTypeEnum.CLIENT) > -1) {
          this.entityType = EntityTypeEnum.CLIENT;
          this.service = this.clientsService;
        }
        this.setup(params);
      }
    });
  }

  setup(params) {
    this.entityId = params.id;
    if (this.entityType === EntityTypeEnum.CLIENT) {
      this.request$ = this.clientsService.getClient(this.entityId);
    } else if (this.entityType === EntityTypeEnum.PROVIDER) {
      this.request$ = this.providersService.getProvider(this.entityId);
    }
    this.getEntityDetails();
  }

  public getEntityDetails() {
    this.isLoading = true;

    this.request$
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((data) => {
        this.entity = data.result;
        this.setBreadcrumb();

        this.primaryAccount = this.entity.primary_user;
      });
  }

  public setBreadcrumb() {
    if (this.entityType === "provider") {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForProvider(this.entity, "details")
      );
    } else {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForClient(this.entity, "details")
      );
    }
  }

  setPrimaryAccount(users: EntityUserData[]) {
    this.primaryAccount = users.filter(
      (entityUser) => entityUser.is_primary === true
    )[0];
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

<div *ngIf="user">
  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.FIRST-NAME" | translate }} *</mat-label>
        <input [(ngModel)]="user.first_name" matInput />
      </mat-form-field>
    </div>

    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.LAST-NAME" | translate }} *</mat-label>
        <input [(ngModel)]="user.last_name" matInput />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.EMAIL" | translate }} *</mat-label>
        <input [(ngModel)]="user.email" matInput />
      </mat-form-field>
    </div>

    <div class="w-50">
      <div class="row px-10">
        <div class="w-50">
          <app-phone-prefix-selector
            [control]="form.get('phone_prefix')"
          ></app-phone-prefix-selector>
        </div>
        <div class="w-50 p-0">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "LABEL.PHONE-NUMBER" | translate }} *</mat-label>
            <input
              [(ngModel)]="user.phone"
              matInput
              (keypress)="validateNumericalInput($event)"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="w-50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }} *</mat-label>
        <input [(ngModel)]="user.company_position" matInput />
      </mat-form-field>
    </div>

    <div class="w-50" *ngIf="userType !== 'provider'">
      <app-loader-spinner-general
        *ngIf="isLoadingGeneral"
        [loadingType]="LoadingType.INPUT"
      ></app-loader-spinner-general>
      <mat-form-field
        *ngIf="!isLoadingGeneral"
        appearance="outline"
        class="w-100"
      >
        <mat-label>{{ "GENERALS.SELECT-DEPARTMENT" | translate }}</mat-label>
        <mat-select [(ngModel)]="user.department_id">
          <mat-option
            *ngFor="let department of departments"
            [value]="department.id"
          >
            {{ department.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

import { Component, Input, OnChanges, Output } from "@angular/core";
import { DictionariesService } from "../../../../../_services/dictionaries.service";

@Component({
  selector: "app-characteristic-form",
  templateUrl: "./characteristic-form.component.html",
  styleUrls: ["./characteristic-form.component.css"],
})
export class CharacteristicFormComponent implements OnChanges {
  @Input() formdata;
  @Output() characteristics;
  countries;
  timeUnit = [
    "Seconds",
    "Minutes",
    "Hours",
    "Days",
    "Weeks",
    "Months",
    "Years",
  ];
  enabledFields;
  fieldsValue;
  some;

  constructor(private dictionariesService: DictionariesService) {
    this.getCountryList();
  }

  ngOnChanges(changes) {
    if (changes.formdata && changes.formdata.currentValue) {
      if (changes.formdata.currentValue == "reset") {
        this.formdata = null;
      } else {
        this.formdata = changes.formdata.currentValue;
      }
    }
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      this.countries = data.result;
    });
  }

  getFieldSelectType(field) {
    if (field.value.includes("country")) {
      return "country";
    }

    if (field.value.includes("language")) {
      return "language";
    }

    return "unit";
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}

import {
  Directive,
  HostBinding,
  HostListener,
  ElementRef,
  Renderer2,
  OnInit,
} from "@angular/core";

@Directive({
  selector: "[windowScrollTableBulk]",
})
export class WindowScrollTableBulkDirective implements OnInit {
  elemPosition;
  innerWidth;
  xs;

  constructor(public el: ElementRef, public renderer: Renderer2) {
    this.innerWidth = window.innerWidth;
    if (innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }
  }
  ngOnInit() {
    // add position fixed helper
    const heleprElem = document.createElement("div");
    heleprElem.classList.add("small-table-bulk-helper");
    this.el.nativeElement.before(heleprElem);
    // 5 = margin bottom for .bulk-action-wrapper
    this.renderer.setStyle(
      heleprElem,
      "height",
      this.el.nativeElement.offsetHeight + 5 + "px"
    );
    this.renderer.setStyle(
      document.querySelector(".small-table-bulk-helper"),
      "display",
      "none"
    );

    this.elemPosition = this.el.nativeElement.offsetTop;
  }

  @HostListener("window:scroll", ["$event"])
  @HostListener("window:resize", ["$event"])
  onScroll() {
    this.innerWidth = window.innerWidth;

    if (innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }

    if (!this.xs) {
      const scrolled =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentElement ||
              document.body
            ).scrollTop;

      // 51 = small header height;
      // 49 = small-dashboard-body-top height;
      // 50 = small-dashboard-body-action height;
      if (scrolled >= this.elemPosition - 51 - 49 - 50) {
        this.renderer.addClass(this.el.nativeElement, "small-table-bulk");
        this.renderer.setStyle(
          document.querySelector(".small-table-bulk-helper"),
          "display",
          "inline-block"
        );
      } else {
        this.renderer.removeClass(this.el.nativeElement, "small-table-bulk");
        this.renderer.setStyle(
          document.querySelector(".small-table-bulk-helper"),
          "display",
          "none"
        );
      }
    }
  }
}

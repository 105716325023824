import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class RFQSService {
  private rfqsURL = "rfqs";

  constructor(private http: HttpClient) {}

  // GET RFQS and Bids
  getRFQS(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.rfqsURL, {
      params: params,
    });
  }

  // GET RFQ and Bids by id
  getRFQById(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.rfqsURL + "/" + id
    );
  }

  // GET RFQ history
  getRFQHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "rfqs/" + id + "/history",
      { params: params }
    );
  }

  // GET RFQ and Bids by id
  getProvidersInvitationList(params = {}): Observable<any> {
    const keys = Object.keys(params);
    let url = "";
    keys.forEach((key) => {
      if (key === "service_category_id") {
        params[key].forEach((e, i) => {
          url = url + "service_category_id[" + i + "]=" + e + "&";
        });
      } else {
        url = url + key + "=" + params[key] + "&";
      }
    });
    url = url.slice(0, -1);

    return this.http.get<any>(
      // environment.gateway_endpoint + '/rfqs/list-of-providers',
      environment.gateway_endpoint + "rfqs/list-of-providers?" + url,
      params
    );
  }

  // GET RFQ and Bids by id
  getAllProvidersInvitationList(params = {}): Observable<any> {
    const keys = Object.keys(params);
    let url = "";
    keys.forEach((key) => {
      if (key === "service_category_id") {
        params[key].forEach((e, i) => {
          url = url + "service_category_id[" + i + "]=" + e + "&";
        });
      } else {
        url = url + key + "=" + params[key] + "&";
      }
    });
    url = url.slice(0, -1);

    return this.http.get<any>(
      environment.gateway_endpoint + "rfqs/list-of-all-providers?" + url,
      params
    );
  }

  // GET service offers for creating new RFQ
  getRFQServiceOffers(params = {}): Observable<any> {
    let url = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (key === "category_id") {
        params[key].forEach((e, i) => {
          url = url + "category_id[" + i + "]=" + e + "&";
        });
      } else {
        url = url + key + "=" + params[key] + "&";
      }
    });
    url = url.slice(0, -1);

    return this.http.get<any>(
      environment.gateway_endpoint + "rfqs/list-of-offers" + url
    );
  }

  createRFQAndBid(rfq): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "rfqs/create",
      rfq,
      httpOptions
    );
  }

  saveDraftRFQAndBid(rfq): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "rfqs/create/draft",
      rfq,
      httpOptions
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "rfqs/status-transition"
    );
  }

  // Update RFQ/Bid status
  updateStatusBulk(status, client_id, rfq_id, reason = ""): Observable<any> {
    let body: any = { status: status, client_id: client_id };
    if (reason) {
      body = {
        status: status,
        client_id: client_id,
        declined_reason: reason,
      };
    }
    return this.http.post<any>(
      environment.gateway_endpoint + "rfqs/" + rfq_id + "/change-status",
      body,
      httpOptions
    );
  }

  // Update RFQ/Bid details
  updateRFQDetails(params): Observable<any> {
    return this.http.put<any>(
      environment.gateway_endpoint + "rfqs/update",
      params,
      httpOptions
    );
  }

  // Update RFQ/Bid details
  updateDraftRFQDetails(params): Observable<any> {
    return this.http.put<any>(
      environment.gateway_endpoint + "rfqs/update/draft",
      params,
      httpOptions
    );
  }

  // add RFQ offer
  addOfferQuotation(offer, rfq_id): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "rfqs/" + rfq_id + "/add-offer",
      offer,
      httpOptions
    );
  }

  // get offers by provider for RFQ
  getOffersByProvider(params): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "rfqs/list-of-offers-by-provider",
      { params: params }
    );
  }

  // Accept/ Decline RFQ quotation offers
  statusOfferQuotation(params, rfq_quotation_id): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        "rfqs/" +
        rfq_quotation_id +
        "/change-status-offer-quotation",
      params,
      httpOptions
    );
  }

  // Get RFQ quotation offers
  getAcceptedOffers(params): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "finalize-rfqs",
      params,
      httpOptions
    );
  }

  // Add to cart
  addRFQtoCart(params): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "rfqs/add-to-cart",
      params,
      httpOptions
    );
  }

  extendAvailability(period, id) {
    return this.http.put<any>(
      environment.gateway_endpoint + "rfqs/update-period/" + id,
      { period: period },
      httpOptions
    );
  }

  declineRFQInvitation(params, id): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        "rfqs/" +
        id +
        "/change-status-provider-invitation",
      params,
      httpOptions
    );
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { ItemsListComponent } from "../../../items-list/items-list.component";
import { SelectionModel } from "@angular/cdk/collections";
import cloneDeep from "lodash-es/cloneDeep";
import { ServiceCategory } from "src/app/_models/servicecategory";

@Component({
  selector: "app-categories-tree",
  templateUrl: "./categories-tree.component.html",
})
export class CategoriesTreeComponent
  extends ItemsListComponent
  implements OnInit
{
  selection = new SelectionModel(true, []);
  readonlySelection = new SelectionModel(true, []);
  categories: ServiceCategory[];
  previouslySelectedCategory;

  @Input() set serviceCategories(value) {
    if (value) {
      this.categories = cloneDeep(value);
    }
  }

  @Input() isRFQ = false;
  @Input() edit = false;

  @Input() set selectedServiceCategories(data) {
    if (data) {
      data.forEach((key) => {
        const idx = this.categories.findIndex(
          (f) => f.id === key.service_category_id && !key.edit_flag
        );
        if (idx > -1) {
          this.readonlySelection.select(this.categories[idx]);
        }

        const idxS = this.categories.findIndex(
          (f) => f.id === key.service_category_id && key.edit_flag
        );
        if (idxS > -1) {
          this.selection.select(this.categories[idxS]);
        }
      });
      this.previouslySelectedCategory = data;
    }
  }

  @Input() templateRef: TemplateRef<HTMLElement>;

  @Output() onselect: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  changeSelection(item) {
    this.selection.toggle(item);
    if (!item.active_children_count) {
      this.onselect.emit({
        selection: item,
        id: item.parent_id,
      });
    }
  }

  ngOnInit() {
    this.onResize();
  }

  onResize() {
    if (this.isRFQ) {
      this.isRFQ = window.innerWidth >= 1600 ? true : false;
    }
  }
}

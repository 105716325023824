<!-- <div class="dashboard-body-top">
</div> -->
<div class="clear m-t-30"></div>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>{{ "GENERALS.OFFERED-BY" | translate }}</th>
            <th>
              {{ "GENERALS.SERVICE-TITLE" | translate }}
            </th>
            <th>{{ "GENERALS.LISTINGPRICE" | translate }}</th>
            <th>{{ "GENERALS.SALEPRICE" | translate }}</th>
            <th>{{ "GENERALS.CITY" | translate }}</th>
            <th>{{ "GENERALS.POSITION" | translate }}</th>
            <th>{{ "GENERALS.RATING" | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr *ngFor="let serviceOffer of associatedOffers; let i = index">
            <td class="gray-pointer">
              <a href="/service-offers/{{ serviceOffer.id }}" target="_blank">{{
                serviceOffer.company_name
              }}</a>
            </td>
            <td class="gray-pointer">
              <a href="/service-offers/{{ serviceOffer.id }}" target="_blank">{{
                serviceOffer.title
              }}</a>
            </td>
            <td class="gray-pointer">
              <a href="/service-offers/{{ serviceOffer.id }}" target="_blank">{{
                serviceOffer.listing_price.price
                  | currency : serviceOffer.listing_price.currency
              }}</a>
            </td>
            <td class="gray-pointer">
              <a href="/service-offers/{{ serviceOffer.id }}" target="_blank">{{
                serviceOffer.sale_price.price
                  | currency : serviceOffer.sale_price.currency
              }}</a>
            </td>
            <td class="gray-pointer">
              <a href="/service-offers/{{ serviceOffer.id }}" target="_blank">{{
                serviceOffer.city
              }}</a>
            </td>
            <td class="gray-pointer">
              <a href="/service-offers/{{ serviceOffer.id }}" target="_blank"
                >{{ serviceOffer.positioning }}/{{
                  serviceOffer.total_positions
                }}</a
              >
            </td>
            <td class="gray-pointer">
              <a href="/service-offers/{{ serviceOffer.id }}" target="_blank">
                <app-rating [currentRate]="serviceOffer.rating"></app-rating>
              </a>
            </td>
          </tr>
          <tr *ngIf="associatedOffers === 0">
            <td colspan="10" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="let serviceOffer of associatedOffers; let i = index"
      >
        <div class="responsive-table-row">
          <div class="responsive-table-details">
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.OFFERED-BY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.company_name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.SERVICE-TITLE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.title }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.LISTINGPRICE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{
              serviceOffer.listing_price.price
                | currency : serviceOffer.listing_price.currency
            }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.SALEPRICE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{
              serviceOffer.sale_price.price
                | currency : serviceOffer.sale_price.currency
            }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.CITY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.city }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.POSITION" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.positioning }}/{{ serviceOffer.total_positions }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.RATING" | translate }}
          </div>
          <div class="responsive-table-data-item resposive-table-rating">
            <app-rating [currentRate]="serviceOffer.rating"></app-rating>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>
</div>
<!-- end table -->

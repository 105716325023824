<div
  *ngFor="let category of categories; let i = index"
  class="tree-row-wrapper"
>
  <div
    *ngIf="category.children_count"
    class="toggle-row-action fl-l"
    toggle-row
  >
    <i class="icon-plus-1"></i>
  </div>
  <div class="tree-row">
    <div
      *ngIf="!readonlySelection.isSelected(category)"
      class="tree-check fl-l"
    >
      <mat-checkbox
        (change)="$event ? changeSelection(category) : null"
        (click)="$event.stopPropagation()"
        [checked]="selection.isSelected(category)"
        [disableRipple]="true"
      >
      </mat-checkbox>
    </div>
    <div
      *ngIf="readonlySelection.isSelected(category)"
      class="tree-check dark-green fl-l"
    >
      <i class="icon-ok"></i>
    </div>
    <div class="tree-label fl-l">
      <span
        [ngClass]="{
          'fw-500': selection.isSelected(category),
          underline: selection.isSelected(category)
        }"
      >
        {{ category.label }}
      </span>
      <span *ngIf="category.children_count">
        ({{ category.children_count }})
      </span>
    </div>
  </div>
  <div *ngIf="category.children_count" class="toggle-tree">
    <app-add-categories
      (onselect)="onselect.emit($event)"
      [level]="category.level + 1"
      [parentIsSelected]="selection.isSelected(category)"
      [providerId]="providerId"
      [selectedServiceCategories]="parseSelectedCategory(category.path)"
      [serviceCategories]="category.children"
    ></app-add-categories>
  </div>

  <div class="clear"></div>
</div>

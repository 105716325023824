export class AdsDetails {
  links: Array<any>;

  constructor() {
    this.links = [
      {
        link: "/cms/ads/pricing",
        label: "Ads & Pricing",
        page: "pricing",
      },
      {
        link: "/cms/ads/ad-campaigns",
        label: "Ad Campaigns",
        page: "ad-campaigns",
      },
    ];
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { ProvidersService } from "../../../../_services/providers.service";

@Component({
  selector: "app-providers",
  templateUrl: "./provider-offers.component.html",
  styleUrls: ["./provider-offers.component.css"],
})
export class ProviderOffersComponent implements OnInit {
  provider_id;
  constructor(
    private route: ActivatedRoute,
    private service: ProvidersService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.provider_id = this.route.snapshot.parent.params.id;
  }
  ngOnInit() {
    this.service.getProvider(this.provider_id).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForProvider(data.result, "offers")
      );
    });
  }
}

<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">
    {{ "SUB-NAVIGATION.ADS-PRICING" | translate }}
  </div>
  <!-- end left -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>{{ "GENERALS.PLACEMENT" | translate }}</th>
            <th>{{ "GENERALS.CPC-UNIT-PRICE" | translate }}</th>
            <th>{{ "GENERALS.CPM-UNIT-PRICE" | translate }}</th>
            <th></th>
          </tr>
        </thead>

        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr *ngFor="let item of listBannerType; let i = index">
            <td class="gray-pointer">
              {{ "BANNER-TYPE." + item.key | translate }}
            </td>

            <td class="gray-pointer">
              {{ item.unit_price_cpc | currency : "EUR" }}
            </td>

            <td class="gray-pointer">
              {{ item.unit_price_cpm | currency : "EUR" }}
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action with overlay -->

              <div toggle-action-table class="table-action-wrapper">
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li (click)="openEditUnitPrice(item)">
                      {{ "GENERALS.EDIT" | translate }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="listBannerType?.length === 0">
            <td colspan="3" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->

    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="let item of listBannerType; let i = index"
      >
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.PLACEMENT" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ "BANNER-TYPE." + item.key | translate }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.CPC-UNIT-PRICE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ item.unit_price_cpc | currency : "EUR" }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.CPM-UNIT-PRICE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ item.unit_price_cpm | currency : "EUR" }}
          </div>
        </div>

        <div class="responsive-table-action">
          <span (click)="openEditUnitPrice(item)" class="action-link-orange">{{
            "GENERALS.EDIT" | translate
          }}</span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>
</div>
<!-- end table -->

<app-edit-unit-price-modal
  [item]="editData"
  (saveEditData)="saveEditData($event)"
  (getItem)="getListBannerType()"
></app-edit-unit-price-modal>

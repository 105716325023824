import { Subscription } from "./subscription";

export class Customer {
  subscription?: Subscription;
  entity_id: number;
  entity_type: string;
  id: number;
  name: string;
  stripe_id: string;
  updated_at: string;
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
export class ShareDataService {
  _sendData: Observable<any>;

  private sendDataSubject = new Subject<any>();

  constructor() {
    this._sendData = this.sendDataSubject.asObservable();
  }

  sendData(data) {
    this.sendDataSubject.next(data);
  }
}

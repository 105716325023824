<div class="white-box-row" *ngIf="primaryAccount">
  <div class="white-box-header">
    <div class="medium-title fl-l">
      {{ "GENERALS.ACCOUNT-INFO" | translate }}
    </div>
  </div>
  <div class="clear"></div>
  <div class="white-box-body">
    <p class="case-label">
      {{ primaryAccount.user.first_name }} {{ primaryAccount.user.last_name }}
    </p>
    <p class="case-label">
      <i class="icon-visa_business"></i>
      {{ primaryAccount.company_position }}
    </p>
    <p class="case-label">
      <a href="mailto:{{ primaryAccount.user.email }}">
        <i class="icon-mail"></i>
        {{ primaryAccount.user.email }}
      </a>
    </p>
    <p class="case-label">
      <i class="icon-phone"></i>
      {{ primaryAccount.user.phone_prefix }} {{ primaryAccount.user.phone }}
    </p>
  </div>
</div>

<ng-container *ngIf="isLoadingGeneral">
  <div class="d-flex loading-wrapper">
    <div class="w-profile">
      <app-loader-spinner-general
        [loadingType]="LoadingType.PROFILE"
      ></app-loader-spinner-general>
    </div>
    <div class="w-info">
      <div class="w-first-row">
        <app-loader-spinner-general
          [repeat]="7"
          [loadingType]="LoadingType.INFO"
        ></app-loader-spinner-general>
      </div>
    </div>
  </div>
</ng-container>

<div class="details-body-wrapper" *ngIf="RFQ">
  <!-- 2 col details -->
  <div class="details-2c d-flex">
    <!-- left -->
    <app-rfq-details-actions-panel
      [item]="refresh"
    ></app-rfq-details-actions-panel>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right m-0">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title-rfq fl-l">
              <div>
                {{ "RFQS.SERVICE-QUOTATIONS" | translate }}
              </div>
            </div>

            <!-- <div *ngIf="RFQ.status_name.toLowerCase() === 'open'" class="white-box-header-action fl-r" (click)="openModal(item, RFQ.categories[idx])">
              <i class="icon-plus-1"></i>  {{ 'RFQS.ADD-OFFER' | translate }}
             
            </div> -->
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <div
              class="rfq-details-row"
              *ngFor="let item of RFQ.offers_request; let idx = index"
            >
              <div class="small-title">
                {{ RFQ.categories[idx].service_category_name }}

                <ng-container
                  *ngIf="RFQ.offers_request[idx].quotation_offers.length === 0"
                >
                  <span
                    *ngIf="
                      RFQ.status_name.toLowerCase() === 'open' ||
                      RFQ.status_name.toLowerCase() === 'extended'
                    "
                    class="action-link-orange fl-r"
                    (click)="openModal(item, RFQ.categories[idx])"
                  >
                    {{ "RFQS.ADD-OFFER" | translate }}
                  </span>
                </ng-container>
              </div>

              <div class="rfq-qty">
                <span>
                  {{ "RFQS.REQUIRED-QUANTITY" | translate }}:
                  <span class="orange">
                    {{ item.quantity }}
                  </span>
                </span>
              </div>
              <div class="rfq-service-desc-readonly m-b-10">
                <span>
                  {{ item.service_description }}
                </span>
              </div>

              <!-- <div
                *ngFor="let quotation of RFQ.offers_request[idx].quotation_offers"
                >

                  <ng-container *ngIf="quotation.accepted_by_client === 1"> 
                      <div class="gray fw-500 m-b-15" > <i class="icon-ok dark-green"></i> {{ 'GENERALS.WAS-ACCEPTED' | translate }}</div>
                  </ng-container>

                  <ng-container *ngIf="quotation.accepted_by_client === 0"> 
                      <div class="gray fw-500 m-b-15" > <i class="icon-cancel dark-red"></i> {{ 'GENERALS.WAS-NOT-ACCEPTED' | translate }}</div>
                  </ng-container>

                  <ng-container *ngIf="quotation.accepted_by_client === null"> 
                      <div class="gray fw-500 m-b-15" > <i class="icon-attention-alt orange"></i> {{ 'GENERALS.PROVIDER-SENT-YOUR-OFFER' | translate }}</div>
                  </ng-container>

              </div> -->

              <div *ngIf="item.quotation_offers.length">
                <div id="bulk-action-fixed" *ngIf="bulkActions[idx]">
                  <div class="bulk-to-top">
                    <div class="quotation-bulk bulk-action-wrapper">
                      <div class="bulk-action-body">
                        {{ allSelection[idx].selected.length }}
                        {{ "GENERALS.ITEMS-SELECTED" | translate }}
                      </div>
                      <div class="bulk-action-btn">
                        <div *ngFor="let action of availableActions">
                          <button
                            class="btn-small orange-bg"
                            (click)="bulkChange(idx, action.id)"
                          >
                            {{
                              "ACTIONS." + action.text.toUpperCase() | translate
                            }}
                          </button>
                        </div>
                      </div>
                      <div
                        class="bulk-action-close"
                        (click)="clearSelection(idx)"
                      >
                        <i
                          class="icon-cancel"
                          title=" {{ 'GENERALS.DECLINE-OFFER' | translate }}"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-wrapper inner-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <!-- <ng-container matColumnDef="select">
                          <th
                            *ngIf="
                              RFQ.status_name.toLowerCase() !== 'finalized'
                            "
                          >
                            <mat-checkbox
                              (change)="
                                $event
                                  ? customMasterToggle(
                                      idx,
                                      item.quotation_offers
                                    )
                                  : null
                              "
                              [checked]="
                                allSelection[idx].hasValue() &&
                                customIsAllSelected(idx, item.quotation_offers)
                              "
                              [indeterminate]="
                                allSelection[idx].hasValue() &&
                                !customIsAllSelected(idx, item.quotation_offers)
                              "
                              [disableRipple]="true"
                            >
                            </mat-checkbox>
                          </th>
                        </ng-container> -->
                        <th>
                          {{ "GENERALS.OFFERED-BY" | translate }}
                        </th>
                        <th>
                          {{ "GENERALS.PRICE" | translate }}
                        </th>
                        <th>
                          {{ "GENERALS.TOTAL-PRICE" | translate }}
                        </th>
                        <th>
                          {{ "GENERALS.DETAILS" | translate }}
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let serviceOffer of item.quotation_offers
                            | paginate
                              : {
                                  itemsPerPage: itemsPage,
                                  currentPage: p,
                                  totalItems: total
                                };
                          let i = index
                        "
                      >
                        <!-- <td
                          *ngIf="RFQ.status_name.toLowerCase() !== 'finalized'"
                        >
                          <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="
                              $event
                                ? customChangeSelection(idx, serviceOffer)
                                : null
                            "
                            [checked]="
                              allSelection[idx].isSelected(serviceOffer)
                            "
                            [disableRipple]="true"
                          >
                          </mat-checkbox>
                        </td> -->
                        <td class="gray">
                          {{ serviceOffer.company_name }}
                        </td>
                        <td class="gray">
                          {{
                            serviceOffer.price
                              | currency : serviceOffer.currency_code
                          }}
                        </td>
                        <td class="gray">
                          <!--Qty: {{ serviceOffer.quantity }}-->
                          <!--<br />-->
                          Total:
                          {{
                            serviceOffer.price * serviceOffer.quantity
                              | currency : serviceOffer.currency_code
                          }}
                        </td>
                        <td>
                          <a
                            [routerLink]="[
                              '/service-offers/',
                              serviceOffer.offer_id
                            ]"
                            class="action-link-orange"
                            target="_blank"
                          >
                            {{ "GENERALS.VIEW-DETAILS" | translate }}</a
                          >
                        </td>
                        <td
                          class="gray-pointer no-padding"
                          *ngIf="
                            RFQ.status_name.toLowerCase() === 'open' ||
                            RFQ.status_name.toLowerCase() === 'closed' ||
                            RFQ.status_name.toLowerCase() === 'extended'
                          "
                        >
                          <span
                            class="quotation-actions"
                            [ngClass]="{
                              'dark-green':
                                serviceOffer.accepted_by_client === 1,
                              gray: serviceOffer.accepted_by_client === 0
                            }"
                            (click)="statusOfferQuotation(1, serviceOffer)"
                          >
                            <i
                              class="icon-ok"
                              title=" {{ 'GENERALS.ACCEPT-OFFER' | translate }}"
                            ></i>
                          </span>
                          <span
                            class="quotation-actions"
                            [ngClass]="{
                              'dark-red': serviceOffer.accepted_by_client === 0,
                              gray: serviceOffer.accepted_by_client === 1
                            }"
                            (click)="statusOfferQuotation(0, serviceOffer)"
                          >
                            <i
                              class="icon-cancel"
                              title=" {{
                                'GENERALS.DECLINE-OFFER' | translate
                              }}"
                            ></i>
                          </span>
                        </td>
                        <td
                          *ngIf="
                            serviceOffer.accepted_by_client !== null &&
                            RFQ.status_name.toLowerCase() === 'finalized'
                          "
                        >
                          <span
                            class="quotation-actions"
                            [ngClass]="{
                              'dark-green':
                                serviceOffer.accepted_by_client === 1,
                              gray: serviceOffer.accepted_by_client === 0
                            }"
                          >
                            <i
                              class="icon-ok"
                              title=" {{ 'GENERALS.ACCEPT-OFFER' | translate }}"
                            ></i>
                          </span>
                          <span
                            class="quotation-actions"
                            [ngClass]="{
                              'dark-red': serviceOffer.accepted_by_client === 0,
                              gray: serviceOffer.accepted_by_client === 1
                            }"
                          >
                            <i
                              class="icon-cancel"
                              title=" {{
                                'GENERALS.DECLINE-OFFER' | translate
                              }}"
                            ></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="white-box-footer">
          <div class="fl-l medium-title">
            {{ "RFQS.OFFERS-TOTAL" | translate }}:

            <ng-container *ngIf="RFQ.summary.length > 0; else nothingAccepted">
              <span *ngFor="let total of RFQ.summary; let last = last">
                {{ total.total_amount | currency : total.currency_code
                }}<span *ngIf="!last">,</span>
              </span>
            </ng-container>

            <ng-template #nothingAccepted>
              <span> {{ "GENERALS.NO-OFFER-ACCEPTED-YET" | translate }}</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>

<app-add-rfq-offer
  *ngIf="RFQ"
  (oncreate)="addOfferQuotation($event)"
  [providers]="RFQ.providers"
  [itemfilters]="RFQ"
  [offer]="selectedOffer"
></app-add-rfq-offer>

<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div windowScrollDashboardBodyTop class="dashboard-body-top">
  <!-- right -->
  <div class="fl-r">
    <div
      *ngIf="addPermission && this.authService.$canCreate(resource.USER)"
      class="btn white-bg"
      (click)="openModal()"
    >
      <i class="icon-user-add-outline"></i>
      {{ "GENERALS.ADD" | translate }}
    </div>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div
  windowScrollDashboardBodyAction
  class="dashboard-body-action"
  *ngIf="isFiltersLoaded"
>
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; getItems()"
      (filter)="filters = $event; getItems()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; getItems()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div class="clear-btn btn white-bg fl-r" (click)="clearFilters(); getItems()">
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table matSort (matSortChange)="sortData($event)">
        <thead>
          <tr>
            <ng-container matColumnDef="select">
              <th>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [disableRipple]="true"
                >
                </mat-checkbox>
              </th>
            </ng-container>
            <th mat-sort-header="id">
              {{ "GENERALS.ID" | translate }}
            </th>
            <th>{{ "LABEL.FIRST-LAST-NAME" | translate }}</th>
            <th>
              {{ "LABEL.ROLE" | translate }}
            </th>
            <th mat-sort-header="company_position">
              {{ "GENERALS.POSITION" | translate }}
            </th>
            <th mat-sort-header="email">
              {{ "LABEL.EMAIL" | translate }}
            </th>
            <th mat-sort-header="phone_prefix">
              {{ "LABEL.PHONE-NUMBER" | translate }}
            </th>
            <th>{{ "GENERALS.STATUS" | translate }}</th>
            <th mat-sort-header="is_primary">
              {{ "GENERALS.PRIMARY" | translate }}
            </th>
            <th mat-sort-header="is_primary">
              {{ "GENERALS.LAST-LOGIN" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let user of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? changeSelection(user) : null"
                [checked]="selection.isSelected(user)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>

            <td
              class="gray-pointer"
              (click)="navigateTo(user.id, filters.entity_id)"
            >
              <a [href]="'/users/' + user.id" class="orange underline">
                {{ user.id }}</a
              >
            </td>

            <td
              class="gray-pointer"
              (click)="navigateTo(user.id, filters.entity_id)"
            >
              {{ user.name }}
            </td>
            <td
              class="gray-pointer"
              (click)="navigateTo(user.id, filters.entity_id)"
            >
              {{ user.role_name }}
            </td>
            <td
              class="gray-pointer"
              (click)="navigateTo(user.id, filters.entity_id)"
            >
              {{ user.company_position }}
            </td>
            <td
              class="gray-pointer"
              (click)="navigateTo(user.id, filters.entity_id)"
            >
              {{ user.email }}
            </td>
            <td
              class="gray-pointer"
              (click)="navigateTo(user.id, filters.entity_id)"
            >
              {{ dictionariesService.getPhonePrefix(user.phone_prefix)
              }}{{ user.phone }}
            </td>
            <td (click)="navigateTo(user.id, filters.entity_id)">
              <div class="entity_status-label-{{ user.status_id }}">
                {{ user.status_name }}
              </div>
            </td>
            <td
              class="gray-pointer"
              (click)="navigateTo(user.id, filters.entity_id)"
            >
              {{
                user.is_primary
                  ? ("GENERALS.YES" | translate)
                  : ("GENERALS.NO" | translate)
              }}
            </td>
            <td
              class="gray-pointer"
              (click)="navigateTo(user.id, filters.entity_id)"
            >
              {{ user.last_login | date : "dd/MM/yyyy HH:mm" }}
            </td>

            <td class="ta-r">
              <!-- table action with overlay -->
              <div
                toggle-action-table
                class="table-action-wrapper"
                *ngIf="actions"
              >
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <ng-container
                      *ngFor="let action of actions[user.status_id]"
                    >
                      <li
                        *ngIf="showAction(action.id, user)"
                        (click)="changeStatus(action, user)"
                      >
                        {{ action.text | translate }}
                      </li>
                    </ng-container>
                    <li
                      *ngIf="!user.confirmed_account"
                      (click)="resendConfirmationMail(user.id)"
                    >
                      {{ "GENERALS.RESEND-CONFIRMATION-MAIL" | translate }}
                    </li>
                    <li
                      *ngIf="!user.confirmed_account"
                      (click)="confirmEmail(user.id)"
                    >
                      {{ "GENERALS.CONFIRM_EMAIL" | translate }}
                    </li>
                    <li (click)="resetPassword(user.email)">
                      {{ "AUTH.CHANGE-PASSWORD" | translate }}
                    </li>
                    <li *ngIf="!user.is_primary" (click)="makePrimry(user.id)">
                      {{ "GENERALS.MAKE-PRIMARY" | translate }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>

            <!-- end table action with overlay -->
            <!-- </td> -->
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="10" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let user of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(user) : null"
              [checked]="selection.isSelected(user)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
          <div
            (click)="navigateTo(user.id, filters.entity_id)"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ user.id }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.FIRST-LAST-NAME" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ user.name }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.ROLE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ user.role_name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.POSITION" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ user.company_position }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.EMAIL" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ user.email }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "LABEL.PHONE-NUMBER" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ dictionariesService.getPhonePrefix(user.phone_prefix)
            }}{{ user.phone }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ user.status }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.PRIMARY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ user.is_primary }}
          </div>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="getItems($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->

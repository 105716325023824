export class ExpatDetails {
  links: Array<any>;

  constructor(_id: number, filters) {
    this.links = [
      {
        link: "/expats/" + _id,
        filters: filters,
        label: "Details",
        page: "details",
      },
      {
        link: "/expats/" + _id + "/docs",
        filters: filters,
        label: "Docs",
        page: "docs",
      },
      {
        link: "/expats/" + _id + "/history",
        filters: filters,
        label: "History",
        page: "history",
      },
    ];
  }
}

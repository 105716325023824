import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class CrmService {
  constructor(private http: HttpClient, private auth: AuthenticationService) {}
  // GET Orders
  goToOrder(marketplaceId, email, MarketplaceCompanyId): Observable<any> {
    return this.http.post<any>(environment.gateway_endpoint + "crm/getUrl", {
      marketplaceId: marketplaceId,
      entity: "Order",
      email: email,
      MarketplaceCompanyId: MarketplaceCompanyId,
    });
  }
}

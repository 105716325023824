import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { Subject } from "rxjs";

@Directive()
@Injectable()
export class LoadingService {
  private subject = new Subject<any>();

  @Output() loadingStarted: EventEmitter<any> = new EventEmitter();
  @Output() loadingFinished: EventEmitter<any> = new EventEmitter();
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { RFQSService } from "src/app/_services/rfqs.service";
import { environment } from "src/environments/environment";
import cloneDeep from "lodash-es/cloneDeep";
import { UsersService } from "src/app/_services/users.service";

@Component({
  selector: "app-finalize-rfq",
  templateUrl: "./finalize-rfq.component.html",
})
export class FinalizeRFQComponent implements OnInit, OnChanges {
  @Input() rfq_id;
  @Input() client_id;
  @Input() type;
  @Input() status;
  @Input() status_name;
  @Input() isOpen;
  @Output() onfinalize: EventEmitter<any> = new EventEmitter();

  offers;
  urlProvider = environment.app_host + "/providers";
  user;
  setModalOpen;
  isLoadingGeneral;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private rfqService: RFQSService,
    private userService: UsersService
  ) {
    this.getCurrentUser();
  }

  ngOnInit() {
    this.getAcceptedOffers();
  }

  ngOnChanges(changes) {
    if (changes) {
      this.getAcceptedOffers();
    }
  }

  getCurrentUser() {
    this.user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
  }

  getAcceptedOffers() {
    if (!this.client_id || !this.rfq_id) {
      return;
    }
    this.rfqService
      .getAcceptedOffers({
        rfq_id: this.rfq_id,
        client_id: this.client_id,
      })
      .subscribe((res) => {
        this.offers = res.result;
      });
  }

  finalize() {
    this.isLoadingGeneral = true;
    this.rfqService
      .updateStatusBulk("closed", this.client_id, this.rfq_id)
      .subscribe(
        () => {
          this.isLoadingGeneral = false;
          this.onfinalize.emit();
          this.isOpen = false;
          this.ngxSmartModalService.closeLatestModal();
        },
        (error) => {
          this.isLoadingGeneral = false;
          this.alertService.errors(error.error.errors);
        }
      );
  }

  addToCart() {
    this.isLoadingGeneral = true;

    const params1 = {};
    params1["entity_id"] = this.client_id;

    this.isLoadingGeneral = true;
    this.userService.list(params1).subscribe(
      (res) => {
        const primaryAccount = res.result.items.filter(
          (f) => f.is_primary === true
        )[0];
        const params2 = {};
        params2["items"] = cloneDeep(this.offers);
        params2["items"].forEach((of) => {
          of.user_id = primaryAccount.id;
          of.unit_price = of.price;
          of.rfq_id = this.rfq_id;
        });
        this.rfqService.addRFQtoCart(params2).subscribe(
          () => {
            this.isLoadingGeneral = false;
            this.onfinalize.emit();
            this.isOpen = false;
            this.ngxSmartModalService.closeLatestModal();
          },
          (error) => {
            this.isLoadingGeneral = false;

            this.alertService.errors(error.error.errors);
          }
        );
      },
      (message) => {
        this.isLoadingGeneral = false;
        this.alertService.errors(message.error.errors);
      }
    );
  }
}

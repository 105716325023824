import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-client-plans",
  templateUrl: "./client-plans.component.html",
  styleUrls: ["./client-plans.component.css"],
})
export class ClientPlansComponent {
  goToStripeTable() {
    window.open(environment.stripe_client_table_url);
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewChecked,
  OnDestroy,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ServiceCategoriesService } from "../../../../_services/servicecategories.service";
import { ServiceCategory } from "../../../../_models/servicecategory";

import { Tree } from "primeng/tree";
import { TreeDragDropService, TreeNode } from "primeng/api";
import { AddCategoryComponent } from "../add-category/add-category.component";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";
import { Sort } from "@angular/material/sort";
import { CompareHelper } from "../../../../_helpers/compare.helper";
import { AddSubCategoryComponent } from "../add-subcategory/add-subcategory.component";

@Component({
  selector: "app-service-categories",
  templateUrl: "./service-categories.component.html",
  styleUrls: ["./service-categories.component.css"],
  providers: [TreeDragDropService],
})
export class ServiceCategoriesComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild("expandingTree", { static: true })
  expandingTree: Tree;

  isLoadingGeneral: boolean;
  serviceCategories: TreeNode[];
  serviceCategory: ServiceCategory;
  actionIsVisible: boolean;
  newServiceCategories: ServiceCategory;
  updateServiceCategory: ServiceCategory;
  parentId: number;
  my_sort: Sort;
  submenuOpen;

  @ViewChild("tableHead", { static: true }) tableHead: ElementRef;
  @ViewChild("draggableTable", { static: true }) draggableTable: ElementRef;
  @ViewChild(AddCategoryComponent, { static: true })
  private _initEmpty: AddCategoryComponent;
  @ViewChild(AddSubCategoryComponent, { static: true })
  private _initEmptySubCategory: AddSubCategoryComponent;

  @ViewChild(Tree, { static: true }) tree: Tree;
  @ViewChild("container", { static: true }) container;

  private _onDestroy = new Subject<void>();

  constructor(
    private serviceCategoriesService: ServiceCategoriesService,
    public renderer: Renderer2,
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private compareHelper: CompareHelper
  ) {
    this.actionIsVisible = false;
    document.addEventListener("click", this.outsideClick.bind(this));
  }

  outsideClick(event: any) {
    if (
      this.container &&
      !this.container.nativeElement.contains(event.target)
    ) {
      this.submenuOpen = null;
    }
  }

  ngOnInit() {
    this.getServiceCategories();
  }

  ngAfterViewChecked() {
    this.onResize();
  }

  onResize() {
    // set height for absolute table
    const getTableBodyHeight =
      this.draggableTable.nativeElement.querySelector(
        ".ui-widget-content"
      ).clientHeight;
    const getTableHeadHeight = this.tableHead.nativeElement.clientHeight;
    this.renderer.setStyle(
      this.draggableTable.nativeElement,
      "height",
      getTableBodyHeight + getTableHeadHeight + "px"
    );

    const setWidth = this.tableHead.nativeElement.offsetWidth;

    const tableToSetWidth =
      this.draggableTable.nativeElement.querySelector(".ui-widget-content");
    this.renderer.setStyle(tableToSetWidth, "width", setWidth + "px");

    const tableRowTableHead = this.tableHead.nativeElement.querySelector("tr");
    const tableHeadTableHead = [tableRowTableHead][0].children;
    const tableHeadWidth = [];

    for (let i = 0; i < tableHeadTableHead.length; i++) {
      tableHeadWidth.push(tableHeadTableHead[i].offsetWidth);
    }

    const elemTable = this.draggableTable.nativeElement;

    const draggableCheck = elemTable.getElementsByClassName("ui-chkbox");

    for (let i = 0; i < draggableCheck.length; i++) {
      this.renderer.setStyle(
        draggableCheck[i],
        "width",
        tableHeadWidth[0] + "px"
      );
    }

    const draggableOtherTr = elemTable.getElementsByClassName("draggable-tr");

    for (let i = 0; i < draggableOtherTr.length; i++) {
      this.renderer.setStyle(
        draggableOtherTr[i].children[0],
        "width",
        tableHeadWidth[1] + "px"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[0],
        "overflow",
        "hidden"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[0],
        "text-overflow",
        "ellipsis"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[0],
        "max-width",
        tableHeadWidth[1] + "px"
      );

      this.renderer.setStyle(
        draggableOtherTr[i].children[1],
        "width",
        tableHeadWidth[2] + "px"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[1],
        "overflow",
        "hidden"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[1],
        "text-align",
        "center"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[1],
        "text-overflow",
        "ellipsis"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[1],
        "max-width",
        tableHeadWidth[2] + "px"
      );

      this.renderer.setStyle(
        draggableOtherTr[i].children[2],
        "width",
        tableHeadWidth[3] + "px"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[2],
        "overflow",
        "hidden"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[2],
        "text-overflow",
        "ellipsis"
      );
      this.renderer.setStyle(
        draggableOtherTr[i].children[2],
        "max-width",
        tableHeadWidth[3] + "px"
      );
    }

    const treeNodeChildren = elemTable.getElementsByClassName(
      "ui-treenode-children"
    );

    // remove ui-treenode-children  if it's empty after drag and drop
    const countEmptyNodeChildren = [];
    for (let i = 0; i < treeNodeChildren.length; i++) {
      if (
        treeNodeChildren[i] !== undefined &&
        treeNodeChildren[i].childElementCount === 0
      ) {
        countEmptyNodeChildren.push(treeNodeChildren[i].childElementCount);
      }
    }
    const treeNodeChildrenLength =
      treeNodeChildren.length - countEmptyNodeChildren.length;

    // add class for each level

    const treeNodeContainer =
      elemTable.getElementsByClassName("ui-tree-container");

    if (treeNodeContainer[0]) {
      for (let i = 0; i < treeNodeContainer[0].childElementCount; i++) {
        treeNodeContainer[0].children[i].classList.add("lvl0");
      }
    }

    const getTreeNodeContainer = elemTable.getElementsByClassName("lvl0");

    const treeNodeChildrenLengthArr = [];
    const treeNodeChildrenArr = [];
    const treeNodeElemLength = [];
    const treeNodeElemArr = [];
    const getTreeNodeChildrenArr = [];

    for (let i = 0; i < getTreeNodeContainer.length; i++) {
      const getTreeNodeChildren = getTreeNodeContainer[
        i
      ].getElementsByClassName("ui-treenode-children");

      getTreeNodeChildrenArr.push(getTreeNodeChildren);

      treeNodeChildrenLengthArr.push(getTreeNodeChildren.length);
      treeNodeChildrenArr.push(getTreeNodeChildren);

      const sumTreeNodeChildrenLengthArr = treeNodeChildrenLengthArr.reduce(
        (a, b) => a + b,
        0
      );

      for (let q = 0; q < sumTreeNodeChildrenLengthArr; q++) {
        if (getTreeNodeChildren[q]) {
          treeNodeElemLength.push(getTreeNodeChildren[q].children.length);
          treeNodeElemArr.push(getTreeNodeChildren[q].children);

          const sumTreeNodeElem = treeNodeElemLength.reduce((a, b) => a + b, 0);

          for (let k = 0; k < sumTreeNodeElem; k++) {
            if (getTreeNodeChildren[q].children[k] !== undefined) {
              getTreeNodeChildren[q].children[k].classList.add("lvl" + (q + 1));
            }
          }
        }
      }
    }

    const filteredTreeNodeElemArr = [];

    for (let i = 0; i < treeNodeElemArr.length; i++) {
      if (treeNodeElemArr[i].length === 0) {
        treeNodeElemArr.splice(i, 1);
      }

      filteredTreeNodeElemArr.push(treeNodeElemArr);
    }

    const filteredTreeNodeElemLvlParentArr = [];

    if (filteredTreeNodeElemArr.length > 0) {
      for (let i = 0; i < filteredTreeNodeElemArr.length; i++) {
        const filteredTreeNodeElemLvlParent = filteredTreeNodeElemArr[i];

        if (filteredTreeNodeElemLvlParent[i] !== undefined) {
          for (let k = 0; k < filteredTreeNodeElemLvlParent[i].length; k++) {
            if (
              filteredTreeNodeElemLvlParent[i][k].classList.contains("lvl1")
            ) {
              const treeNodeChildrenFirst =
                filteredTreeNodeElemLvlParent[i][k].getElementsByClassName(
                  "ui-chkbox"
                );

              for (let z = 0; z < treeNodeChildrenFirst.length; z++) {
                this.renderer.setStyle(
                  treeNodeChildrenFirst[z],
                  "width",
                  tableHeadWidth[0] - 25 + "px"
                );
              }
            }

            if (
              filteredTreeNodeElemLvlParent[i][k].classList.contains("lvl2")
            ) {
              const treeNodeChildrenSecond =
                filteredTreeNodeElemLvlParent[i][k].getElementsByClassName(
                  "ui-chkbox"
                );

              for (let z = 0; z < treeNodeChildrenSecond.length; z++) {
                this.renderer.setStyle(
                  treeNodeChildrenSecond[z],
                  "width",
                  tableHeadWidth[0] - 50 + "px"
                );
              }
            }

            if (
              filteredTreeNodeElemLvlParent[i][k].classList.contains("lvl3")
            ) {
              const treeNodeChildrenThird =
                filteredTreeNodeElemLvlParent[i][k].getElementsByClassName(
                  "ui-chkbox"
                );

              for (let z = 0; z < treeNodeChildrenThird.length; z++) {
                this.renderer.setStyle(
                  treeNodeChildrenThird[z],
                  "width",
                  tableHeadWidth[0] - 75 + "px"
                );
              }
            }

            if (
              filteredTreeNodeElemLvlParent[i][k].classList.contains("lvl4")
            ) {
              const treeNodeChildrenFourth =
                filteredTreeNodeElemLvlParent[i][k].getElementsByClassName(
                  "ui-chkbox"
                );

              for (let z = 0; z < treeNodeChildrenFourth.length; z++) {
                this.renderer.setStyle(
                  treeNodeChildrenFourth[z],
                  "width",
                  tableHeadWidth[0] - 100 + "px"
                );
              }
            }
          }
        }
      }
    }
  }

  getServiceCategories() {
    const params = {};

    if (this.my_sort) {
      params["order"] = this.compareHelper.setOrder(this.my_sort);
      params["order_direction"] = this.compareHelper.setDirection(this.my_sort);
    }
    if (params["order"] === "" && params["order_direction"] === "") {
      delete params["order"];
      delete params["order_direction"];
    }

    this.isLoadingGeneral = true;
    this.serviceCategoriesService
      .getServiceCategories(params)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.serviceCategories = data.result.items;
        this.stopDroppable();

        this.isLoadingGeneral = false;
        setTimeout(() => {
          this.onResize();
        });
      });
  }

  stopDroppable() {
    Tree.prototype.allowDrop = (
      dragNode: any,
      dropNode: any,
      dragNodeScope: any
    ): boolean => {
      if (!dragNode || !dropNode) {
        return false;
      }

      if (dragNode.parent_id !== dropNode.parent_id) {
        return false;
      }

      if (dragNode.id === dropNode.id) {
        return false;
      }

      if (dragNode.level !== dropNode.level) {
        return false;
      }

      return true;
    };
  }

  drop(event) {
    this.serviceCategory = new ServiceCategory();
    this.serviceCategory.name = event.dragNode.label;
    this.serviceCategory.id = event.dragNode.id;

    if (event.dropIndex >= 0) {
      this.serviceCategory.parent_id = event.dropNode.parent_id;
      this.serviceCategory.priority = event.dropNode.priority;
    }

    if ((event.index === 0 || event.index === 1) && !event.dropIndex) {
      this.serviceCategory.parent_id = event.dropNode.id;
      this.serviceCategory.priority = 1;
    }

    this.serviceCategoriesService.update(this.serviceCategory).subscribe();
  }

  findIndex(element: TreeNode) {
    let index = -1;
    for (let i = 0; i < this.serviceCategories.length; i++) {
      if (element.label === this.serviceCategories[i].label) {
        index = i;
        break;
      }
    }
    return index;
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  openModal() {
    this._initEmpty.initEmpty();
    this.ngxSmartModalService.getModal("addCategory").open();
  }

  openModalSubCategory(id) {
    this.parentId = id;
    this._initEmptySubCategory.initEmpty();
    this.ngxSmartModalService.getModal("addSubCategory").open();
  }

  openModalUpdate($updateServiceCategory) {
    this.updateServiceCategory = new ServiceCategory();
    this.updateServiceCategory.name = $updateServiceCategory.label;
    this.updateServiceCategory.details = $updateServiceCategory.data;
    this.updateServiceCategory.id = $updateServiceCategory.id;
    if ($updateServiceCategory.parent) {
      this.updateServiceCategory.parent_id = $updateServiceCategory.parent.id;
    }

    this.ngxSmartModalService.getModal("addCategory").open();
  }

  toggleAction() {
    this.actionIsVisible = !this.actionIsVisible;
  }

  clickStopper(event) {
    event.stopPropagation();
  }

  deleteServiceCategories(serviceCategory) {
    this.isLoadingGeneral = true;
    this.serviceCategoriesService
      .deleteServiceCategories(serviceCategory)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.isLoadingGeneral = false;
        this.getServiceCategories();
      });
  }

  activateServiceCategories(serviceCategory) {
    this.isLoadingGeneral = true;
    this.serviceCategoriesService
      .activateServiceCategories(serviceCategory)
      .subscribe((data) => {
        this.isLoadingGeneral = false;
        this.getServiceCategories();
      });
  }

  documentationStandardRoute(node) {
    const service_category_id = node.id;
    this.router.navigate([
      "cms" + "/" + service_category_id + "/" + "documentation-standards",
    ]);
  }

  downloadTemplate(service_category) {
    this.serviceCategoriesService
      .downloadTemplate(service_category.id)
      .subscribe((data) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = service_category.label + ".xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      });
  }

  sortData(sort: Sort) {
    this.my_sort = sort;
    this.getServiceCategories();
  }

  ngOnDestroy() {
    document.removeEventListener("click", this.outsideClick.bind(this));
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggle(node) {
    console.log(node);

    if (
      !this.submenuOpen ||
      (!!this.submenuOpen && this.submenuOpen !== node.id)
    ) {
      this.submenuOpen = node.id;
    } else {
      this.submenuOpen = null;
    }
  }
}

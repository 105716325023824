import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class OrderCommissionsService {
  private serviceUrl = "billing/commissions";

  constructor(private http: HttpClient) {}

  getStatusTransitions(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceUrl + "/status-transitions"
    );
  }

  getCommissions(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.serviceUrl, {
      params: params,
    });
  }

  updateStatusBulk(status_id, commission_ids, reason = ""): Observable<any> {
    let body: any = {
      status_id: status_id,
      commission_ids: commission_ids,
    };
    if (reason) {
      body = {
        status_id: status_id,
        commission_ids: commission_ids,
        declined_reason: reason,
      };
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + this.serviceUrl,
      body,
      httpOptions
    );
  }
}

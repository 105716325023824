import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PageData } from "src/app/_models/page.model";
import { Plan } from "src/app/_models/plan";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { ReqPlan } from "../models/req-plan.model";
import { PlanResourceEnum } from "../enums/plan-resources.enum";
import { PlanStatusEnum } from "../enums/plan-status.enum";
import { HttpClient } from "@angular/common/http";
import { PlanMapperService } from "./plan-mapper.service";
import { ReqAddPlanToUser } from "../models/req-add-plan-user.model";
import { EntityTypeEnum } from "src/app/modules/shared/_enums/entity-type.enum";

@Injectable({
  providedIn: "root",
})
export class PlanService {
  readonly PLAN_RESOURCES: PlanResourceEnum[] = [
    PlanResourceEnum.CLIENT,
    PlanResourceEnum.PROVIDER,
  ];
  readonly STATUSES = [PlanStatusEnum.ACTIVE];
  readonly ROOT_ROUTE = "subscriptions";

  constructor(
    private http: HttpClient,
    private readonly planMapper: PlanMapperService
  ) {}

  getPlans(params: any = {}): Observable<PageData<Plan>> {
    return this.http
      .get<{ result: PageData<Plan> }>(
        environment.gateway_endpoint + this.ROOT_ROUTE + "/plans",
        {
          params: params,
        }
      )
      .pipe(
        map((data) => ({
          ...data.result,
          items: this.planMapper.mapMany(data.result.items),
        }))
      );
  }

  getPlan(planId: number): Observable<Plan> {
    return this.http
      .get<{ result: { plan: Plan } }>(
        environment.gateway_endpoint + this.ROOT_ROUTE + "/plans/" + planId
      )
      .pipe(map((data) => data.result.plan));
  }

  addOrUpdatePlan(plan: ReqPlan): Observable<{ plan: Plan }> {
    if (plan.id) {
      return this.http
        .put<{ result: { plan: Plan } }>(
          `${environment.gateway_endpoint}${this.ROOT_ROUTE}/plans/${plan.id}`,
          plan
        )
        .pipe(map((data) => data.result));
    }

    return this.http
      .post<{ result: { plan: Plan } }>(
        environment.gateway_endpoint + this.ROOT_ROUTE + "/plans",
        plan
      )
      .pipe(map((data) => data.result));
  }

  addPlanToUser(reqUserPlan: ReqAddPlanToUser): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + this.ROOT_ROUTE + "/purchase",
      reqUserPlan
    );
  }

  getUserPlan(entityId: string, entityType: EntityTypeEnum): Observable<Plan> {
    return this.http
      .get<{ result: { plan: Plan } }>(
        environment.gateway_endpoint + this.ROOT_ROUTE + "/plans/current",
        {
          params: {
            entity_type: entityType,
            entity_id: entityId,
          },
        }
      )
      .pipe(map((data) => this.planMapper.mapOne(data.result.plan)));
  }
}

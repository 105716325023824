import { Provider } from "./provider";
import { WorkflowStatus } from "./workflow-status";

export class ServiceOffer {
  provider: Provider;
  provider_id: number;
  category_id: number;
  id: number;
  title: string;
  country: string;
  city: string;
  default_price: number;
  sale_price: number;
  min_price: number;
  max_price: number;
  min_price_format: number;
  max_price_format: number;
  default_currency_id: number;
  currency_code: string;
  default_quantity: number;
  characteristics: object;
  prices: Array<Price>;
  duration: number;
  unit_id: number;
  description: string;
  category: any;
  is_draft: boolean;
  offers: any;
  status_id: number;
  for_business: boolean;
  for_individual: boolean;
  status_label: string;
  workflow_status?: WorkflowStatus;
  entity_type: string;
}

export class Price {
  price: number;
  currency_id: number;
  starts_on: string;
  ends_on: string;
  quantity: number;
}

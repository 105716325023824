<ng-container *ngIf="isLoadingGeneral">
  <div class="d-flex loading-wrapper">
    <div class="w-profile">
      <app-loader-spinner-general
        [loadingType]="LoadingType.PROFILE"
      ></app-loader-spinner-general>
    </div>
    <div class="w-info">
      <div class="w-first-row">
        <app-loader-spinner-general
          [repeat]="7"
          [loadingType]="LoadingType.INFO"
        ></app-loader-spinner-general>
      </div>
    </div>
  </div>
</ng-container>

<div class="details-body-wrapper" *ngIf="!isLoadingGeneral && RFQ">
  <!-- 2 col details -->
  <div class="details-2c d-flex">
    <!-- left -->
    <app-rfq-details-actions-panel
      [item]="RFQ"
      (oncreate)="getRFQDetails()"
    ></app-rfq-details-actions-panel>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right m-0">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <!-- location -->
          <div class="white-box-header">
            <div class="medium-title-rfq fl-l fx-space-center">
              <div>
                {{ "GENERALS.LOCATION" | translate }}
              </div>
              <div
                appHoverHelper
                class="input-helper fl-l"
                *ngIf="RFQ.status_name.toLowerCase() === 'draft'"
              >
                <i class="icon-help_comp"></i>
                <span class="helper-description helper-rfq">
                  {{ "RFQS.LOCATION-HELPER-TXT" | translate }}
                </span>
              </div>
            </div>
            <div
              class="white-box-header-action fl-r"
              *ngIf="
                RFQ.status_name.toLowerCase() === 'draft' &&
                !RFQ.providers.length
              "
            >
              <span *ngIf="edit !== 'location'" (click)="edit = 'location'">
                {{ "GENERALS.EDIT" | translate }}
                <i class="icon-cog-outline"></i>
              </span>
              <span class="rfq-edit-actions-btn" *ngIf="edit === 'location'">
                <span class="fl-r">
                  <span class="btn-small gray-bg" (click)="initEmpty()">
                    {{ "GENERALS.CANCEL" | translate }}
                  </span>
                  <span class="btn-small orange-bg" (click)="update()">
                    {{ "GENERALS.SAVE" | translate }}
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body" *ngIf="edit !== 'location'">
            <p *ngIf="itemChanges.country || itemChanges.city">
              {{ RFQ.city }}, {{ RFQ.country }}
            </p>
            <p *ngIf="!itemChanges.country && !itemChanges.city">
              {{ "RFQS.INCOMPLETE-INFORMATION" | translate }}
            </p>
          </div>
          <div class="white-box-row" *ngIf="edit === 'location'">
            <div class="white-box-body p-t-0">
              <br />

              <div class="row">
                <div class="w-50">
                  <div class="form-group">
                    <label>{{ "LABEL.COMPANY-COUNTRY" | translate }} </label>
                    <ng-select
                      class="customiz-select"
                      [items]="countries"
                      bindLabel="name"
                      bindValue="country_code"
                      placeholder="{{
                        'GENERALS.SELECT-COMPANYTY-COUNTRY' | translate
                      }}"
                      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                      [(ngModel)]="itemChanges.country"
                      (change)="countryChanged()"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="w-50">
                  <div class="form-group">
                    <label>{{ "LABEL.COMPANY-CITY" | translate }}</label>
                    <ng-select
                      class="customiz-select"
                      [items]="cities"
                      bindLabel="name"
                      bindValue="name"
                      placeholder="{{ 'GENERALS.SELECT-CITY' | translate }}"
                      [(ngModel)]="itemChanges.city"
                      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                      (search)="loadCities($event)"
                      (focus)="loadCities()"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end location -->

        <!-- invite providers -->
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title-rfq fl-l fx-space-center">
              <div>
                {{ "RFQS.PROVIDERS-SENT-TO" | translate }}
              </div>
              <div
                appHoverHelper
                class="input-helper fl-l"
                *ngIf="RFQ.status_name.toLowerCase() === 'draft'"
              >
                <i class="icon-help_comp"></i>
                <span class="helper-description helper-rfq">
                  {{ "RFQS.INVITE-HELPER-TXT" | translate }}
                </span>
              </div>
            </div>
            <div
              class="white-box-header-action fl-r"
              *ngIf="
                RFQ.categories.length &&
                RFQ.country.length &&
                (RFQ.status_name.toLowerCase() === 'draft' ||
                  RFQ.status_name.toLowerCase() === 'open' ||
                  RFQ.status_name.toLowerCase() === 'published' ||
                  RFQ.status_name.toLowerCase() === 'extended')
              "
              (click)="edit = 'providers'; openEditModal()"
            >
              <i class="icon-plus-1"></i>
              {{ "GENERALS.INVITE" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <div
              *ngFor="let provider of RFQ.providers; let i = index"
              class="category-tree-row-periods"
            >
              <span class="category-title">
                <span>
                  {{ provider.company_name }}
                </span>
                <span
                  class="dark-green fw-500"
                  *ngIf="provider.status === 'offers_placed'"
                >
                  -
                  {{ "GENERALS.PROVIDER-SUBMITTED-OFFER" | translate }}</span
                >
                <span
                  class="orange fw-500"
                  *ngIf="provider.status === 'decline'"
                >
                  -
                  {{
                    "GENERALS.PROVIDER-DECLINED-INVITATION" | translate
                  }}</span
                >
                <span
                  class="set-commission-btn fl-r"
                  *ngIf="RFQ.status_name.toLowerCase() === 'draft'"
                >
                  <button
                    type="button"
                    class="btn transparent-bg"
                    (click)="removeProvider(i)"
                  >
                    <span>
                      {{ "GENERALS.DELETE" | translate }}
                    </span>
                  </button>
                </span>
              </span>
            </div>
          </div>
        </div>
        <!-- end invite providers -->

        <!-- service offers-->

        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title-rfq fl-l fx-space-center">
              <div>
                {{ "RFQS.SERVICE-CATEGORIES-OFFERS" | translate }}
              </div>
              <div
                appHoverHelper
                class="input-helper fl-l"
                *ngIf="
                  RFQ.status_name.toLowerCase() === 'draft' ||
                  RFQ.status_name.toLowerCase() === 'published'
                "
              >
                <i class="icon-help_comp"></i>
                <span class="helper-description helper-rfq">
                  {{ "RFQS.CATEGORIES-HELPER-TXT" | translate }}
                </span>
              </div>
            </div>
            <div
              *ngIf="
                (RFQ.status_name.toLowerCase() === 'draft' ||
                  RFQ.status_name.toLowerCase() === 'published') &&
                RFQ.country.length
              "
              class="white-box-header-action fl-r"
              (click)="edit = 'offers'; openEditModal()"
            >
              {{ "GENERALS.EDIT" | translate }}
              <i class="icon-cog-outline"></i>
            </div>
          </div>
          <div class="clear"></div>

          <div class="white-box-body">
            <div
              *ngFor="let category of RFQ.categories; let i = index"
              class="rfq-details-row"
            >
              <div class="rfq-service-header">
                <div class="small-title">
                  {{ category.service_category_name }}
                </div>
                <div class="rfq-expats-qty">
                  <span>
                    {{ "RFQS.EXPATS-NUM" | translate }}:
                    <span class="orange">
                      {{ RFQ.offers_request[i]?.quantity }}
                    </span>
                  </span>
                </div>
                <div class="rfq-service-desc-readonly">
                  {{ RFQ.offers_request[i]?.service_description }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="white-box-footer">
          <div class="fl-l medium-title">
            {{ "RFQS.OFFERS-TOTAL" | translate }}:

            <ng-container *ngIf="RFQ.summary.length > 0; else nothingAccepted">
              <span *ngFor="let total of RFQ.summary; let last = last">
                {{ total.total_amount | currency : total.currency_code
                }}<span *ngIf="!last">,</span>
              </span>
            </ng-container>

            <ng-template #nothingAccepted>
              <span> {{ "GENERALS.NO-OFFER-ACCEPTED-YET" | translate }}</span>
            </ng-template>
          </div>
        </div>

        <!-- end service offers-->
      </div>
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>

<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="editRFQAndBid"
    #editRFQAndBid
    identifier="editRFQAndBid"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title" *ngIf="edit === 'providers'">
        {{ "RFQS.INVITE-PROVIDERS" | translate }}
      </div>
      <div class="modal-title" *ngIf="edit === 'offers'">
        {{ "RFQS.SERVICE-CATEGORIES-OFFERS" | translate }}
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <!-- add offers -->
      <ng-container *ngIf="edit === 'offers'">
        <app-rfq-service-offers
          [item]="itemChanges"
          [edit]="true"
        ></app-rfq-service-offers>
      </ng-container>
      <!-- end add rfq step 3 -->

      <!-- invite -->
      <ng-container *ngIf="edit === 'providers'">
        <app-invite-providers
          [country]="RFQ.country"
          [categoriesFilters]="RFQ.categories"
          [perPage]="5"
          [item]="itemChanges"
          [edit]="true"
          [preinvitedProviders]="RFQ.providers"
        ></app-invite-providers>
      </ng-container>
      <!-- end invite -->
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button type="button" class="btn gray-bg" (click)="cancelModal()">
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button
        type="button"
        class="btn orange-bg"
        [disabled]="!checkIfValid()"
        (click)="update()"
      >
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>

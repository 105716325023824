export class UserDetails {
  id: any;
  statuses: Array<any>;
  name: string;
  email: string;
  created_at: string;
  status_id: number;
  status_name: string;
  details: Array<any>;
  primary_image_path: string;
  phone: string;
  phone_prefix: string;
  user_id: number;
  confirmed_account: boolean;
}

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Filter } from "../../../../_models/filter";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ItemsListComponent } from "../items-list.component";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.css"],
})
export class FiltersComponent extends ItemsListComponent implements OnChanges {
  public keyword;
  public filterOptions = {};
  dateRangeMultipleMonths;
  issueDateRangeMultipleMonths;
  dueDateRangeMultipleMonths;
  paymentDateRangeMultipleMonths;
  public filters = {
    search_fields: null,
    page: 1,
  };
  showClearBtn = false;
  showClearIssueDateBtn = false;
  showClearDueDateBtn = false;
  showClearPaymentDateBtn = false;

  @Input() showTextField = true;
  @Input() showAdvanced = false;
  @Input() showCalendar = false;
  @Input() searchPlaceholder = "GENERALS.SEARCH";
  @Input() availableFilters: Filter[];
  @Input() activeFilters;
  @Input() total;
  @Input() issueDate;
  @Input() dueDate;
  @Input() paymentDate;

  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() date: EventEmitter<any> = new EventEmitter();
  @Output() invoiceIssueDate: EventEmitter<any> = new EventEmitter();
  @Output() invoiceDueDate: EventEmitter<any> = new EventEmitter();
  @Output() invoicePaymentDate: EventEmitter<any> = new EventEmitter();

  @Input() providerId: number;
  @Output() selectedProvider: EventEmitter<any> = new EventEmitter();

  @ViewChild("showAdvancedSearch")
  showAdvancedSearch: ElementRef;
  @ViewChild("toggleAdvancedSearch")
  toggleAdvancedSearch: ElementRef;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler() {
    this.showAdvanced = false;
  }

  constructor(
    private router: Router,
    public renderer: Renderer2,
    private service: DictionariesService,
    private translateService: TranslateService
  ) {
    super();

    this.renderer.listen("window", "click", (e: Event) => {
      if (this.showAdvancedSearch) {
        if (
          !this.toggleAdvancedSearch.nativeElement.contains(e.target) &&
          !this.showAdvancedSearch.nativeElement.contains(e.target)
        ) {
          this.showAdvanced = false;
        }
      }
    });
  }

  init() {
    this.filters = {
      search_fields: null,
      page: 1,
    };
    this.keyword = null;
  }

  /**
   * Populate the filters with the initial options
   */
  private setInitialFilterOptions() {
    if (this.availableFilters) {
      this.availableFilters.forEach((filter) => {
        this.setFilterOptions(filter);

        if (this.activeFilters) {
          this.setActiveFilterOptions(filter);
        }
      });
    }
  }

  /**
   * Populate the filters with options
   */
  private setFilterOptions(filter: Filter) {
    if (filter.data) {
      this.filterOptions[filter.id] = filter.data;
    } else if (filter.service) {
      filter.service[filter.method]().subscribe((data) => {
        if (data.success) {
          this.filterOptions[filter.id] = data.result;
        }
      });
    }
  }

  /**
   * Populate the active filters
   */
  private setActiveFilterOptions(f: Filter) {
    if (this.activeFilters[f.id]) {
      this.filters[f.id] = !isNaN(this.activeFilters[f.id])
        ? +this.activeFilters[f.id]
        : this.activeFilters[f.id];
    } else {
      delete this.activeFilters[f.id];
      delete this.filters[f.id];
    }
  }

  /**
   * Set the initial values for the date filter
   */
  private setActiveDateFilters(startDateKey: string, endDateKey: string) {
    if (this.activeFilters[startDateKey] && this.activeFilters[endDateKey]) {
      this.dateRangeMultipleMonths = {
        start_date: this.formatDataParam(this.activeFilters[startDateKey]),
        end_date: this.formatDataParam(this.activeFilters[endDateKey]),
      };
      this.showClearBtn = true;
    } else {
      this.dateRangeMultipleMonths = null;
      this.showClearBtn = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
    this.setInitialFilterOptions();

    if (this.availableFilters) {
      this.availableFilters = this.availableFilters.map((filter) => {
        if (filter.data) {
          const data = filter.data.map((data) => {
            return {
              ...data,
              text: this.translateService.instant(data.text),
            };
          });
          return { ...filter, data };
        }
        return filter;
      });
    }
    if (this.activeFilters) {
      if (this.activeFilters["search_text"]) {
        this.keyword = this.activeFilters["search_text"];
      }

      this.setActiveDateFilters("start_date", "end_date");
      this.setActiveDateFilters(
        "invoice_issue_start_date",
        "invoice_issue_end_date"
      ); // invoice issue date
      this.setActiveDateFilters(
        "invoice_due_start_date",
        "invoice_due_end_date"
      ); // invoice due date
      this.setActiveDateFilters(
        "invoice_payment_start_date",
        "invoice_payment_end_date"
      ); // invoice payment date
    }
  }

  setCalendarFilter(e) {
    if (e.start_date || e.end_date) {
      if (e.start_date !== null || e.end_date !== null) {
        this.date.emit(this.setDate(e, true));
        this.toggleAdvanceSearch();
      }
    }
  }

  clearFilterData() {
    const ev = {
      start_date: "null",
      end_date: "null",
    };
    this.dateRangeMultipleMonths = null;
    this.date.emit(this.setDate(ev, "clearFilterDate"));
    this.toggleAdvanceSearch();
  }

  toggleAdvanceSearch() {
    this.showAdvanced = !this.showAdvanced;
  }

  loadMoreData(event, filter) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.request_call = this.service
      .filter(
        filter.dataEndpoint,
        Object.assign(this.activeFilters, { q: event })
      )
      .subscribe((response) => {
        if (response.success) {
          this.filterOptions[filter.id] = response.result;
        }
      });
  }

  emitKeyword() {
    if (this.keyword.length === 0) {
      this.search.emit(this.keyword);
      return;
    }

    const wordSearch = this.keyword;
    setTimeout(() => {
      if (wordSearch === this.keyword) {
        if (this.keyword) {
          this.search.emit(this.keyword);
        }
      }
    }, 700);
  }

  formatDataParam(data) {
    const formatedData = moment(new Date(data)).format("YYYY/MM/DD");
    return formatedData.split("/").join("-");
  }

  setInvoiceIssueDateFilter(e) {
    if (e.start_date || e.end_date) {
      if (e.start_date !== null || e.end_date !== null) {
        this.invoiceIssueDate.emit(this.setInvoiceIssueDate(e, true));
        this.toggleAdvanceSearch();
      }
    }
  }

  setInvoiceDueDateFilter(e) {
    if (e.start_date || e.end_date) {
      if (e.start_date !== null || e.end_date !== null) {
        this.invoiceDueDate.emit(this.setInvoiceDueDate(e, true));
        this.toggleAdvanceSearch();
      }
    }
  }

  setInvoicePaymentDateFilter(e) {
    if (e.start_date || e.end_date) {
      if (e.start_date !== null || e.end_date !== null) {
        this.invoicePaymentDate.emit(this.setInvoicePaymentDate(e, true));
        this.toggleAdvanceSearch();
      }
    }
  }

  clearInvoiceIssueDateFilter() {
    const ev = {
      invoice_issue_start_date: "null",
      invoice_issue_end_date: "null",
    };
    this.invoiceIssueDate.emit(this.setInvoiceIssueDate(ev, "clearFilterDate"));
    this.toggleAdvanceSearch();
  }

  clearInvoiceDueDateFilter() {
    const ev = {
      invoice_due_start_date: "null",
      invoice_due_end_date: "null",
    };
    this.invoiceDueDate.emit(this.setInvoiceDueDate(ev, "clearFilterDate"));
    this.toggleAdvanceSearch();
  }

  clearInvoicePaymentDateFilter() {
    const ev = {
      invoice_payment_start_date: "null",
      invoice_payment_end_date: "null",
    };
    this.invoicePaymentDate.emit(
      this.setInvoicePaymentDate(ev, "clearFilterDate")
    );
    this.toggleAdvanceSearch();
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Client } from "../_models/client";
import { map } from "rxjs/operators";
import { User } from "../_models/user";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class UsersService {
  private url = "users";

  constructor(private http: HttpClient) {}

  /**
   * List users
   * with search, filters and pagination
   */
  list(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.url, {
      params: params,
    });
  }

  /**
   * Get user details
   * with search, filters and pagination
   */
  getUserDetails(user_id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.url + "/" + user_id
    );
  }

  /**
   * Get user search fields
   */
  getSearchFields(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/expats/search-fields"
    );
  }

  // Update user details
  updateUserDetails(user: User): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + "users/" + +user.id,
        user,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * PUT Update User for client
   */
  updateUserAccount(user: User): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + "users/" + user.id,
        user,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update user Status
  updateStatusBulk(status_id, user_id, reason = null): Observable<any> {
    const body = { status_id: status_id, user_id: user_id };
    if (reason) {
      body["reason"] = reason;
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + "users/status-update",
      body,
      httpOptions
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "users/statuses-transition"
    );
  }

  getUserRoles(type): Observable<any> {
    return this.http.get(environment.gateway_endpoint + type + "/roles");
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class WindowResizeHelperService {
  private subject = new BehaviorSubject({});

  sendData(data: string) {
    this.subject.next({ responsiveStatus: data });
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }
}

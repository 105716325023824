<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="inviteClient"
    #inviteClient
    identifier="inviteClient"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">{{ "GENERALS.INVITE" | translate }}</div>
    </div>

    <div class="clear"></div>
    <div class="custom-modal-body">
      <ng-container *ngIf="!isLoadingGeneral">
        <!-- <div class="modal-body-desc">{{ 'GENERALS.IPSUM' | translate }}</div> -->
        <div class="form-group w-50">
          <label>{{ "LABEL.COMPANY-NAME" | translate }} *</label>
          <input
            type="text"
            name="company_name"
            class="form-control"
            required
            [(ngModel)]="clientInvite.company_name"
          />
        </div>
        <div class="form-group w-50">
          <label>{{ "LABEL.EMAIL" | translate }} *</label>
          <input
            type="text"
            name="invite.email"
            class="form-control"
            required
            [(ngModel)]="clientInvite.email"
          />
        </div>
        <div class="form-group w-100">
          <label>{{ "LABEL.MESSAGE" | translate }} *</label>
          <textarea
            type="text"
            name="invite.email"
            class="form-control"
            rows="8"
            required
            [ngStyle]="{ height: '160px' }"
            [(ngModel)]="clientInvite.email_message"
          ></textarea>
        </div>
      </ng-container>
      <div class="modal-form" *ngIf="isLoadingGeneral">
        <div class="d-flex w-100 p-0">
          <app-loader-spinner-general
            class="w-50"
            [loadingType]="LoadingType.INPUT"
          ></app-loader-spinner-general>
          <app-loader-spinner-general
            class="w-50"
            [loadingType]="LoadingType.INPUT"
          ></app-loader-spinner-general>
        </div>
        <div class="w-100">
          <app-loader-spinner-general
            [repeat]="3"
            [loadingType]="LoadingType.INPUT"
          ></app-loader-spinner-general>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button type="button" class="btn gray-bg" (click)="cancelInviteModal()">
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button type="button" class="btn orange-bg" (click)="sendClientInvite()">
        {{ "FORMS.SEND" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->

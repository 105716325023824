<div class="custom-modal-wrapper">
  <div class="modal-title-custom">
    {{ "GENERALS.ADD-DOC" | translate }}
  </div>

  <div class="modal-body">
    <div class="form-group" [ngClass]="'w-100'">
      <div class="custom-upload-box">
        <file-upload
          [(ngModel)]="uploadedFiles.file"
          [control]="fileUploadControl"
        >
          <ng-template #placeholder>
            <span><i class="icon-upload-cloud"></i></span>
            <span>{{ "GENERALS.DROP-FILE-TO-UPLOAD" | translate }}</span>
            <span class="orange"> {{ "GENERALS.BROWSE" | translate }}</span>
          </ng-template>

          <ng-template
            let-i="index"
            let-file="file"
            let-control="control"
            #item
          >
            <div class="file-info">
              <span class="file-name">{{ file.name }}</span>
              <span class="remove-file" (click)="control.removeFile(file)">
                <i class="icon-trash"></i>
                {{ "GENERALS.REMOVE-FILE" | translate }}
              </span>
            </div>
          </ng-template>
        </file-upload>
      </div>
    </div>
    <div class="clear"></div>
  </div>

  <div class="modal-footer-custom">
    <button (click)="cancel()" class="btn gray-bg fl-l">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button (click)="confirm()" class="btn orange-bg fl-r">
      {{ "GENERALS.CONFIRM" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>

import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ActionEnum } from "src/app/_enums/action-enum";
import { AlertService } from "src/app/_services/alert.service";
import { ClientsService } from "src/app/_services/clients.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { environment } from "src/environments/environment";
import { PermissionResourceEnum } from "../../../../modules/shared/_enums/permission-resource.enum";
import { User } from "../../../../_models/user";
import { AuthenticationService } from "../../../../_services/authentication.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { UsersService } from "../../../../_services/users.service";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { Subject } from "rxjs";
import { takeUntil, finalize } from "rxjs/operators";
import { AddUserFormComponent } from "../add-user-form/add-user-form.component";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"],
})
export class UsersListComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy
{
  public resource = PermissionResourceEnum;
  user: User;
  items: User[];
  isCanceled;
  entity_id;
  private entityService;
  isXs: boolean;
  roles;
  isFiltersLoaded: boolean = false;
  private destroyed$: Subject<void> = new Subject();

  @Input() filters = { page: 1 };
  @Input() entityType;
  @Input() addPermission = null;

  constructor(
    private service: UsersService,
    private providerService: ProvidersService,
    private clientService: ClientsService,
    public ngxSmartModalService: NgxSmartModalService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    public authService: AuthenticationService,
    private translate: TranslateService,
    public dictionariesService: DictionariesService
  ) {
    super();

    this.user = new User();
    this.showBulkActions = false;
    this.getStatusTransitions();
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.entity_id = parseInt(this.filters["entity_id"], 10);

    this.getItems(1);
    this.setEntityService();

    this.windowResizeHelper
      .getData()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.isXs = data.responsiveStatus;
      });
  }

  navigateTo(id, entity_id) {
    const host = environment.app_host;
    const expatsUrl = "/users/" + id;
    const query = "?entity_id=" + entity_id;
    const url = host + expatsUrl + query;
    window.open(url, "_blank");
  }

  public getItems(page: number = 1) {
    const params = this.getParams(page);
    params["entity_id"] = this.entity_id;

    this.isLoadingGeneral = true;
    this.service
      .list(params)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.isLoadingGeneral = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe({
        next: (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        error: (message) => {
          if (message.error && message.error.errors) {
            this.alertService.errors(message.error.errors);
          }
        },
      });
  }

  public getStatusTransitions() {
    this.service
      .getStatusTransitions()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          if (data.success) {
            this.actions = data.result;
          }
        },
      });
  }

  protected getService() {
    return this.service;
  }
  public setEntityService() {
    switch (this.entityType) {
      case "provider":
        this.entityService = this.providerService;
        break;
      case "client":
        this.entityService = this.clientService;
        break;
    }

    this.getUserRoles();
  }

  getUserRoles() {
    this.service
      .getUserRoles(this.entityType)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.roles = res.result;
      });
  }

  openModal() {
    let dialogRef: MatDialogRef<AddUserFormComponent>;

    if (this.entityType === "provider") {
      this.entityService
        .getProvider(this.filters["entity_id"])
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (res) => {
            this.user.company_name = res.result.company_name;
            dialogRef = this.dialog.open(AddUserFormComponent, {
              data: {
                user: this.user,
                isCanceled: this.isCanceled,
                about: "user",
                userType: this.entityType,
              },
            });

            dialogRef
              .afterClosed()
              .pipe(takeUntil(this.destroyed$))
              .subscribe((user: User) => {
                if (user) {
                  this.user = user;
                  this.addEntityUser();
                } else {
                  this.isCanceled = true;
                }
              });
          },
        });
    } else if (this.entityType === "client") {
      this.entityService
        .getClient(this.filters["entity_id"])
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (res) => {
            this.user.company_name = res.result.company_name;
            dialogRef = this.dialog.open(AddUserFormComponent, {
              data: {
                user: this.user,
                isCanceled: this.isCanceled,
                about: "user",
                userType: this.entityType,
              },
            });

            dialogRef
              .afterClosed()
              .pipe(takeUntil(this.destroyed$))
              .subscribe((user: User) => {
                if (user) {
                  this.user = user;
                  this.addEntityUser();
                } else {
                  this.isCanceled = true;
                }
              });
          },
        });
    }

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
          this.addEntityUser();
        } else {
          this.isCanceled = true;
        }
      });
  }

  cancelModal() {
    this.ngxSmartModalService.getModal("addUser").close();
  }

  addEntityUser() {
    this.user.name = this.user.first_name + " " + this.user.last_name;

    this.entityService
      .createUser(this.entity_id, this.user)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.getItems(1);
          this.cancelModal();
          this.user = new User();
        },
        error: (message) => {
          this.alertService.errors(message.error.errors);
          this.user = new User();
        },
      });
  }

  resetPassword(_email) {
    this.authService
      .sendResetPasswordEmail(_email)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
        },
        error: (error) => {
          if (error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  resendConfirmationMail(id) {
    this.authService
      .resendConfirmationMail(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.translate
            .get("GENERALS.MAIL-WAS-SENT-SUCCESS")
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data) => {
              this.alertService.success(data);
            });
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  confirmEmail(id) {
    this.authService
      .confirmEmail(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.translate
            .get("GENERALS.EMAIL_CONFIRMED_MESSAGE")
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data) => {
              this.alertService.success(data);
              this.getItems();
            });
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  makePrimry(id) {
    this.authService
      .makePrimry(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.getItems(1);
        },
        error: (error) => {
          if (error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  showAction(actionId: number, user: User): boolean {
    if (
      !(actionId === ActionEnum.SUSPENDED || actionId === ActionEnum.DEACTIVATE)
    ) {
      return true;
    }

    if (!user.is_primary) {
      return true;
    }

    return false;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

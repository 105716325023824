<app-loader-spinner-general
  [isLoadingGeneral]="isLoadingGeneral"
></app-loader-spinner-general>

<mat-card class="w100" *ngIf="!edit && categories && !isLoadingGeneral">
  <mat-card-title class="delivery"
    >{{ "COMMISSION.SERVICE-CATEGORIES-COMMISSIONS" | translate }}
    <span class="edit" (click)="edit = true">
      <mat-icon>edit</mat-icon>
      <span>EDIT</span>
    </span></mat-card-title
  >
  <hr />
  <mat-card-subtitle class="commission-subheader">
    <span>{{ "COMMISSION.CATEGORY-TREE" | translate }}</span>
    <span>{{ "COMMISSION.CURRENT-COMMISSION" | translate }}</span>
  </mat-card-subtitle>

  <mat-card-content>
    <div class="w100" *ngFor="let name of categoryLabels">
      <h4>{{ name }}</h4>
      <p-tree
        selectionMode="single"
        #expandingTree
        [value]="categories[name]"
        [(selection)]="selectedItems"
      >
        <ng-template let-node pTemplate="default">
          <div class="draggable-table row-style w100">
            <div>{{ node.label }}</div>
            <div>
              <span>
                {{ node.commission }}
              </span>
              <span> +{{ node.children_count }} more </span>
            </div>
          </div>
        </ng-template>
      </p-tree>
    </div>
    <div>
      <mat-card-subtitle class="delivery"
        >{{ "COMMISSION.DELIVERY-COUNTRIES" | translate }}
        <span class="edit" (click)="openModal()">
          <mat-icon>edit</mat-icon>
          <span>EDIT</span>
        </span></mat-card-subtitle
      >
      <span *ngIf="!provider.countries.length">
        {{ "GENERALS.NO-ITEMS-FOUND" | translate }}
      </span>
      <span *ngIf="provider.countries.length">
        <span *ngFor="let country of provider.countries; let last = last">
          {{ !last ? country.name + "," : country.name }}
        </span>
      </span>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="edit && !isLoadingGeneral">
  <div (click)="edit = false">{{ "GENERALS.CANCEL" | translate }}</div>
  <hr />
  <mat-card-title>
    {{ "COMMISSION.EDIT-CATEGORIES-COMMISSIONS" | translate }}
  </mat-card-title>
  <mat-card-content>
    <div class="row">
      <div class="form-group w-100" *ngFor="let name of categoryLabels">
        <h4>{{ name }}</h4>
        <app-add-commissions
          [serviceCategories]="categories[name]"
          [level]="0"
          (onselect)="setCategories($event, name)"
        ></app-add-commissions>
      </div>
    </div>

    <button type="button" class="btn orange-bg" (click)="updateCommission()">
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </mat-card-content>
</mat-card>

<ngx-smart-modal
  *ngIf="!isLoadingGeneral"
  id="editCountries"
  #editCountries
  identifier="editCountries"
  [dismissable]="false"
>
  <app-chips-select
    [items]="countries"
    [placeholder]="'GENERALS.COUNTRIES-CHIPS-PLACEHOLDER'"
    [bindValue]="'name'"
    [bindLabel]="'name'"
    [preselectedItems]="countryCodes"
    (onselect)="countryCodes = $event"
  ></app-chips-select>
  <div class="modal-footer">
    <button type="button" class="btn gray-bg" (click)="closeModal()">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button
      type="button"
      class="btn orange-bg"
      (click)="updateDeliveryCountries()"
    >
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</ngx-smart-modal>

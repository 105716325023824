<div class="tax-wrapper">
  <div
    mat-dialog-title
    class="modal-title-wrapper d-flex justify-content-start p-"
  >
    <h5 class="modal-title">
      {{ "TAX.EDITING_TAXES_FOR" | translate }} {{ data.name }}
    </h5>
  </div>
  <app-loader-spinner-general
    [isLoadingGeneral]="isLoadingGeneral"
  ></app-loader-spinner-general>
  <div mat-dialog-content [formGroup]="form" class="col-lg-12">
    <div class="row field">
      <div class="col-lg-6">
        <div class="w-100 mb-3 d-flex">
          <mat-form-field
            appearance="outline"
            class="w-100 p-0 mr-10"
            *ngFor="let tax of data.taxes"
          >
            <mat-label>{{ "TAX." + tax.tax_type | translate }} </mat-label>
            <input
              matInput
              type="number"
              min="0"
              [formControlName]="tax.tax_type"
              oninput="this.value = Math.abs(this.value)"
            />
            <mat-error [formControlError]="tax.tax_type"> </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="modal-footer mt-3 mr-4">
    <button
      type="button"
      class="btn gray-bg"
      role="button"
      (click)="dialogRef.close()"
    >
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button
      type="button"
      class="btn orange-bg"
      role="button"
      (click)="submit()"
    >
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>

export enum PermissionResourceEnum {
  PROVIDER = "provider",
  SERVICE = "service",
  CLIENT = "client",
  ASSIGNMENT = "assignment",
  ORDER = "order",
  CASE = "case",
  RFQ = "rfq",
  REPORTS = "reports",
  CATEGORY = "category",
  AD = "ad",
  MESSAGE = "message",
  USER = "user",
  ADMIN = "admin",
  EXPAT = "expat",
}

export enum PermissionSecondaryResourceEnum {
  DETAILS = "details",
  INVITES = "invites",
  FINANCIAL = "financial",
}

export enum PermissionActionEnum {
  VIEW = "view",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  MANAGE = "manage",
  DOWNLOAD = "download",
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class InvoicesService {
  private serviceUrl = "billing/invoices";

  constructor(private http: HttpClient) {}

  getInvoicesList(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.serviceUrl, {
      params: params,
    });
  }

  getInvoicesLog(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceUrl + "/log",
      {
        params: params,
      }
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceUrl + "/status-transitions"
    );
  }

  updateStatusBulk(status_id, invoice_ids, reason = ""): Observable<any> {
    const body: any = { status_id: status_id, invoice_ids: invoice_ids };
    if (reason) {
      body["reason"] = reason;
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + this.serviceUrl,
      body,
      httpOptions
    );
  }

  updateInvoiceStatus(invoiceId, statusId): Observable<any> {
    const params = {
      invoice_id: invoiceId,
      status_id: statusId,
    };
    return this.http.post<any>(
      environment.gateway_endpoint + this.serviceUrl + "/update-status",
      params
    );
  }

  getInvoiceDetails(invoiceId): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceUrl + "/" + invoiceId
    );
  }

  exportCsv(invoiceId) {
    return this.http.get(
      environment.gateway_endpoint +
        this.serviceUrl +
        "/" +
        invoiceId +
        "/export/csv/",
      { responseType: "blob" }
    );
  }

  exportPdf(invoiceId) {
    const headers = new HttpHeaders({
      Accept: "application/pdf",
    });
    return this.http.get(
      environment.gateway_endpoint +
        this.serviceUrl +
        "/" +
        invoiceId +
        "/export/pdf/",
      {
        headers: headers,
        responseType: "blob",
      }
    );
  }
}

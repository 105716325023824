import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "../../../../modules/shared/_enums/permission-resource.enum";
import { ProviderCommissionEnum } from "../../../../_enums/provider-commission-enum";
import { AuthenticationService } from "../../../../_services/authentication.service";
import { ItemsListComponent } from "../../items-list/items-list.component";

@Component({
  selector: "app-provider-categories-tree",
  templateUrl: "./provider-categories-tree.component.html",
})
export class ProviderCategoriesTreeComponent extends ItemsListComponent {
  @Input() categories;
  @Output() onchange: EventEmitter<any> = new EventEmitter();

  public resource = PermissionResourceEnum;
  public subResource = PermissionSecondaryResourceEnum;
  public action = PermissionActionEnum;

  public providerCommissionEnum = ProviderCommissionEnum;

  constructor(public authService: AuthenticationService) {
    super();
  }
}

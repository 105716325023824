<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModal }">
  <ngx-smart-modal
    id="addSubCategory"
    #addSubCategory
    identifier="addSubCategory"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">
        {{ "GENERALS.ADD-SUBCATEGORY" | translate }}
      </div>
    </div>

    <div class="clear"></div>
    <div class="custom-modal-body">
      <!-- <div class="modal-body-desc">
          {{ 'GENERALS.IPSUM' | translate }}
        </div> -->

      <div class="modal-form">
        <div class="row">
          <div class="form-group w-40">
            <label>{{ "LABEL.CATEGORY-NAME" | translate }}</label>
            <input
              type="text"
              name="category_name"
              class="form-control"
              [(ngModel)]="newServiceSubCategories.name"
            />
          </div>
          <div class="form-group w-60">
            <label>{{ "LABEL.DETAILS" | translate }}</label>
            <input
              type="text"
              name="details"
              class="form-control"
              [(ngModel)]="newServiceSubCategories.details"
            />
          </div>
          <div class="form-group w-60">
            <label>Drupal URL</label>
            <input
              type="text"
              name="drupal_url"
              class="form-control"
              [(ngModel)]="newServiceSubCategories.drupal_url"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clear"></div>

    <div class="modal-footer-l-r">
      <div class="modal-footer-l">
        <button
          type="button"
          class="btn orange-bg"
          (click)="createOtherServiceSubCategories()"
        >
          {{ "GENERALS.ADD-ANOTHER" | translate }}
        </button>
      </div>

      <div class="modal-footer-r">
        <button type="button" class="btn gray-bg" (click)="cancelModal()">
          {{ "GENERALS.CANCEL" | translate }}
        </button>

        <button
          type="button"
          class="btn orange-bg"
          (click)="createServiceSubCategories()"
        >
          {{ "GENERALS.DONE" | translate }}
        </button>
      </div>
    </div>
  </ngx-smart-modal>
</div>

<!-- end modal -->

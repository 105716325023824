<ng-container *ngIf="!offer">
  <div class="wrapper-profile m-t-20">
    <div class="w-profile">
      <app-loader-spinner-general
        [loadingType]="LoadingType.PROFILE"
      ></app-loader-spinner-general>
      <div class="status-loader">
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
          [repeat]="3"
        ></app-loader-spinner-general>
      </div>
    </div>
    <app-loader-spinner-general
      class="w-info h-100"
      [repeat]="6"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
  </div>
</ng-container>

<div class="details-body-wrapper" *ngIf="offer">
  <!-- 2 col details -->
  <div class="details-2c">
    <!-- left -->
    <div class="details-2c-left m-r-15">
      <div class="small-box">
        <div class="small-box-header-img">
          <img [src]="offer.primary_image_path" alt="img" />
        </div>
        <!-- <div class="small-box-header-img">
          <img src="../../../assets/images/footer-img.jpg" alt="img" />
        </div> -->
        <div class="small-box-details">
          <div class="medium-title">{{ offer.title }}</div>
          <div class="small-box-status">
            <div class="services-status-label-{{ offer.status_id }}">
              {{ offer.status_label }}
            </div>
          </div>
          <div
            *ngIf="offer.status_id === 5 || offer.status_id === 3"
            class="fw-500"
          >
            <span class="gray block-span"
              >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
            >
            {{ offer.decline_message }}
          </div>
          <div class="fw-500">
            <span class="gray block-span"
              >{{ "GENERALS.WORKFLOW-STATUS" | translate }}:</span
            >
            <div class="small-box-status">
              <div
                class="services-status-label-{{
                  getStatusLabel(offer.workflow_status)
                }}"
              >
                {{ offer.workflow_status }}
              </div>
            </div>
          </div>
          <div class="fw-500">
            <span class="gray block-span"
              >{{ "GENERALS.OFFERED-BY" | translate }}:</span
            >
            <a
              class="action-link-black"
              href="/providers/{{ offer.provider.id }}"
              target="_blank"
              >{{ offer.provider.company_name }}</a
            >
          </div>
        </div>

        <div class="small-box-list">
          <ul>
            <ng-container
              *ngIf="offer.status_id === 6 || offer.status_id === 7"
            >
              <li class="switch-check-list">
                <span class="inner-label">
                  {{ "GENERALS.SHOW-MP" | translate }}:
                </span>
                <input
                  class="checkbox-switch"
                  (change)="updateStatus()"
                  [(ngModel)]="offer.active"
                  type="checkbox"
                  name="change-status-switch"
                />
              </li>
            </ng-container>

            <li>
              <div class="fw-500">
                <span class="gray block-span"
                  >{{ "GENERALS.MARKETPLACE_PRICE" | translate }}:
                </span>
                <!--                <div class="card-price" *ngIf="offer.sale_price.price">-->
                <span
                  *ngIf="offer.default_price.price > offer.current_price.price"
                >
                  <span class="old-price">{{
                    offer.default_price.price
                      | currency : offer.default_price.currency
                  }}</span>

                  <span class="discount">
                    (-{{
                      serviceOffers.calculateDiscount(
                        offer.default_price.price,
                        offer.current_price.price
                      )
                    }}%)</span
                  >
                </span>
                <div class="new-price">
                  {{
                    offer.current_price.price
                      | currency : offer.current_price.currency
                  }}
                </div>
                <!--                </div>-->
              </div>
            </li>

            <ng-container *ngFor="let action of actions[offer.status_id]">
              <li>
                <div
                  (click)="openConfirmAction(action)"
                  class="btn-small orange-bg"
                >
                  {{ action.text }}
                </div>
              </li>
            </ng-container>

            <li>
              <div (click)="copyOffer(offer.id)" class="btn-small orange-bg">
                {{ "GENERALS.COPY-OFFER" | translate }}
              </div>
            </li>

            <li
              class="action-link-gray"
              *ngIf="!(offer.status_id === 3) && !(offer.status_id === 5)"
              (click)="goToMarketplaceOffer(offer.id)"
            >
              <i class="icon-right-dir"></i>
              {{ "GENERALS.PREVIEW-IN-MARKETPLACE" | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.OFFER-INFO" | translate }}
            </div>
            <div
              class="white-box-header-action fl-r"
              [routerLink]="['/service-offers', id, 'edit']"
            >
              {{ "GENERALS.EDIT" | translate }}
              <i class="icon-cog-outline"></i>
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <div class="row">
              <div class="w-50">
                <p>
                  <span>
                    {{ "GENERALS.CATEGORISATION" | translate }}
                  </span>
                  <span class="inner-breadcrumbs-item-wrapper">
                    <ng-container
                      *ngFor="
                        let category of offer.category.breadcrumbs;
                        let last = last;
                        let first = first
                      "
                    >
                      <a
                        class="breadcrumbs-item"
                        [ngClass]="{ orange: last }"
                        >{{ category.label }}</a
                      >
                      <ng-container *ngIf="!last">
                        <span class="breadcrumbs-separator"
                          >/</span
                        ></ng-container
                      >
                    </ng-container>
                  </span>
                </p>

                <p>
                  <span>
                    {{ "GENERALS.SERVICE-DESCRIPTION" | translate }}
                  </span>
                  <span
                    class="desc-content-innerHtml"
                    [innerHTML]="offer.description"
                  ></span>
                </p>

                <p>
                  <span>
                    {{ "GENERALS.LISTINGPRICE" | translate }}
                  </span>
                  {{
                    offer.listing_price.price
                      | currency : offer.listing_price.currency
                  }}
                </p>

                <p>
                  <span>
                    {{ "GENERALS.SALEPRICE" | translate }}
                  </span>
                  {{
                    offer.sale_price.price
                      | currency : offer.sale_price.currency
                  }}
                  <span
                    class="discount"
                    *ngIf="offer.listing_price.price > offer.sale_price.price"
                  >
                    (-{{
                      serviceOffers.calculateDiscount(
                        offer.listing_price.price,
                        offer.sale_price.price
                      )
                    }}%)</span
                  >
                </p>

                <p>
                  <span>
                    {{ "GENERALS.STOCK" | translate }}
                  </span>
                  {{ offer.current_price.quantity }}
                </p>
                <p *ngIf="bundle">
                  <span>
                    {{ "PORTAL.INCLUDED-SERVICES" | translate }}
                  </span>

                  <a
                    *ngFor="let offer of bundle.parent_offers"
                    routerLink="/service-offers/{{ offer.id }}"
                    target="_blank"
                  >
                    {{ offer.title }}
                    <br />
                  </a>
                </p>
              </div>
              <div class="w-50">
                <app-availability-form [offer]="offer"></app-availability-form>

                <p *ngIf="offer.prices.length > 0">
                  <span>{{ "GENERALS.PRICES-PER-PERIOD" | translate }}</span>
                </p>
                <ul
                  class="category-breadcrumb"
                  *ngFor="let customPrice of offer.prices"
                >
                  <li class="font-weight-bold">
                    {{ customPrice.price | currency : customPrice.currency }}
                    <span
                      class="discount"
                      *ngIf="offer.sale_price.price > customPrice.price"
                    >
                      (-{{
                        serviceOffers.calculateDiscount(
                          offer.sale_price.price,
                          customPrice.price
                        )
                      }}%)</span
                    >
                  </li>
                  <li>
                    {{ customPrice.starts_on | date : "dd/MM/yyyy" }}
                    -
                    {{ customPrice.ends_on | date : "dd/MM/yyyy" }}
                  </li>
                </ul>
              </div>
              <div class="w-50" *ngIf="offer.duration">
                <p>
                  <span
                    >{{ "GENERALS.DURATION" | translate }} ({{
                      "GENERALS.DAYS" | translate
                    }})</span
                  >
                  {{ offer.duration }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.CHARACTERISTICS" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <div>
              <p>
                <span>{{ "GENERALS.LOCATION" | translate }} </span>
                {{ offer.country }},{{ offer.city }}
              </p>
            </div>

            <div *ngFor="let characteristic of offer.characteristics">
              <p>
                <span> {{ characteristic.name }} </span>

                <ng-container
                  *ngIf="characteristic.fields[0].type === 'checkbox'"
                >
                  <ng-container *ngIf="characteristic.value === 1">
                    {{ "GENERALS.YES" | translate }}
                  </ng-container>
                  <ng-container *ngIf="characteristic.value === 0">
                    {{ "GENERALS.NO" | translate }}
                  </ng-container>
                </ng-container>

                <ng-container
                  *ngIf="characteristic.fields[0].type !== 'checkbox'"
                >
                  {{ characteristic.value }}
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-chips-select",
  templateUrl: "./chips-select.component.html",
})
export class ChipsSelectComponent implements OnInit {
  @Output() onselect: EventEmitter<any> = new EventEmitter();
  @ViewChild("chipsSelect", { static: true }) chipsSelect: ElementRef;

  @Input() items;
  @Input() multiple = true;
  @Input() hideSelected = true;
  @Input() placeholder = "";
  @Input() bindValue;
  @Input() bindLabel;
  @Input() preselectedItems = [];

  selectedItems = [];

  ngOnInit() {
    if (this.preselectedItems && this.preselectedItems.length) {
      this.selectedItems = this.preselectedItems;
    } else {
      this.selectedItems = [];
    }
  }

  removeChips(index) {
    this.selectedItems.splice(index, 1);
    this.selectedItems = Object.assign([], this.selectedItems);
    this.emitChanges();
  }

  hidePlaceholder() {
    const getPlaceholder =
      this.chipsSelect.nativeElement.querySelector(".ng-placeholder");
    // this.renderer.setStyle(getPlaceholder, 'display',  'none');
    getPlaceholder.classList.add("hide-placeholder");

    // getPlaceholder.innerHTML = '';
  }

  emitChanges() {
    this.onselect.emit(this.selectedItems);
  }
}

<ngx-smart-modal
  id="ressetPassModal"
  #ressetPassModal
  identifier="ressetPassModal"
  [dismissable]="false"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "AUTH.CHANGE-PASSWORD" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <div class="custom-modal-body">
    <!-- Modal body -->

    <!-- <div class="modal-body-desc">{{ 'GENERALS.IPSUM' | translate }}</div> -->
    <div class="modal-form">
      <div class="row">
        <div class="form-group w-100">
          <label>{{ "GENERALS.CURRENT-PASSWORD" | translate }} *</label>
          <input
            type="password"
            name="current_password"
            class="form-control"
            [(ngModel)]="pass.current_password"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group w-100">
          <label>{{ "GENERALS.PASSWORD" | translate }} *</label>
          <input
            type="password"
            name="password"
            class="form-control"
            [(ngModel)]="pass.password"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group w-100">
          <label>{{ "GENERALS.PASSWORD-CONFIRMATION" | translate }} *</label>
          <input
            type="password"
            name="password_confirmation"
            class="form-control"
            [(ngModel)]="pass.password_confirmation"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="clear"></div>

  <!-- Modal footer -->
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn gray-bg"
      (click)="emitChanges('cancel_reset_pass')"
    >
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button
      type="button"
      class="btn orange-bg"
      [disabled]="disabled"
      (click)="emitChanges('save_reset_pass')"
    >
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</ngx-smart-modal>

<div #draggableTableControl class="draggable-table-control-wrapper">
  <ng-container *ngIf="categories">
    <div #tableWrapper>
      <table
        #table
        mat-table
        id="draggable-table-control"
        [dataSource]="categories"
        cdkDropList
        [cdkDropListData]="categories"
        (cdkDropListDropped)="drop($event)"
        (window:resize)="onResize($event)"
        (window:scroll)="onResize($event)"
      >
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef style="width: 70px"></th>
          <td class="gray-pointer" mat-cell *matCellDef="let category">
            <i class="icon-double-arrow"></i>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="width: calc(50% - 50px)">
            {{ "GENERALS.MAIN-CATEGORY" | translate }}
          </th>
          <td
            class="gray-pointer expandBtn"
            mat-cell
            *matCellDef="let category"
          >
            <i
              *ngIf="category.children_count"
              class="expand-icon icon-plus-squared"
            ></i>
            {{ category.label }}
            <ng-container *ngIf="category.children_count"
              >({{ category.children_count }})</ng-container
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="commission">
          <th mat-header-cell *matHeaderCellDef style="width: 10%">
            {{ "LABEL.DEFAULT-COMMISSION" | translate }}
          </th>
          <td class="gray-pointer" mat-cell *matCellDef="let category">
            <span
              *ngIf="
                category.commission_type_id &&
                category.commission_type_id.toString() ===
                  providerCommissionEnum.fixed.toString()
              "
              >{{ category.commission | currency : "EUR" }}</span
            >
            <span
              *ngIf="
                category.commission_type_id &&
                category.commission_type_id.toString() ===
                  providerCommissionEnum.percent.toString()
              "
              >{{ category.commission }}%</span
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">
            {{ "GENERALS.STATUS" | translate }}
          </th>
          <td mat-cell *matCellDef="let category">
            <div class="status-label-{{ category.status }}">
              {{ category.status }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th class="ta-r" mat-header-cell *matHeaderCellDef style="width: 15%">
            <i class="icon-cog-outline-1"></i>
          </th>

          <!-- table cel with action -->
          <td class="ta-r action-coll" mat-cell *matCellDef="let category">
            <!-- table action btn -->
            <div
              class="table-action-btn"
              *ngIf="category.level < 3 && category.status === 'Active'"
              (click)="subcategory.emit(category)"
              title="{{ 'GENERALS.ADD-SUBCATEGORY' | translate }}"
            >
              <i class="icon-list-add"></i>
            </div>
            <!-- end table action btn -->

            <!-- table action with overlay -->
            <div toggle-action-table class="table-action-wrapper">
              <i class="icon-dot-3"></i>

              <div class="table-action">
                <ul>
                  <li
                    *ngIf="category.status === 'Inactive'"
                    (click)="activate.emit(category)"
                  >
                    {{ "GENERALS.ACTIVATE" | translate }}
                  </li>
                  <li
                    *ngIf="category.status === 'Active'"
                    (click)="deactivate.emit(category)"
                  >
                    {{ "GENERALS.DEACTIVATE" | translate }}
                  </li>
                  <li
                    [routerLink]="
                      '/cms/' + category.id + '/documentation-standards'
                    "
                    *ngIf="category.children_count === 0"
                  >
                    {{ "GENERALS.DOCUMENTATION-STANDARDS" | translate }}
                  </li>
                  <li
                    (click)="downloadTemplate(category)"
                    *ngIf="
                      category.children_count === 0 &&
                      category.status === 'Active'
                    "
                  >
                    {{ "GENERALS.DOWNLOAD-TEMPLATE" | translate }}
                  </li>
                  <li
                    (click)="edit.emit(category)"
                    *ngIf="
                      this.authService.$can(
                        action.CREATE,
                        resource.CATEGORY,
                        subResource.FINANCIAL
                      )
                    "
                  >
                    {{ "GENERALS.EDIT" | translate }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- end table action with overlay -->
          </td>
          <!--end table cel with action -->
        </ng-container>

        <ng-container *ngIf="isMainLevel">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        </ng-container>

        <tr
          mat-row
          cdkDrag
          [cdkDragData]="row"
          *matRowDef="let row; columns: displayedColumns"
          class="draggable-tr"
          [cdkDetailRow]="row"
          [template]="tpl"
          (click)="rowClick($event)"
          (cdkDragStarted)="startDrag()"
        ></tr>
      </table>
    </div>
  </ng-container>

  <ng-template #tpl let-category>
    <tr #dynamic class="inserted-tr">
      <td
        colspan="6"
        class="dragable-row-children"
        *ngIf="category.children_count"
      >
        <app-category-item
          [categories]="category.children"
          [isMainLevel]="false"
          (update)="update.emit($event)"
          (subcategory)="subcategory.emit($event)"
          (activate)="activate.emit($event)"
          (deactivate)="deactivate.emit($event)"
          (edit)="edit.emit($event)"
        ></app-category-item>
      </td>
    </tr>
  </ng-template>
</div>

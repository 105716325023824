<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">
    {{ "GENERALS.SERVICE-CATEGORISATION" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <div (click)="openModal()" class="btn white-bg shadow">
      <i class="icon-plus-1"></i>
      {{ "GENERALS.ADD-CATEGORY" | translate }}
    </div>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="isLoadingGeneral"
  [loadingType]="LoadingType.TABLE"
></app-loader-spinner-general>

<!-- table -->
<div class="table-wrapper draggable-table" *ngIf="!isLoadingGeneral">
  <div class="table-responsive">
    <app-category-item
      [categories]="items"
      [isMainLevel]="true"
      (update)="update($event)"
      (refresh)="getItems()"
      (subcategory)="addSubcategory($event)"
      (activate)="activate($event)"
      (deactivate)="deactivate($event)"
      (edit)="edit($event)"
    ></app-category-item>
  </div>
</div>
<!-- end table -->

<!-- add category modal -->
<app-add-category
  [parent]="parent"
  [category]="selectedCategory"
  (saved)="getItems()"
></app-add-category>
<!-- end add category modal -->

<!-- add subCategory modal -->
<!--<app-add-subcategory-->
<!--(getServiceCategories)="getServiceCategories()"-->
<!--[parentId]="parentId"-->
<!--&gt;</app-add-subcategory>-->
<!-- end add subCategory modal -->

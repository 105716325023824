import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProvidersService } from "../../../../_services/providers.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";

@Component({
  selector: "app-provider-orders",
  templateUrl: "./provider-orders.component.html",
  styleUrls: ["./provider-orders.component.css"],
})
export class ProviderOrdersComponent implements OnInit {
  entity_id;

  constructor(
    private route: ActivatedRoute,
    private service: ProvidersService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.entity_id = this.route.snapshot.parent.params.id;
  }

  ngOnInit() {
    this.service.getProvider(this.entity_id).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForProvider(data.result, "orders")
      );
    });
  }
}

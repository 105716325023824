<div class="details-body-wrapper">
  <ng-container *ngIf="id">
    <app-documents
      [entityType]="'expats'"
      [entityId]="id"
      [hideTitle]="true"
      [docType]="'docs'"
    ></app-documents>
  </ng-container>
</div>

import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { ClientsService } from "../../../../_services/clients.service";

@Component({
  selector: "app-client-documents",
  templateUrl: "./client-documents.component.html",
  styleUrls: ["./client-documents.component.css"],
})
export class ClientDocumentsComponent {
  public id: number;

  constructor(
    private route: ActivatedRoute,
    private service: ClientsService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.service.getClient(this.id).subscribe((data) => {
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForClient(data.result, "documents")
          );
        });
      }
    });
  }
}

import { KeyValue } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import * as shape from "d3-shape";
import { StatisticsDataChart } from "../../../_models/statisticsDataChart";
import { OrderStatuses } from "../../../_models/_statuses/order-statuses";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { StatisticsOverdueOrders } from "./../../../_models/statisticsOverdueOrders";
import { StatisticsService } from "./../../../_services/statistics.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  colorScheme = {
    domain: [
      "#517820",
      "#99a83d",
      "#fdcc5d",
      "#f3ae55",
      "#f96332",
      "#d3391a",
      "#AC0F0F",
      "#4D3229",
    ],
  };
  colorSchemeLineOrange = {
    domain: ["#9b9b9b", "#f96332"],
  };
  colorSchemeDoughnutYellow = {
    domain: ["#fff", "#e8a834"],
  };
  colorSchemeDoughnutWhite = {
    domain: ["#f96332", "#efefef"],
  };
  colorSchemeDoughnutOrange = {
    domain: ["#fff", "#ee6836"],
  };

  single = [
    {
      name: "name1",
      value: 1,
    },
  ];
  xAxisLabel = "Amount";
  yAxisLabel = "Month";

  ordersStatisticsStatus;
  isLoadingGeneral: boolean;

  // response data for the line chart at the top of the page
  ordersPerMonthData;
  ordersPerMonthCurrency = "EUR";
  ordersPerMonthStatus = OrderStatuses.statuses.new;
  ordersPerMonthInterval = {};

  overdueOrders: StatisticsOverdueOrders[];
  ordersByDay;
  totalOrdersCurrencySumByDay = 0;
  totalOrdersSumByDay = 0;
  daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  dayOfWekk = [];
  canceledOrdersComparison;
  firstDayCurrentYear;
  lastDayCurrentYear;
  ordersFinalized: StatisticsDataChart[];
  offersByStatus;
  inValidateServices;
  providerByStatus;
  clientByStatus;
  totalProviderByStatus = 0;
  totalClientByStatus = 0;
  soldOffers;
  curve = shape.curveCatmullRom;
  selectedCurr;
  boxMaskLoader: boolean;
  selectedDateOrderOverview = {};
  topFiveProviders;
  selectedStatusTopProviders;
  selectedDateTopProviders = {};
  selectedDateCanceled = {};
  selectedDateCompleted = {};
  noOrdersAccepted;
  noOrdersWaiting;
  selectedCurrencyOrderValue;
  clientLeads;
  providerLeads;
  boxMaskLoaderOrderValue: boolean;

  constructor(
    private service: StatisticsService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.firstDayCurrentYear = this.formatDate(
      new Date(new Date().getFullYear(), 0, 1)
    );
    this.lastDayCurrentYear = this.formatDate(
      new Date(new Date().getFullYear(), 11, 31)
    );
    this.selectedCurr = "EUR";
    this.ordersPerMonthInterval = {
      start_date: this.firstDayCurrentYear,
      end_date: this.lastDayCurrentYear,
      initial_loading: true,
    };
    this.selectedStatusTopProviders = "1";
    this.selectedDateTopProviders = {
      start_date: this.firstDayCurrentYear,
      end_date: this.lastDayCurrentYear,
      initial_loading: true,
    };
    this.selectedDateCanceled = {
      start_date: this.firstDayCurrentYear,
      end_date: this.lastDayCurrentYear,
      initial_loading: true,
    };
    this.selectedDateCompleted = {
      start_date: this.firstDayCurrentYear,
      end_date: this.lastDayCurrentYear,
      initial_loading: true,
    };
    this.boxMaskLoader = false;
    this.boxMaskLoaderOrderValue = false;
    this.selectedCurrencyOrderValue = "EUR";
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setStatic("dashboard", "/dashboard")
    );
    this.getOverdueOrders();
    this.getOrdersByDay(this.selectedCurr);
    this.getCanceledOrdersComparison(this.selectedDateCanceled);
    this.getOrdersStatisticsStatus(this.selectedDateCompleted);
    this.getOffersByStatus();
    this.getProviderByStatus();
    this.getClientByStatus();
    this.getSoldOffers();
    this.getOrdersPerMonthChart(
      this.ordersPerMonthCurrency,
      this.ordersPerMonthStatus,
      this.ordersPerMonthInterval
    );
    this.getTopFiveProviders(
      this.selectedStatusTopProviders,
      this.selectedDateTopProviders
    );
    this.getNoOrdersAccepted(this.selectedCurrencyOrderValue);
    this.getNoOrdersWaiting(this.selectedCurrencyOrderValue);
    this.getClientLeads();
    this.getProviderLeads();
  }

  getOrdersByDay(curr) {
    this.boxMaskLoader = true;
    if (curr) {
      this.selectedCurr = curr;
    }
    this.totalOrdersCurrencySumByDay = 0;
    this.totalOrdersSumByDay = 0;

    this.service.getOrdersByDay().subscribe((data) => {
      this.ordersByDay = data.result;
      for (const key in this.ordersByDay) {
        if (Object.prototype.hasOwnProperty.call(this.ordersByDay, key)) {
          const date = new Date(key);
          const day = date.getDay();
          this.dayOfWekk.push(this.daysOfWeek[day]);

          for (const currenciesKey in this.ordersByDay[key].currencies) {
            if (
              Object.prototype.hasOwnProperty.call(
                this.ordersByDay[key].currencies,
                currenciesKey
              )
            ) {
              if (currenciesKey == curr) {
                this.totalOrdersCurrencySumByDay +=
                  this.ordersByDay[key].currencies[currenciesKey].amount;
                this.totalOrdersSumByDay +=
                  this.ordersByDay[key].currencies[currenciesKey].total;
              }
            }
          }
        }
      }
      this.boxMaskLoader = false;
    });
  }

  getCanceledOrdersComparison(date) {
    const params = {};

    if (date.initial_loading) {
      params["start_date"] = date.start_date;
      params["end_date"] = date.end_date;
    } else {
      this.selectedDateCanceled["start_date"] =
        this.formatCalendarDate(date).start_date;
      this.selectedDateCanceled["end_date"] =
        this.formatCalendarDate(date).end_date;
      params["start_date"] = this.formatCalendarDate(date).start_date;
      params["end_date"] = this.formatCalendarDate(date).end_date;
    }
    this.service.getCanceledOrdersComparison(params).subscribe((data) => {
      this.canceledOrdersComparison = data.result;
    });
  }

  getOverdueOrders() {
    this.isLoadingGeneral = true;
    this.service.getOverdueOrders().subscribe((data) => {
      this.overdueOrders = data.result;
      this.isLoadingGeneral = false;
    });
  }

  getOrdersStatisticsStatus(date) {
    const params = {};

    if (date.initial_loading) {
      params["start_date"] = date.start_date;
      params["end_date"] = date.end_date;
    } else {
      this.selectedDateCompleted["start_date"] =
        this.formatCalendarDate(date).start_date;
      this.selectedDateCompleted["end_date"] =
        this.formatCalendarDate(date).end_date;
      params["start_date"] = this.formatCalendarDate(date).start_date;
      params["end_date"] = this.formatCalendarDate(date).end_date;
    }

    this.service.getOrdersStatisticsStatus(params).subscribe((data) => {
      this.ordersStatisticsStatus = data.result;

      this.ordersFinalized = [];

      Object.keys(this.ordersStatisticsStatus.orders_by_status).forEach(
        (key) => {
          if (
            this.ordersStatisticsStatus.orders_by_status[key].status_id == 7
          ) {
            this.ordersFinalized.push(
              {
                name: "progress",
                value: this.ordersStatisticsStatus.orders_by_status[key].total,
              },
              {
                name: "total",
                value: this.ordersStatisticsStatus.total,
              }
            );
          }
        }
      );
    });
  }

  getOffersByStatus() {
    this.service.getOffersByStatus().subscribe((data) => {
      this.offersByStatus = data.result;

      Object.keys(this.offersByStatus).forEach((key) => {
        // status_id = 2 (Pending & Active)
        // status_id = 4 (Pending & Inactive)
        this.inValidateServices =
          this.offersByStatus[2].total + this.offersByStatus[4].total;
      });
    });
  }

  getProviderByStatus() {
    const params = {};
    params["entity_type_id"] = "1";
    this.service.getEntitiesByStatus(params).subscribe((data) => {
      this.providerByStatus = data.result;

      Object.keys(this.providerByStatus).forEach((key) => {
        this.totalProviderByStatus += this.providerByStatus[key].total;
      });
    });
  }

  getClientByStatus() {
    const params = {};
    params["entity_type_id"] = "2";
    this.service.getEntitiesByStatus(params).subscribe((data) => {
      this.clientByStatus = data.result;

      Object.keys(this.clientByStatus).forEach((key) => {
        this.totalClientByStatus += this.clientByStatus[key].total;
      });
    });
  }

  getSoldOffers() {
    this.service.getSoldOffers().subscribe((data) => {
      this.soldOffers = data.result;
    });
  }

  getOrdersPerMonthChart(_currency, _status, _date) {
    const params = {};
    this.ordersPerMonthCurrency = _currency;
    this.ordersPerMonthStatus = _status;
    params["status_id"] = _status;

    if (_date.initial_loading) {
      params["start_date"] = _date.start_date;
      params["end_date"] = _date.end_date;
    } else {
      this.ordersPerMonthInterval["start_date"] =
        this.formatCalendarDate(_date).start_date;
      this.ordersPerMonthInterval["end_date"] =
        this.formatCalendarDate(_date).end_date;
      params["start_date"] = this.ordersPerMonthInterval["start_date"];
      params["end_date"] = this.ordersPerMonthInterval["end_date"];
    }

    this.service.getFinalizedOrdersComp(params).subscribe((data) => {
      this.ordersPerMonthData = data.result;
    });
  }

  getTopFiveProviders(sts, date) {
    const params = {};

    if (sts) {
      this.selectedStatusTopProviders = sts;
    }

    if (date.initial_loading) {
      params["start_date"] = date.start_date;
      params["end_date"] = date.end_date;
    } else {
      this.selectedDateTopProviders["start_date"] =
        this.formatCalendarDate(date).start_date;
      this.selectedDateTopProviders["end_date"] =
        this.formatCalendarDate(date).end_date;
      params["start_date"] = this.formatCalendarDate(date).start_date;
      params["end_date"] = this.formatCalendarDate(date).end_date;
    }
    params["status_id"] = sts;

    this.service.getTopFiveProviders(params).subscribe((data) => {
      this.topFiveProviders = data.result;
    });
  }

  getNoOrdersAccepted(curr) {
    this.boxMaskLoaderOrderValue = true;
    const params = {};
    if (curr) {
      this.selectedCurrencyOrderValue = curr;
    }

    params["accepted"] = 1;

    this.service.getNoOrdersByStatus(params).subscribe((data) => {
      this.noOrdersAccepted = data.result;

      this.boxMaskLoaderOrderValue = false;
    });
  }

  getNoOrdersWaiting(curr) {
    this.boxMaskLoaderOrderValue = true;
    const params = {};

    if (curr) {
      this.selectedCurrencyOrderValue = curr;
    }

    params["accepted"] = 0;

    this.service.getNoOrdersByStatus(params).subscribe((data) => {
      this.noOrdersWaiting = data.result;

      this.boxMaskLoaderOrderValue = false;
    });
  }

  getClientLeads() {
    this.service.getClientLeads().subscribe((data) => {
      this.clientLeads = data.result;
    });
  }

  getProviderLeads() {
    this.service.getProviderLeads().subscribe((data) => {
      this.providerLeads = data.result;
    });
  }

  keyDescOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  formatDate(date) {
    const formatted_date =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return formatted_date;
  }

  formatCalendarDate(e) {
    if (!e.start_date || !e.end_date) {
      return;
    }

    const start_date =
      e.start_date.year +
      "-" +
      (e.start_date.month < 10
        ? "0" + e.start_date.month
        : e.start_date.month) +
      "-" +
      (e.start_date.day < 10 ? "0" + e.start_date.day : e.start_date.day);

    const end_date =
      e.end_date.year +
      "-" +
      (e.end_date.month < 10 ? "0" + e.end_date.month : e.end_date.month) +
      "-" +
      (e.end_date.day < 10 ? "0" + e.end_date.day : e.end_date.day);

    return {
      start_date: start_date,
      end_date: end_date,
    };
  }
}

<div class="select-language-wrapper">
  <ng-select
    #langSelect
    class="customiz-select lang-select no-clearable-select"
    [items]="translate.getLangs()"
    placeholder="chose lang"
    [(ngModel)]="selectedLang"
    (change)="changeLang(selectedLang)"
    notFoundText="no found"
    [clearable]="false"
  >
  </ng-select>
</div>

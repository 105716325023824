<div class="dashboard-body-action">
  <div class="fl-l advanced-search-wrapper gray-search-bg">
    <app-filters
      [showAdvanced]="false"
      (search)="search_text = $event; getItems()"
    ></app-filters>
  </div>

  <div class="btn orange-bg fl-r">
    <span *ngIf="selectAll" (click)="clearSelection()">{{
      "RFQS.DESELECT-ALL" | translate
    }}</span>
    <span *ngIf="!selectAll" (click)="getAllItems()">{{
      "RFQS.SELECT-ALL" | translate
    }}</span>
  </div>

  <div class="btn orange-bg fl-r" (click)="clearSelection()">
    {{ "RFQS.CLEAR-SELECTION" | translate }}
  </div>
</div>
<div class="clear"></div>

<div class="table-wrapper">
  <ng-container>
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <ng-container matColumnDef="select">
              <th>
                <mat-checkbox
                  (change)="$event ? customMasterToggle() : null"
                  [checked]="selection.hasValue() && areAllSelected()"
                  [indeterminate]="selection.hasValue() && !areAllSelected()"
                  [disableRipple]="true"
                  [disabled]="selectAll"
                  [class.faded-checkbox]="selectAll"
                >
                </mat-checkbox>
              </th>
            </ng-container>
            <th>
              {{ "GENERALS.COMPANY" | translate }}
            </th>
            <th>
              {{ "GENERALS.COUNTRY" | translate }}
            </th>
            <th>
              {{ "GENERALS.RATING" | translate }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let provider of items
                | paginate
                  : {
                      id: 'invite-providers',
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                *ngIf="!checkPreinvited(provider.id)"
                (click)="$event.stopPropagation()"
                (change)="$event ? customChangeSelection(provider) : null"
                [checked]="checkValue(provider.id)"
                [disableRipple]="true"
                [disabled]="selectAll"
                [class.faded-checkbox]="selectAll"
              >
              </mat-checkbox>
              <div
                class="tree-check dark-green fl-l"
                *ngIf="checkPreinvited(provider.id)"
              >
                <i class="icon-ok"></i>
              </div>
            </td>

            <td class="gray-pointer">
              {{ provider.company_name }}
            </td>
            <td class="gray-pointer">
              {{ country }}
            </td>
            <td class="gray-pointer">
              <app-rating [currentRate]="provider.rating"></app-rating>
            </td>
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="4" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <!-- paginator -->
  <div class="paginator rfq-paginator">
    <pagination-controls
      id="invite-providers"
      (pageChange)="getItems($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>

<div *ngIf="cookieConsent" class="cookie-consent-wrapper">
  <div class="cookie-consent-text">
    {{ "GENERALS.COOKIE-CONSENT-TEXT" | translate }}
    <span (click)="cookieAccept('true')" class="cookie-consent-btn">{{
      "GENERALS.ACCEPT" | translate
    }}</span>
    <span (click)="goToPrivacyPolicy()">{{
      "GENERALS.LEARN-MORE" | translate
    }}</span>
  </div>
</div>

import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ExpatsService } from "../../../_services/expats.service";
import { OrderPopupComponent } from "../orders/order-popup/order-popup.component";
import { DictionariesService } from "../../../_services/dictionaries.service";
import { ItemsListComponent } from "../items-list/items-list.component";
import { environment } from "src/environments/environment";
import { WindowResizeHelperService } from "./../../../_services/window-resize-helper.service";
import cloneDeep from "lodash-es/cloneDeep";
import { ActivatedRoute, Router } from "@angular/router";
import { EntityStatuses } from "src/app/_models/_statuses/entity-statuses";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { ConfirmActionModalComponent } from "../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { AlertService } from "src/app/_services/alert.service";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { StatusColorEnum } from "../../../modules/shared/_enums/status-color.enum";

@Component({
  selector: "app-expats",
  templateUrl: "./expats.component.html",
  styleUrls: ["./expats.component.scss"],
})
export class ExpatsComponent extends ItemsListComponent implements OnInit {
  @Input() entity_id;
  @Input() hideTitle = true;
  isXs: boolean = false;
  isFiltersLoaded: boolean = false;
  active = "";
  direction = "";
  constructor(
    private service: ExpatsService,
    public dictionariesService: DictionariesService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
  }

  ngOnInit() {
    if (this.entity_id) {
      this.filters = this.getParams(1);
      this.getItems();
    } else {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForList("/expats", "expats")
      );
      this.route.queryParams.subscribe((query) => {
        if (query && Object.keys(query).length > 0) {
          this.filters = cloneDeep(query);
          this.filters["status_id"] = +this.filters["status_id"];
        } else {
          this.clearFilters();
          const params = this.getParams(1);
          this.filters = params;
        }
        if (this.filters["search_text"]) {
          this.search_text = this.filters["search_text"];
        }
        if (this.filters["per_page"]) {
          this.perPage = this.filters["per_page"];
        }
        if (this.filters["order"]) {
          this.active = this.filters["order"];
          this.direction = this.filters["order_direction"];
        } else {
          this.active = this.filters["order"] = "id";
          this.direction = this.filters["order_direction"] = "desc";
        }
        this.getItems();
      });
    }

    this.getStatusTransitions();

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    if (this.entity_id) {
      if (!id) {
        this.getItems();
      } else {
        const host = environment.app_host;
        const expatsUrl = "/expats/" + id;
        const url = host + expatsUrl;
        window.open(url, "_blank");
      }
    } else {
      let url = "";
      if (id) {
        url = "/expats/" + id;
      } else {
        url = "/expats";
      }
      this.router.navigate([url], {
        queryParams: params,
      });
    }
  }

  public getItems(page: number = 1): void {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = this.getParams(page);
    if (this.entity_id) {
      params["client_id"] = this.entity_id;
    }

    // if (!params['client_id']) {
    //   delete params['client_id'];
    // }

    this.isLoadingGeneral = true;
    this.request_call = this.service
      .getExpatsList(params)
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe((data) => {
        this.processResponse(data);
        this.isFiltersLoaded = true;
      });
  }

  public getStatusTransitions() {
    this.service.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  openDialog(): void {
    const expat = {
      client_id: this.entity_id,
      edit: false,
    };

    const dialogRef = this.dialog.open(OrderPopupComponent, {
      data: {
        type: "newExpat",
        openedFrom: "expats",
        expat: { data: expat },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "save") {
        this.getItems(1);
      }
      console.log(" Expats The dialog was closed", result);
    });
  }

  openConfirmAction(action, expat?) {
    let ids = [];
    this.isLoadingGeneral = true;
    if (expat) {
      ids = [expat.id];
    } else {
      this.selection.selected.forEach((row) => ids.push(row.id));
    }

    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.service.updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.getItems(1);
              this.isLoadingGeneral = false;
            },
            (error) => {
              this.isLoadingGeneral = false;
            }
          );
        }
        this.isLoadingGeneral = false;
      });
    } else {
      this.service.updateStatusBulk(action.id, ids, null).subscribe(
        (data) => {
          this.getItems(1);
          this.isLoadingGeneral = false;
        },
        (error) => {
          this.isLoadingGeneral = false;
        }
      );
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  protected readonly StatusColorEnum = StatusColorEnum;
}

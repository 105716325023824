<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div windowScrollDashboardBodyTop class="dashboard-body-top">
  <!-- left -->

  <!-- title -->
  <div *ngIf="!id" class="fl-l big-title">
    <i (click)="navigateBack()" class="icon-left-open back-arrow"></i>
    {{ "GENERALS.ADD-BUNDLE" | translate }}
  </div>
  <div *ngIf="id" class="fl-l big-title">
    <i (click)="navigateBack()" class="icon-left-open back-arrow"></i>
    {{ "GENERALS.EDIT-BUNDLE" | translate }}
  </div>
  <!-- end title -->

  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <div (click)="navigateBack()" class="btn white-bg">
      <i class="icon-cancel-2"></i> {{ "GENERALS.CANCEL" | translate }}
    </div>

    <ng-container *ngIf="!id">
      <!-- <div (click)="saveDraft()" class="btn white-bg">
        <i class="icon-floppy"></i> {{ 'GENERALS.SAVE-DRAFT' | translate }}
      </div> -->
      <div (click)="addOffer()" class="btn orange-bg">
        <i class="icon-plus-1"></i> {{ "GENERALS.ADD" | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="id">
      <div (click)="updateOffer()" class="btn orange-bg">
        <i class="icon-plus-1"></i> {{ "GENERALS.UPDATE" | translate }}
      </div>
    </ng-container>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="clear"></div>
<!-- content -->

<div class="white-box-wrapper">
  <div class="row">
    <!-- left -->
    <div class="w-50">
      <!-- Service offers -->
      <div class="white-box-row">
        <div class="white-box-header no-border">
          <div class="medium-title fl-l">
            {{ "GENERALS.SERVICEOFFERS" | translate }}
          </div>
        </div>
        <div class="clear"></div>
        <div class="white-box-body p-t-0">
          <div class="row" *ngIf="!edit && !readonly">
            <div class="form-group w-100">
              <label>{{ "GENERALS.PROVIDER" | translate }} * </label>
              <ng-select
                class="customiz-select"
                [items]="providers"
                bindLabel="company_name"
                bindValue="id"
                (search)="getProviders($event, status)"
                (ngModelChange)="updateSelectedProvider($event)"
                placeholder="{{ 'GENERALS.SELECT-PROVIDER' | translate }}"
                [(ngModel)]="newOffer.provider_id"
                notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                [clearable]="false"
              >
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="edit || readonly">
            <div class="w-100">
              <div class="form-group">
                <label>{{ "GENERALS.PROVIDER" | translate }} * </label>
                <input
                  class="form-control"
                  value="{{ newOffer?.company_name }}"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="row" *ngIf="offers || (edit && newOffer.offers)">
            <div class="form-group w-100">
              <label
                >{{ "GENERALS.SERVICE-TITLE" | translate }} *
                <span appHoverHelper class="input-helper"
                  ><i class="icon-help_comp"></i>
                  <span class="helper-description">
                    {{ "HELPERS.TITLE" | translate }}
                  </span>
                </span></label
              >
              <input
                type="text"
                name="title"
                class="form-control"
                [(ngModel)]="newOffer.title"
              />
            </div>
          </div>

          <div class="row" *ngIf="offers || (edit && newOffer.offers)">
            <div
              class="form-group w-100"
              [ngClass]="{ 'm-t-30': newOffer.offers.length > 0 }"
            >
              <div
                *ngFor="
                  let offer of newOffer.offers;
                  let k = index;
                  let last = last
                "
                class="bundle-offers"
                [class.no-border]="last"
              >
                <div class="medium-title order-details-box-left-header">
                  {{ offer.title }}
                  <span
                    *ngIf="!edit"
                    class="fl-r pointer-orange-hover"
                    (click)="removeOffer(offer, k)"
                  >
                    <i class="icon-trash-empty"></i>
                  </span>
                </div>
                <div class="order-details-box-left">
                  <img
                    *ngIf="!offer?.primary_image_path?.length"
                    src="../../../assets/images/footer-img.jpg"
                    class="pointer"
                    alt="img"
                  />

                  <img
                    *ngIf="offer?.primary_image_path?.length"
                    src="{{ offer.primary_image_path }}"
                    class="pointer"
                    alt="img"
                  />

                  <div class="fl-l order-details-left-content">
                    <div class="order-details-box-left-body">
                      <div class="info-row-editable">
                        <span class="inner-label">
                          {{ "GENERALS.LISTINGPRICE" | translate }}:
                        </span>
                        <span>
                          {{
                            offer.listing_price.price
                              | currency : offer.listing_price.currency
                          }}
                        </span>
                      </div>

                      <div class="info-row-editable">
                        <span class="inner-label">
                          {{ "GENERALS.SALEPRICE" | translate }}:
                        </span>
                        <span>
                          {{
                            offer.sale_price.price
                              | currency : offer.sale_price.currency
                          }}
                        </span>
                      </div>

                      <div class="info-row-editable">
                        <span class="inner-label">
                          {{ "GENERALS.MIN-PRICE" | translate }}:
                        </span>
                        <span>
                          {{
                            offer.min_price
                              | currency : offer.sale_price.currency
                          }}
                        </span>
                      </div>

                      <div class="info-row-editable">
                        <span class="inner-label">
                          {{ "GENERALS.MAX-PRICE" | translate }}:
                        </span>
                        <span>
                          {{
                            offer.max_price
                              | currency : offer.sale_price.currency
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group w-100"
              *ngIf="!edit && newOffer.offers.length < 3"
            >
              <label *ngIf="newOffer.offers.length === 0"
                >{{ "GENERALS.ADD-SERVICEOFFERS" | translate }}
                *
              </label>
              <label *ngIf="newOffer.offers.length > 0 && !disableSelect"
                >{{ "GENERALS.ADD-ANOTHER-SERVICEOFFERS" | translate }}
                *
              </label>
              <label *ngIf="newOffer.offers.length > 0 && disableSelect"
                >{{ "GENERALS.NO-AVAILABLE-SERVICEOFFERS" | translate }}
                *
                <span
                  *ngIf="newOffer.offers.length === 3"
                  appHoverHelper
                  class="input-helper"
                  ><i class="icon-help_comp"></i>
                  <span class="helper-description">
                    {{ "GENERALS.CAN-CHOOSE-MAX-3-OFFERS" | translate }}
                  </span>
                </span>
              </label>

              <div>
                <ng-select
                  class="customiz-select chips-select"
                  [disabled]="disableSelect"
                  [items]="offers"
                  bindLabel="title"
                  (ngModelChange)="updateSelectedOffer($event, 1)"
                  placeholder="{{ placeholders.offers | translate }}"
                  [(ngModel)]="newOffer.offers"
                  [hideSelected]="true"
                  [multiple]="true"
                  maxSelectedItems="3"
                  (search)="searchServiceOffers($event)"
                  notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                  [clearable]="false"
                  (focus)="hidePlaceholder()"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end  Service offers -->
    </div>
    <!-- end left -->

    <!-- right -->
    <div class="w-50">
      <!-- Price & availability -->
      <div class="white-box-row">
        <div class="white-box-header no-border">
          <div class="medium-title fl-l">
            {{ "GENERALS.PRICE-AVAILABILITY" | translate }}
          </div>
        </div>
        <div class="clear"></div>
        <div class="white-box-body p-t-0">
          <div class="row">
            <div class="w-50">
              <div class="form-group">
                <label
                  >{{ "GENERALS.LISTINGPRICE" | translate }}
                  <span *ngIf="newOffer.currency_code">
                    ({{ newOffer.currency_code | uppercase }}) </span
                  >*
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.LISTING-PRICE" | translate }}
                    </span>
                  </span></label
                >
                <input
                  type="number"
                  name="default_price"
                  class="form-control"
                  [(ngModel)]="newOffer.default_price"
                  placeholder="{{ placeholders.default_price }}"
                />
                <input
                  type="hidden"
                  name="default_price_id"
                  [(ngModel)]="newOffer.default_currency_id"
                />
              </div>
            </div>
            <div class="w-50">
              <div class="form-group">
                <label
                  >{{ "GENERALS.SALEPRICE" | translate }}
                  <span *ngIf="newOffer.currency_code">
                    ({{ newOffer.currency_code | uppercase }}) </span
                  >*
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.SALE-PRICE" | translate }}
                    </span>
                  </span></label
                >
                <input
                  type="number"
                  name="sale_price"
                  class="form-control"
                  [(ngModel)]="newOffer.sale_price"
                  placeholder="{{ placeholders.sale_price }}"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="w-50">
              <div class="form-group">
                <label
                  >{{ "GENERALS.MIN-PRICE" | translate }}
                  <span *ngIf="newOffer.currency_code">
                    ({{ newOffer.currency_code | uppercase }}) </span
                  >*
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.MIN-PRICE" | translate }}
                    </span>
                  </span></label
                >
                <input
                  type="number"
                  name="min_price"
                  (keypress)="
                    validateNumericalInput($event, 'min_price_format')
                  "
                  class="form-control"
                  [(ngModel)]="newOffer.min_price"
                  placeholder="{{ placeholders.min_price }}"
                />
                <div *ngIf="newOffer.min_price_format" class="field-message">
                  {{ "VALIDATIONS.NUMERIC-INPUT" | translate }}
                </div>
              </div>
            </div>

            <div class="w-50">
              <div class="form-group">
                <label
                  >{{ "GENERALS.MAX-PRICE" | translate }}
                  <span *ngIf="newOffer.currency_code">
                    ({{ newOffer.currency_code | uppercase }}) </span
                  >*
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"> </i>
                    <span class="helper-description">
                      {{ "HELPERS.MAX-PRICE" | translate }}
                    </span>
                  </span></label
                >
                <input
                  type="number"
                  (keypress)="
                    validateNumericalInput($event, 'max_price_format')
                  "
                  name="max_price"
                  class="form-control"
                  [(ngModel)]="newOffer.max_price"
                  placeholder="{{ placeholders.max_price }}"
                />
                <div *ngIf="newOffer.max_price_format" class="field-message">
                  {{ "VALIDATIONS.NUMERIC-INPUT" | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="w-50">
              <div class="form-group">
                <label>
                  {{ "GENERALS.DURATION" | translate }} ({{
                    "GENERALS.DAYS" | translate
                  }}) *
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.DURATION" | translate }}
                    </span>
                  </span></label
                >
                <input
                  type="text"
                  name="duration"
                  class="form-control"
                  [(ngModel)]="newOffer.duration"
                  placeholder="{{ placeholders.duration }}"
                />
              </div>
            </div>
          </div>

          <div class="row" *ngIf="edit">
            <div class="w-100">
              <div
                (click)="openPriceModal()"
                class="action-link-orange custom-price d-inline"
              >
                <i class="icon-plus-1"></i>
                {{ "GENERALS.ADD-PRICE" | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- added price -->
        <div class="white-box-row" *ngIf="newOffer.prices.length">
          <div class="white-box-header no-border">
            <div class="medium-title fl-l">
              {{ "GENERALS.ADDED-PRICE" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div
            class="white-box-body added-price-row"
            *ngFor="let price of newOffer.prices; let i = index"
          >
            <div class="row">
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.PRICE" | translate }}
                    <span *ngIf="newOffer.currency_code">
                      ({{ newOffer.currency_code | uppercase }}) </span
                    >*
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.CUSTOM-PRICE" | translate }}
                      </span>
                    </span></label
                  >
                  <input
                    type="text"
                    name="price"
                    class="form-control"
                    [(ngModel)]="price.price"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.STOCK-AVAILABILITY" | translate }}
                    *
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.CUSTOM-MAX-AVAILABILITY" | translate }}
                      </span></span
                    ></label
                  >
                  <input
                    type="text"
                    name="stock"
                    class="form-control"
                    [(ngModel)]="price.quantity"
                  />
                </div>
              </div>
              <div class="w-50">
                <app-date-range-calendar
                  [placeholder]="'GENERALS.SELECT-DATE' | translate"
                  [dateRange]="{
                    start_date: price.starts_on,
                    end_date: price.ends_on
                  }"
                  (change)="setDate($event, i)"
                >
                </app-date-range-calendar>
              </div>
            </div>

            <!-- delete added price row -->
            <div
              class="delete-price-row"
              (click)="deletePrice(i)"
              title="remove added price"
            >
              <i class="icon-trash-empty"></i>
            </div>
            <!-- end delete added price row -->
          </div>
        </div>
        <!-- end added price -->
      </div>
      <!-- end Price & availability -->

      <div class="white-box-row fl-r service-offer-footer">
        <ng-container *ngIf="!id && !isLoadingGeneral">
          <!-- <div (click)="saveDraft()" class="btn white-bg">
            <i class="icon-floppy"></i> {{ 'GENERALS.SAVE-DRAFT' | translate }}
          </div> -->
          <div (click)="addOffer()" class="btn orange-bg">
            <i class="icon-plus-1"></i>
            {{ "GENERALS.ADD" | translate }}
          </div>
        </ng-container>

        <ng-container *ngIf="id && !isLoadingGeneral">
          <div (click)="updateOffer()" class="btn orange-bg">
            <i class="icon-plus-1"></i>
            {{ "GENERALS.UPDATE" | translate }}
          </div>
        </ng-container>
      </div>
    </div>
    <!-- end right -->
  </div>
</div>
<!-- end content -->

<!-- add price modal -->
<app-add-price
  [currency_code]="newOffer.currency_code"
  (saved)="addPrice($event)"
></app-add-price>
<!-- end add price modal -->

<div class="custom-modal-wrapper">
  <div class="modal-title-custom" *ngIf="!edit">
    {{ "PORTAL.ADD-EXPAT" | translate }}
  </div>
  <div class="modal-title-custom" *ngIf="edit">
    {{ "PORTAL.EDIT-EXPAT" | translate }}
  </div>
  <div *ngIf="showExpatAddedMessage" class="inner-notification-msg">
    {{
      "PORTAL.EXPAT-CREATED"
        | translate
          : {
              first_name: recentlyAddedExpat.user.first_name,
              last_name: recentlyAddedExpat.user.last_name
            }
    }}
  </div>
  <div class="loading-wrapper" *ngIf="isLoadingGeneral">
    <div class="w-100">
      <div class="w-50">
        <app-loader-spinner-general
          [repeat]="5"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <div class="w-50">
        <app-loader-spinner-general
          [repeat]="4"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
    </div>
  </div>
  <form [formGroup]="form" *ngIf="!isLoadingGeneral && form && expat">
    <div class="modal-body-custom">
      <div class="row">
        <div class="form-group w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "LABEL.FIRST-NAME" | translate }} *</mat-label>
            <input matInput formControlName="first_name" />
            <mat-error *ngIf="form.controls.first_name.errors?.required">
              {{ "GENERALS.FIELD-REQUIRED" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "LABEL.LAST-NAME" | translate }} *</mat-label>
            <input matInput type="text" formControlName="last_name" />
            <mat-error *ngIf="form.controls.last_name.errors?.required">
              {{ "GENERALS.FIELD-REQUIRED" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "LABEL.DEPARTMENT" | translate }} *</mat-label>
            <input matInput formControlName="department_name" />
            <mat-error *ngIf="form.controls.department_name.errors?.required">
              {{ "GENERALS.FIELD-REQUIRED" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label
              >{{ "LABEL.POSITION-IN-COMPANY" | translate }} *</mat-label
            >
            <input matInput type="text" formControlName="company_position" />
            <mat-error *ngIf="form.controls.company_position.errors?.required">
              {{ "GENERALS.FIELD-REQUIRED" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group w-50">
          <mat-form-field
            appearance="outline"
            class="w-100"
            [ngClass]="{ 'm-b-15': !!form.controls.email.errors }"
          >
            <mat-label>{{ "LABEL.EMAIL" | translate }} *</mat-label>
            <input matInput type="text" formControlName="email" />
            <mat-error *ngIf="form.controls.email.errors?.required">
              {{ "GENERALS.FIELD-REQUIRED" | translate }}
            </mat-error>
            <mat-error *ngIf="form.controls.email.errors?.email">
              {{ "GENERALS.EMAIL-INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group w-50">
          <div class="row">
            <div class="form-group w-50">
              <mat-form-field
                appearance="outline"
                class="w-100"
                [ngClass]="{ 'm-b-25': !!form.controls.phone_prefix.errors }"
              >
                <mat-label
                  >{{ "GENERALS.SELECT-PHONE-CODE" | translate }} *</mat-label
                >
                <input
                  type="text"
                  matInput
                  formControlName="phone_prefix"
                  [matAutocomplete]="autoPrefix"
                />
                <mat-autocomplete #autoPrefix="matAutocomplete">
                  <mat-option
                    *ngFor="let pre of filteredPrefix"
                    [value]="pre.phone_code"
                  >
                    {{ pre.phone_code }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="form.controls.phone_prefix.errors?.match">
                  {{ "EXPATS.PHONE-PREFIX-MATCH" | translate }}
                </mat-error>
                <mat-error *ngIf="form.controls.phone_prefix.errors?.required">
                  {{ "GENERALS.FIELD-REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="form.errors?.isDependent">
                {{ "EXPATS.PHONE-PREFIX" | translate }}
              </mat-error>
            </div>

            <div class="form-group w-50">
              <mat-form-field
                appearance="outline"
                class="w-100"
                [ngClass]="{ 'm-b-25': !!form.controls.phone_prefix.errors }"
              >
                <input matInput formControlName="phone" />
                <mat-error
                  *ngIf="form.controls.phone.errors?.pattern"
                  class="field-message"
                  >{{ "VALIDATIONS.NUMERIC-INPUT" | translate }}
                </mat-error>
                <mat-error *ngIf="form.controls.phone_prefix.errors?.match">
                  {{ "EXPATS.PHONE-PREFIX-MATCH" | translate }}
                </mat-error>
                <mat-error *ngIf="form.controls.phone.errors?.required">
                  {{ "GENERALS.FIELD-REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="form.errors?.isDependent">
                {{ "EXPATS.PHONE-PREFIX" | translate }}
              </mat-error>
            </div>
          </div>
        </div>

        <div class="form-group w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "PORTAL.ADULTS" | translate }}</mat-label>
            <input type="number" matInput formControlName="adults_number" />
            <mat-error *ngIf="form.controls.adults_number.errors?.pattern">
              {{ "VALIDATIONS.NUMERIC-INPUT" | translate }}
            </mat-error>
            <mat-error *ngIf="form.controls.adults_number.errors?.required">
              {{ "GENERALS.FIELD-REQUIRED" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "PORTAL.CHILDREN" | translate }}</mat-label>
            <input type="number" matInput formControlName="children_number" />
            <mat-error *ngIf="form.controls.children_number.errors?.pattern">
              {{ "VALIDATIONS.NUMERIC-INPUT" | translate }}
            </mat-error>
            <mat-error *ngIf="form.controls.children_number.errors?.required">
              {{ "GENERALS.FIELD-REQUIRED" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group w-50">
          <mat-form-field
            appearance="outline"
            class="w-100"
            [ngClass]="{ 'm-b-15': !!form.controls.country_code.errors }"
          >
            <mat-label>{{ "LABEL.RESIDENCE-COUNTRY" | translate }} *</mat-label>
            <mat-select
              formControlName="country_code"
              [placeholder]="'LABEL.COUNTRY' | translate"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'LABEL.COUNTRY' | translate"
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  [formControl]="countryFilterControl"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let country of filteredCountries"
                [value]="country.country_code"
              >
                {{ country.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="form.controls.country_code.errors?.required">
              {{ "GENERALS.FIELD-REQUIRED" | translate }}
            </mat-error>
            <mat-error
              class="m-b-10"
              *ngIf="form.controls.country_code.errors?.match"
            >
              {{ "EXPATS.COUNTRY-MATCH" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group w-50 m-l-10" *ngIf="!edit">
          <label
            >{{ "GENERALS.UPLOAD-PHOTO" | translate }}
            <span appHoverHelper class="input-helper"
              ><i class="icon-help_comp"></i>
              <span class="helper-description">{{
                "GENERALS.USE-SQUARE-PHOTO" | translate
              }}</span>
            </span>
          </label>

          <div class="upload-photo-wrapper">
            <div class="uplaod-photo-box">
              <img [src]="imgURL" *ngIf="imgURL" />
              <span class="orange" *ngIf="message && !imgURL">{{
                message
              }}</span>
            </div>
            <label *ngIf="!imgURL" class="upload-files">
              <div class="btn-small l-h-1-5 orange-bg">
                <i class="icon-upload-cloud"></i
                >{{ "GENERALS.ADD-PHOTO" | translate }}
              </div>
              <input
                #file
                type="file"
                accept="image/*"
                (change)="preview(file.files)"
              />
            </label>
            <label *ngIf="imgURL" class="upload-files">
              <div
                (click)="removeUploaded()"
                class="btn-small l-h-1-5 orange-bg"
              >
                <i class="icon-trash"></i
                >{{ "GENERALS.REMOVE-PHOTO" | translate }}
              </div>
            </label>
          </div>
          <div class="clear"></div>
          <p class="m-t-10">
            {{ "GENERALS.UPLOAD-RESTRICTION-PHOTO" | translate }}
          </p>
        </div>

        <div class="form-group w-50" *ngIf="edit">
          <div class="empty-container"></div>
        </div>
      </div>
    </div>
  </form>
  <div class="clear"></div>

  <div class="modal-footer-custom">
    <button class="btn gray-bg fl-l" (click)="emitChanges('cancel')">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button class="btn orange-bg fl-r" (click)="emitChanges('save')">
      {{ "GENERALS.SAVE" | translate }}
    </button>
    <button
      *ngIf="!edit"
      type="button"
      class="btn orange-bg"
      (click)="emitChanges('another')"
    >
      {{ "MARKETPLACE.ADD-ANOTHER" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>

<ngx-smart-modal
  id="addRFQOffer"
  #addRFQOffer
  identifier="addRFQOffer"
  class="fx-column"
  [dismissable]="false"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "RFQS.ADD-OFFER" | translate }}
    </div>
  </div>
  <div class="clear"></div>
  <!-- Modal body -->
  <div class="custom-modal-body" *ngIf="providers">
    <div class="row" *ngIf="offer">
      <div class="w-100">
        <div class="modal-other-info-list">
          <p>
            <span class="inner-label">
              {{ "GENERALS.SERVICE-CATEGORY" | translate }}:
            </span>
            {{ offer.category_name }}
          </p>
          <p>
            <span class="inner-label">
              {{ "GENERALS.QUANTITY" | translate }}:
            </span>
            {{ offer.quantity }}
          </p>
          <p>
            <span class="inner-label">
              {{ "GENERALS.SERVICE-DESCRIPTION" | translate }}:
            </span>
            {{ offer.service_description }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-100">
        <label
          >{{ "GENERALS.PROVIDER" | translate }} *
          <!-- <span appHoverHelper class="input-helper"
            ><i class="icon-help_comp"></i>
            <span class="helper-description">test text helper</span>
          </span> -->
        </label>
        <ng-select
          class="customiz-select"
          [items]="providers"
          bindLabel="company_name"
          bindValue="provider_id"
          placeholder="{{ 'GENERALS.SELECT-PROVIDER' | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [(ngModel)]="item.provider_id"
          (ngModelChange)="getProviderOffers()"
        >
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="w-100" *ngIf="categories">
        <app-category-selection
          [categories]="categories"
          [level]="0"
          [clear]="clear"
          (categorySelected)="item.category_id = $event.id; getProviderOffers()"
        ></app-category-selection>
      </div>
    </div>
    <div class="row">
      <div class="form-group w-50">
        <label>
          {{ "GENERALS.SERVICEOFFERS" | translate }} *

          <!-- <span appHoverHelper class="input-helper"
            ><i class="icon-help_comp"></i>
            <span class="helper-description">test text helper</span>
          </span> -->
        </label>
        <ng-select
          class="customiz-select"
          [items]="offers"
          bindLabel="title"
          bindValue="id"
          placeholder="{{ 'RFQS.SELECT-OFFERS' | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [(ngModel)]="item.offer_id"
        >
        </ng-select>
      </div>
      <div class="form-group w-50">
        <label
          >{{ "GENERALS.QUANTITY" | translate }} *

          <!-- <span appHoverHelper class="input-helper"
            ><i class="icon-help_comp"></i>
            <span class="helper-description">test text helper</span>
          </span> -->
        </label>
        <input
          type="number"
          name="price"
          class="form-control"
          [(ngModel)]="item.quantity"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group w-50">
        <label
          >{{ "GENERALS.PRICE" | translate }} *

          <!-- <span appHoverHelper class="input-helper"
            ><i class="icon-help_comp"></i>
            <span class="helper-description">test text helper</span>
          </span> -->
        </label>
        <input
          type="number"
          name="price"
          class="form-control"
          [(ngModel)]="item.price"
        />
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button
      type="button"
      class="btn gray-bg"
      (click)="addRFQOffer.close(); close()"
    >
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button type="button" class="btn orange-bg" (click)="emitChanges()">
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</ngx-smart-modal>

import { Directive, HostBinding, HostListener } from "@angular/core";

@Directive({
  selector: "[toggle-row]",
})
export class ToggleRowDirective {
  @HostBinding("class.sub-is-open") isOpen = false;

  @HostListener("click") toggleOpen($event) {
    this.isOpen = !this.isOpen;
  }
}

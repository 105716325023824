import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-statistics-date-filter",
  templateUrl: "./statistics-date-filter.component.html",
})
export class StatisticsDateFilterComponent {
  @Input() statusId: number;

  @Output() selectedDate: EventEmitter<any> = new EventEmitter();

  options = [
    {
      id: 1,
      text: "Last 7 days",
      dropdown: "last-week",
    },
    {
      id: 2,
      text: "Month to date",
      dropdown: "month-to-date",
    },
    {
      id: 3,
      text: "Last month",
      dropdown: "last-month",
    },
    {
      id: 4,
      text: "Year to date",
      dropdown: "year-to-date",
    },
    {
      id: 5,
      text: "Last year",
      dropdown: "last-year",
    },
    // {
    //   id: 6,
    //   text: 'Custom'
    // }
  ];

  dateModel;

  selectedTime(selection) {
    switch (selection.dropdown) {
      case "last-week":
        this.selectedDate.emit(this.lastWeek());
        break;
      case "month-to-date":
        this.selectedDate.emit(this.monthToDate());
        break;
      case "last-month":
        this.selectedDate.emit(this.lastMonth());
        break;
      case "year-to-date":
        this.selectedDate.emit(this.yearToDate());
        break;
      case "last-year":
        this.selectedDate.emit(this.lastYear());
        break;

      default:
        console.log("other");
        break;
    }
  }

  formatDate(date) {
    const formatted_date =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return formatted_date;
  }

  lastWeek() {
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );

    const params = {
      start_date: this.formatDate(lastWeek),
      end_date: this.formatDate(today),
    };
    return params;
  }

  monthToDate() {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    const params = {
      start_date: this.formatDate(firstDay),
      end_date: this.formatDate(today),
    };
    return params;
  }

  lastMonth() {
    const today = new Date();
    const firstDayLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    const params = {
      start_date: this.formatDate(firstDayLastMonth),
      end_date: this.formatDate(lastDayLastMonth),
    };
    return params;
  }

  yearToDate() {
    const today = new Date();
    const firstDayCurentYear = new Date(today.getFullYear(), 0, 1);

    const params = {
      start_date: this.formatDate(firstDayCurentYear),
      end_date: this.formatDate(today),
    };
    return params;
  }

  lastYear() {
    const today = new Date();
    const firstDayLastYear = new Date(today.getFullYear() - 1, 0, 1);
    const lastDayLastYear = new Date(today.getFullYear() - 1, 11, 31);

    const params = {
      start_date: this.formatDate(firstDayLastYear),
      end_date: this.formatDate(lastDayLastYear),
    };
    return params;
  }
}

import { Component, OnInit } from "@angular/core";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "src/app/_services/alert.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { ActivatedRoute, Router } from "@angular/router";
import cloneDeepWith from "lodash-es/cloneDeepWith";
import deepCastToInt from "../../../../_helpers/deepCastToInt";
import cloneDeep from "lodash-es/cloneDeep";
import { TaxRatesService } from "src/app/_services/tax-rates.service";
import { EditTaxRatesComponent } from "../edit-tax-rates/edit-tax-rates.component";
import { forkJoin } from "rxjs";
import { Tax, CountryTax } from "src/app/_models/tax";
import { finalize } from "rxjs/operators";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-tax-rates",
  templateUrl: "./tax-rates.component.html",
  styleUrls: ["./tax-rates.component.scss"],
})
export class TaxRatesComponent extends ItemsListComponent implements OnInit {
  items: CountryTax[];
  taxTypes: Tax[];
  request_call;
  availableFilters;
  isXs: boolean = false;
  isFiltersLoaded: boolean = false;
  hasFilters = false;

  constructor(
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private service: TaxRatesService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.route.queryParams.subscribe((query) => {
      if (query && Object.keys(query).length > 0) {
        this.filters = cloneDeepWith(query, deepCastToInt);
      } else {
        this.clearFilters();
        const params = this.getParams(1);
        this.filters = params;
      }
      if (this.filters["search_text"]) {
        this.search_text = this.filters["search_text"];
      }
      if (this.filters["per_page"]) {
        this.perPage = this.filters["per_page"];
      }
      if (this.filters["order"]) {
        this.active = this.filters["order"];
        this.direction = this.filters["order_direction"];
      } else {
        this.active = "";
        this.direction = "";
      }

      this.getItems();
    });
    this.showBulkActions = false;
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/clients", "clients")
    );
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    const url = "/cms/tax-rates";

    this.router.navigate([url], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = {};
    params = cloneDeep(this.filters);
    this.isLoadingGeneral = true;

    this.request_call = forkJoin({
      taxCountries: this.service.getTaxCountries(params),
      taxTypes: this.service.getTaxTypes(),
    })
      .pipe(
        finalize(() => {
          this.isLoadingGeneral = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe({
        next: (joinRes) => {
          this.processResponse({ result: joinRes.taxCountries });
          this.hasFilters = joinRes.taxCountries.filters.length > 0;
          this.isFiltersLoaded = true;
          this.taxTypes = joinRes.taxTypes.tax_types;
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  public openDialog(tax: CountryTax): void {
    const dialogRef = this.dialog.open(EditTaxRatesComponent, {
      data: tax,
    });

    dialogRef.afterClosed().subscribe({
      next: () => {
        this.getItems();
      },
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

import { Component, OnDestroy } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { ProviderInvite } from "../../../../_models/provider-invite";
import { AlertService } from "../../../../_services/alert.service";
import { ProvidersService } from "../../../../_services/providers.service";

@Component({
  selector: "app-provider-invite",
  templateUrl: "./provider-invite.component.html",
})
export class ProviderInviteComponent implements OnDestroy {
  setModalOpen = true;
  isCanceled = false;
  isLoadingGeneral = false;
  providerInvite: ProviderInvite = new ProviderInvite();

  private _onDestroy = new Subject<void>();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private service: ProvidersService,
    private alertService: AlertService
  ) {
    this.setModalOpen = false;
  }

  initEmpty(): void {
    this.providerInvite = new ProviderInvite();
  }

  sendProviderInvites() {
    this.service.inviteProvider(this.providerInvite).subscribe(
      (data) => {
        this.isModalClose();
        this.ngxSmartModalService.close("inviteProvider");
      },
      (error) => {
        this.alertService.errors(error.error.errors);
      }
    );
  }

  isModalOpen() {
    this.setModalOpen = true;
    this.isCanceled = false;
  }

  isModalClose() {
    this.setModalOpen = false;
  }
  cancelInviteModal() {
    this.isModalClose();
    this.ngxSmartModalService.close("inviteProvider");
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

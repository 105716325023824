import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { ServiceCategory } from "../_models/servicecategory";
import { DocumentationStandard } from "../_models/documentation-standard";
import cloneDeep from "lodash-es/cloneDeep";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ServiceCategoriesService {
  private serviceCategoriesUrl = "offers/serviceCategories";

  constructor(private http: HttpClient) {}

  // GET Service Categories
  getServiceCategories(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "offers/categories",
      { params: params }
    );
  }

  /**
   * Get the service category tree
   */
  getCategoryTree(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "offers/serviceCategories/forMenu"
    );
  }

  getCategoriesForProvider(providerId): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}entities/providers/${providerId}/categories`
    );
  }

  getServiceCategoriesbyId(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceCategoriesUrl + "/" + id
    );
  }

  // POST Create Service Categories
  createServiceCategories(serviceCategories): Observable<any> {
    serviceCategories.details = serviceCategories.data;
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.serviceCategoriesUrl,
        serviceCategories,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // DELETE Service Categories
  deleteServiceCategories(serviceCategories: ServiceCategory): Observable<any> {
    return this.http.delete(
      environment.gateway_endpoint +
        this.serviceCategoriesUrl +
        "/" +
        serviceCategories.id,
      httpOptions
    );
  }

  // Activate Service Categories
  activateServiceCategories(
    serviceCategories: ServiceCategory
  ): Observable<any> {
    return this.http.post(
      environment.gateway_endpoint +
        this.serviceCategoriesUrl +
        "/" +
        serviceCategories.id +
        "/restore",
      httpOptions
    );
  }

  /**
   * Update a master category
   * @param category
   */
  public update(category: ServiceCategory): Observable<any> {
    // we only need to send the data of the current category to the backend
    // without the children
    const _category = cloneDeep(category);
    _category.children = [];
    _category.details = category.data;
    return this.http.put(
      `${environment.gateway_endpoint}offers/categories/${_category.id}`,
      _category,
      httpOptions
    );
  }

  // GET List of documentation standards
  getDocumentationStandardsList(service_category_id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.serviceCategoriesUrl +
        "/getDocumentationStandard" +
        "/" +
        service_category_id
    );
  }

  // POST change action  - documentation standards (checkbox)
  saveDocumentationStandards(
    documentationStandard: DocumentationStandard
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          this.serviceCategoriesUrl +
          "/documentationStandards",
        documentationStandard,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Update multiple categories
   */
  bulkUpdateCategories(categories): Observable<any> {
    return this.http.post(
      environment.gateway_endpoint + this.serviceCategoriesUrl + "/bulk",
      categories,
      httpOptions
    );
  }

  /**
   * Download Template
   *
   */
  downloadTemplate(service_category_id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        "offers/serviceCategories/" +
        service_category_id +
        "/importTemplate",
      { responseType: "blob" as const }
    );
  }
}

import { Location } from "@angular/common";
import { Component, Input, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { Customer } from "src/app/_models/customer";
import { AlertService } from "src/app/_services/alert.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { environment } from "src/environments/environment";
import { User } from "../../../../_models/user";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { UsersService } from "../../../../_services/users.service";
import { AddUserFormComponent } from "../add-user-form/add-user-form.component";
import { HubspotDialogComponent } from "../hubspot-dialog/hubspot-dialog.component";

@Component({
  selector: "app-user-details-page",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.css"],
})
export class UserDetailsComponent implements OnDestroy {
  @Input() set entity(value) {
    if (value) {
      switch (value.type) {
        case "provider":
          this.setup(value.id, "provider", "providersService", "getProvider");
          break;
        case "client":
          this.setup(value.id, "client", "clientsService", "getClient");
          break;
      }
    }
  }
  isLoadingGeneral;
  id;
  user;
  userType;
  primaryAccount;
  isCanceled;
  edit = true;
  about;
  userInfo: User;
  service;
  user$;
  actionsDetails;
  customer: Customer;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public providersService: ProvidersService,
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private matDialog: MatDialog,
    private userService: UsersService,
    private breadcrumbService: BreadcrumbService,
    public dictionariesService: DictionariesService
  ) {
    this.isLoadingGeneral = true;
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params.id) {
        if (this.location.path().indexOf("provider") > -1) {
          this.setup(params.id, "provider", "providersService", "getProvider");
        } else if (this.location.path().indexOf("client") > -1) {
          this.setup(params.id, "client", "clientsService", "getClient");
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  setup(id, type, service, method) {
    this.id = id;
    this.userType = type;
    this.service = service;
    this.user$ = this[service][method](id);
    this.actionsDetails = {
      service: this.service,
      method: method,
      id: id,
      userType: type,
    };
    this.getEntityDetails();
  }

  getEntityDetails() {
    this.user$
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe((data) => {
        this.user = data.result;
        if (this.userType === "provider") {
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForProvider(this.user, "details")
          );
        } else {
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForClient(this.user, "details")
          );
        }
        this.setPrimaryAccount(data.result.users);
      });
  }

  viewSubscription() {
    window.open(
      environment.stripe_view_subscription_url +
        this.customer.subscription.stripe_subscription_id
    );
  }

  setPrimaryAccount(data) {
    this.primaryAccount = data.filter((f) => f.is_primary === true)[0];
    this.userInfo = cloneDeep(
      data.filter((f) => f.is_primary === true)[0]["user"]
    );
    const props = Object.keys(this.user);
    props.forEach((p) => {
      if (
        !this.primaryAccount[p] &&
        p !== "users" &&
        this.user[p] &&
        this.userInfo[p]
      ) {
        this.userInfo[p] = this.user[p];
      }
    });
  }

  openHubspotModal() {
    const dialogRef = this.matDialog.open(HubspotDialogComponent, {
      data: {
        entity: this.user,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result.entity) {
          this.user = result.entity;
        }
      });
  }

  openEditModal(info) {
    this.about = info;

    this.matDialog
      .open(AddUserFormComponent, {
        data: {
          userType: this.userType,
          user: this.userInfo,
          isCanceled: this.isCanceled,
          edit: this.edit,
          about: this.about,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user: User) => {
          if (user) {
            if (this.about === "account") {
              this.updateAccountDetails();
            } else if (this.about === "company") {
              this.updateCompanyDetails();
            }
          } else {
            this.cancelEditModal();
          }
        },
      });
  }

  cancelEditModal() {
    this.userInfo = null;
    this.setPrimaryAccount(this.user.users);
    this.isCanceled = true;
  }

  updateAccountDetails() {
    this.userService
      .updateUserAccount(this.userInfo)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.cancelEditModal();
          this.getEntityDetails();
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  updateCompanyDetails() {
    this[this.service]
      .updateCompanyDetails(this.userInfo)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.cancelEditModal();
          this.getEntityDetails();
        },
        (error) => {
          if (error && error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  switchToAccount(e) {
    this.ngxSmartModalService.getModal("switchAccount").close();
    const params = {};
    params[this.userType + "_id"] = this.id;
    params["password"] = e;
    this[this.service].switchToAccount(params).subscribe(
      (res) => {
        this.ngxSmartModalService.getModal("switchAccount").close();
      },
      (error) => {
        if (error && error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }
}

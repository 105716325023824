import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { filter } from "rxjs/operators";
import { User } from "src/app/_models/user";
import { UsersService } from "src/app/_services/users.service";
import { Dictionary } from "../../../../_models/dictionary";
import { DictionariesService } from "../../../../_services/dictionaries.service";

@Component({
  selector: "app-add-user-step1",
  templateUrl: "./add-user-step1.component.html",
})
export class AddUserStep1Component implements OnInit, OnChanges {
  @Input() user: User;
  @Input() edit = false;
  @Input() isCanceled: boolean;
  @Input() loaderAnim: boolean;
  @Input() userType: string;

  isLoadingGeneral = {};
  phoneCode;
  phoneNumber;
  prefix;
  countries;
  departments: Dictionary;
  phone_prefix;
  roles;
  constructor(
    private dictionariesService: DictionariesService,
    private userService: UsersService
  ) {}

  form: UntypedFormGroup;

  ngOnInit() {
    this.getDepartments();
    // this.getCountryList();
    if (this.userType !== "expat") {
      this.getUserRoles();
    }

    this.form = new UntypedFormGroup({
      phone_prefix: new UntypedFormControl(""),
    });

    // if (this.edit) {
    //   this.form.patchValue({ phone_prefix: this.user.phone_prefix });
    // }

    this.form
      .get("phone_prefix")
      .valueChanges.pipe(filter((value) => value))
      .subscribe((value) => {
        this.user.phone_prefix = value;
      });
  }

  getUserRoles() {
    this.userService.getUserRoles(this.userType).subscribe((res) => {
      this.roles = res.result;
    });
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
        this.prefix = data.result;
        this.prefix.map((p) => {
          if (
            this.edit &&
            this.user.phone_prefix &&
            this.dictionariesService.getPhonePrefix(this.user.phone_prefix) ===
              this.dictionariesService.getPhonePrefix(p.phone_code)
          ) {
            p.phone_code =
              this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
            this.setPrefix(p);
            this.phone_prefix = p.phone_code;
          } else {
            p.phone_code =
              this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
          }
        });
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.isCanceled && changes.isCanceled.currentValue) {
      this.phoneCode = null;
      this.phoneNumber = null;
      this.phone_prefix = null;
    }
  }

  getDepartments() {
    this.dictionariesService.getDepartments().subscribe((data) => {
      this.departments = data.result;
    });
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setPrefix(e) {
    if (e) {
      const code = e.phone_code.match(/\((.*?)\)/);
      this.user.phone_prefix = code[1];
    }
  }
}

import { Component } from "@angular/core";
import { Pricing } from "src/app/_models/_menu/pricing";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.css"],
})
export class PricingComponent {
  menu: Pricing;

  constructor() {
    this.menu = new Pricing();
  }
}

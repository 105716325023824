<div class="details-body-wrapper">
  <div *ngIf="!id">
    <app-loader-spinner-general
      class="d-flex justify-content-end"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      [loadingType]="LoadingType.FILTER"
    ></app-loader-spinner-general>
    <div class="m-t-15"></div>
    <app-loader-spinner-general
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
  </div>
  <ng-container *ngIf="id">
    <app-documents
      [entityType]="'orders'"
      [entityId]="id"
      [hideTitle]="true"
      [docType]="'docs'"
    ></app-documents>
  </ng-container>
</div>

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import Echo from "laravel-echo";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { ShareDataService } from "src/app/_services/share-data.service";
import { UsersService } from "src/app/_services/users.service";
import { environment } from "src/environments/environment";
import { User } from "../../../../_models/user";
import { CurrentUserService } from "../../../../_services/current.user.service";
import { AppState } from "../../state";

@Component({
  selector: "app-top-menu",
  templateUrl: "./top-menu.component.html",
})
export class TopMenuComponent implements OnInit {
  public user: User;
  public userMenuIsOpen = false;
  public isLoggedInUser = false;

  public echo = new Echo({
    broadcaster: "pusher",
    key: environment.pusher_key,
    wsHost: environment.wsHost,
    wsPort: environment.wsPort,
    wssPort: environment.wsPort,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
  });
  innerWidth;
  xs;
  userPic;

  constructor(
    private service: UsersService,
    public authService: AuthenticationService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private shareDataService: ShareDataService,
    private currentUserService: CurrentUserService
  ) {
    this.innerWidth = window.innerWidth;
    this.xs = this.innerWidth <= 992;

    this.shareDataService._sendData.subscribe((data) => {
      if (data === "newProfilePicture") {
        this.getCurrentUser();
        this.getUserPic();
      }
    });
  }

  ngOnInit() {
    this.user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );

    if (this.user) {
      this.isLoggedInUser = true;
      this.getCurrentUser();
      this.getUserPic();
    }
  }

  getCurrentUser() {
    this.user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
    this.currentUserService.userChanges$.subscribe((data) => {
      if (data) {
        this.user = data;
        localStorage.setItem(
          environment.user_local_storage_key,
          JSON.stringify(this.user)
        );
      }
    });
  }

  getUserPic() {
    this.service.getUserDetails(this.user.id).subscribe((res) => {
      this.userPic = res.result;
    });
  }

  logout() {
    this.authService.logout().toPromise().then().catch();
  }

  onResize() {
    this.innerWidth = window.innerWidth;

    this.xs = this.innerWidth <= 992;
  }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PageData } from "../_models/page.model";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Tax, CountryTax } from "../_models/tax";
import { ValueList } from "../modules/shared/models/value-list.model";

@Injectable({
  providedIn: "root",
})
export class TaxRatesService {
  constructor(private http: HttpClient) {}

  // Get all tax countries
  public getTaxCountries(params: ValueList): Observable<PageData<CountryTax>> {
    return this.http.get<PageData<CountryTax>>(
      environment.gateway_endpoint + "countries/taxes",
      {
        params: params,
      }
    );
  }

  // Get Tax Types
  public getTaxTypes(): Observable<{ tax_types: Tax[] }> {
    return this.http.get<{ tax_types: Tax[] }>(
      environment.gateway_endpoint + "countries/taxes/types"
    );
  }

  // Set country taxes
  public setCountryTaxes(tax: Tax): Observable<Tax> {
    return this.http.post<Tax>(
      environment.gateway_endpoint +
        `countries/taxes/${tax.country_code}/type/${tax.tax_type}`,
      {
        value: tax.value,
      }
    );
  }
}

<app-loader-spinner-general
  [isLoadingGeneral]="isLoadingGeneral"
></app-loader-spinner-general>

<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div
  windowScrollDashboardBodyTop
  class="dashboard-body-top"
  *ngIf="serviceCategory"
>
  <!-- left -->
  <div class="fl-l big-title">
    <span
      *ngFor="let breadcrumb of serviceCategory.breadcrumbs; let last = last"
    >
      {{ breadcrumb.label }}
      <i *ngIf="!last" class="icon-angle-right"></i>
    </span>
    <!-- (  {{ 'GENERALS.DOCUMENTATION-STANDARDS' | translate }} ) -->
  </div>
  <!-- end left -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>{{ "GENERALS.SECTION-NAME" | translate }}</th>
            <th>{{ "GENERALS.SECTION-TYPE" | translate }}</th>
            <th>{{ "GENERALS.REQUIRED" | translate }}</th>
            <th>{{ "GENERALS.ACTIVE" | translate }}</th>
            <th>{{ "GENERALS.INFO" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let documentationStandard of documentationStandardsList
                | sortBy : 'asc' : 'name';
              let i = index
            "
          >
            <td>{{ documentationStandard.name }}</td>
            <td class="section-type">
              <div *ngFor="let field of documentationStandard.fields">
                <ng-container [ngSwitch]="field.type">
                  <i *ngSwitchCase="'text'" class="icon-financial_comp"></i>
                  <i *ngSwitchCase="'select'" class="icon-dashboard"></i>
                  <i *ngSwitchCase="'checkbox'" class="icon-clients_comp"></i>
                </ng-container>
                {{ field.type }}
              </div>
            </td>
            <td>
              <input
                class="checkbox-switch"
                [class.disabled]="!documentationStandard.enabled"
                [disabled]="!documentationStandard.enabled"
                (change)="saveDocumentationStandards(documentationStandard)"
                [(ngModel)]="documentationStandard.required"
                type="checkbox"
                name="required"
              />
            </td>
            <td>
              <input
                class="checkbox-switch"
                (change)="saveDocumentationStandards(documentationStandard)"
                [(ngModel)]="documentationStandard.enabled"
                type="checkbox"
                name="enabled"
              />
            </td>
            <td class="help-table">
              <i class="icon-help_comp"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->

    <div
      class="resposive-table-wrapper"
      *ngFor="
        let documentationStandard of documentationStandardsList;
        let i = index
      "
    >
      <div class="responsive-table-row">
        <div class="responsive-table-header-item">
          {{ "GENERALS.SECTION-NAME" | translate }}
        </div>
        <div class="responsive-table-data-item">
          {{ documentationStandard.name }}
        </div>
      </div>
      <div class="responsive-table-row">
        <div class="responsive-table-header-item">
          {{ "GENERALS.SECTION-TYPE" | translate }}
        </div>
        <div class="responsive-table-data-item">
          <div *ngFor="let field of documentationStandard.fields">
            <ng-container [ngSwitch]="field.type">
              <i *ngSwitchCase="'text'" class="icon-financial_comp"></i>
              <i *ngSwitchCase="'select'" class="icon-dashboard"></i>
              <i *ngSwitchCase="'checkbox'" class="icon-clients_comp"></i>
            </ng-container>
            {{ field.type }}
          </div>
        </div>
      </div>
      <div class="responsive-table-row">
        <div class="responsive-table-header-item">
          {{ "GENERALS.REQUIRED" | translate }}
        </div>
        <div class="responsive-table-data-item">
          <input
            class="checkbox-switch"
            [class.disabled]="!documentationStandard.enabled"
            [disabled]="!documentationStandard.enabled"
            (change)="saveDocumentationStandards(documentationStandard)"
            [(ngModel)]="documentationStandard.required"
            type="checkbox"
            name="required"
          />
        </div>
      </div>
      <div class="responsive-table-row">
        <div class="responsive-table-header-item">
          {{ "GENERALS.ACTIVE" | translate }}
        </div>
        <div class="responsive-table-data-item">
          <input
            class="checkbox-switch"
            (change)="saveDocumentationStandards(documentationStandard)"
            [(ngModel)]="documentationStandard.enabled"
            type="checkbox"
            name="enabled"
          />
        </div>
      </div>
      <div class="responsive-table-row">
        <div class="responsive-table-header-item">
          {{ "GENERALS.INFO" | translate }}
        </div>
        <div class="responsive-table-data-item">description info...</div>
      </div>
    </div>
    <!-- end responsive table -->
  </ng-template>
</div>
<!-- end table -->

<ng-container *ngIf="!offer">
  <div class="wrapper-profile m-t-20">
    <div class="w-profile">
      <app-loader-spinner-general
        [loadingType]="LoadingType.PROFILE"
      ></app-loader-spinner-general>
      <div class="status-loader">
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
          [repeat]="3"
        ></app-loader-spinner-general>
      </div>
    </div>
    <app-loader-spinner-general
      class="w-info h-100"
      [repeat]="6"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
  </div>
</ng-container>

<div class="details-body-wrapper review-offer-wrapper" *ngIf="offer">
  <div class="details-2c">
    <!-- left -->
    <div class="details-2c-left m-r-15">
      <div class="small-box">
        <div class="small-box-header-img">
          <img [src]="offer.primary_image_path" alt="img" />
        </div>
        <!-- <div class="small-box-header-img">
          <img src="../../../assets/images/footer-img.jpg" alt="img" />
        </div> -->
        <div class="small-box-details">
          <div class="medium-title">{{ offer.title }}</div>
          <div class="small-box-status">
            <div class="services-status-label-{{ offer.status_id }}">
              {{ offer.status_label }}
            </div>
          </div>
          <div
            *ngIf="offer.status_id === 5 || offer.status_id === 3"
            class="fw-500"
          >
            <span class="gray block-span"
              >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
            >
            {{ offer.decline_message }}
          </div>
          <div class="fw-500">
            <span class="gray block-span"
              >{{ "GENERALS.OFFERED-BY" | translate }}:</span
            >
            {{ offer.provider.company_name }}
          </div>
        </div>

        <div class="small-box-list">
          <ul>
            <ng-container
              *ngIf="offer.status_id === 6 || offer.status_id === 7"
            >
              <li class="switch-check-list">
                <span class="inner-label">
                  {{ "GENERALS.SHOW-MP" | translate }}:
                </span>
                <input
                  class="checkbox-switch"
                  (change)="updateStatus()"
                  [(ngModel)]="offer.active"
                  type="checkbox"
                  name="change-status-switch"
                />
              </li>
            </ng-container>

            <ng-container *ngFor="let action of actions[offer.status_id]">
              <li>
                <div
                  (click)="openConfirmAction(action)"
                  class="btn-small orange-bg"
                >
                  {{ action.text }}
                </div>
              </li>
            </ng-container>

            <li>
              <div (click)="copyOffer(offer.id)" class="btn-small orange-bg">
                {{ "GENERALS.COPY-OFFER" | translate }}
              </div>
            </li>

            <li
              class="action-link-gray"
              *ngIf="!(offer.status_id === 3) && !(offer.status_id === 5)"
              (click)="goToMarketplaceOffer(offer.id)"
            >
              <i class="icon-right-dir"></i>
              {{ "GENERALS.PREVIEW-IN-MARKETPLACE" | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.MODERATE-REVIEWS" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <div
              *ngFor="
                let review of offersReviews?.items
                  | paginate
                    : {
                        itemsPerPage: itemsPage,
                        currentPage: p,
                        totalItems: numOfReviews
                      }
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  userReview;
                  context: { review: review, comment: false }
                "
              ></ng-container>
            </div>

            <ng-template #userReview let-review="review" let-comment="comment">
              <ng-container>
                <div
                  class="review-box-wrapper"
                  [ngClass]="
                    review.parent_id === 0 ? 'review-lvl0' : 'review-lvl1-plus'
                  "
                >
                  <div class="review-box-left">
                    <img
                      alt=""
                      class="user-img"
                      src="../../../assets/images/footer-img.jpg"
                    />
                    <div class="fw-500">
                      {{ review.user.name }}
                    </div>
                    <div class="gray">
                      {{ review.user.company_position }},
                      {{ review.user.company_name }}
                    </div>
                    <div class="gray review-date">
                      {{ review.created_at | date : "dd/MM/yyyy" }}
                    </div>
                  </div>

                  <div class="review-box-right">
                    <p class="big-p">
                      {{ review.title }}
                      <span
                        *ngIf="review.responses.length > 0"
                        class="gray inline-block fs-13"
                        >({{ review.responses.length }}
                        {{ "GENERALS.REPLIES" | translate }})</span
                      >
                    </p>
                    <app-rating
                      *ngIf="!comment"
                      [currentRate]="review.rating"
                    ></app-rating>
                    <div class="review-body">
                      {{ review.review }}

                      <ng-container *ngIf="review.status === 'new'">
                        <div toggle-action-inner class="table-action-wrapper">
                          <i class="icon-dot-3"></i>
                          <div class="tbale-action">
                            <ul>
                              <li (click)="approveOfferReview(review.id)">
                                {{ "GENERALS.ACCEPT-COMMENT" | translate }}
                              </li>
                              <li (click)="deleteOfferReview(review.id)">
                                {{ "GENERALS.DECLINE-COMMENT" | translate }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="review.status === 'approved'">
                        <span class="green-label">{{
                          "GENERALS.COMM-APPROVED" | translate
                        }}</span>
                        <div toggle-action-inner class="table-action-wrapper">
                          <i class="icon-dot-3"></i>
                          <div class="table-action">
                            <ul>
                              <li (click)="deleteOfferReview(review.id)">
                                {{ "GENERALS.DECLINE-COMMENT" | translate }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="review.status === 'declined'">
                        <span class="red-label">{{
                          "GENERALS.COMM-DECLINED" | translate
                        }}</span>

                        <div toggle-action-inner class="table-action-wrapper">
                          <i class="icon-dot-3"></i>
                          <div class="table-action">
                            <ul>
                              <li (click)="approveOfferReview(review.id)">
                                {{ "GENERALS.ACCEPT-COMMENT" | translate }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                    <div
                      *ngIf="review.responses.length > 0"
                      (click)="
                        showCommentId[review.id] = !showCommentId[review.id]
                      "
                      class="view-more"
                    >
                      <ng-container
                        *ngIf="showCommentId[review.id]; else showCommFalse"
                      >
                        {{ "GENERALS.HIDE-COMMENTS" | translate }}
                      </ng-container>
                      <ng-template #showCommFalse>
                        {{ "GENERALS.SEE-COMMENTS" | translate }}
                      </ng-template>
                    </div>
                  </div>

                  <!-- children comments and reply -->
                  <div class="children-comments-wrapper">
                    <div *ngIf="showCommentId[review.id]">
                      <div *ngFor="let comment of review.responses">
                        <ng-container
                          *ngTemplateOutlet="
                            userReview;
                            context: {
                              review: comment,
                              comment: true
                            }
                          "
                        ></ng-container>
                      </div>
                    </div>
                  </div>

                  <!-- end children comments and reply -->
                </div>
              </ng-container>
            </ng-template>

            <div *ngIf="!numOfReviews" class="no-reviews">
              {{ "GENERALS.NO-REVIEWS" | translate }}
            </div>

            <ng-container *ngIf="numOfReviews > 20">
              <div class="paginator" *ngIf="numOfReviews">
                <pagination-controls
                  (pageChange)="getOffersReviews($event)"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- end right -->
  </div>
</div>

<div class="table" *ngIf="loadingType === LoadingTypeEnum.TABLE">
  <div class="ph-item">
    <div class="ph-col-12">
      <div class="td-ph-picture">
        <div class="ph-picture empty"></div>
      </div>

      <div class="ph-row">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
      </div>
    </div>
  </div>
</div>

<div class="filter" *ngIf="loadingType === LoadingTypeEnum.FILTER">
  <div class="ph-item">
    <div class="ph-col-12">
      <div class="td-ph-picture">
        <div class="ph-picture empty"></div>
      </div>

      <div class="ph-row justify-content-between">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-6 empty big"></div>
        <div class="ph-col-2 big"></div>
      </div>
    </div>
  </div>
</div>

<div class="profile" *ngIf="loadingType === LoadingTypeEnum.PROFILE">
  <div class="ph-item">
    <div class="ph-col-12">
      <div class="ph-picture"></div>

      <div class="ph-row justify-content-between">
        <div class="ph-col-2"></div>
        <div class="ph-col-2 empty"></div>
        <div class="ph-col-6"></div>
      </div>
      <div class="ph-row justify-content-between">
        <div class="ph-col-12"></div>
      </div>
      <div class="ph-row justify-content-between">
        <div class="ph-col-6"></div>
        <div class="ph-col-2 empty"></div>
        <div class="ph-col-2"></div>
      </div>
      <div class="ph-row justify-content-between">
        <div class="ph-col-8"></div>
      </div>
    </div>
  </div>
</div>

<div class="info h-100" *ngIf="loadingType === LoadingTypeEnum.INFO">
  <div class="ph-item h-100">
    <div class="ph-col-12">
      <div class="ph-row m-b-20">
        <div class="ph-col-4 big"></div>
      </div>

      <div *ngFor="let i of repeat" class="info-box">
        <div class="ph-row">
          <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-12 big"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="big-info" *ngIf="loadingType === LoadingTypeEnum.BIG_INFO">
  <div class="ph-item">
    <div class="ph-col-12">
      <div class="ph-row">
        <div class="ph-col-2 big"></div>
      </div>

      <div class="info-box m-t-20">
        <div class="ph-row">
          <div class="ph-col-2"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-4 big"></div>
          <div class="ph-col-2 empty big"></div>
          <div class="ph-col-4 big"></div>
        </div>
      </div>
      <div class="info-box m-t-20">
        <div class="ph-row">
          <div class="ph-col-2"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-4 big"></div>
          <div class="ph-col-2 empty big"></div>
          <div class="ph-col-4 big"></div>
        </div>
      </div>
      <div class="info-box m-t-20">
        <div class="ph-row">
          <div class="ph-col-2"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-4 big"></div>
          <div class="ph-col-2 empty big"></div>
          <div class="ph-col-4 big"></div>
        </div>
      </div>
      <div class="info-box m-t-20">
        <div class="ph-row">
          <div class="ph-col-2"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-4 big"></div>
          <div class="ph-col-2 empty big"></div>
          <div class="ph-col-4 big"></div>
        </div>
      </div>
      <div class="info-box m-t-20">
        <div class="ph-row">
          <div class="ph-col-2"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-4 big"></div>
          <div class="ph-col-2 empty big"></div>
          <div class="ph-col-4 big"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="chart" *ngIf="loadingType === LoadingTypeEnum.CHART">
  <div class="ph-item h-100">
    <div class="ph-col-12 fit-content">
      <div class="ph-row">
        <div class="ph-col-4 big"></div>
        <div class="ph-col-2 empty big"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-2 big"></div>
      </div>
    </div>
    <div class="left-side">
      <div class="ph-row h-100 w-100">
        <div class="section-chart">
          <div class="ph-col-2 h-1 big"></div>
          <div class="ph-col-2 h-3 big"></div>
          <div class="ph-col-2 h-2 big"></div>
        </div>
        <div class="section-chart">
          <div class="ph-col-2 h-1 big"></div>
          <div class="ph-col-2 h-1 big"></div>
          <div class="ph-col-2 h-3 big"></div>
        </div>
        <div class="section-chart">
          <div class="ph-col-2 h-3 big"></div>
          <div class="ph-col-2 h-2 big"></div>
          <div class="ph-col-2 h-2 big"></div>
        </div>
      </div>
    </div>
    <div class="right-side">
      <div class="ph-row">
        <div class="ph-col-4 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-8"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-8"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-6"></div>
      </div>
    </div>
  </div>
</div>

<div class="pie" *ngIf="loadingType === LoadingTypeEnum.PIE">
  <div class="ph-item">
    <div class="ph-col-12">
      <div class="d-flex justify-content-between ph-row">
        <div
          class="ph-picture circle d-flex justify-content-center align-items-center"
        >
          <div class="inner-circle"></div>
        </div>
        <div class="right-side">
          <div class="ph-row">
            <div class="ph-col-4 big"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-8"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-6"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-2"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-12"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="buttons" *ngIf="loadingType === LoadingTypeEnum.BUTTONS">
  <div class="ph-item">
    <div class="ph-col-12">
      <div class="ph-row">
        <div class="ph-col-2 big" *ngFor="let i of repeat"></div>
      </div>
    </div>
  </div>
</div>

<div class="info-buttons" *ngIf="loadingType === LoadingTypeEnum.INFO_BUTTONS">
  <div class="ph-item">
    <div class="ph-col-12">
      <div class="section">
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 big"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-8 big"></div>
        </div>
      </div>
      <div class="section">
        <div class="ph-row">
          <div class="ph-col-8 big"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
        </div>
      </div>
      <div class="section">
        <div class="ph-row">
          <div class="ph-col-4 big"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
        </div>
      </div>
      <div class="section">
        <div class="ph-row">
          <div class="ph-col-4 big"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-8 big"></div>
        </div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12 big ph-btn"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12 big ph-btn"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12 big ph-btn"></div>
      </div>
    </div>
  </div>
</div>

<div class="timeline" *ngIf="loadingType === LoadingTypeEnum.TIMELINE">
  <div class="ph-item">
    <div class="ph-col-12" *ngFor="let i of repeat">
      <div class="ph-row">
        <div class="circle-section">
          <div class="ph-col-2 circle"></div>
          <div class="text">
            <div class="ph-col-2"></div>
            <div class="ph-col-4"></div>
          </div>
        </div>
        <div class="box-section">
          <div class="ph-col-2 box" *ngFor="let i of numberToArray(25)"></div>
        </div>
      </div>
      <div class="ph-row">
        <div class="circle-section">
          <div class="circle empty"></div>
          <div class="text">
            <div class="ph-col-2"></div>
            <div class="ph-col-4"></div>
          </div>
        </div>
        <div class="box-section">
          <div class="ph-col-2 box" *ngFor="let i of numberToArray(25)"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="input" *ngIf="loadingType === LoadingTypeEnum.INPUT">
  <div class="ph-item">
    <div class="ph-col-12" *ngFor="let i of repeat">
      <div class="ph-row">
        <div class="ph-col-12 big"></div>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="loadingType === LoadingTypeEnum.CARD">
  <div class="ph-item" *ngFor="let i of repeat">
    <div class="ph-col-12">
      <div class="ph-picture"></div>
      <div class="ph-row m-b-20">
        <div class="ph-col-8 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-2"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-6 big"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-8 big"></div>
      </div>
      <div class="ph-row last-row">
        <div class="ph-col-2 bigger"></div>
        <div class="ph-col-2 bigger"></div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ActivatedRoute, Router } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import cloneDeepWith from "lodash-es/cloneDeepWith";
import { AlertService } from "src/app/_services/alert.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { RfqHelper } from "../../../_helpers/_models/rfq.helper";
import { RFQ } from "../../../_models/rfq";
import { ItemsListComponent } from "../../dashboard/items-list/items-list.component";
import { ClientsService } from "src/app/_services/clients.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { RFQSService } from "src/app/_services/rfqs.service";
import { TranslateService } from "@ngx-translate/core";
import deepCastToInt from "../../../_helpers/deepCastToInt";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmActionModalComponent } from "../../dashboard/confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-rfqs",
  templateUrl: "./rfqs.component.html",
})
export class RFQSComponent extends ItemsListComponent implements OnInit {
  items: RFQ[];
  isXs: boolean = false;
  isFiltersLoaded: boolean = false;
  type;
  clients;
  request_call_client;
  countries;

  constructor(
    private service: RFQSService,
    private dictionariesService: DictionariesService,
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private windowResizeHelper: WindowResizeHelperService,
    private clientService: ClientsService,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    public helper: RfqHelper
  ) {
    super();
    this.route.queryParams.subscribe((query) => {
      this.watchQueryParams(query);
    });

    this.showBulkActions = false;

    this.getCountryList();
    this.getStatusTransitions();
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/rfqs-bids", "RFQs & Bids")
    );
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      this.countries = [];

      data.result.forEach((cl) => {
        this.countries.push({ id: cl.country_code, text: cl.name });
      });
    });
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    let url = "";
    if (id) {
      url = "/rfqs-bids/" + id;
    } else {
      url = "/rfqs-bids";
    }

    this.router.navigate([url], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = {};
    params = cloneDeep(this.filters);
    if (params["client_id"]) {
      params["client_id"] = [params["client_id"]];
    }
    this.isLoadingGeneral = true;

    this.request_call = this.service
      .getRFQS(params)
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe((data) => {
        this.processResponse(data);
        this.isFiltersLoaded = true;
      });
  }

  protected getService() {
    return this.service;
  }

  public getClients(page: number = 1) {
    if (this.request_call_client) {
      this.request_call_client.unsubscribe();
    }
    this.isLoadingGeneral = true;

    this.request_call_client = this.clientService.getAllClients().subscribe(
      (data) => {
        this.isLoadingGeneral = false;
        this.clients = [];

        data.result.forEach((cl) => {
          this.clients.push({
            id: cl.id.toString(),
            text: cl.company_name,
          });
        });
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  setFilteringDate(e) {
    if (e) {
      this.startDate = e.start_date;
      this.endDate = e.end_date;
    }
  }

  public getStatusTransitions() {
    this.service.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  openConfirmAction(action, rfq) {
    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.service
            .updateStatusBulk(action.id, rfq.client_id, rfq.id, result)
            .subscribe(
              (data) => {
                this.getItems();
              },
              (error) => {
                this.isLoadingGeneral = false;
                this.alertService.errors(error.error.errors);
              }
            );
        }
      });
    } else {
      this.service
        .updateStatusBulk(action.id, rfq.client_id, rfq.id, null)
        .subscribe((data) => {
          this.getItems();
        });
    }
  }

  openModal(type) {
    this.type = type;
    this.ngxSmartModalService.getModal("addRFQAndBid").open();
  }

  customSearchFn(term, item) {
    term = term.toLocaleLowerCase();
    return item.company_name.toLocaleLowerCase().indexOf(term) === 0;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { ServiceCategory } from "../../../../_models/servicecategory";
import { SelectionModel } from "@angular/cdk/collections";
import { Commission } from "src/app/_models/providerscommissions";
import cloneDeep from "lodash-es/cloneDeep";
import { AlertService } from "src/app/_services/alert.service";
import { ProviderCommissionEnum } from "../../../../_enums/provider-commission-enum";
import { DateService } from "../../../../_services/date.service";
import * as moment from "moment";

@Component({
  selector: "app-add-commissions",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./add-commissions.component.html",
  styleUrls: ["./add-commissions.component.css"],
})
export class AddCommissionsComponent implements OnInit, OnChanges, OnDestroy {
  selection = new SelectionModel(true, []);
  newCommission;
  editCategoryCommission = {
    id: null,
    idx: null,
  };
  categories: ServiceCategory[];
  setSelection = false;
  providerCommissionEnum = ProviderCommissionEnum;
  minDate;
  singleMonthDate = null;
  multipleMonthDate = null;
  @Input() level: number;
  @Input() set serviceCategories(value) {
    if (value) {
      this.categories = cloneDeep(value);
    }
  }
  @Input() parentIsSelected = false;

  @Output() onselect: EventEmitter<any> = new EventEmitter();

  isLoading: boolean;
  commissionTypes = [
    { id: ProviderCommissionEnum.fixed, label: "Fixed" },
    { id: ProviderCommissionEnum.percent, label: "Percent" },
  ];
  commission: Commission;
  intersectsWith;
  dateTypes = [
    { id: "singleMonth", label: "Only start date" },
    { id: "multipleMonths", label: "Period" },
  ];
  dataType;
  constructor(
    calendar: NgbCalendar,
    private alertService: AlertService,
    private dateService: DateService
  ) {
    this.commission = new Commission();
  }

  ngOnInit() {
    this.minDate = moment().add(1, "d");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentIsSelected && changes.parentIsSelected.previousValue) {
      if (changes.parentIsSelected.currentValue) {
        this.categories.forEach((row) => {
          this.selection.select(row);
        });
      } else {
        this.categories.forEach((row) => {
          const idx = this.selection.selected.find((f) => f.id === row.id);
          if (idx) {
            this.handleEvent(row);
          }
        });
        this.selection.clear();
      }
    } else if (
      changes.parentIsSelected &&
      changes.parentIsSelected.currentValue &&
      !changes.parentIsSelected.firstChange
    ) {
      this.categories.forEach((row) => {
        const idx = this.selection.selected.find((f) => f.id === row.id);
        if (!idx) {
          this.handleEvent(row);
        }
        this.selection.select(row);
      });
    }
  }

  onRemove(category, idx) {
    if (category.commissions.length) {
      category.commissions.splice(idx, 1);
      if (this.selection.selected.find((f) => f.id === category.id)) {
        this.handleEvent(category);
      }
    }
  }

  getDateFormatted(dateString) {
    const date = new Date(dateString);
    return (
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      "-" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      date.getFullYear()
    );
  }

  changeCalendarType(event, _commission) {
    this.multipleMonthDate = null;
    this.singleMonthDate = null;
    _commission.start_date = null;
    _commission.end_date = null;
  }

  setDate(e, category, commission) {
    let start_date;
    let end_date;

    if (this.dataType === "multipleMonths") {
      if (!e.start_date || !e.end_date) {
        return;
      }

      start_date = e.start_date;
      end_date = e.end_date;
      this.multipleMonthDate = {
        start_date: start_date,
        end_date: end_date,
      };
    } else if (this.dataType === "singleMonth") {
      if (!e) {
        return;
      }
      start_date = end_date = e;
      this.singleMonthDate = e;
    }

    commission.start_date = start_date;
    commission.end_date = end_date;

    /* ### Periods overlaps ### */

    // if (
    //   this.newCommission &&
    //   category.commissions &&
    //   category.commissions.length
    // ) {
    //   let newStart;
    //   let newEnd;
    //   if (typeof e === 'string') {
    //     newStart = new Date(e);
    //     newEnd = new Date(e);
    //   } else {
    //     newStart = new Date(start_date);
    //     newEnd = new Date(end_date);
    //   }
    //   category.commissions.forEach(date => {
    //     const start = new Date(date.start_date);
    //     const end = new Date(date.end_date);

    //     if (
    //       (newStart >= start && newStart <= end) ||
    //       (newEnd >= start && newEnd <= end)
    //     ) {
    //       this.intersectsWith = {
    //         start: date.start_date,
    //         end: date.end_date
    //       };
    //       return;
    //     }
    //     this.intersectsWith = null;
    //   });
    // }

    // if (!this.intersectsWith) {
    //   commission.start_date = start_date;
    //   commission.end_date = end_date;
    // }
  }

  addCommission(commission, category) {
    if (!commission || !(Object.keys(commission).length >= 4)) {
      this.alertService.errors([
        {
          message: "All fields are mandatory",
        },
      ]);
      return;
    }
    if (!category.commissions) {
      category.commissions = [];
    }

    const idx = category.commissions.findIndex(
      (f) =>
        f.start_date === commission.start_date &&
        f.end_date === commission.end_date
    );
    if (idx > -1) {
      if (typeof commission.commission === "string") {
        commission.commission = +commission.commission;
      }
    } else {
      category.commissions.push(commission);
    }

    if (this.selection.selected.find((f) => f.id === category.id)) {
      this.handleEvent(category, 1);
    }

    this.initEmpty();
  }

  setEditOptions(category, idx) {
    this.editCategoryCommission = { id: category.id, idx: idx };
    category.commissions[idx].dateRange =
      category.commissions[idx].start_date.split(" ")[0] +
      "  -  " +
      category.commissions[idx].end_date.split(" ")[0];
  }

  onEvent(event) {
    event.stopPropagation();
  }

  initEmpty() {
    this.dataType = null;
    this.newCommission = null;
    this.editCategoryCommission = {
      id: null,
      idx: null,
    };
    this.commission = new Commission();
  }
  /**
   * Change checkbox selection
   */
  changeSelection(item) {
    this.selection.toggle(item);
    this.handleEvent(item);
  }

  handleEvent(item, update = 0) {
    if (!item.children_count) {
      this.onselect.emit({
        selection: item,
        id: item.parent_id ? item.parent_id : "parent",
        update: update,
      });
    }
  }

  trackById(index, item) {
    return item.id;
  }
  ngOnDestroy() {
    this.initEmpty();
    this.intersectsWith = null;
  }
}

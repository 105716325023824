import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private httpOptions;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const sessionCookie = this.cookieService.get(
      environment.session_local_storage_key
    );
    const session = sessionCookie && JSON.parse(sessionCookie);
    if (session && session.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${session.token}`,
        },
      });
    }

    return next.handle(request);
  }
  constructor(private cookieService: CookieService) {}
}

import { SelectionModel } from "@angular/cdk/collections";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { RFQSService } from "src/app/_services/rfqs.service";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { ItemsListComponent } from "../../dashboard/items-list/items-list.component";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-quotations",
  templateUrl: "./quotations.component.html",
  styleUrls: ["../rfq-details/rfq-details.component.scss"],
})
export class QuotationsComponent extends ItemsListComponent {
  id;
  isLoadingGeneral;
  RFQ;
  quotations;
  selectedOffer;
  allSelection = [];
  availableActions = [];
  bulkActions = [];
  refresh;
  constructor(
    private route: ActivatedRoute,
    private rfqService: RFQSService,
    public ngxSmartModalService: NgxSmartModalService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    if (!this.id && this.route.snapshot.parent.params.id) {
      this.id = this.route.snapshot.parent.params.id;
      this.getRFQDetails();
    }
  }

  initSelection() {
    this.items.forEach((sel) => {
      const selection = new SelectionModel(true, []);
      this.allSelection.push(selection);
      this.bulkActions.push(false);
    });
  }

  getRFQDetails() {
    this.isLoadingGeneral = true;
    this.rfqService.getRFQById(this.id).subscribe(
      (res) => {
        this.isLoadingGeneral = false;
        this.RFQ = res.result;
        this.breadcrumbService.changeBreadcrumbs(
          this.breadcrumbService.setForRFQ(this.RFQ, "quotations")
        );
        this.items = res.result.offers_request;
        this.refresh = res.result;
        this.initSelection();
      },
      (error) => {
        this.isLoadingGeneral = false;
      }
    );
  }

  addOfferQuotation(item) {
    this.isLoadingGeneral = true;
    this.rfqService.addOfferQuotation(item, this.selectedOffer.id).subscribe(
      (res) => {
        this.cancelModal();
        this.getRFQDetails();
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  statusOfferQuotation(value, offer) {
    this.isLoadingGeneral = true;

    this.rfqService
      .statusOfferQuotation(
        { accepted_by_client: value, client_id: this.RFQ.client_id },
        offer.id
      )
      .subscribe(
        (res) => {
          offer.accepted_by_client = value;
          this.refresh = value;
          this.RFQ.summary = res.result.summary;
          this.isLoadingGeneral = false;
        },
        (error) => {
          this.isLoadingGeneral = false;
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  customIsAllSelected(idx, item) {
    const numSelected = this.allSelection[idx].selected.length;
    const numRows = item.length;
    return numSelected === numRows;
  }

  customMasterToggle(idx, item) {
    this.customIsAllSelected(idx, item)
      ? this.allSelection[idx].clear()
      : item.forEach((row) => this.allSelection[idx].select(row));
    this.setAvailableActions(idx);
  }

  customChangeSelection(idx, item) {
    this.allSelection[idx].toggle(item);
    this.setAvailableActions(idx);
  }

  setAvailableActions(idx) {
    let statuses = [
      { id: 1, text: "accept" },
      { id: 0, text: "decline" },
    ];
    this.allSelection[idx].selected.forEach((s) => {
      if (s.accepted_by_client !== null) {
        statuses = [];
      }
    });
    this.availableActions = statuses;
    if (this.allSelection[idx].selected.length) {
      this.bulkActions[idx] = true;
    } else {
      this.bulkActions[idx] = false;
    }
  }

  bulkChange(idx, value) {
    this.allSelection[idx].selected.forEach((sel) => {
      this.isLoadingGeneral = true;
      this.statusOfferQuotation(value, sel);
    });
    this.clearSelection(idx);
  }

  clearSelection(idx) {
    this.allSelection[idx] = new SelectionModel(true, []);
    this.bulkActions[idx] = false;
  }

  openModal(offer, category) {
    this.selectedOffer = offer;
    this.selectedOffer.category_name = category.service_category_name;
    this.ngxSmartModalService.getModal("addRFQOffer").open();
  }

  cancelModal() {
    this.ngxSmartModalService.closeLatestModal();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

@Component({
  selector: "app-bulk-upload-errors-modal",
  templateUrl: "./bulk-upload-errors-modal.component.html",
  styleUrls: ["./bulk-upload-errors-modal.component.css"],
})
export class BulkUploadErrorsModalComponent {
  isLoadingGeneral = false;
  @Output() changeErrors: EventEmitter<any> = new EventEmitter();

  constructor(public ngxSmartModalService: NgxSmartModalService) {}

  close() {
    this.ngxSmartModalService.closeLatestModal();
  }

  downloadSpreadsheet() {
    this.changeErrors.emit();
  }
}

import { Component, OnInit } from "@angular/core";
import { ServiceCategoriesService } from "../../../../_services/servicecategories.service";
import { CompareHelper } from "../../../../_helpers/compare.helper";
import { ActivatedRoute } from "@angular/router";
import { DocumentationStandard } from "../../../../_models/documentation-standard";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";

@Component({
  selector: "app-documentation-standards",
  templateUrl: "./documentation-standards.component.html",
})
export class DocumentationStandardsComponent implements OnInit {
  isLoadingGeneral: boolean;
  documentationStandardsList: DocumentationStandard[];
  id;
  serviceCategory;
  isXs: boolean;

  constructor(
    private serviceCategoriesService: ServiceCategoriesService,
    private compareHelper: CompareHelper,
    private route: ActivatedRoute,
    private bs: BreadcrumbService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.service_category_id;
    });
  }

  ngOnInit() {
    this.getDocumentationStandardsList();
    this.getServiceCategoriesById();

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  getDocumentationStandardsList() {
    this.isLoadingGeneral = true;
    this.serviceCategoriesService
      .getDocumentationStandardsList(this.id)
      .subscribe((data) => {
        this.documentationStandardsList = data.result;
        this.isLoadingGeneral = false;
      });
  }
  getServiceCategoriesById() {
    this.serviceCategoriesService
      .getServiceCategoriesbyId(this.id)
      .subscribe((data) => {
        this.serviceCategory = data.result;
        this.breadcrumbService.changeBreadcrumbs(
          this.breadcrumbService.setForDocStandards(this.serviceCategory)
        );
      });
  }
  saveDocumentationStandards(documentationStandard: DocumentationStandard) {
    if (!documentationStandard.enabled) {
      documentationStandard.required = false;
    }

    documentationStandard.service_category_id = this.id;
    this.serviceCategoriesService
      .saveDocumentationStandards(documentationStandard)
      .subscribe(() => {
        this.isLoadingGeneral = false;
      });
  }
}

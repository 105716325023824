import {
  Directive,
  HostBinding,
  HostListener,
  AfterViewInit,
  ElementRef,
} from "@angular/core";

@Directive({
  selector: "[table-width]",
})
export class TableWidthDirective implements AfterViewInit {
  private el: HTMLElement;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  @HostBinding("style.width.px") elWidth: number;

  @HostListener("window:resize", ["$event.target"]) onResize() {
    this.windowResize();
  }

  windowResize() {
    // this.elWidth = this.el.offsetWidth;
    const x = this.el.offsetWidth;
  }

  ngAfterViewInit() {
    this.windowResize();
  }
}

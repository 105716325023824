import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
  Validator,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
// import { environment } from "@environment/environment";
import { environment } from "src/environments/environment";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import {
  combineLatest,
  debounceTime,
  filter,
  ReplaySubject,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { CountrySelectorComponent } from "../country-selector/country-selector.component";
import { City } from "src/app/modules/shared/models/city.model";

@Component({
  selector: "app-city-selector",
  templateUrl: "./city-selector.component.html",
  styleUrls: ["./city-selector.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CitySelectorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CitySelectorComponent,
    },
  ],
})
export class CitySelectorComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input()
  countrySelectorComponent: CountrySelectorComponent;

  @Input()
  initialValue;

  loaded: boolean = false;

  @Output()
  loadedEvent: EventEmitter<boolean> = new EventEmitter();

  selectedCity: string;
  onChange: (value: any) => void;
  onTouched: () => void;
  touched = false;
  control: AbstractControl = new UntypedFormControl();

  cities: City[] = [];
  citySearchControl = new FormControl();
  filteredCities$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected unsubscribe$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  writeValue(city: City | string): void {
    if (typeof city === "string") {
      city = { name: city } as City;
    }
    this.selectedCity = city?.name;
    this.citySearchControl.setValue(city?.name);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onSelectChange(matSelect: MatSelectChange) {
    this.onChange(matSelect.value);
    this.selectedCity = matSelect.value;
  }

  validate(control: AbstractControl): any {
    this.control = control;
  }

  ngOnInit(): void {
    combineLatest([
      this.countrySelectorComponent.valueUpdated.pipe(
        tap(() => this.writeValue(null)),
        startWith(this.countrySelectorComponent.getCurrentValue())
      ),
      this.citySearchControl.valueChanges.pipe(startWith("")),
    ])
      .pipe(
        debounceTime(600),
        filter(([country, value]) => !!country),
        switchMap(([country, value]) => this.loadCities(country, value))
      )
      .subscribe(({ result }) => {
        this.cities = result;

        this.filteredCities$.next(this.cities);
      });

    this.filteredCities$.subscribe(() => {
      if (!this.loaded) {
        this.loaded = true;

        this.loadedEvent.emit(true);
      }
    });
  }

  protected filterCities(): void {
    if (!this.cities) {
      return;
    }

    let search = this.citySearchControl.value;
    if (!search) {
      this.filteredCities$.next(this.cities.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCities$.next(
      this.cities.filter((item) => item.name.toLowerCase().includes(search))
    );
  }

  loadCities(country?: any, city = "") {
    let countryCode = "";
    if (typeof country === "string") {
      countryCode = country;
    } else {
      countryCode = country.iso ?? country.code;
    }
    return this.http
      .get<any>(environment.gateway_endpoint + "cities", {
        params: { q: city, country_code: countryCode },
      })
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((response) => response.success)
      );
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled === this.control.disabled) {
      return;
    }

    if (isDisabled) {
      this.control?.disable();

      return;
    }

    this.control?.enable();
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { TranslateService } from "@ngx-translate/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { ServiceCategory } from "../../../../_models/servicecategory";
import { ProvidersService } from "../../../../_services/providers.service";
import { ServiceCategoriesService } from "../../../../_services/servicecategories.service";
import { ServiceOffersService } from "../../../../_services/serviceoffers.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { finalize, take } from "rxjs";

@Component({
  selector: "app-bulk-upload-offers",
  templateUrl: "./bulk-upload-offers.component.html",
  styleUrls: ["./bulk-upload-offers.component.scss"],
})
export class BulkUploadOffersComponent {
  @Output() changeOffers: EventEmitter<any> = new EventEmitter();
  @Input() set provider(value) {
    if (value) {
      this.uploadModel.provider_id = +value;

      this.readonly = true;
      this.getProviderById(value);
    } else {
      this.getProviders(this.search_text, this.status);
    }
  }
  readonly = false;
  providers;
  request_call;
  search_text: string;
  search_fields;

  uploadModel = {
    file: null,
    provider_id: null,
    category_id: null,
  };

  level = 0;
  status = 3;
  public categories;
  public selectedCategory: ServiceCategory;

  public fileUploadControl = new FileUploadControl();

  setModalOpen = true;
  uploadedFiles = { file: null, name: null, status: null };

  sts = [{ id: 1, text: "signed" }];
  clear;
  isLoadingGeneral = false;
  isLoadingCategories = false;
  errorsFileUrl;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    protected service: ServiceOffersService,
    private alertService: AlertService,
    private providersService: ProvidersService,
    private serviceCategoriesService: ServiceCategoriesService,
    private translate: TranslateService
  ) {
    this.setModalOpen = false;
    // this.getProviders(this.status);
    // this.getCategories();

    this.alertService.isCloseErrNotifObs().subscribe((data) => {
      if (data === true) {
        this.cancelModal();
        this.changeOffers.emit(" ");
      }
    });
  }

  getCategories(providerId) {
    this.isLoadingCategories = true;
    this.isLoadingGeneral = true;
    this.serviceCategoriesService
      .getCategoriesForProvider(providerId)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoadingCategories = false;
          this.isLoadingGeneral = false;
        })
      )
      .subscribe((data) => {
        if (data.success) {
          this.categories = data.result;
        }
      });
  }

  getProviders(search_text, status_id) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoadingGeneral = true;

    const params = {};

    params["status_id"] = status_id;

    if (search_text) {
      params["search_text"] = search_text.term;
    }
    if (this.search_text) {
      params["search_text"] = this.search_text["term"];
    }

    this.providersService.getProviders(params).subscribe(
      (data) => {
        this.providers = data.result.items;
        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
      }
    );
  }

  getProviderById(id) {
    this.isLoadingCategories = true;
    this.isLoadingGeneral = true;

    this.providersService
      .getProvider(id)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoadingCategories = false;
          this.isLoadingGeneral = false;
        })
      )
      .subscribe((data) => {
        this.providers = [data.result];
        this.getCategories(id);
      });
  }

  limitFilesUpload(e) {
    if (e && e.length > 1) {
      this.uploadedFiles.file = [e[0]];
    }
  }

  isModalOpen() {
    this.setModalOpen = true;
  }

  isModalClose() {
    this.setModalOpen = false;
    this.clearUploadDoc();
    this.clear = true;
    setTimeout(() => {
      this.clear = false;
    }, 1);
    if (this.readonly) {
      this.onProviderChanged(this.uploadModel.provider_id);
    } else {
      this.uploadModel.provider_id = null;
    }
    this.selectedCategory = null;
    this.categories = "";
  }

  cancelModal() {
    this.ngxSmartModalService.getModal("addDoc").close();
    this.clear = true;
    setTimeout(() => {
      this.clear = false;
    }, 1);

    this.clearUploadDoc();
    if (this.readonly) {
      this.onProviderChanged(this.uploadModel.provider_id);
    } else {
      this.uploadModel.provider_id = null;
    }
    this.selectedCategory = null;
    this.categories = "";
  }

  updateSelectedProvider(providerId) {
    const selectedProvider = this.providers.find(
      (provider) => provider.id === providerId
    );
  }

  onProviderChanged(providerId) {
    this.getCategories(providerId);
    this.updateSelectedProvider(providerId);
    this.selectedCategory = null;
    this.categories = "";
  }

  downloadFile(dataurl) {
    const a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", "");
    a.click();
  }

  uploadFile() {
    this.isLoadingGeneral = true;
    if (this.uploadedFiles) {
      this.service
        .uploadBulkOffers(
          this.uploadModel.provider_id,
          this.uploadModel.category_id,
          this.uploadedFiles
        )
        .subscribe(
          (res) => {
            this.isLoadingGeneral = false;

            if (res.result.errors > 0) {
              this.cancelModal();
              this.errorsFileUrl = res.result.errors_file.file_url;
              this.ngxSmartModalService.getModal("bulkUploadErrors").open();
              // this.downloadFile(res.result.errors_file.file_url);

              // this.translate.get('GENERALS.BULK-UPLOAD-OFFERS-ERR').subscribe(trans => {
              //   this.alertService.stringError(trans);
              // });
              this.clearUploadDoc();
              // window.open(res.result.errors_file.file_url, '_blank');
              // alert(
              //   'There was an error with your file. Please check the downloaded document for more details!'
              // );

              // BULK-UPLOAD-OFFERS-ERR
            } else {
              if (res.message) {
                this.alertService.success(res.message);
              }
              this.changeOffers.emit("newDoc");
              this.cancelModal();
            }
          },
          (error) => {
            this.isLoadingGeneral = false;
            this.alertService.errors(error.error.errors);
          }
        );
    }
  }

  errDownloadFile(e) {
    this.downloadFile(this.errorsFileUrl);
    this.ngxSmartModalService.closeLatestModal();
  }

  selectCategory(category: ServiceCategory) {
    this.selectedCategory = category;
    if (category) {
      this.uploadModel.category_id = category.id;
    }
  }

  downloadTemplate(service_category) {
    this.serviceCategoriesService
      .downloadTemplate(service_category.id)
      .subscribe((data) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = service_category.label + ".xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      });
  }
  clearUploadDoc() {
    this.fileUploadControl.clear();
    this.uploadedFiles = {
      file: null,
      name: null,
      status: null,
    };
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

<ng-container *ngIf="!xs; else xsLayoutAdmin">
  <app-left-navigation></app-left-navigation>

  <div class="dashboard-wrapper">
    <app-header></app-header>

    <div class="dashboard-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>

<ng-template #xsLayoutAdmin>
  <div class="dashboard-wrapper">
    <app-header-xs></app-header-xs>

    <app-left-navigation-xs></app-left-navigation-xs>

    <div class="dashboard-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>

<div class="window-width" (window:resize)="onResize()"></div>

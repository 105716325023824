import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-cookie-consent",
  templateUrl: "./cookie-consent.component.html",
  styleUrls: ["./cookie-consent.component.css"],
})
export class CookieConsentComponent implements OnInit {
  cookieConsent = true;
  constructor(private router: Router, private cookieService: CookieService) {}

  ngOnInit() {
    if (this.cookieService.get(environment.cookieConsent)) {
      this.cookieConsent = false;
    } else {
      this.cookieConsent = true;
    }
  }

  cookieAccept(e) {
    if (e) {
      this.cookieConsent = false;
      this.cookieService.set(environment.cookieConsent, e, 365, "/");
    }
  }

  goToPrivacyPolicy() {
    window.open(environment.marketplace_url + "privacy-policy");
  }
}

<div class="form-login">
  <div class="auth-form-wrapper">
    <form name="form" class="auth-form" (ngSubmit)="login()">
      <div class="auth-form-title-wrapper">
        <div class="auth-form-title">
          {{ "AUTH.SIGNIN" | translate }}
        </div>
      </div>
      <ng-container *ngIf="isLoadingGeneral">
        <app-loader-spinner-general
          [repeat]="2"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </ng-container>
      <ng-container *ngIf="!isLoadingGeneral">
        <input
          type="text"
          class="form-control"
          name="username"
          placeholder="{{ 'LABEL.EMAIL' | translate }}"
          [(ngModel)]="user.username"
          required
        />
        <div class="password-container">
          <input
            [type]="hide ? 'password' : 'text'"
            name="password"
            class="form-control"
            placeholder="{{ 'LABEL.PASSWORD' | translate }}"
            [(ngModel)]="user.password"
            required
          />
          <mat-icon class="show-pass" (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </div>
      </ng-container>

      <button [disabled]="isLoadingGeneral" class="btn orange-bg">
        {{ "AUTH.SIGNIN" | translate }}
      </button>
    </form>
    <div class="under-form">
      <a class="action-link-orange-sm" routerLink="/recover-password">{{
        "AUTH.FORGOT-PASSWORD" | translate
      }}</a>
    </div>
  </div>

  <div class="auth-footer login-footer">
    <p>
      {{ "AUTH.FOOTER-1" | translate }}
      <span class="action-link-orange" [routerLink]="['/sign-up']">{{
        "AUTH.SIGNUP" | translate
      }}</span
      >!
    </p>
    <p>
      {{ "AUTH.FOOTER-2" | translate }}
      <span class="action-link-orange" (click)="goToTerms()">{{
        "GENERALS.TERMS" | translate
      }}</span>
      &
      <span class="action-link-orange" (click)="goToPrivacy()">{{
        "GENERALS.PRIVACY-POLICY" | translate
      }}</span>
    </p>
  </div>
</div>

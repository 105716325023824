import { finalize, pipe, take } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../_services/authentication.service";
import { User } from "../../../_models/user";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService } from "../../../_services/alert.service";
import { environment } from "../../../../environments/environment";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-login-form",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  user: User;
  returnUrl: string;
  isLoadingGeneral: boolean = false;
  hide = true;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    this.user = new User();
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  login() {
    this.isLoadingGeneral = true;
    this.authenticationService
      .login(this.user.username, this.user.password)
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe(
        (data) => {
          this.isLoadingGeneral = false;
          this.returnUrl = decodeURI(this.returnUrl);
          this.router.navigateByUrl(this.returnUrl);
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  goToPrivacy() {
    window.open(environment.marketplace_url + "privacy-policy");
  }

  goToTerms() {
    window.open(environment.marketplace_url + "terms-conditions");
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

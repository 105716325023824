<app-loader-spinner-general
  [isLoadingGeneral]="isLoadingGeneral"
></app-loader-spinner-general>

<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div windowScrollDashboardBodyTop class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "INVOICES.LOG" | translate }}</div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!--    todo add commissions btn modal-->
  </div>
  <!-- end right -->
</div>

<div class="clear"></div>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div windowScrollDashboardBodyAction class="dashboard-body-action">
  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- table -->
<div class="table-wrapper">
  <div class="table-responsive">
    <table
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="{{ active }}"
      matSortDirection="{{ direction }}"
    >
      <thead>
        <tr>
          <th>
            {{ "INVOICES.INVOICE-NO" | translate }}
          </th>
          <th>
            {{ "LABEL.EMAIL" | translate }}
          </th>
          <th>
            {{ "GENERALS.ACTION" | translate }}
          </th>
          <th>
            {{ "INVOICES.FIELD" | translate }}
          </th>
          <th>
            {{ "INVOICES.OLD-VALUE" | translate }}
          </th>
          <th>
            {{ "INVOICES.NEW-VALUE" | translate }}
          </th>
          <th>
            {{ "GENERALS.CREATED-AT" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let invoice of items
              | paginate
                : {
                    itemsPerPage: itemsPage,
                    currentPage: p,
                    totalItems: total
                  };
            let i = index
          "
        >
          <td>
            <a [routerLink]="['/invoices/view/' + invoice.id]">
              {{ invoice.invoice_id }}
            </a>
          </td>
          <td>
            {{ invoice.user.email }}
          </td>
          <td>
            {{ invoice.action }}
          </td>
          <td>
            {{ invoice.field }}
          </td>
          <td>
            {{ invoice.old_value }}
          </td>
          <td>
            {{ invoice.new_value }}
          </td>
          <td>
            {{ invoice.created_at | date : "dd/MM/yyyy HH:mm:ss" }}
          </td>
        </tr>
        <tr *ngIf="items?.length === 0">
          <td colspan="7" class="gray">
            {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->

import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { AuthenticationService } from "src/app/_services/authentication.service";

@Component({
  selector: "app-header-xs",
  templateUrl: "./header-xs.component.html",
  styleUrls: ["./header-xs.component.css"],
})
export class HeaderXsComponent {
  user;
  isLoadingGeneral;
  @ViewChild("headerRef", { static: true }) headerRef: ElementRef;
  constructor(
    private authenticationService: AuthenticationService,
    private renderer: Renderer2
  ) {
    this.isLoadingGeneral = false;
  }

  fixedHeader() {
    const scrolled =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentElement ||
            document.body
          ).scrollTop;

    if (scrolled >= 30) {
      this.renderer.addClass(
        this.headerRef.nativeElement,
        "small-fixed-header-admin"
      );
    } else {
      this.renderer.removeClass(
        this.headerRef.nativeElement,
        "small-fixed-header-admin"
      );
    }
  }
}

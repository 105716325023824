<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="addDoc"
    #addDoc
    identifier="addDoc"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <!-- <app-loader-spinner [isLoading]="isLoading"></app-loader-spinner> -->
    <div class="modal-title-wrapper">
      <div class="modal-title">
        {{ "GENERALS.UPLOAD-OFFERS" | translate }}
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <div class="loading-wrapper">
        <div *ngIf="isLoadingGeneral">
          <label>{{ "GENERALS.PROVIDER" | translate }} *</label>
          <app-loader-spinner-general
            [loadingType]="LoadingType.INPUT"
          ></app-loader-spinner-general>
        </div>
        <div *ngIf="isLoadingCategories">
          <label> {{ "GENERALS.SERVICE-CATEGORY" | translate }} * </label>
          <app-loader-spinner-general
            [loadingType]="LoadingType.INPUT"
          ></app-loader-spinner-general>
        </div>
      </div>
      <div class="modal-form">
        <div class="row" *ngIf="!isLoadingGeneral">
          <div class="form-group w-100">
            <label>{{ "GENERALS.PROVIDER" | translate }} *</label>
            <ng-select
              [ngClass]="{
                'customiz-select': true,
                'customiz-select-disabled': false
              }"
              [disabled]="readonly"
              [items]="providers"
              bindLabel="company_name"
              bindValue="id"
              (search)="getProviders($event, status)"
              (ngModelChange)="onProviderChanged($event)"
              placeholder="{{ 'GENERALS.SELECT-PROVIDER' | translate }}"
              [(ngModel)]="uploadModel.provider_id"
              notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
              [clearable]="false"
            >
            </ng-select>
          </div>
        </div>

        <div class="row" *ngIf="!isLoadingCategories">
          <div class="form-group w-100">
            <app-category-selection
              [categories]="categories"
              [level]="level"
              [clear]="clear"
              (intermediaryCategory)="selectCategory($event)"
              (categorySelected)="selectCategory($event)"
            >
            </app-category-selection>
          </div>
        </div>

        <div class="row m-b-25" *ngIf="selectedCategory">
          <div class="w-100">
            <div class="modal-body-desc">
              {{ "GENERALS.DOWNLOAD-TEMPLATE-INFO" | translate }}
            </div>
          </div>
          <div class="w-100">
            <div class="relative-pos">
              <div
                class="action-link-orange fl-l fw-500"
                (click)="downloadTemplate(selectedCategory)"
              >
                <i class="icon-download"></i>
                {{ "GENERALS.DOWNLOAD-TEMPLATE" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="selectedCategory">
          <div class="form-group w-100">
            <label>{{ "GENERALS.UPLOAD-DOC" | translate }}</label>

            <div class="custom-upload-box m-b-25">
              <file-upload
                [(ngModel)]="uploadedFiles.file"
                [control]="fileUploadControl"
                (ngModelChange)="limitFilesUpload($event)"
              >
                <ng-template #placeholder>
                  <span><i class="icon-upload-cloud"></i></span>
                  <span>{{ "GENERALS.DROP-FILE-TO-UPLOAD" | translate }} </span>
                  <span class="orange">
                    {{ "GENERALS.BROWSE" | translate }}</span
                  >
                </ng-template>

                <ng-template
                  let-i="index"
                  let-file="file"
                  let-control="control"
                  #item
                >
                  <div class="file-info">
                    <span class="file-name">{{ file.name }}</span>
                    <span class="remove-file" (click)="clearUploadDoc()">
                      <i class="icon-trash"></i
                      >{{ "GENERALS.REMOVE-FILE" | translate }}</span
                    >
                  </div>
                </ng-template>
              </file-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button type="button" class="btn gray-bg" (click)="cancelModal()">
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button
        [disabled]="isLoadingGeneral"
        type="button"
        class="btn orange-bg"
        (click)="uploadFile()"
      >
        {{ "GENERALS.ADD" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->

<app-bulk-upload-errors-modal
  (changeErrors)="errDownloadFile($event)"
></app-bulk-upload-errors-modal>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import { ServiceCategory } from "../../../../../_models/servicecategory";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-category-selection",
  templateUrl: "./category-selection.component.html",
  styleUrls: ["./category-selection.component.css"],
})
export class CategorySelectionComponent implements OnInit, OnChanges {
  public selectedCategory: ServiceCategory;
  public selectedPath: string;
  placeholder;
  preselectedCateg;

  @Input() categories;
  @Input() level;
  @Input() clear;
  @Input() helper;
  @Input() preselectedPath;

  @Output() categorySelected: EventEmitter<any> = new EventEmitter();
  @Output() intermediaryCategory: EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if (this.preselectedPath && this.preselectedPath.length) {
      this.selectedPath = this.preselectedPath[this.level];
    }

    if (this.level === 0) {
      this.translate
        .get("GENERALS.SELECT-SERVICE-CATEGORY")
        .subscribe((data) => {
          this.placeholder = data;
        });
    } else {
      this.translate.get("GENERALS.SELECT-SUBCATEGORY").subscribe((data) => {
        this.placeholder = data;
      });
    }
  }

  ngOnChanges(change) {
    if (change.categories) {
      this.selectedCategory = null;
      this.selectedPath = null;
      this.checkPreselectedPath();
    }

    if (change.clear && change.clear.currentValue) {
      this.selectedCategory = null;
      this.selectedPath = null;
    }

    if (change.preselectedPath && change.preselectedPath.currentValue) {
      this.checkPreselectedPath();
    }
  }

  checkPreselectedPath() {
    if (this.preselectedPath && this.preselectedPath.length) {
      this.selectedPath = this.preselectedPath[this.level];

      if (this.categories) {
        this.selectedCategory = this.categories[this.selectedPath];

        if (this.selectedCategory == undefined) {
          this.preselectedPath.pop();
          this.selectedPath = this.preselectedPath[this.level];
        }

        this.preselectedCateg =
          this.categories[this.preselectedPath.slice(-1)[0]];

        //this.categorySelected.emit(this.preselectedCateg);
      }
    }
  }

  selectCategory(selection) {
    this.selectedCategory = this.categories[selection];

    if (this.selectedCategory && this.selectedCategory.children_count === 0) {
      this.categorySelected.emit(this.selectedCategory);
    } else {
      this.intermediaryCategory.emit();
    }
    this.preselectedPath = null;
  }

  /**
   * Forward the received event to the parent
   *
   * @param event :the child category selected
   */
  forward(event) {
    this.categorySelected.emit(event);
  }
}
// {{ 'GENERALS.SELECT-SERVICE-CATEGORY' | translate }}

import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { RFQStatus } from "../../_enums/rfq-status-enum";
import { RFQ } from "../../_models/rfq";

export class RfqHelper {
  constructor(private translate: TranslateService) {}

  isDraft(rfq: RFQ) {
    const _rfq = Object.assign(new RFQ(), rfq);
    return _rfq.status === RFQStatus.DRAFT;
  }

  canExtend(rfq: RFQ) {
    const _rfq = Object.assign(new RFQ(), rfq);
    // check if RFQ was already extended
    if (_rfq.update_period) return false;
  }

  timeLeft(rfq: RFQ) {
    const _rfq = Object.assign(new RFQ(), rfq);
    const endDate = moment(_rfq.end_date);
    const now = moment.now();

    // if the submission period hasn't started (RFQ is not open), time left is not available
    if (_rfq.status !== RFQStatus.OPEN && _rfq.status !== RFQStatus.CLOSED) {
      return "N/A";
    }

    // if the RFQ is closed, but it's still within the offer acceptance period, we'll show time left until then
    // only if the user hasn't yet placed an order for it
    if (_rfq.status === RFQStatus.CLOSED && _rfq.order_id === null) {
      endDate.add(14, "days");
    }

    // if the submission period has closed, RFQ must be listed as Expired
    if (endDate.isBefore(now)) {
      return this.translate.instant("RFQS.EXPIRED");
    }

    const timeLeft = moment.duration(endDate.diff(now));

    if (endDate.diff(now, "minutes") < 60) {
      return this.translate.instant("GENERALS.LESS-THAN-AN-HOUR");
    }

    let label = "";

    if (timeLeft.days()) {
      label =
        label + timeLeft.days() + " " + this.translate.instant("GENERALS.DAYS");
    }

    if (timeLeft.hours()) {
      label =
        label +
        " " +
        timeLeft.hours() +
        " " +
        this.translate.instant("GENERALS.HOURS");
    }

    return label;
  }
}

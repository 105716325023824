export class RFQ {
  is_bid: number;
  client_id: number;
  created_by_id: number;
  country: string;
  city: string;
  categories: Array<any>;
  offers_request: Array<RFQOfferRequest> = [];
  providers: Array<any>;
  target_currency_id: number;
  target_price: number;
  delivery_date: string;
  start_date: string;
  end_date: string;
  lowest_price: number;
  mix_quality_price: number;
  time;
  status: number;
  order_id?: number;
  status_name: string;
  declined_reason: string;
  company_name: string;
  id: number;
  request_type: string;
  update_period: number;
  target_currency_code: string;
  order_identity_number: string;
}

export class RFQOffer {
  provider_id: number;
  category_id: number;
  offer_id: number;
  quantity: number;
  currency_id: number;
  price: number;
  country: number;
}

export class RFQOfferRequest {
  private _service_name: string;
  private _quantity: number;
  private _category_id: number;

  constructor(
    category_id: number,
    service_name: string = "",
    quantity: number = 1
  ) {
    this._category_id = category_id;
    this._service_name = service_name;
    this._quantity = quantity > 0 ? quantity : 1;
  }

  set quantity(offerQuantity: number) {
    this._quantity = offerQuantity > 0 ? offerQuantity : 1;
  }

  get quantity(): number {
    return this._quantity;
  }

  set service_name(service_name: string) {
    this._service_name = service_name;
  }

  get service_name(): string {
    return this._service_name;
  }

  set category_id(category_id: number) {
    this._category_id = category_id;
  }

  get category_id(): number {
    return this._category_id;
  }

  toJSON() {
    return {
      service_category_id: this._category_id,
      service_name: this._service_name,
      quantity: this._quantity,
    };
  }
}

export class RFQSDetails {
  links: Array<any>;

  constructor(_id: number, filters) {
    this.links = [
      {
        link: "/rfqs-bids/" + _id,
        filters: filters,
        label: "Details",
        page: "details",
      },
      {
        link: "/rfqs-bids/" + _id + "/quotations",
        filters: filters,
        label: "Quotations",
        page: "quotations",
      },
      {
        link: "/rfqs-bids/" + _id + "/history",
        filters: filters,
        label: "History",
        page: "history",
      },
    ];
  }
}

<app-loader-spinner-general
  [isLoadingGeneral]="isLoadingGeneral"
></app-loader-spinner-general>

<mat-card>
  <!-- subHeader -->
  <mat-card-title class="card-title-cms subheader-wrapper">
    <div class="subheader-title">
      {{ "GENERALS.SERVICE-CATEGORISATION" | translate }}
    </div>

    <div class="subheader-actions">
      <div>search here</div>
      <div (click)="openModal()" class="btn white-bg shadow">
        <i class="icon-plus-1"></i>
        {{ "GENERALS.ADD-CATEGORY" | translate }}
      </div>
    </div>
  </mat-card-title>
  <!-- end subHeader -->

  <!-- table -->

  <div class="table-wrapper draggable-table" #draggableTable>
    <table matSort (matSortChange)="sortData($event)">
      <thead #tableHead (window:resize)="onResize()">
        <tr>
          <th>
            <label class="container-checkbox">
              <input type="checkbox" />
              <span class="checkmark-checkbox"></span>
            </label>
          </th>
          <th mat-sort-header="name">
            {{ "GENERALS.MAIN-CATEGORY" | translate }}
          </th>

          <th>{{ "LABEL.DEFAULT-COMMISSION" | translate }}</th>
          <th>{{ "GENERALS.STATUS" | translate }}</th>
          <th><i class="icon-cog"></i></th>
        </tr>
      </thead>

      <tbody>
        <p-tree
          selectionMode="checkbox"
          (onNodeDrop)="drop($event)"
          #expandingTree
          [value]="serviceCategories"
          dropRestrict="sibling"
          draggableNodes="true"
          droppableNodes="true"
        >
          <ng-template let-node pTemplate="default">
            <tr class="draggable-tr">
              <td>{{ node.label }} ({{ node.children_count }})</td>
              <td>{{ node.commission }}</td>

              <td>
                <div class="status-lable-{{ node.status }}">
                  {{ node.status }}
                </div>
              </td>

              <td (click)="clickStopper($event)">
                <div
                  class="add-subcategory"
                  (click)="openModalSubCategory(node.id)"
                >
                  <i class="icon-list-add"></i>
                </div>
                <div
                  #container
                  class="table-action-wrapper"
                  (click)="toggle(node)"
                >
                  <i class="icon-dot-3" id="{{ node.id }}"></i>
                </div>
                <div
                  class="table-action shadow"
                  *ngIf="submenuOpen === node.id"
                >
                  <ul>
                    <li
                      *ngIf="node.status === 'Inactive'"
                      (click)="activateServiceCategories(node)"
                    >
                      {{ "GENERALS.ACTIVATE" | translate }}
                    </li>
                    <li
                      *ngIf="node.status === 'Active'"
                      (click)="deleteServiceCategories(node)"
                    >
                      {{ "GENERALS.DEACTIVATE" | translate }}
                    </li>
                    <li (click)="documentationStandardRoute(node)">
                      {{ "GENERALS.DOCUMENTATION-STANDARDS" | translate }}
                    </li>
                    <li (click)="downloadTemplate(node)">
                      {{ "GENERALS.DOWNLOAD-TEMPLATE" | translate }}
                    </li>
                    <li (click)="openModalUpdate(node)">
                      {{ "GENERALS.EDIT" | translate }}
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-tree>
      </tbody>
    </table>

    <!-- paginator -->
    <!-- <div class="paginator">
      <pagination-controls
        (pageChange)="getClients($event)"
        previousLabel=""
        nextLabel="">
      </pagination-controls>
  </div> -->

    <!-- end paginator -->
  </div>
</mat-card>
<!-- end table -->

<!--&lt;!&ndash; add category modal &ndash;&gt;-->
<!--<app-add-category-->
<!--(getServiceCategories)="getServiceCategories()"-->
<!--[service]="updateServiceCategory"-->
<!--&gt;</app-add-category>-->
<!--&lt;!&ndash; end add category modal &ndash;&gt;-->

<!-- add subCategory modal -->
<!--<app-add-subcategory-->
<!--(getServiceCategories)="getServiceCategories()"-->
<!--[parentId]="parentId"-->
<!--&gt;</app-add-subcategory>-->
<!-- end add subCategory modal -->

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClientsService } from "../../../../_services/clients.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";

@Component({
  selector: "app-client-users",
  templateUrl: "./client-users.component.html",
  styleUrls: ["./client-users.component.css"],
})
export class ClientUsersComponent implements OnInit {
  filters = {};

  constructor(
    private route: ActivatedRoute,
    private service: ClientsService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.filters["entity_id"] = this.route.snapshot.parent.params.id;
  }

  ngOnInit() {
    this.service.getClient(this.filters["entity_id"]).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForClient(data.result, "users")
      );
    });
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User } from "../_models/user";

@Injectable({
  providedIn: "root",
})
export class CurrentUserService {
  private userSource = new BehaviorSubject<User | null>(null);
  userChanges$ = this.userSource.asObservable();

  changeCurrentUser(user: User | null): void {
    this.userSource.next(user);
  }
}

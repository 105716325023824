// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration=development` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

import { processEnvironment } from "./environment.process";

export const environment = {
  production: false,
  gateway_endpoint: "https://gate.dev.xpath.global/",
  client_id: 2,
  client_secret: "JH1QdlJ5gVic2KfQfjtuBxEiRLDA3M0uH85TEXDK",
  app_host: "https://admin.dev.xpath.global",
  session_local_storage_key: "sessionDEVStorageKey",
  user_local_storage_key: "userDEVStorageKey",
  lang_cookie_key: "langCookie",
  cookieConsent: "cookieConsent",
  marketplace_url: "https://mktp.dev.xpath.global/",
  marketplace: "https://mktp.dev.xpath.global/home",
  stripe_plans_url: "https://dashboard.stripe.com/test/products?active=true",
  stripe_provider_table_url:
    "https://dashboard.stripe.com/test/pricing-tables/prctbl_1LwkzWByT6Cm3bGGcIbFU3BI",
  stripe_client_table_url:
    "https://dashboard.stripe.com/test/pricing-tables/prctbl_1M1wFtByT6Cm3bGGygmtpdpG",
  stripe_view_subscription_url:
    "https://dashboard.stripe.com/test/subscriptions/",
  stripe_payments_url: "https://dashboard.stripe.com/test/payments",
  role: "Super Admin",
  provider_portal: "https://provider.dev.xpath.global/",
  domain: "admin.dev.xpath.global",
  unit_id: 3, // days
  pusher_key: "nestlers-messaging-websocketkey",
  wsHost: "msg.dev.xpath.global",
  wsPort: 6001,
  google_tracking_id: "UA-136866838-3",
  show_prerelease_features: true,
  crm_endpoint: false,
  ...(processEnvironment ? processEnvironment : {}),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="finalizeRFQ"
    #finalizeRFQ
    identifier="finalizeRFQ"
    [dismissable]="false"
  >
    <app-loader-spinner-general
      [isLoadingGeneral]="isLoadingGeneral"
    ></app-loader-spinner-general>
    <div class="modal-title-wrapper">
      <div class="modal-title" *ngIf="type">
        {{ "RFQS.FINALIZE-" + type.toUpperCase() | translate }}
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <div
        class="order-details-box-wrapper"
        *ngFor="let offer of offers; let i = index"
      >
        <div class="order-details-box-top">
          <div class="order-details-box-left">
            <img class="pointer" src="assets/images/footer-img.jpg" alt="img" />
            <div class="fl-l">
              <div class="medium-title order-details-box-left-header pointer">
                {{ offer.offer_name }}
              </div>
              <div class="order-details-box-left-body">
                <div class="info-row">
                  <span class="inner-label"
                    >{{ "GENERALS.QUANTITY" | translate }}:
                  </span>
                  <span class="fw-700">
                    {{ offer.quantity }}
                  </span>
                </div>
                <div class="info-row">
                  <span class="inner-label">
                    {{ "GENERALS.PRICE-PER-UNIT" | translate }}:
                  </span>

                  <span class="fw-700">
                    {{ offer.price | currency : offer.currency_code }}
                  </span>
                </div>

                <div class="info-row">
                  <span class="inner-label fs-15 fw-500">
                    {{ "GENERALS.TOTAL-PRICE" | translate }}:
                  </span>
                  <span class="fw-700">
                    {{
                      offer.price * offer.quantity
                        | currency : offer.currency_code
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="order-details-box-right-wrapper">
            <div class="order-details-box-right">
              <div class="fw-500 gray">
                {{ "GENERALS.PROVIDER" | translate }}:
                {{ offer.company_name }}
              </div>

              <div class="order-details-box-link">
                <ul>
                  <li>
                    <a
                      class="orange"
                      target="_blank"
                      href="{{ urlProvider }}/{{ offer.provider_id }}"
                    >
                      <i class="icon-eye-1"></i>
                      {{ "GENERALS.VIEW-PROVIDER-DETAILS" | translate }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="clear"></div>
        </div>
      </div>
      <div *ngIf="!offers || !offers.length" class="order-details-box-wrapper">
        {{ "GENERALS.NO-ITEMS-FOUND-RFQ" | translate }}
      </div>
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn gray-bg"
        (click)="finalizeRFQ.close(); isOpen = false"
      >
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button
        type="button"
        *ngIf="status && status_name.toLowerCase() !== 'closed'"
        class="btn white-bg"
        (click)="finalize()"
      >
        {{ "RFQS.FINALIZE-ONLY" | translate }}
      </button>

      <button type="button" class="btn orange-bg" (click)="addToCart()">
        {{ "RFQS.ADD-TO-CART" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->

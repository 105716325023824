<section class="dialog-wrapper">
  <h1 mat-dialog-title class="white-box-header">
    {{ "SUBSCRIPTIONS.ADD-PLAN" | translate }}
  </h1>
  <div mat-dialog-content>
    <ng-template #loading>
      <app-loader-spinner-general
        [loadingType]="LoadingType.INPUT"
      ></app-loader-spinner-general>
    </ng-template>
    <form
      [formGroup]="form"
      class="inputs-wrapper mb-3"
      *ngIf="plans$ | async as plans; else loading"
    >
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LEFT-NAVIGATION.PLANS" | translate }}</mat-label>
        <mat-select formControlName="plan" [compareWith]="compareWith">
          <mat-option *ngFor="let plan of plans" [value]="plan">
            {{ plan.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-form-errors
        class="error-holder mb-3"
        [control]="form.get('plan')"
      ></app-form-errors>
      <div class="w-100 space-inputs" *ngIf="recurring$ | async as recurrings">
        <div class="w-50 p-0">
          <mat-form-field appearance="outline" class="w-100 p-0">
            <mat-label>{{ "SUBSCRIPTIONS.PRODUCTS" | translate }}</mat-label>
            <mat-select formControlName="recurring">
              <mat-option
                *ngFor="let recurring of recurrings"
                [value]="recurring"
              >
                {{
                  recurring.price.unit_amount
                    | currency : (recurring.price.currency | uppercase)
                }}
                / {{ recurring.interval_count }}
                {{ recurring.interval }}
              </mat-option>
              <mat-option *ngIf="recurrings.length < 1" [disabled]="true">
                {{ "SUBSCRIPTIONS.NO-PRODUCTS" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-form-errors
            class="error-holder mb-3"
            [control]="form.get('recurring')"
          ></app-form-errors>
        </div>
        <div class="w-50 p-0">
          <div class="w-50">
            <mat-form-field appearance="outline" class="w-100 p-0">
              <mat-label>{{
                "SUBSCRIPTIONS.DAYS-UNTIL-INVOICE" | translate
              }}</mat-label>
              <input onlyNumber formControlName="days_until_due" matInput />
            </mat-form-field>
            <app-form-errors
              class="error-holder mb-3"
              [control]="form.get('days_until_due')"
            ></app-form-errors>
          </div>
          <div class="w-50 p-0">
            <mat-form-field appearance="outline" class="w-100 p-0">
              <mat-label>{{
                "SUBSCRIPTIONS.FREE-TRIAL-PERIOD" | translate
              }}</mat-label>
              <input onlyNumber formControlName="trial_period_days" matInput />
            </mat-form-field>
            <app-form-errors
              class="error-holder mb-3"
              [control]="form.get('trial_period_days')"
            ></app-form-errors>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="button-group" align="end">
    <button mat-button [mat-dialog-close]="null" class="btn gray-bg">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button
      mat-button
      [disabled]="isLoadingGeneral"
      (click)="submit()"
      class="btn orange-bg"
    >
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</section>

<div
  #headerRef
  id="header-wrapper-admin"
  class="header-wrapper-no-bg"
  (window:scroll)="fixedHeader()"
>
  <div class="header-top">
    <div class="logo fl-l" [routerLink]="['/orders']">
      <img src="assets/images/logo.svg" alt="" />
    </div>

    <div class="header-top-action-wrapper fl-r">
      <!-- widgets -->
      <app-top-menu></app-top-menu>
    </div>
  </div>
  <div class="clear"></div>
</div>

<div class="clear"></div>

<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModal }">
  <ngx-smart-modal
    id="addPrice"
    #addPrice
    identifier="addPrice"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">
        {{ "GENERALS.ADD-PRICE" | translate }}
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <div class="modal-form">
        <div class="row">
          <div class="w-50">
            <div class="form-group">
              <label
                >{{ "GENERALS.PRICE" | translate }}
                <span *ngIf="currency_code">
                  ({{ currency_code | uppercase }})*
                </span>
                <span appHoverHelper class="input-helper"
                  ><i class="icon-help_comp"></i>
                  <span class="helper-description">
                    {{ "HELPERS.CUSTOM-PRICE" | translate }}
                  </span>
                </span></label
              >
              <input
                type="number"
                name="price"
                class="form-control"
                [(ngModel)]="price.price"
              />
            </div>
          </div>
          <div class="w-50">
            <div class="form-group">
              <label
                >{{ "GENERALS.STOCK-AVAILABILITY" | translate }}
                *
                <span appHoverHelper class="input-helper"
                  ><i class="icon-help_comp"></i>
                  <span class="helper-description">
                    {{ "HELPERS.CUSTOM-MAX-AVAILABILITY" | translate }}
                  </span>
                </span></label
              >
              <input
                type="number"
                name="stock"
                [(ngModel)]="price.quantity"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <!-- calendar -->
          <div class="w-50">
            <div class="fix-position-calendar-modal">
              <div class="modal-calendar-wrapper">
                <app-date-range-calendar
                  [showLabel]="true"
                  [multiple]="true"
                  [dateRange]="dateRangeMultipleMonths"
                  [placeholder]="'GENERALS.SELECT-DATE' | translate"
                  (change)="setDate($event)"
                >
                </app-date-range-calendar>
              </div>
            </div>
          </div>
          <!-- end calendar -->
          <div class="w-50">
            <div class="form-group">
              <label
                >{{ "GENERALS.MARKETPLACE_PRICE" | translate }}
                *
                <span appHoverHelper class="input-helper"
                  ><i class="icon-help_comp"></i>
                  <span class="helper-description">
                    {{ "HELPERS.MARKETPLACE_PRICE" | translate }}
                  </span>
                </span></label
              >
              <div class="card-price" *ngIf="salePrice && price">
                <span *ngIf="salePrice > price.price">
                  <span class="old-price">{{
                    salePrice | currency : currency_code
                  }}</span>

                  <span class="discount">
                    (-{{
                      serviceOffersService.calculateDiscount(
                        salePrice,
                        price.price
                      )
                    }}%)</span
                  >
                </span>
                <span class="new-price">{{
                  price.price | currency : currency_code
                }}</span>
              </div>
              <div class="card-price" *ngIf="!salePrice">
                <span class="new-price">{{
                  price.price | currency : currency_code
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="clear"></div>

    <div class="modal-footer-l-r">
      <div class="modal-footer-r">
        <button type="button" class="btn gray-bg" (click)="cancelModal()">
          {{ "GENERALS.CANCEL" | translate }}
        </button>

        <button
          type="button"
          class="btn orange-bg"
          (click)="add()"
          [disabled]="enableSaveButton()"
        >
          {{ "GENERALS.DONE" | translate }}
        </button>

        <!-- <button type="button" class="btn orange-bg" (click)="add()" *ngIf="!category?.id">
          {{ 'GENERALS.DONE' | translate }}
        </button> -->
      </div>
    </div>
  </ngx-smart-modal>
</div>

<!-- end modal -->

<div windowScrollAppNav class="details-navigation">
  <!-- <div class="fl-l big-title"  (click)="goBack()" *ngIf="expat"> <i class="icon-left-open back-arrow"></i>  {{ expat.user.name }} </div> -->
  <!-- <div class="fl-l big-title" *ngIf="expat">{{ expat.user.name }}</div> -->
  <div class="fl-l big-title" *ngIf="expat">
    <i
      class="icon-left-open back-arrow"
      routerLink="../"
      [queryParams]="filters"
    ></i>
    {{ expat.user.name }}
  </div>
  <div class="fl-r">
    <app-details-navigation [menu]="menu"></app-details-navigation>
  </div>
</div>

<div class="clear"></div>

<router-outlet></router-outlet>

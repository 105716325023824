import { ActionReducerMap } from "@ngrx/store";

import { AppState, messagesState, MessagesState } from "./app.state";
import { ActionTypes, UnsafeAction } from "./app.actions";

export function messages(
  state = messagesState,
  action: UnsafeAction
): MessagesState {
  switch (action.type) {
    case ActionTypes.SET_MESSAGES_SUCCESS: {
      return {
        ...state,
        messagesNum: action.payload,
      };
    }
    case ActionTypes.SET_MESSAGES_FAILED: {
      return state;
    }
  }
  return state;
}

export const getStoreMessages = (state: AppState) => state.messages;

export const reducers: ActionReducerMap<AppState> = {
  messages,
};

import { Pipe, PipeTransform } from "@angular/core";
import { Observable, map } from "rxjs";
import { DictionariesService } from "src/app/_services/dictionaries.service";

/**
 * @usageNotes
 * Needs to be used with async pipe always it returns an observable holding the country name
 * example:
 * * `{{ string | country | async }}`
 */
@Pipe({
  name: "country",
})
export class CountryPipe implements PipeTransform {
  constructor(private dictionary: DictionariesService) {}

  transform(value: string): Observable<string> {
    return this.dictionary.getCountryMap().pipe(
      map((country) => country.get(value.toUpperCase()) ?? value),
      map((name) => {
        console.log(value);
        return name
          .split(" ")
          .map(
            (name) =>
              name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase()
          )
          .join(" ");
      })
    );
  }
}

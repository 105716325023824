import { Injectable } from "@angular/core";
import { Product } from "../models/product.model";
import { PriceMapperService } from "./price-mapper.service";

@Injectable({
  providedIn: "root",
})
export class ProductMapperService {
  constructor(private readonly priceMapper: PriceMapperService) {}

  mapOne(input: any): Product {
    if (input === null) {
      return null;
    }
    return {
      ...input,
      default_price: this.priceMapper.mapOne(input.default_price),
      prices: this.priceMapper.mapMany(input.prices),
    };
  }

  mapMany(input: any[]): Product[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: any): Product[] {
    return {
      ...input,
    };
  }
}

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "../../../modules/shared/_enums/permission-resource.enum";
import { User } from "../../../_models/user";
import { AuthenticationService } from "../../../_services/authentication.service";

@Component({
  selector: "app-left-navigation-xs",
  templateUrl: "./left-navigation-xs.component.html",
})
export class LeftNavigationXsComponent {
  public resource = PermissionResourceEnum;
  public subResource = PermissionSecondaryResourceEnum;
  public action = PermissionActionEnum;
  public user: User;
  urlMarketplace: string;

  constructor(
    public router: Router,
    public authService: AuthenticationService
  ) {
    this.urlMarketplace = environment.marketplace;
    this.user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
  }

  goToBlog() {
    // window.open(environment.marketplace_url + 'blog');
    window.open("https://xpath.zendesk.com/hc/en-us");
  }

  openMobileNav() {
    document.querySelector("body").classList.add("lock-scroll");
    document
      .querySelector(".mobile-navigation-wrapper")
      .classList.add("open-mobile-nav");
  }

  closeMobileNav() {
    document.querySelector("body").classList.remove("lock-scroll");
    document
      .querySelector(".mobile-navigation-wrapper")
      .classList.remove("open-mobile-nav");
  }
}

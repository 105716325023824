import { Component, OnInit, ViewChild } from "@angular/core";

import { ItemsListComponent } from "../../items-list/items-list.component";
import { ServiceCategoriesService } from "../../../../_services/servicecategories.service";
import { ServiceCategory } from "../../../../_models/servicecategory";
import { AlertService } from "../../../../_services/alert.service";
import { AddCategoryComponent } from "../add-category/add-category.component";
import { NgxSmartModalService } from "ngx-smart-modal";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.css"],
})
export class CategoriesComponent extends ItemsListComponent implements OnInit {
  parent: ServiceCategory;
  selectedCategory: ServiceCategory;

  @ViewChild(AddCategoryComponent, { static: true })
  private _initEmpty: AddCategoryComponent;

  constructor(
    private service: ServiceCategoriesService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList(
        "/cms/service-categories",
        "Service Categories"
      )
    );
    this.selectedCategory = new ServiceCategory();
    this.parent = new ServiceCategory();
  }

  ngOnInit() {
    this.getItems();
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = this.getParams(page);
    this.isLoadingGeneral = true;
    this.request_call = this.service
      .getServiceCategories({
        ...params,
        service_types: "",
      })
      .pipe(
        finalize(() => {
          this.isLoadingGeneral = false;
        })
      )
      .subscribe((data) => {
        this.processResponse(data);
      });
  }

  public update(categories: ServiceCategory[]) {
    // update priorities
    for (let i = 0; i < categories.length; i++) {
      categories[i].priority = i + 1;
    }

    this.service.bulkUpdateCategories({ categories: categories }).subscribe(
      (data) => {
        if (data.success) {
          // this.alert.success([data.message]);
        } else {
          this.alertService.errors(data.error.errors);
        }
      },
      (error) => {
        this.alertService.errors(error.error.errors);
      }
    );
  }

  openModal() {
    this.ngxSmartModalService.getModal("addCategory").open();
  }

  addSubcategory(parent: ServiceCategory) {
    this.parent = parent;
    this.openModal();
  }

  activate(category: ServiceCategory) {
    this.service.activateServiceCategories(category).subscribe(
      (data) => {
        this.getItems();
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  deactivate(category: ServiceCategory) {
    this.service.deleteServiceCategories(category).subscribe(
      (data) => {
        this.getItems();
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  edit(category: ServiceCategory) {
    this.selectedCategory = category;
    this.openModal();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

import { Injectable } from "@angular/core";
import { StripePrice } from "../models/product.model";

@Injectable({
  providedIn: "root",
})
export class PriceMapperService {
  mapOne(input: any): StripePrice {
    if (input === null) {
      return null;
    }

    return {
      ...input,
      unit_amount: input.unit_amount / 100,
    };
  }

  mapMany(input: any[]): StripePrice[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: any): StripePrice[] {
    return {
      ...input,
    };
  }
}

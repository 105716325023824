<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModal }">
  <ngx-smart-modal
    id="addCategory"
    #addCategory
    identifier="addCategory"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title" *ngIf="category?.id">
        {{ "GENERALS.EDIT-CATEGORY" | translate }}
      </div>

      <div class="modal-title" *ngIf="!category?.id && !parent?.id">
        {{ "GENERALS.ADD-MAIN-CATEGORY" | translate }}
      </div>

      <div class="modal-title" *ngIf="!category?.id && parent?.id">
        {{ "GENERALS.ADD-SUBCATEGORY" | translate }}
      </div>
    </div>

    <div class="clear"></div>
    <div class="custom-modal-body">
      <ng-container>
        <!-- <div class="modal-body-desc">{{ 'GENERALS.IPSUM' | translate }}</div> -->

        <div class="modal-form">
          <div class="row">
            <div class="form-group w-50">
              <label>{{ "LABEL.CATEGORY-NAME" | translate }}</label>
              <input
                type="text"
                name="category_name"
                class="form-control"
                [(ngModel)]="category.label"
              />
            </div>

            <div class="form-group w-50">
              <label>Drupal URL</label>
              <input
                type="text"
                name="drupal_url"
                class="form-control"
                [(ngModel)]="category.drupal_url"
              />
            </div>
          </div>

          <div class="row">
            <div class="form-group w-100">
              <label>{{ "GENERALS.CATEGORY-ICON" | translate }}</label>
              <ng-select
                class="customiz-select white-select min-select-width"
                [items]="iconsList.icons"
                bindLabel="text"
                bindValue="id"
                [(ngModel)]="category.icon"
                notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                placeholder="{{ 'GENERALS.SELECT-ICON' | translate }}"
                [clearable]="false"
              >
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="select-option-with-icon">
                    <i class="{{ item.id }}"></i>
                    {{ item.text }}
                  </div>
                </ng-template>
              </ng-select>

              <!-- [(ngModel)]="selectedPath" -->
              <!-- (change)="selectCategory($event)" -->
            </div>
          </div>

          <div class="row">
            <div class="form-group w-50 textar">
              <div class="category-default-commission">
                <label>{{ "LABEL.DEFAULT-COMMISSION" | translate }}</label>

                <mat-radio-group
                  class="form-group-radio-btn"
                  *ngFor="let type of commissionTypes"
                  [(ngModel)]="category.commission_type_id"
                >
                  <mat-radio-button [value]="type.id">
                    {{ type.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <input
                type="number"
                name="commission"
                class="form-control"
                [(ngModel)]="category.commission"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group w-50">
              <label>{{ "GENERALS.SERVICE_TYPES" | translate }}</label>

              <ng-select
                class="customiz-select"
                [items]="allowedServiceTypes"
                bindLabel="text"
                [multiple]="true"
                bindValue="id"
                [(ngModel)]="category.service_types"
                notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                placeholder="{{ 'GENERALS.SELECT-ICON' | translate }}"
                [clearable]="false"
              >
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="select-option-with-icon">
                    {{ item.replace("_", " ") | titlecase }}
                  </div>
                </ng-template>
              </ng-select>

              <!-- [(ngModel)]="selectedPath" -->
              <!-- (change)="selectCategory($event)" -->
            </div>
          </div>
          <div class="row">
            <div class="form-group w-100">
              <label>{{ "LABEL.DETAILS" | translate }}</label>
              <textarea
                name="details"
                class="textarea-control"
                [(ngModel)]="category.data"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <div class="form-group w-50">
              <label>Page URI</label>
              <input
                type="text"
                name="page_uri"
                class="form-control"
                [(ngModel)]="category.page_uri"
              />
            </div>
            <div class="form-group w-50">
              <label>{{ "LABEL.SEO_TITLE" | translate }}</label>
              <input
                type="text"
                name="seo_title"
                class="form-control"
                [(ngModel)]="category.seo_title"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group w-100">
            <label>{{ "LABEL.SEO_DESCRIPTION" | translate }}</label>
            <textarea
              name="seo_description"
              class="textarea-control"
              [(ngModel)]="category.seo_description"
            ></textarea>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="clear"></div>

    <div class="modal-footer-l-r">
      <div class="modal-footer-l" *ngIf="!category?.id">
        <button type="button" class="btn orange-bg" (click)="add(true)">
          {{ "GENERALS.ADD-ANOTHER" | translate }}
        </button>
      </div>

      <div class="modal-footer-r">
        <button type="button" class="btn gray-bg" (click)="cancelModal()">
          {{ "GENERALS.CANCEL" | translate }}
        </button>

        <button
          type="button"
          class="btn orange-bg"
          (click)="update()"
          *ngIf="category?.id"
        >
          {{ "GENERALS.DONE" | translate }}
        </button>

        <button
          type="button"
          class="btn orange-bg"
          (click)="add()"
          *ngIf="!category?.id"
        >
          {{ "GENERALS.DONE" | translate }}
        </button>
      </div>
    </div>
  </ngx-smart-modal>
</div>

<!-- end modal -->

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
} from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { RFQ } from "src/app/_models/rfq";
import { RFQSService } from "src/app/_services/rfqs.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import * as moment from "moment";

@Component({
  selector: "app-add-rfqs-bids",
  templateUrl: "./add-rfqs-bids.component.html",
})
export class AddRFQBidsComponent implements OnInit, OnChanges {
  @Input() type;
  item;
  setModalOpen = true;
  isLoadingGeneral = false;
  currentStep = 1;
  user;
  @Output() onnext: EventEmitter<any> = new EventEmitter();
  isOpenRfqBid: boolean;
  inputNumberEmpty = false;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private rfqService: RFQSService,
    private router: Router
  ) {
    this.setModalOpen = false;
    // this.initEmpty();
    this.item = new RFQ();
    if (this.user) {
      this.item.created_by_id = this.user.id;
    }
    this.isOpenRfqBid = true;
  }

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
    this.item.created_by_id = this.user.id;
  }

  ngOnChanges(changes) {
    if (changes && changes.type && changes.type.currentValue) {
      this.item.is_bid = this.type && this.type === "rfq" ? 0 : 1;
    }
  }

  initEmpty(): void {
    this.item = new RFQ();
    if (this.user) {
      this.item.created_by_id = this.user.id;
    }
    this.currentStep = 1;
  }

  isModalOpen() {
    this.setModalOpen = true;
  }

  isModalClose() {
    this.setModalOpen = false;
  }

  cancelModal() {
    this.ngxSmartModalService.getModal("addRFQAndBid").close();
    this.initEmpty();
  }

  checkItemProps() {
    if (this.currentStep === 1) {
      return (
        this.item.client_id &&
        this.item.created_by_id &&
        this.item.country &&
        this.item.city &&
        this.item.delivery_date &&
        this.item.start_date &&
        this.item.end_date &&
        this.item.start_time &&
        this.item.end_time &&
        (this.item.is_bid
          ? this.item.target_currency_id &&
            this.item.target_price &&
            this.item.mix_quality_price >= 0 &&
            this.item.lowest_price >= 0
          : true)
      );
    } else if (this.currentStep === 2) {
      let checkDescription;
      if (Object.prototype.hasOwnProperty.call(this.item, "offers_request")) {
        checkDescription = this.item["offers_request"].every(
          (x) =>
            Object.prototype.hasOwnProperty.call(x, "service_description") &&
            x["service_description"].trim().length > 0
        );
      }
      return (
        this.item.categories &&
        this.item.categories.length &&
        !this.inputNumberEmpty &&
        checkDescription
      );
    } else if (this.currentStep === 3) {
      return this.item.providers && this.item.providers.length;
    }
  }

  create() {
    this.isLoadingGeneral = true;
    this.isOpenRfqBid = false;
    this.item.is_bid = this.type && this.type === "rfq" ? 0 : 1;

    this.item.start_date = this.addTimeToDate(
      this.item.start_date,
      this.item.start_time
    );
    this.item.end_date = this.addTimeToDate(
      this.item.end_date,
      this.item.end_time
    );

    this.rfqService.createRFQAndBid(this.item).subscribe(
      (res) => {
        this.isLoadingGeneral = false;
        this.isOpenRfqBid = true;

        this.cancelModal();
        this.onnext.emit();
        this.router.navigate(["rfqs-bids/" + res.result.id]);
      },
      (error) => {
        this.isLoadingGeneral = false;
        this.isOpenRfqBid = true;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  saveDraft() {
    this.item.start_date = this.addTimeToDate(
      this.item.start_date,
      this.item.start_time
    );
    this.item.end_date = this.addTimeToDate(
      this.item.end_date,
      this.item.end_time
    );

    this.isLoadingGeneral = true;
    this.item.is_bid = this.type && this.type === "rfq" ? 0 : 1;

    this.rfqService.saveDraftRFQAndBid(this.item).subscribe(
      (res) => {
        this.cancelModal();
        this.isLoadingGeneral = false;
        this.onnext.emit();
        this.router.navigate(["rfqs-bids/" + res.result.id]);
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }
  disabledNextAction(e) {
    this.inputNumberEmpty = e;
  }

  addTimeToDate(date, timeObj) {
    // format date 00:00
    const formatDate = moment(date).startOf("day").toISOString();
    return moment(formatDate)
      .add(timeObj.hour, "hours")
      .add(timeObj.minute, "minutes")
      .toISOString();
  }
}

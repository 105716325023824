import { Location } from "@angular/common";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from "file-saver";
import cloneDeep from "lodash-es/cloneDeep";
import cloneDeepWith from "lodash-es/cloneDeepWith";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { OrderStatusEnum } from "src/app/_enums/order-status-enum";
import { AlertService } from "src/app/_services/alert.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { environment } from "src/environments/environment";
import deepCastToInt from "../../../_helpers/deepCastToInt";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { OrdersService } from "../../../_services/orders.service";
import { ConfirmActionModalComponent } from "../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { ItemsListComponent } from "../items-list/items-list.component";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent
  extends ItemsListComponent
  implements OnInit, OnChanges
{
  active = "";
  direction = "";
  isXs: boolean = false;
  isFiltersLoaded: boolean = false;
  checkClientOrder = false;
  @Input() entity_id;
  @Input() hideTitle = true;
  @Input() entity_type;
  @Input() stage;

  constructor(
    protected service: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService,
    protected dialog: MatDialog,
    protected alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {
    if (
      this.location.path().indexOf("providers") === -1 &&
      this.location.path().indexOf("clients") === -1
    ) {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForList("/orders", "orders")
      );
    }

    if (!this.checkClientOrder) {
      this.watchQueryParams();
      this.getStatusTransitions();

      this.windowResizeHelper.getData().subscribe((data) => {
        this.isXs = data.responsiveStatus;
      });
    } else {
      this.getItems();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stage == "client-order") {
      this.checkClientOrder = true;
      if (
        this.entity_id &&
        this.entity_type &&
        this.entity_type !== undefined
      ) {
        this.filters = this.getParams(1);
        this.getItems();
      }
    }
  }

  public get OrderStatus() {
    return OrderStatusEnum;
  }

  protected watchQueryParams() {
    this.route.queryParams.subscribe((query) => {
      if (query && Object.keys(query).length > 0) {
        this.filters = cloneDeepWith(query, deepCastToInt);
      } else {
        this.clearFilters();
        const params = this.getParams(1);
        this.filters = params;
      }
      if (this.filters["search_text"]) {
        this.search_text = this.filters["search_text"];
      }
      if (this.filters["per_page"]) {
        this.perPage = this.filters["per_page"];
      }
      if (this.filters["order"]) {
        this.active = this.filters["order"];
        this.direction = this.filters["order_direction"];
      } else {
        this.active = "";
        this.direction = "";
      }

      this.getItems();
    });
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    if (this.entity_id) {
      if (!id) {
        this.getItems();
      } else {
        const host = environment.app_host;
        const ordersUrl = "/orders/" + id;
        const url = host + ordersUrl;
        window.open(url, "_blank");
      }
    } else {
      let url = "";
      if (id) {
        url = "/orders/" + id;
      } else {
        url = "/orders";
      }
      this.router.navigate([url], {
        queryParams: params,
      });
    }
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = {};
    params = cloneDeep(this.filters);
    if (params["entity_id"]) {
      params["entity_type"] = "entity";
    }
    if (this.entity_id) {
      params["entity_id"] = this.entity_id;
    }
    if (this.entity_type && this.entity_type !== undefined) {
      params["entity_type"] = this.entity_type;
    }
    let orders$ = this.service.getOrdersList(params);

    if (this.entity_id && this.location.path().indexOf("provider") > -1) {
      delete params["entity_id"];
      orders$ = this.service.getProviderOrdersList(params, this.entity_id);
    }
    this.isLoadingGeneral = true;
    this.request_call = orders$
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public getStatusTransitions() {
    this.service.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  expatsOnOrder(order) {
    return order.details.reduce((sum, value) => (sum += value.quantity), 0);
  }

  assignedExpatsOnOrder(order) {
    return order.details.reduce(
      (sum, detail) => (sum += detail.expats.length),
      0
    );
  }

  remainingExpatsToAssign(order) {
    return this.expatsOnOrder(order) - this.assignedExpatsOnOrder(order);
  }

  /**
   * Export orders to CSV
   */
  exportOrders() {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = {};
    params = cloneDeep(this.filters);
    this.request_call = this.service.exportOrders(params).subscribe(
      (data: Blob) => {
        const blob = new Blob([data], {
          type: "application/vnd.ms-excel",
        });
        saveAs(blob, "orders.xlsx");
        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  openConfirmAction(action, order?) {
    let ids = [];

    if (order) {
      ids = [order.id];
    } else {
      this.selection.selected.forEach((row) => ids.push(row.id));
    }

    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.service.updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.isLoadingGeneral = false;
              this.watchQueryParams();
              if (data && data.message) {
                this.alertService.success(data.message);
              }
            },
            (error) => {
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
              this.isLoadingGeneral = false;
            }
          );
        }
      });
    } else {
      this.service.updateStatusBulk(action.id, ids, null).subscribe(
        (data) => {
          this.isLoadingGeneral = false;
          this.watchQueryParams();
          if (data && data.message) {
            this.alertService.success(data.message);
          }
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoadingGeneral = false;
        }
      );
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

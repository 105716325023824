import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { ServiceOffer } from "../_models/service-offer";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Directive()
@Injectable()
export class ServiceOffersService {
  private serviceOffersUrl = "offers/offers";
  private offProvider = "offers/provider";
  private offerHistory = "history/offer";

  @Output() statusChange: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {}

  getServiceOffers(params: any = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceOffersUrl,
      {
        params: {
          ...params,
          exclude_id: params.exclude_id ? params.exclude_id : ";",
        },
      }
    );
  }

  getServiceOfferById(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceOffersUrl + "/" + id
    );
  }

  createServiceOffers(offer: ServiceOffer): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.serviceOffersUrl,
        offer,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateServiceOffers(offer: ServiceOffer, id): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + this.serviceOffersUrl + "/" + id,
        offer,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update Status Bulk
  updateStatusBulk(status_id, offer_ids, reason = ""): Observable<any> {
    let body: any = { status_id: status_id, offer_ids: offer_ids };
    if (reason) {
      body = {
        status_id: status_id,
        offer_ids: offer_ids,
        decline_message: reason,
      };
    }

    const response = this.http.patch<any>(
      environment.gateway_endpoint + this.serviceOffersUrl,
      body,
      httpOptions
    );

    this.statusChange.emit();

    return response;
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.serviceOffersUrl +
        "/status-transitions"
    );
  }

  // Upload by doc type
  uploadImage(id, files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", files[0], files[0].name);
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "offers/offers/" +
          id +
          "/setPrimaryImage",
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Upload by doc type
  uploadBulkOffers(provider_id, category_id, obj): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    formData.append("provider_id", provider_id);
    formData.append("category_id", category_id);

    return this.http
      .post<any>(environment.gateway_endpoint + "offers/import", formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  downloadExcel(provider_id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.offProvider +
        "/" +
        provider_id +
        "/download-offers-zip",
      { responseType: "blob" as const }
    );
  }

  createBundleServiceOffers(offer: ServiceOffer): Observable<any> {
    return this.http
      .post<any>(environment.gateway_endpoint + "bundles", offer, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getBundleServiceOffersById(id): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "bundles/" + id);
  }

  // Copy offer
  copyOffer(offerId): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        this.serviceOffersUrl +
        "/" +
        offerId +
        "/duplicate",
      {},
      httpOptions
    );
  }

  // GET list of associated offers list
  getAssociatedOffers(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + `offers/offers/${id}/associations`
    );
  }

  getOffersReviews(id, params): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "offers/" + id + "/reviews",
      {
        params: params,
      }
    );
  }

  approveOfferReview(offerReviewId): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        "offer-review" +
        "/" +
        offerReviewId +
        "/approve",
      {},
      httpOptions
    );
  }

  deleteOfferReview(offerReviewId): Observable<any> {
    return this.http.delete(
      environment.gateway_endpoint + "offer-review" + "/" + offerReviewId,
      httpOptions
    );
  }

  // GET Offer history
  getOfferHistory(id, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.offerHistory + "/" + id,
      {
        params: params,
      }
    );
  }

  calculateDiscount(oldP, newP) {
    return Math.round(((oldP - newP) / oldP) * 100);
  }

  /**
   * Get number of providers pending approval
   */
  getPendingCount(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.serviceOffersUrl + "/pending/count",
      { params: { "low-priority": "low-priority" } }
    );
  }

  /**
   * Export filtered offers to CSV
   * @param params
   */
  export(params = {}) {
    return this.http.get(
      environment.gateway_endpoint + this.serviceOffersUrl + "/export",
      {
        responseType: "blob",
        params: params,
      }
    );
  }
}

export class ClientDetails {
  links: Array<any>;

  constructor(_id: number, filters) {
    this.links = [
      {
        link: "/clients/" + _id,
        filters: filters,
        label: "Details",
        page: "details",
      },
      {
        link: "/clients/" + _id + "/users",
        filters: filters,
        label: "Users",
        page: "users",
      },
      {
        link: "/clients/" + _id + "/orders",
        filters: filters,
        label: "Orders",
        page: "orders",
      },
      {
        link: "/clients/" + _id + "/docs",
        filters: filters,
        label: "Docs",
        page: "docs",
      },
      {
        link: "/clients/" + _id + "/history",
        filters: filters,
        label: "History",
        page: "history",
      },
    ];
  }
}

import { Action } from "@ngrx/store";
import { type } from "src/app/_helpers/type";

export const ActionTypes = {
  // NOTIF ACTIONS
  SET_NOTIFICATION: type("[Notification] Set Notification"),
  SET_NOTIFICATION_SUCCESS: type("[Notification] Save Success"),
  SET_NOTIFICATION_FAILED: type("[Notification] Save Failed"),
  SET_MESSAGES: type("[Messages] Set Messages"),
  SET_MESSAGES_SUCCESS: type("[Messages] Save Success"),
  SET_MESSAGES_FAILED: type("[Messages] Save Failed"),
};

export interface UnsafeAction extends Action {
  payload?: any;
}

export class SetNotificationNum implements UnsafeAction {
  type = ActionTypes.SET_NOTIFICATION;
  constructor(public payload: any) {}
}

export class SetMessagesNum implements UnsafeAction {
  type = ActionTypes.SET_MESSAGES;
  constructor(public payload: any) {}
}

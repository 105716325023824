import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-bulk-actions",
  templateUrl: "./bulk-actions.component.html",
  styleUrls: ["./bulk-actions.component.css"],
})
export class BulkActionsComponent implements OnChanges {
  public availableActions = [];

  @Input() selection: SelectionModel<any>;
  @Input() actions;

  @Output() selectionCleared: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setAvailableStatuses();

    this.selection.changed.subscribe((data) => {
      this.setAvailableStatuses();
    });
  }

  // Generic helper function that can be used for the three operations:
  private operation = (list1, list2, isUnion = false) =>
    list1.filter((a) => isUnion === list2.some((b) => a.id === b.id));

  // Following functions are to be used:
  private inBoth = (list1, list2) => this.operation(list1, list2, true);

  private setAvailableStatuses() {
    const selectedStatuses = [];
    this.availableActions = [];

    let isFirstLoop = true;

    // go through the selection and determine which actions should be available;
    this.selection.selected.forEach((item) =>
      selectedStatuses.push(item.status_id)
    );

    for (const index in selectedStatuses) {
      if (isFirstLoop) {
        this.availableActions = this.actions[selectedStatuses[index]];
        isFirstLoop = false;
      } else {
        this.availableActions = this.inBoth(
          this.availableActions,
          this.actions[selectedStatuses[index]]
        );
      }
    }
  }

  /**
   * Clear bulk selection
   */
  clearSelection() {
    this.selection.clear();
    this.selectionCleared.emit();
  }
}

import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { combineLatest } from "rxjs";
import { AdsDetails } from "src/app/_models/_menu/ads-details";

@Component({
  selector: "app-ads-wrapper",
  templateUrl: "./ads-wrapper.component.html",
  styleUrls: ["./ads-wrapper.component.css"],
})
export class AdsWrapperComponent {
  menu: AdsDetails;
  private id;
  filters;

  constructor(private route: ActivatedRoute) {
    combineLatest(this.route.params, this.route.queryParams).subscribe(
      ([params, query]) => {
        this.id = params["id"];
        this.filters = query;

        this.menu = new AdsDetails();
      }
    );
  }
}

<label>
  {{ "FILTERS.STATUS" | translate }}
</label>

<ng-select
  class="customiz-select"
  [items]="options"
  bindLabel="text"
  bindValue="id"
  placeholder="{{ 'FILTERS.STATUS' | translate }}"
  [loading]="loading"
  loadingText="Loading text"
  [(ngModel)]="selectedStatusId"
  notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
  (change)="selectedStatus.emit(selectedStatusId)"
>
</ng-select>

import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  ngOnInit() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("auth");
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("auth");
  }
}

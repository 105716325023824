import { Component, OnInit } from "@angular/core";
import { AdsService } from "src/app/_services/ads.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { finalize } from "rxjs";

@Component({
  selector: "app-ads",
  templateUrl: "./ads.component.html",
  styleUrls: ["./ads.component.css"],
})
export class AdsComponent implements OnInit {
  isLoadingGeneral: boolean;
  request_call;
  listBannerType;
  isXs: boolean;
  editData;

  constructor(
    private adsService: AdsService,
    private windowResizeHelper: WindowResizeHelperService,
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/cms/ads/pricing", "Ads & Pricing")
    );
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
    this.getListBannerType();
  }

  getListBannerType() {
    this.isLoadingGeneral = true;
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.request_call = this.adsService
      .getListBannerType()
      .pipe(
        finalize(() => {
          this.isLoadingGeneral = false;
        })
      )
      .subscribe((data) => {
        this.listBannerType = data.result;
        this.isLoadingGeneral = false;
      });
  }

  updateBanner(e) {
    this.adsService.updateBanner(e).subscribe(
      (data) => {
        this.getListBannerType();
      },
      (error) => {
        this.alertService.errors(error.error.errors);
      }
    );
  }

  openEditUnitPrice(data) {
    this.ngxSmartModalService.getModal("editUnitPrice").open();
    this.editData = data;
  }

  saveEditData(e) {
    this.updateBanner(e);
    this.ngxSmartModalService.getModal("editUnitPrice").close();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProviderDetails } from "src/app/_models/_menu/provider-details";
import { Provider } from "../../../../_models/provider";
import { ProvidersService } from "../../../../_services/providers.service";
import { Location } from "@angular/common";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { combineLatest } from "rxjs";

@Component({
  selector: "app-client",
  templateUrl: "./provider.component.html",
  styleUrls: ["./provider.component.css"],
})
export class ProviderComponent implements OnInit {
  private id;
  public menu: ProviderDetails;
  public provider: Provider;
  filters;

  constructor(
    private route: ActivatedRoute,
    private service: ProvidersService,
    private location: Location,
    private bs: BreadcrumbService
  ) {
    combineLatest(this.route.params, this.route.queryParams).subscribe(
      ([params, query]) => {
        this.id = params["id"];
        this.filters = query;

        const { ...menuQuery } = query;

        this.menu = new ProviderDetails(params["id"], menuQuery);
      }
    );
  }

  ngOnInit() {
    // get provider details to show the name at the top
    this.service.getProvider(this.id).subscribe((data) => {
      this.provider = data.result;
    });
  }
}

<div
  class="category-tree-group"
  *ngFor="let item of categories | keyvalue"
  (window:resize)="onResize()"
>
  <div
    toggle-row
    class="toggle-row-action"
    *ngIf="item.value.active_children_count"
  >
    <i class="icon-plus-1"></i>
  </div>
  <div class="tree-row">
    <div
      class="tree-check fl-l"
      *ngIf="
        (!edit && !item.value.active_children_count) ||
        (!readonlySelection.isSelected(item.value) &&
          !item.value.active_children_count)
      "
    >
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? changeSelection(item.value) : null"
        [checked]="selection.isSelected(item.value)"
        [disableRipple]="true"
      >
      </mat-checkbox>
    </div>
    <div
      class="tree-check dark-green fl-l"
      *ngIf="
        edit &&
        readonlySelection.isSelected(item.value) &&
        !item.value.active_children_count
      "
    >
      <i class="icon-ok"></i>
    </div>

    <span
      class="tree-label fl-l"
      [ngClass]="{ 'fw-500': item.value.level === 0, 'fs-15': isRFQ }"
      >{{ item.value.label }}</span
    >

    <ng-container *ngFor="let selected of previouslySelectedCategory">
      <ng-container *ngIf="item.value.id === selected.service_category_id">
        <ng-container
          class="fs-16"
          *ngTemplateOutlet="
            templateRef;
            context: { id: selected.service_category_id }
          "
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="toggle-tree" *ngIf="item.value.active_children_count">
    <app-categories-tree
      [serviceCategories]="item.value.children"
      [selectedServiceCategories]="previouslySelectedCategory"
      [isRFQ]="isRFQ"
      [edit]="edit"
      (onselect)="onselect.emit($event)"
      [templateRef]="templateRef"
    ></app-categories-tree>
  </div>
</div>

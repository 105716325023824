import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { AlertService } from "../_services/alert.service";
import { AuthenticationService } from "../_services/authentication.service";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private translate: TranslateService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // auto logout if 401 response returned from api
          if (this.router.url.indexOf("login") === -1) {
            this.authenticationService.logout(error.status);
            location.reload();
          }
        }

        if (
          !Array.isArray(error.error.errors) &&
          typeof error.error.errors === "object"
        ) {
          this.alertService.errors(Object.values(error.error.errors));
        } else if (error?.error?.errors) {
          this.alertService.errors(error.error.errors);
        } else if (error?.error?.message) {
          this.alertService.errors([error.error.message]);
        } else {
          this.translate.get("GENERALS.GENERIC_ERROR").subscribe((data) => {
            this.alertService.error([data]);
          });
        }

        return throwError(error);
      })
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ServiceOfferDetails } from "src/app/_models/_menu/service-offer-details";
import { ServiceOffersService } from "src/app/_services/serviceoffers.service";
import { Location } from "@angular/common";
import { combineLatest } from "rxjs";

@Component({
  selector: "app-client",
  templateUrl: "./service-offer.component.html",
  styleUrls: ["./service-offer.component.css"],
})
export class ServiceOfferComponent implements OnInit {
  menu;
  offer;
  id;
  filters;

  constructor(
    private route: ActivatedRoute,
    public serviceOffers: ServiceOffersService,
    private location: Location
  ) {
    combineLatest(this.route.params, this.route.queryParams).subscribe(
      ([params, query]) => {
        this.id = params["id"];
        this.filters = query;

        this.menu = new ServiceOfferDetails(params["id"], query);
      }
    );
  }

  ngOnInit() {
    this.serviceOffers.getServiceOfferById(this.id).subscribe((data) => {
      this.offer = data.result;
    });
  }
}

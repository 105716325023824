<div class="select-wrapper">
  <ng-select
    class="customiz-select date-filter-select"
    [items]="options"
    bindLabel="text"
    bindValue="id"
    placeholder="{{ 'GENERALS.CHANGE-PERIOD' | translate }}"
    [(ngModel)]="dateModel"
    notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
    (change)="selectedTime($event)"
  >
  </ng-select>
</div>

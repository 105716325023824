import { Directive, HostBinding, HostListener } from "@angular/core";

@Directive({
  selector: "[expand-menu]",
})
export class ExpandMenuDirective {
  @HostBinding("class.sub-is-open") isOpen = false;

  @HostListener("click") toggleOpen($event) {
    this.isOpen = !this.isOpen;
  }
}

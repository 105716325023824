import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { AlertService } from "../../../../_services/alert.service";
import { DocumentsService } from "../../../../_services/documents.service";

@Component({
  selector: "app-add-profile-image",
  templateUrl: "./add-profile-image.component.html",
  styleUrls: ["./add-profile-image.component.css"],
})
export class AddProfileImageComponent implements OnInit {
  @Input() entityType;
  @Input() entityId;

  public fileUploadControl = new FileUploadControl();
  public uploadedFiles = { file: null, name: null, status: null };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddProfileImageComponent>,
    private service: DocumentsService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.entityId = this.data.entityId;
    this.entityType = this.data.entityType;
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.service
      .uploadProfilePicture(this.entityId, this.uploadedFiles, this.entityType)
      .subscribe(
        (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.alertService.success("Saved successfully");
          this.dialogRef.close({ res });
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }
}

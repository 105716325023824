import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { ProvidersService } from "src/app/_services/providers.service";

@Component({
  selector: "app-provider-activity",
  templateUrl: "./provider-activity.component.html",
  styleUrls: ["./provider-activity.component.css"],
})
export class ProviderActivityComponent implements OnInit {
  public id;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private service: ProvidersService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  ngOnInit() {
    this.service.getProvider(this.id).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForProvider(data.result, "history")
      );
    });
  }
}

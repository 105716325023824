<div class="details-2c-left m-r-15">
  <div class="small-box">
    <div class="small-box-header-img">
      <img
        [src]="entity.primary_image_path"
        alt="img"
        class="profile-pic"
        (error)="
          $event.target['src'] = '../assets/images/profile-pics-placeholder.jpg'
        "
        (click)="openUploadModal()"
      />
      <div class="edit-trigger">
        {{ "GENERALS.CHANGE-PIC" | translate }}
      </div>
    </div>

    <div class="small-box-details">
      <div class="medium-title">
        {{ entity.company_name }}
      </div>

      <div class="rating" *ngIf="entityType === entityTypes.PROVIDER">
        <app-rating [currentRate]="rating"></app-rating>
        <span class="review-nr"
          >({{ rating ? rating : "0" }})
          {{ "GENERALS.RATING" | translate }}</span
        >
      </div>

      <div class="small-box-status">
        <div class="entity_status-label-{{ entity.status_id }}">
          {{ entity.status_name }}
        </div>
      </div>

      <div class="fw-500" *ngIf="entity.status_reason || ''">
        <span class="gray block-span"
          >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
        >
        {{ entity.status_reason }}
      </div>

      <div class="fw-500">
        <span class="gray block-span"
          >{{ "GENERALS.REGISTRED-ON" | translate }}:</span
        >
        {{ entity.created_at | date : "dd/MM/yyyy" }}
      </div>

      <div *ngIf="entityType === entityTypes.PROVIDER" class="fw-500 inline">
        <label for="managed" class="pr-10">{{
          "GENERALS.MANAGED_BY_XPATH" | translate
        }}</label>
        <input
          id="managed"
          class="checkbox-switch"
          [(ngModel)]="entity.is_managed"
          (change)="changeManagedState()"
          type="checkbox"
          name="enabled"
        />
      </div>
    </div>
    <div class="small-box-list">
      <div class="loading-wrapper">
        <div *ngIf="isLoadingStatus">
          <app-loader-spinner-general
            [loadingType]="LoadingType.BUTTONS"
          ></app-loader-spinner-general>
          <app-loader-spinner-general
            [loadingType]="LoadingType.BUTTONS"
          ></app-loader-spinner-general>
        </div>
      </div>
      <ul *ngIf="!isLoadingStatus">
        <ng-container *ngFor="let option of statusOptions">
          <li>
            <div
              (click)="openStatusChangeAction(option)"
              class="btn-small orange-bg"
            >
              {{ option.text }}
            </div>
          </li>
        </ng-container>
        <li class="action-link-gray" (click)="openImpersonateModal()">
          <i class="icon-right-dir"></i>
          {{ "GENERALS.LOG-IN-AS" | translate }}
          {{ entityType }}
        </li>
      </ul>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ProvidersService } from "src/app/_services/providers.service";
import { ActivatedRoute } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import cloneDeepWith from "lodash-es/cloneDeepWith";
import { ProvidersCommissions } from "src/app/_models/providerscommissions";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { NgxSmartModalService } from "ngx-smart-modal";

@Component({
  selector: "app-commission",
  templateUrl: "./commission.component.html",
  styleUrls: ["./commission.component.css"],
})
export class CommissionComponent implements OnInit {
  isLoadingGeneral: boolean;
  id;
  categories = {};
  categoryLabels = [];
  edit = false;
  selectedCategories = {};
  provider;
  countries;
  countryCodes = [];

  private _onDestroy = new Subject<void>();

  constructor(
    private service: ProvidersService,
    private dictionariesService: DictionariesService,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
    this.isLoadingGeneral = true;
  }

  ngOnInit() {
    this.getProvider();
    this.getCountryList();
    this.getProviderCommision();
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe(
      (data) => {
        this.countries = data.result;
      },
      (error) => {
        this.isLoadingGeneral = false;
      }
    );
  }

  getProvider() {
    this.service.getProvider(this.id).subscribe(
      (res) => {
        this.provider = res.result;
        this.countryCodes = [];
        this.provider.countries.forEach((c) => {
          this.countryCodes.push(c.name);
        });
      },
      (error) => {
        this.isLoadingGeneral = false;
      }
    );
  }

  getProviderCommision() {
    this.service.getProviderCommission(this.id).subscribe(
      (res) => {
        const ccp = cloneDeepWith(res.result);
        ccp.forEach((item, idx) => {
          delete item["service_category"];
          delete res.result[idx].service_category.commission;
          item = Object.assign(item, res.result[idx].service_category);
          item["expanded"] = true;
          if (!this.categories[item.breadcrumbs[0]]) {
            this.categories[item.breadcrumbs[0]] = [];
            this.categoryLabels.push(item.breadcrumbs[0]);
          }
          this.categories[item.breadcrumbs[0]].push(item);
        });
        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
      }
    );
  }

  updateCommission() {
    const keys = Object.keys(this.selectedCategories);
    const categories = cloneDeep(this.selectedCategories);
    const dataCommissions = new ProvidersCommissions();
    dataCommissions.provider_id = this.id;
    dataCommissions.categories = [];
    keys.forEach((ctg) => {
      if (categories[ctg].length) {
        dataCommissions.categories = dataCommissions.categories.concat(
          categories[ctg]
        );
      }
    });
    if (dataCommissions.categories.length) {
      dataCommissions.categories.forEach((item) => {
        delete item.children;
        delete item.parent;
      });
    }

    this.service.updateCategory(dataCommissions, this.id).subscribe((res) => {
      this.getProviderCommision();
    });
  }

  openModal() {
    this.ngxSmartModalService.getModal("editCountries").open();
  }

  closeModal() {
    this.ngxSmartModalService.getModal("editCountries").close();
    this.countryCodes = [];
    this.provider.countries.forEach((c) => {
      this.countryCodes.push(c.name);
    });
  }

  updateDeliveryCountries() {
    let codes;

    if (this.countryCodes) {
      codes = this.countries.filter((f) =>
        this.countryCodes.find((name) => name == f.name)
      );
      codes = codes.map((f) => f.country_code);
    } else {
      codes = [];
    }

    this.service
      .setCountryProviderStep3({ countries: codes }, this.id)
      .subscribe((res) => {
        this.getProvider();
        this.closeModal();
      });
  }

  setCategories(e, name) {
    if (e.length == 0 && this.selectedCategories[name]) {
      delete this.selectedCategories[name];
    } else {
      this.selectedCategories[name] = e;
    }
  }
}

import { finalize } from "rxjs/operators";
import { take } from "rxjs/operators";
import { Component, OnDestroy } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { ClientInvite } from "../../../../_models/client-invite";
import { AlertService } from "../../../../_services/alert.service";
import { ClientsService } from "../../../../_services/clients.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-client-invite",
  templateUrl: "./client-invite.component.html",
})
export class ClientInviteComponent implements OnDestroy {
  setModalOpen = true;
  isCanceled = false;
  isLoadingGeneral = false;
  clientInvite: ClientInvite = new ClientInvite();

  private _onDestroy = new Subject<void>();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private service: ClientsService,
    private alertService: AlertService
  ) {
    this.setModalOpen = false;
  }

  initEmpty(): void {
    this.clientInvite = new ClientInvite();
  }

  sendClientInvite() {
    this.isLoadingGeneral = true;

    this.service
      .inviteClient(this.clientInvite)
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe(
        (data) => {
          this.isModalClose();
          this.ngxSmartModalService.close("inviteClient");
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  isModalOpen() {
    this.setModalOpen = true;
    this.isCanceled = false;
  }

  isModalClose() {
    this.setModalOpen = false;
  }
  cancelInviteModal() {
    this.isModalClose();
    this.ngxSmartModalService.close("inviteClient");
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { HubspotDialogComponent } from "../../../components/dashboard/users/hubspot-dialog/hubspot-dialog.component";
import { Customer } from "../../../_models/customer";
import { EntityTypeEnum } from "../../shared/_enums/entity-type.enum";
import { CompanyFormComponent } from "../company-form/company-form.component";
import { EntityUserData } from "../models/entity-user.data";
import { EntityData } from "../models/entity.data";

@Component({
  selector: "app-company-info",
  templateUrl: "./company-info.component.html",
  styleUrls: ["./company-info.component.css"],
})
export class CompanyInfoComponent {
  @Input() entity: EntityData;
  @Input() customer: Customer;
  @Input() entityType: string;
  @Input() primaryAccount: EntityUserData;

  public entityTypes = EntityTypeEnum;

  constructor(private matDialog: MatDialog) {}

  viewSubscription() {
    window.open(
      environment.stripe_view_subscription_url +
        this.customer.subscription.stripe_subscription_id
    );
  }

  openEditModal() {
    const dialogRef = this.matDialog.open(CompanyFormComponent, {
      data: {
        entity: this.entity,
        entityType: this.entityType,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.entity = result.data.result;
      }
    });
  }

  openHubspotModal() {
    const dialogRef = this.matDialog.open(HubspotDialogComponent, {
      data: {
        entity: this.entity,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.entity) {
        this.entity = result.entity;
      }
    });
  }
}

export interface MessagesState {
  messagesNum: number;
}

export const messagesState: MessagesState = {
  messagesNum: 0,
};

export interface AppState {
  messages: MessagesState;
}

<!-- add expat form -->
<app-add-expat-form
  *ngIf="stage === 'newExpat'"
  [expat]="expat.data"
  (emitter)="addedExpat($event)"
></app-add-expat-form>
<!-- end add expat form -->
<!-- contact client/provider modal -->

<div class="custom-modal-wrapper" *ngIf="stage === 'contact'">
  <div class="modal-title-custom">
    {{ "GENERALS.ABOUT-THE" | translate }} {{ role }}
  </div>

  <!-- contact provider modal -->
  <app-user-details-page
    *ngIf="role === 'provider'"
    [entity]="entity"
  ></app-user-details-page>
  <!--  end contact provider modal -->

  <!-- contact expat modal -->
  <app-expat-details-page
    *ngIf="role === 'expat'"
    [entity]="entity"
  ></app-expat-details-page>
  <!--  end contact expat modal -->

  <div class="clear"></div>
  <div class="modal-footer-custom">
    <button class="btn gray-bg fl-l" (click)="onNoClick()">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button
      class="btn orange-bg fl-r"
      [mat-dialog-close]="yes"
      (click)="navigateTo()"
    >
      {{ "GENERALS.SEE-MORE" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>

<!-- end contact client/provider modal -->

import { Component, Input } from "@angular/core";
import { EntityUserData } from "../models/entity-user.data";

@Component({
  selector: "app-company-primary-account",
  templateUrl: "./company-primary-account.component.html",
  styleUrls: ["./company-primary-account.component.css"],
})
export class CompanyPrimaryAccountComponent {
  @Input() primaryAccount: EntityUserData;
}

<!-- edit unit price modal -->

<ngx-smart-modal
  id="editUnitPrice"
  #editUnitPrice
  identifier="editUnitPrice"
  [dismissable]="false"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "GENERALS.EDIT-UNIT-PRICE" | translate }}
    </div>
  </div>

  <!-- Modal body -->

  <div class="clear"></div>

  <div class="custom-modal-body">
    <div class="modal-form" *ngIf="item">
      <div class="row">
        <div class="form-group w-50">
          <label>{{ "GENERALS.UNIT-PRICE-CPC" | translate }} (€)</label>
          <input
            type="number"
            step="0.01"
            name="unitPriceCpc"
            class="form-control"
            [(ngModel)]="item.unit_price_cpc"
          />
        </div>

        <div class="form-group w-50">
          <label>{{ "GENERALS.UNIT-PRICE-CPM" | translate }} (€)</label>
          <input
            type="number"
            step="0.01"
            name="unitPriceCpm"
            class="form-control"
            [(ngModel)]="item.unit_price_cpm"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button type="button" class="btn gray-bg" (click)="closeEditUnitPrice()">
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button type="button" class="btn orange-bg" (click)="save(item)">
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</ngx-smart-modal>

<!-- end edit unit price modal -->

import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortDate",
})
@Injectable()
export class SortDatePipe implements PipeTransform {
  transform(array: Array<any>, args: string): Array<any> {
    if (typeof args[0] === "undefined") {
      return array;
    }
    const direction = args[0][0];
    const column = args.replace("-", "");

    array.sort((a: any, b: any) => {
      const left = Number(
        new Date(a[column].replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
      );
      const right = Number(
        new Date(b[column].replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
      );
      return direction === "-" ? right - left : left - right;
    });

    return array;
  }
}

export class ServiceOfferDetails {
  links: Array<any>;

  constructor(_id: number, filters) {
    this.links = [
      {
        link: "/service-offers/" + _id,
        filters: filters,
        label: "Details",
        page: "details",
      },
      {
        link: "/service-offers/" + _id + "/associated-offers",
        filters: filters,
        label: "Associated offers",
        page: "Associated offers",
      },
      {
        link: "/service-offers/" + _id + "/reviews-offers",
        filters: filters,
        label: "Reviews",
        page: "Reviews",
      },
      {
        link: "/service-offers/" + _id + "/history",
        filters: filters,
        label: "History",
        page: "History",
      },
    ];
  }
}

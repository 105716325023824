import { Injectable } from "@angular/core";
import { Plan } from "src/app/_models/plan";
import { ProductMapperService } from "./product-mapper.service";

@Injectable({
  providedIn: "root",
})
export class PlanMapperService {
  constructor(private readonly productMapper: ProductMapperService) {}

  mapOne(input: any): Plan {
    return {
      ...input,
      product: this.productMapper.mapOne(input.product),
    };
  }

  mapMany(input: any[]): Plan[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: any): Plan[] {
    return {
      ...input,
    };
  }
}

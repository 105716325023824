import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import cloneDeepWith from "lodash-es/cloneDeepWith";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { User } from "src/app/_models/user";
import { AdminService } from "src/app/_services/admins.service";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { UsersService } from "src/app/_services/users.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import deepCastToInt from "../../../../_helpers/deepCastToInt";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { AddUserFormComponent } from "../../users/add-user-form/add-user-form.component";

@Component({
  selector: "app-admins",
  templateUrl: "./admins.component.html",
})
export class AdminsComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy
{
  items = [];
  request_call;
  availableFilters;
  active = "";
  direction = "";
  isFiltersLoaded: boolean = false;
  isLoadingGeneral: boolean = false;
  isXs: boolean = false;
  newAdmin = { userType: "admin", new: true };

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private service: AdminService,
    public dictionariesService: DictionariesService,
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private userService: UsersService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.route.queryParams.subscribe((query) => {
      if (query && Object.keys(query).length > 0) {
        this.filters = cloneDeepWith(query, deepCastToInt);
      } else {
        this.clearFilters();
        const params = this.getParams(1);
        this.filters = params;
      }
      if (this.filters["search_text"]) {
        this.search_text = this.filters["search_text"];
      }
      if (this.filters["per_page"]) {
        this.perPage = this.filters["per_page"];
      }
      if (this.filters["order"]) {
        this.active = this.filters["order"];
        this.direction = this.filters["order_direction"];
      } else {
        this.active = "";
        this.direction = "";
      }

      this.getItems();
    });
    this.showBulkActions = false;
    this.setAvailableFilters();
    this.getStatusTransitions();
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/admins", "Admins")
    );

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    let url = "";
    if (id) {
      url = "/admins/" + id;
    } else {
      url = "/admins";
    }
    this.router.navigate([url], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoadingGeneral = true;
    let params = {};
    params = cloneDeep(this.filters);

    this.request_call = this.service
      .getAdmins(params)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        (error) => {
          if (error && error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  protected getService() {
    return this.userService;
  }

  public setAvailableFilters() {
    this.availableFilters = [
      {
        id: "status_id",
        label: "FILTERS.STATUS",
        service: this.dictionariesService,
        method: "getUserStatuses",
      },
    ];
  }

  public getStatusTransitions() {
    this.userService
      .getStatusTransitions()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data.success) {
          this.actions = data.result;
        }
      });
  }

  openModal() {
    this.dialog
      .open(AddUserFormComponent, {
        data: {
          user: this.newAdmin,
          edit: false,
          userType: "admin",
          about: "user",
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user?: User) => {
        if (user) {
          this.addAdminUser();
        } else {
          this.cancelEditModal();
        }
      });
  }

  addAdminUser() {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.request_call = this.service.addAdmin(this.newAdmin).subscribe(
      (data) => {
        this.cancelEditModal();
        this.getItems(1);
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  cancelEditModal() {
    this.newAdmin = { userType: "admin", new: true };
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

import { Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { combineLatest, Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { ActionEnum } from "src/app/_enums/action-enum";
import { User } from "src/app/_models/user";
import { AlertService } from "src/app/_services/alert.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { UsersService } from "src/app/_services/users.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { AddUserFormComponent } from "../add-user-form/add-user-form.component";

@Component({
  selector: "app-company-user-details-page",
  templateUrl: "./company-user-details.component.html",
})
export class CompanyUserDetailsComponent
  extends ItemsListComponent
  implements OnDestroy
{
  isLoadingGeneral: boolean = false;
  user: User;
  userID;
  entityID;
  id;
  userType: string;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private service: UsersService,
    public providersService: ProvidersService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    private breadCrumbService: BreadcrumbService,
    private authService: AuthenticationService,
    private translate: TranslateService,
    public dictionariesService: DictionariesService
  ) {
    super();
    combineLatest(this.route.params, this.route.queryParams)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([params, query]) => {
        if (params.id) {
          this.userID = params.id;
        }
        if (query) {
          this.entityID = query.entity_id;
        }
        this.isLoadingGeneral = true;

        this.getItems();
      });
    this.getStatusTransitions();
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  getItems() {
    this.service
      .getUserDetails(this.userID)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe({
        next: (res) => {
          this.user = res.result;
          let userRole = "";
          let entityType = "";
          switch (this.user.role) {
            case "client":
              userRole = "clients";
              entityType = "client Details";
              break;
            case "provider":
              userRole = "providers";
              entityType = "provider Details";
              break;
          }
          this.breadCrumbService.changeBreadcrumbs(
            this.breadCrumbService.setForUser(
              this.user,
              userRole,
              entityType,
              "details"
            )
          );

          this.userType = res.result.role;

          if (res.messages) {
            this.alertService.success(res.messages);
          }
        },
        error: (error) => {
          if (error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  protected getService() {
    return this.service;
  }

  public getStatusTransitions() {
    this.service
      .getStatusTransitions()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data.success) {
          this.actions = data.result;
        }
      });
  }

  openModal() {
    this.dialog
      .open(AddUserFormComponent, {
        data: {
          user: this.user,
          edit: true,
          about: "user",
          userType: this.userType,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: User) => {
        if (user) {
          console.log(user);

          this.updateUserInfo(user);
        }
      });
  }

  updateUserInfo(user) {
    user.user_id = user.id;
    this.service.updateUserDetails(user).subscribe(
      (res) => {
        this.user = res.result;
        console.warn(res);
      },

      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoadingGeneral = false;
      }
    );
    // Update API call
  }

  resetPassword() {
    const email = this.user.email;
    this.authService.sendResetPasswordEmail(email).subscribe(
      (res) => {
        if (res && res.message) {
          this.alertService.success(res.message);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  resendConfirmationMail() {
    this.authService.resendConfirmationMail(this.userID).subscribe(
      (data) => {
        this.translate
          .get("GENERALS.MAIL-WAS-SENT-SUCCESS")
          .subscribe((data) => {
            this.alertService.success(data);
          });
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  handleEvent(e) {
    this.getItems();
  }

  openPicModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").open();
  }

  makePrimry(id) {
    this.authService.makePrimry(id).subscribe(
      (data) => {
        this.getItems();
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  showAction(actionId: number): boolean {
    if (
      !(actionId === ActionEnum.SUSPENDED || actionId === ActionEnum.DEACTIVATE)
    ) {
      return true;
    }

    if (!this.user.is_primary) {
      return true;
    }

    return false;
  }
}

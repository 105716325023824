<app-loader-spinner [isLoading]="isLoading"></app-loader-spinner>

<div class="auth-form-wrapper">
  <form name="form" class="auth-form" (ngSubmit)="resetPassword()">
    <div class="auth-form-title-wrapper">
      <div class="auth-form-title">
        {{ "AUTH.SET-PASSWORD" | translate }}
      </div>
    </div>
    <div [formGroup]="passwordFormGroup">
      <input
        type="password"
        name="password"
        class="form-control"
        placeholder="{{ 'LABEL.PASSWORD' | translate }}"
        required
        formControlName="password"
      />
      <input
        type="password"
        name="password-control"
        class="form-control"
        placeholder="{{ 'LABEL.CONFIRM-PASSWORD' | translate }}"
        [(ngModel)]="reset.password"
        required
        formControlName="repeatPassword"
      />
      <span
        class="form-err"
        *ngIf="passwordFormGroup.errors?.doesMatchPassword"
        >{{ "FORMS.PASSWORD_MISMATCH" | translate }}</span
      >
    </div>
    <button
      [disabled]="isLoading || !passwordFormGroup.valid"
      class="btn orange-bg"
    >
      {{ "GENERALS.DONE" | translate }}
    </button>
  </form>
</div>

<ng-container *ngIf="isLoadingGeneral">
  <div class="loading-wrapper">
    <div class="w-100">
      <app-loader-spinner-general
        [loadingType]="LoadingType.INPUT"
      ></app-loader-spinner-general>
    </div>
    <div class="w-100">
      <app-loader-spinner-general
        [loadingType]="LoadingType.INPUT"
      ></app-loader-spinner-general>
    </div>
    <div class="w-100">
      <app-loader-spinner-general
        [loadingType]="LoadingType.INPUT"
      ></app-loader-spinner-general>
    </div>
    <app-loader-spinner-general
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
  </div>
</ng-container>

<div
  class="details-body-wrapper"
  *ngIf="!isLoadingGeneral && categories && !edit"
>
  <!-- 1 col details -->
  <div class="details-1c">
    <div class="white-box-wrapper">
      <div class="white-box-row">
        <div class="white-box-header">
          <div class="medium-title fl-l">
            {{ "GENERALS.SERVICE-CATEGORIES-COMMISSIONS" | translate }}
          </div>
          <div class="white-box-header-action fl-r" (click)="edit = true">
            {{ "COMMISSION.EDIT-CATEGORY-COUNTRY" | translate }}
            <i class="icon-cog-outline"></i>
          </div>
        </div>
        <div class="clear"></div>
        <div class="white-box-body">
          <app-provider-categories-tree
            [categories]="categories"
            (onchange)="handleEvent($event)"
          ></app-provider-categories-tree>
        </div>
      </div>

      <div class="white-box-row">
        <div class="white-box-header">
          <div class="medium-title fl-l">
            {{ "GENERALS.COUNTRIES-WHERE-DELIVER" | translate }}
          </div>
        </div>
        <div class="clear"></div>
        <div class="white-box-body" *ngIf="provider">
          <div class="row">
            <div class="w-100">
              <ng-container
                *ngFor="let item of provider.countries; let isLast = last"
              >
                <span> {{ item.name }}{{ isLast ? "" : ", " }} </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="white-box-row">
        <div class="white-box-header">
          <div class="medium-title fl-l">
            {{ "GENERALS.INVOICE_DUE_DAYS" | translate }}
          </div>
        </div>
        <div class="clear"></div>
        <div class="white-box-body" *ngIf="provider">
          <div class="row">
            <div *ngIf="provider.invoice_due_days !== 1" class="w-100">
              {{
                "GENERALS.INVOICE_DUE_DAYS_NOTICE"
                  | translate : { days: provider.invoice_due_days }
              }}
            </div>
            <div *ngIf="provider.invoice_due_days === 1" class="w-100">
              {{
                "GENERALS.INVOICE_DUE_DAY_NOTICE"
                  | translate : { days: provider.invoice_due_days }
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end 1 col details -->
</div>

<div class="details-body-wrapper" *ngIf="categories && edit && allCategories">
  <!-- 1 col details -->
  <div class="details-1c">
    <div class="white-box-wrapper">
      <div class="white-box-row">
        <div class="white-box-header">
          <div class="medium-title fl-l">
            {{ "COMMISSION.EDIT-CATEGORIES-COMMISSIONS" | translate }}
          </div>
          <div class="white-box-header-action fl-r" (click)="edit = false">
            {{ "GENERALS.CANCEL" | translate }}
          </div>
        </div>
        <div class="clear"></div>
        <div class="white-box-body">
          <app-add-categories
            [serviceCategories]="allCategories"
            [selectedServiceCategories]="categories"
            [level]="0"
            (onselect)="updateCategorySelection($event)"
            [providerId]="id"
          ></app-add-categories>
        </div>
      </div>

      <div class="white-box-row">
        <div class="white-box-header">
          <div class="medium-title fl-l">
            {{ "GENERALS.COUNTRIES-WHERE-DELIVER" | translate }}
          </div>
        </div>
        <div class="clear"></div>
        <div class="white-box-body" *ngIf="provider">
          <div class="row">
            <div class="w-100">
              <app-chips-select
                *ngIf="selectedCountries"
                [items]="countries"
                [multiple]="true"
                [placeholder]="'GENERALS.COUNTRIES-CHIPS-PLACEHOLDER'"
                [bindValue]="'name'"
                [bindLabel]="'name'"
                [preselectedItems]="selectedCountries"
                (onselect)="selectedCountries = $event"
              ></app-chips-select>
            </div>
          </div>
        </div>
      </div>
      <div class="white-box-row">
        <div class="white-box-header">
          <div class="medium-title fl-l">
            {{ "GENERALS.INVOICE_DUE_DAYS" | translate }}
          </div>
        </div>
        <div class="clear"></div>
        <div class="white-box-body p-b-0" *ngIf="provider">
          <div class="row">
            <div class="form-group w-100">
              <label>{{ "GENERALS.INVOICE_DUE_DAY_NO" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="w20">
          <input
            class="form-control w-10"
            type="number"
            name="invoice_due_days"
            [(ngModel)]="provider.invoice_due_days"
          />
        </div>
      </div>
      <div class="clear"></div>

      <div class="modal-footer">
        <button type="button" class="btn gray-bg" (click)="edit = false">
          {{ "GENERALS.CANCEL" | translate }}
        </button>
        <button
          type="button"
          class="btn orange-bg"
          (click)="setCountriesAndCategories()"
        >
          {{ "GENERALS.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
  <!-- end 1 col details -->
</div>

<ngx-smart-modal
  id="editCommissions"
  #editCommissions
  identifier="editCommissions"
  [dismissable]="false"
>
  <app-add-custom-commission
    [category]="selectedCategory"
    [commission]="selectedCommission"
    [provider]="provider"
    (save)="showCommission($event)"
    (closeCommission)="editCommissions.close()"
  ></app-add-custom-commission>
</ngx-smart-modal>

import { Provider } from "./provider";
import { ServiceCategory } from "./servicecategory";
import { ProviderCommissionEnum } from "../_enums/provider-commission-enum";

export class ProviderCommission {
  id: number;
  commission: number;
  commission_type: CommissionType;
  start_date: Date;
  end_date: Date;
  provider: Provider;
  service_category: ServiceCategory;
}

export class CommissionType {
  static elements = [
    { id: ProviderCommissionEnum.fixed, label: "Fixed" },
    { id: ProviderCommissionEnum.percent, label: "Percent" },
  ];
  id: number;
  name: string;
}

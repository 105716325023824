import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { OrdersService } from "../../../../_services/orders.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-order-invoices",
  templateUrl: "./order-invoices.component.html",
  styleUrls: ["./order-invoices.component.css"],
})
export class OrderInvoicesComponent {
  public id: number;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrdersService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.getOrderByIdIdentityNumber(params.id);
      }
    });
  }

  getOrderByIdIdentityNumber(identity_number) {
    const params = {};
    this.orderService
      .getOrderByIdentityNumber(params, identity_number)
      .subscribe((res) => {
        if (res && res.result) {
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForOrder(res.result, "invoices")
          );
          this.id = res.result.id;
        }
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

<div class="phone-wrapper">
  <app-loader-spinner-general
    *ngIf="isLoadingGeneral"
    [loadingType]="LoadingType.INPUT"
  ></app-loader-spinner-general>
  <mat-form-field
    *ngIf="!isLoadingGeneral"
    class="w-100 p-0"
    appearance="outline"
  >
    <mat-label>{{ "GENERALS.SELECT-PHONE-CODE" | translate }} *</mat-label>
    <mat-select
      [formControl]="control"
      (selectionChange)="onSelectChange($event)"
      [(ngModel)]="selectedCountry"
      disableOptionCentering
      panelClass="country-panel"
      [compareWith]="preselectCountries"
    >
      <mat-option>
        <ngx-mat-select-search
          [placeholderLabel]="'LABEL.COUNTRY' | translate"
          [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
          [formControl]="countrySearchControl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let country of filteredCountries | async"
        [value]="country.phone_prefix"
      >
        {{ country.phone_prefix_label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

export class Tax {
  country_code: string;
  tax_type: string;
  value: string;
}

export class TaxType {
  label: string;
  value: string;
}

export class CountryTax {
  name: string;
  alpha2: string;
  alpha3: string;
  slug: string;
  prefix: string;
  taxes: Tax[];
}

<ng-container *ngIf="formdata">
  <ng-container *ngFor="let characteristic of formdata">
    <div
      *ngIf="characteristic.enabled"
      class="white-box-row characteristic-row border-b"
    >
      <div class="white-box-body">
        <div class="row">
          <div class="form-group-row w-100">
            <label class="characteristic-left"
              >{{ characteristic.name }}
              <span *ngIf="characteristic.required">*</span>
              <!-- <span appHoverHelper class="input-helper"><i class="icon-help_comp"></i>
                <span class="helper-description"></span> </span> -->
            </label>

            <ng-container
              *ngFor="let field of characteristic.fields; let i = index"
              [ngSwitch]="field.type"
            >
              <ng-container *ngSwitchCase="'text'">
                <div class="characteristic-right fx-center-end">
                  <input
                    type="text"
                    name="field.data"
                    class="form-control"
                    [(ngModel)]="field.data"
                  />
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'checkbox'">
                <div class="characteristic-right">
                  <input
                    class="checkbox-switch"
                    [(ngModel)]="field.data"
                    type="checkbox"
                    name="field.data"
                  />
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'number'">
                <div class="characteristic-right">
                  <input
                    class="form-control"
                    [(ngModel)]="field.data"
                    type="number"
                    [min]="0"
                    (keypress)="validateNumericalInput($event)"
                    name="field.data"
                  />
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'select'">
                <div class="characteristic-right fx-center-end">
                  <ng-container
                    *ngIf="
                      field.options || false;
                      then selectOptions;
                      else selectNoOptions
                    "
                  ></ng-container>
                  <ng-template #selectOptions>
                    <ng-select
                      class="customiz-select"
                      [items]="field.options"
                      placeholder="{{ 'GENERALS.SELECT-OPTION' | translate }}"
                      [(ngModel)]="field.data"
                      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                    >
                    </ng-select>
                  </ng-template>
                  <ng-template #selectNoOptions>
                    <ng-container [ngSwitch]="getFieldSelectType(field)">
                      <!--                      <ng-template *ngSwitchCase="'country'">-->
                      <ng-select
                        *ngSwitchCase="'country'"
                        class="customiz-select"
                        [items]="countries"
                        bindLabel="name"
                        bindValue="country_code"
                        placeholder="{{
                          'GENERALS.SELECT-COUNTRY' | translate
                        }}"
                        [(ngModel)]="field.data"
                        notFoundText="{{
                          'GENERALS.NO-ITEMS-FOUND' | translate
                        }}"
                      >
                      </ng-select>
                      <!--                      </ng-template>-->
                      <!--                      <ng-template *ngSwitchCase="'unit'">-->
                      <ng-select
                        *ngSwitchCase="'unit'"
                        class="customiz-select"
                        [items]="timeUnit"
                        bindLabel="unit"
                        bindValue="unit"
                        placeholder="{{ 'GENERALS.SELECT-UNIT' | translate }}"
                        [(ngModel)]="field.data"
                        notFoundText="{{
                          'GENERALS.NO-ITEMS-FOUND' | translate
                        }}"
                      >
                      </ng-select>
                      <!--                      </ng-template>-->
                      <!--                      <ng-template *ngSwitchCase="'language'">-->
                      <ng-select
                        *ngSwitchCase="'language'"
                        class="customiz-select"
                        [items]="countries"
                        bindLabel="name"
                        bindValue="name"
                        placeholder="{{ 'GENERALS.SELECT-OPTION' | translate }}"
                        [(ngModel)]="field.data"
                        notFoundText="{{
                          'GENERALS.NO-ITEMS-FOUND' | translate
                        }}"
                      >
                      </ng-select>
                      <!--                      </ng-template>-->
                    </ng-container>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

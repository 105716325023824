<div class="row">
  <div class="form-group w-100">
    <label>{{ "LABEL.COUNTRIES-CHIPS" | translate }}</label>
    <div #chipsSelect class="chihp-select-wrapper">
      <ng-select
        class="customiz-select chips-select"
        [items]="items"
        [multiple]="multiple"
        [hideSelected]="hideSelected"
        bindLabel="{{ bindLabel }}"
        bindValue="{{ bindValue }}"
        placeholder="{{ placeholder | translate }}"
        [(ngModel)]="selectedItems"
        notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        (focus)="hidePlaceholder()"
        (change)="emitChanges()"
      >
      </ng-select>
      <div *ngFor="let item of selectedItems; let k = index">
        <div class="customa-chips">
          {{ item }}
          <div class="remove-chips" (click)="removeChips(k)">
            <i class="icon-cancel-squared"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

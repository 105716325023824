import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ActivityService {
  constructor(private http: HttpClient) {}

  // GET activity
  list(model, modelId, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + model + "/" + modelId + "/activity",
      { params }
    );
  }
}

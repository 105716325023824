<app-loader-spinner [isLoading]="isLoading"></app-loader-spinner>

<div class="auth-form-wrapper">
  <form name="form" class="auth-form" (ngSubmit)="sendEmail()" #f="ngForm">
    <div class="auth-form-title-wrapper">
      <div class="auth-form-title">
        {{ "AUTH.FORGOT-PASSWORD" | translate }}
      </div>
    </div>
    <div>
      <p class="forgot-pass">
        {{ "AUTH.FORGOT-PASSWORD-MESSAGE" | translate }}
      </p>
    </div>
    <div>
      <input
        class="form-control"
        name="email"
        [(ngModel)]="userEmail"
        required
        placeholder="{{ 'LABEL.EMAIL' | translate }}"
      />
    </div>
    <button class="btn orange-bg">
      {{ "GENERALS.SEND" | translate }}
    </button>
  </form>
</div>

<div class="auth-footer login-footer">
  <p>
    {{ "AUTH.FOOTER-SING-IN-LINK" | translate }}
    <span class="action-link-orange" [routerLink]="['/login']">{{
      "AUTH.SIGN-IN" | translate
    }}</span
    >!
  </p>
</div>

import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClientsService } from "src/app/_services/clients.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";

@Component({
  selector: "app-history",
  templateUrl: "./client-activity.component.html",
  styleUrls: ["./client-activity.component.css"],
})
export class ClientActivityComponent implements OnInit {
  public id;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private clientsService: ClientsService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  ngOnInit() {
    this.clientsService.getClient(this.id).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForClient(data.result, "history")
      );
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { DocumentsService } from "src/app/_services/documents.service";
import { ProvidersService } from "../../../../_services/providers.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-user-agreements",
  templateUrl: "./user-agreements.component.html",
  styleUrls: ["./user-agreements.component.css"],
})
export class UserAgreementsComponent implements OnInit {
  public id: number;
  isLoadingGeneral = false;
  isXs: boolean;
  items;

  constructor(
    private route: ActivatedRoute,
    private windowResizeHelper: WindowResizeHelperService,
    protected service: DocumentsService,
    private serviceProvider: ProvidersService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.serviceProvider.getProvider(this.id).subscribe((data) => {
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForProvider(data.result, "Documents")
          );
        });
        this.getUserAgreements();
      }
    });
  }

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  getUserAgreements() {
    this.isLoadingGeneral = true;
    this.service.getUserAgreements(this.id).subscribe((data) => {
      this.items = data.result;
      this.isLoadingGeneral = false;
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

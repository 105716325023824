export enum OrderStatusEnum {
  new = 1,
  inProgress = 2,
  onHold = 3,
  cancelledByClient = 4,
  cancelledByProvider = 5,
  refund = 6,
  finalized = 7,
  awaitingPayment = 8,
  onHolByClient = 10,
  onHoldByProvider = 9,
  accepted = 11,
}

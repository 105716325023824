export class User {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  company_position: string;
  phone_prefix: string;
  phone: string;
  status: string;
  is_primary: boolean;
  profile_image: string;
  last_login: string;
  created_at: string;
  updated_at: string;
  role: string;
  role_id: number;
  permissions: Array<any>;
  company_name: string;
  username: string;
  password: string;
  entity_type_id = 0;
  company_legal_name: string;
  stripe_account: any;
}

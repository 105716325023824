export class ProviderDetails {
  links: Array<any>;

  constructor(_id: number, filters) {
    this.links = [
      {
        link: "/providers/" + _id,
        filters: filters,
        label: "Details",
        page: "details",
      },
      {
        link: "/providers/" + _id + "/users",
        filters: filters,
        label: "Users",
        page: "users",
      },
      {
        link: "/providers/" + _id + "/categories",
        filters: filters,
        label: "Categories",
        page: "categories",
      },
      // {
      //   link: '/providers/' + _id + '/commission',
      //   label: 'Commission',
      //   page: 'commission'
      // },
      {
        link: "/providers/" + _id + "/offers",
        filters: filters,
        label: "Offers",
        page: "offers",
      },
      {
        link: "/providers/" + _id + "/orders",
        filters: filters,
        label: "Orders",
        page: "orders",
      },
      {
        link: "/providers/" + _id + "/agreements",
        filters: {},
        label: "Agreements",
        page: "agreements",
      },
      {
        link: "/providers/" + _id + "/docs",
        filters: filters,
        label: "Docs",
        page: "docs",
      },

      {
        link: "/providers/" + _id + "/history",
        filters: filters,
        label: "History",
        page: "history",
      },
    ];
  }
}

import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "src/app/_services/alert.service";
import { ServiceOffersService } from "src/app/_services/serviceoffers.service";
import { environment } from "src/environments/environment";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { ConfirmActionModalComponent } from "../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";

@Component({
  selector: "app-offer-history",
  templateUrl: "./offer-history.component.html",
  styleUrls: ["./offer-history.component.css"],
})
export class OfferHistoryComponent {
  offer;
  isLoading;
  bundle;
  id;
  actions;
  offerHistory;
  total;
  itemsPage;
  p;

  constructor(
    private serviceOffers: ServiceOffersService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private alertService: AlertService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getServiceOfferById(params.id);
        this.getStatusTransitions();
        this.getOfferHistory(1, this.id);
      }
    });
  }

  getServiceOfferById(id) {
    this.isLoading = true;
    this.serviceOffers.getServiceOfferById(id).subscribe((data) => {
      this.offer = data.result;
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForOffer(this.offer, "history")
      );
      if (data.result.bundle_id) {
        this.serviceOffers
          .getBundleServiceOffersById(data.result.bundle_id)
          .subscribe((res) => {
            this.bundle = res.result;
            this.isLoading = false;
          });
      }
      this.isLoading = false;
    });
  }

  getOfferHistory(page: number, id) {
    const params = {};
    params["page"] = page;

    this.serviceOffers.getOfferHistory(id, params).subscribe((data) => {
      this.offerHistory = data.result.items;
      this.total = data.result.total;
      this.p = data.result.currentPage;
      this.itemsPage = data.result.perPage;
      this.isLoading = false;
    });
  }

  public getStatusTransitions() {
    this.serviceOffers.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  copyOffer(offerId) {
    this.serviceOffers.copyOffer(offerId).subscribe(
      (data) => {
        this.isLoading = false;
        let url = "";
        url = "/service-offers/" + data.result.id + "/edit";
        this.router.navigate([url], {
          queryParams: { ...{ copy: 1 } },
        });
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  goToMarketplaceOffer(id) {
    const marketplaceUrlPathArray = environment.marketplace.split("/");
    const protocol = marketplaceUrlPathArray[0];
    const host = marketplaceUrlPathArray[2];
    const goToMarketplaceUrl = protocol + "//" + host;
    const goToOfferUrl = goToMarketplaceUrl + "/offers/" + id;

    window.open(goToOfferUrl, "_blank");
  }

  updateStatus() {
    if (this.offer.active === true) {
      this.openConfirmAction({ id: 6 }); // published and active
    } else {
      this.openConfirmAction({ id: 7 }); // published and inactive
    }
  }

  openConfirmAction(action) {
    const ids = [this.id];

    if (!action.needs_confirmation && !action.needs_reason && action.text) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.serviceOffers.updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.getServiceOfferById(this.id);
              this.translate
                .get("GENERALS.STATUS-CHANGED")
                .subscribe((statusChangedTranslation) => {
                  this.alertService.success(statusChangedTranslation);
                });
            },
            (error) => {
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this.serviceOffers.updateStatusBulk(action.id, ids, null).subscribe(
        (data) => {
          this.getServiceOfferById(this.id);
          this.translate
            .get("GENERALS.STATUS-CHANGED")
            .subscribe((statusChangedTranslation) => {
              this.alertService.success(statusChangedTranslation);
            });
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
    }
  }
}

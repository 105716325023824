import { Pipe, PipeTransform } from "@angular/core";
import { WorkflowStatus } from "../_models/workflow-status";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "getWorkflowStatus",
})
export class GetWorkflowStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(status: WorkflowStatus): string {
    switch (status) {
      case WorkflowStatus.DRAFT:
      case WorkflowStatus.MISSING:
        return this.translateService.instant("GENERALS.NO");
      case WorkflowStatus.PUBLISHED:
        return this.translateService.instant("GENERALS.YES");
    }
    return null;
  }
}

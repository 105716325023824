import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RFQSService } from "src/app/_services/rfqs.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ItemsListComponent } from "../../dashboard/items-list/items-list.component";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-rfq-history",
  templateUrl: "./rfq-history.component.html",
})
export class RFQHistoryComponent extends ItemsListComponent implements OnInit {
  id;
  constructor(
    private route: ActivatedRoute,
    private rfqService: RFQSService,
    public ngxSmartModalService: NgxSmartModalService,
    private service: RFQSService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.id = this.route.snapshot.parent.params.id;
    this.getItems();
  }
  ngOnInit() {
    this.service.getRFQById(this.id).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForRFQ(data.result, "history")
      );
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = this.getParams(page);

    this.isLoadingGeneral = true;
    this.request_call = this.rfqService
      .getRFQHistory(this.id, params)
      .subscribe((data) => {
        this.processResponse(data);
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

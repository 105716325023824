import { Component, EventEmitter, Output } from "@angular/core";
import { ResetPasswordConfirmation } from "src/app/_models/reset-password-conf";

@Component({
  selector: "app-reset-pass-modal",
  templateUrl: "./reset-pass-modal.component.html",
})
export class ResetPassModalComponent {
  pass: ResetPasswordConfirmation;

  @Output() changePass: EventEmitter<any> = new EventEmitter();
  @Output() inputModel: EventEmitter<any> = new EventEmitter();

  disabled = false;

  constructor() {
    this.pass = new ResetPasswordConfirmation();
  }

  initEmpty(): void {
    this.pass = new ResetPasswordConfirmation();
  }

  emitChanges(type) {
    this.disabled = true;
    setTimeout(() => {
      this.disabled = false;
    }, 2000);
    this.changePass.emit(type);

    if (type === "save_reset_pass") {
      this.inputModel.emit(this.pass);
    }
  }
}

import { Component } from "@angular/core";

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
})
export class DashboardLayoutComponent {
  innerWidth;
  xs;

  constructor() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }
  }

  onResize() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }
  }
}

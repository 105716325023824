<div class="elem-page-wrapper">
  <div class="select-wrapper">
    <ng-select
      class="customiz-select white-select no-clearable-select"
      placeholder="{{ 'GENERALS.SELECT' | translate }}"
      [(ngModel)]="per_page"
      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
      [clearable]="false"
      [searchable]="false"
      (change)="getItem.emit(per_page)"
    >
      <ng-option *ngFor="let option of _perPageValues" [value]="option.key"
        >{{ option.key }} {{ option.label }}</ng-option
      >
    </ng-select>
  </div>
</div>
